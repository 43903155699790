import { css } from 'styled-components';
import { getScrollbarWidth } from '../utils';

/** More class names can be added as needed */
export const enum PortalClassName {
  ModalContainer = 'portal__modal-container',
}

export const BodyModalClass = 'modal-is-open';

const portal = css`
  :root {
    --scrollbar-width: ${getScrollbarWidth()}px;
  }

  .${PortalClassName.ModalContainer} {
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
  }

  // Hides scrollbar using the scrollbar width variable to add an equal amount of padding
  // to the right, preventing the UI from jumping sideways when the scrollbar is removed.
  .${BodyModalClass} {
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    overscroll-behavior: none;
    padding-right: var(--scrollbar-width);
  }
`;

export default portal;
