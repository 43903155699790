import React from 'react';
import { zip } from 'lodash';
import { CardProps, CardType } from '../components/common/card/Card';
import { ContentfulCardTypes } from '../contentful/sections/types';
import { MappedContentfulCategory } from './useContentfulCategoryData';
import useContentfulTransformedCardGridData from './useContentfulTransformedCardGridData';

// Zip cards by categories and return list of cards
const zipCardsByCategory = (
  cards: CardProps<CardType.Default>[],
  categories: MappedContentfulCategory[],
) => {
  const zippedIdList = zip(...categories.map((i) => i.content))
    .flat()
    .filter((i) => i !== undefined);

  return zippedIdList.map((id) => cards.find((card) => card.id === id));
};

const useCategorizedCards = (
  categories: MappedContentfulCategory[],
  cards: ContentfulCardTypes[],
  enableUnsetCategory = false,
  limitCount: number | undefined,
) => {
  const [currentCategory, setCurrentCategory] = React.useState<
    MappedContentfulCategory | undefined
  >(enableUnsetCategory ? undefined : categories[0]);

  const transformedCards = useContentfulTransformedCardGridData({
    cards,
    type: CardType.Default,
  });

  const cardGridData = React.useMemo(() => {
    return currentCategory === undefined
      ? zipCardsByCategory(transformedCards, categories)
      : transformedCards.filter(
          (card) =>
            card.id &&
            categories[categories.indexOf(currentCategory)].content.includes(
              card.id,
            ),
        );
  }, [transformedCards, categories, currentCategory]);

  const onSelect = React.useCallback(
    (category: MappedContentfulCategory | undefined) => {
      setCurrentCategory(category);
    },
    [],
  );

  return {
    currentCategory,
    cardGridData: limitCount ? cardGridData.slice(0, limitCount) : cardGridData,
    onSelect,
  };
};

export default useCategorizedCards;
