import { graphql, useStaticQuery } from 'gatsby';
import useLocale from '../localization/useLocale';

export type ContentfulRoutes = {
  slug: string;
  pageId: string;
  label: string;
  hiddenInMainNav: boolean;
  hideFromLocalization: boolean;
  type: string;
  subpages?: ContentfulRoutes[];
};

const query = graphql`
  #fragment for creating routeTree from pages for navigation
  fragment route on ContentfulPage {
    slug
    pageId: contentful_id
    hiddenInMainNav
    label: navigation_label
    hideFromLocalization
    type
    subpages {
      slug
      pageId: contentful_id
      hiddenInMainNav
      label: navigation_label
      hideFromLocalization
      type
      subpages {
        slug
        pageId: contentful_id
        hiddenInMainNav
        label: navigation_label
        hideFromLocalization
        type
        subpages {
          slug
          pageId: contentful_id
          hiddenInMainNav
          label: navigation_label
          hideFromLocalization
          type
          subpages {
            slug
            pageId: contentful_id
            hiddenInMainNav
            label: navigation_label
            hideFromLocalization
            type
          }
        }
      }
    }
  }
  # get localized routeTree with created fragment
  query RouteQuery {
    fi: contentfulSite(
      contentful_id: { eq: "1guLuNt5b3mO9jB13rrnfR" }
      node_locale: { eq: "fi" }
    ) {
      node_locale
      frontpage {
        ...route
      }
    }
    en: contentfulSite(
      contentful_id: { eq: "1guLuNt5b3mO9jB13rrnfR" }
      node_locale: { eq: "en" }
    ) {
      node_locale
      frontpage {
        ...route
      }
    }
  }
`;

// Finds page from route structure and returns it
const findPage = (
  id: string,
  pages: ContentfulRoutes
): ContentfulRoutes | undefined => {
  for (const topLevel of pages.subpages || []) {
    if (topLevel.pageId === id) {
      return topLevel;
    } else {
      const page = findPage(id, topLevel);

      if (page) {
        return page;
      }
    }
  }
};

const useContentfulNavigationData = (
  pageId?: string,
): ContentfulRoutes | undefined => {
  const locale = useLocale();
  const siteData = useStaticQuery(query);
  const localizedSiteData = siteData[locale].frontpage;

  if (!pageId) {
    // return whole page structure
    return localizedSiteData;
  }

  const pageData = findPage(pageId, localizedSiteData);
  if (!pageData) {
    console.warn(`Page ${pageId} was not found from routeTree`);
  }
  return pageData;
};

export default useContentfulNavigationData;
