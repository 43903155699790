import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import { hover } from '../../../styles/utils';
import transitions from '../../../styles/transitions';

const Carousel = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;

  ${breakpoints.sm} {
    grid-column-start: 2;
    grid-column-end: -2;
  }

  ${breakpoints.md} {
    grid-column-start: 3;
    grid-column-end: -3;
  }
`;

const CarouselTrack = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: var(--gutter);

  transform: translate3d(0, 0, 0);

  ${transitions.CarouselTrack}
`;

const CarouselImage = styled.figure`
  width: 100%;
  flex-shrink: 0;
  position: relative;

  opacity: 0.3;

  &.active {
    opacity: 1;
  }

  ${transitions.CarouselItem}
`;

const CarouselActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 0.5rem;

  > button {
    padding: 0;
    color: ${AppColor.LeviBlue};

    ${hover} {
      cursor: pointer;
      figure {
        transform: translate3d(0, 0, 0);
        ${transitions.ArrowLinkHover}
      }
      &:first-of-type {
        &:hover {
          figure {
            transform: translate3d(-0.5rem, 0, 0);
          }
        }
      }
      &:last-of-type {
        &:hover {
          figure {
            transform: translate3d(0.5rem, 0, 0);
          }
        }
      }
    }
  }
`;

export default {
  Carousel,
  CarouselTrack,
  CarouselImage,
  CarouselActions,
};
