import {
  subDays,
  areIntervalsOverlapping,
  isDate,
  isWithinInterval,
  isAfter,
} from 'date-fns';
import { BookingStatus } from '../components/common/card/types';
import { NumRange } from '../contentful/templates/serviceListPages/searchAccommodationsPage';
import {
  DateRange,
  DateRangeValid,
  differenceInFullDays,
  isDateRange,
} from '../utils/date-utils';
import matchesProperty from 'lodash/matchesProperty';

export type MinMax = {
  min: number;
  max: number;
};

export type ValueOperatorPair = {
  value: number;
  operator: string;
};

export type FilterFunc = <T extends Record<string, unknown>>(
  item: T,
) => boolean;
type Filterable =
  | boolean
  | string
  | string[]
  | number
  | number[]
  | NumRange
  | MinMax[]
  | Date
  | DateRange
  | DateRangeValid
  | ValueOperatorPair[];

type Filter<U extends Filterable = string, V extends Filterable = U> = (
  propertyKey: string,
  valueToCompare: V,
  ...args: unknown[]
) => <T extends Record<string, unknown>>(item: T) => boolean;

export type FilteredDataInput<T> = {
  data: T[];
  filters: FilterFunc[];
};

export type FilteredDataOutput<T> = {
  filteredData: T[];
};

const hasOwnProperty = <X extends Record<string, unknown>, Y extends string>(
  obj: X,
  string: Y,
): obj is X & Record<Y, unknown> => obj.hasOwnProperty(string);

const isString = (value: unknown): value is string => typeof value === 'string';
const isNumber = (value: unknown): value is number => typeof value === 'number';
const isArray = <T>(value: unknown): value is Array<T> =>
  value instanceof Array;

export const isInsideTimeInterval: Filter<
  Date | DateRangeValid,
  DateRangeValid
> = (propertyKey, valueToCompare) => (item) => {
  // Check that args are set properly
  if(!valueToCompare) return false;
  if(!hasOwnProperty(item, propertyKey)) return false;

  // Get target property
  const itemProperty = item[propertyKey] as Date | DateRange;

  // Check for date case
  if(isDate(itemProperty)){
    const date = itemProperty as Date;

    return isWithinInterval(date, valueToCompare);
  }

  // Check for range case
  if(isDateRange(itemProperty))
  {
    const dateRange = itemProperty as DateRangeValid;

    // Item can't end before it starts.. (can be equal though)
    if(isAfter(dateRange.start, dateRange.end)){
      // console.warn(`>> ITEM ENDS BEFORE START: '${item.title}'`, item);
      return false;
    }

    return areIntervalsOverlapping(dateRange, valueToCompare);
  }

  // Something went wrong?
  return false;
}

export const isInsideInterval: Filter<number> =
  (propertyKey, valueToCompare) => (item) =>
    !valueToCompare ||
    (hasOwnProperty(item, propertyKey) &&
      !!(item[propertyKey] as NumRange).min &&
      !!(item[propertyKey] as NumRange).max &&
      valueToCompare >= (item[propertyKey] as NumRange).min &&
      valueToCompare <= (item[propertyKey] as NumRange).max);

export const isTrueByValueOperatorPair: Filter<ValueOperatorPair[]> =
  (propertyKey, valueToCompare) => (item) => {
    return (
      valueToCompare.length === 0 ||
      (hasOwnProperty(item, propertyKey) &&
        valueToCompare.some((i) => {
          if (i.operator === '>=') {
            return (item[propertyKey] as number) >= i.value;
          } else if (i.operator === '<=') {
            return (item[propertyKey] as number) <= i.value;
          }
          return (item[propertyKey] as number) === i.value;
        }))
    );
  };

export const isLessOrEqualTo: Filter<number> =
  (propertyKey, valueToCompare) => (item) =>
    !valueToCompare ||
    (hasOwnProperty(item, propertyKey) &&
      isNumber(item[propertyKey]) &&
      (item[propertyKey] as number) <= valueToCompare);

export const containsString: Filter<string> =
  (propertyKey, valueToCompare) => (item) =>
    !valueToCompare ||
    (hasOwnProperty(item, propertyKey) &&
      isString(item[propertyKey]) &&
      (item[propertyKey] as string)
        .toLowerCase()
        .includes(valueToCompare.toLowerCase()));

export const isDateAvailableWithinDateRange: Filter<DateRangeValid> =
  (propertyKey, dateRange) => (item) => {
    if (!dateRange) {
      return true;
    }

    if (
      (dateRange && !hasOwnProperty(item, propertyKey)) ||
      !(item[propertyKey] as Date[])
    ) {
      return false;
    }

    const bookings = item[propertyKey] as Date[];
    // find first index in bookings which is on start date or later
    const startIndex = bookings.findIndex(
      (date) => date.getTime() >= dateRange.start.getTime(),
    );
    // find first index in bookings which is after end date
    const endIndex = bookings.findIndex(
      (date) => date.getTime() > dateRange.end.getTime(),
    );

    return (startIndex >= 0 && endIndex === -1) || endIndex > startIndex;
  };

//TODO The allowUnsureAvailabilities flag is named wrong. Historically it had different meaning.
export const isBookingStatusAvailableWithinDateRange: Filter<DateRangeValid> =
  (propertyKey, dateRange, allowUnsureAvailabilities = false) => (item) => {
    if (allowUnsureAvailabilities) {
      return true;
    };

    if (!dateRange) {
      return true;
    }

    if (
      (dateRange && !hasOwnProperty(item, propertyKey)) ||
      !(item[propertyKey] as Date[])
    ) {
      return false;
    }

    // if (item.noAvailabilitiesAvailable && allowUnsureAvailabilities) return true;

    const bookings = item[propertyKey] as BookingStatus[];

    const startTime = dateRange.start.getTime();
    // subtract one day since it's ok to end reservation on the day of the next booking (one leaves, other one comes)
    const endTime = subDays(dateRange.end, 1).getTime();

    // if either end of the date range is over 12 months away, return true
    if (differenceInFullDays(new Date(), dateRange.start) > 360) return true;
    if (differenceInFullDays(new Date(), dateRange.end) > 360) return true;

    const startIndex = bookings.findIndex(
      (bookingStatus) => bookingStatus.date.getTime() === startTime,
    );
    // starting from the startIndex would be a time saver
    const endIndex = bookings.findIndex(
      (bookingStatus) => bookingStatus.date.getTime() === endTime,
    );

    if (startIndex === -1 || endIndex === -1) {
      // either date in the date range was not found
      return false;
    }

    // add one day which was subtracted above to get the right length for the stay
    const bookingDays = endIndex + 1 - startIndex;
    const startDayBookingStatus = bookings[startIndex];
    const endDayBookingStatus = bookings[endIndex];

    if (
      startDayBookingStatus.prices.length === 0 &&
      item.resolvedPrice === undefined &&
      !allowUnsureAvailabilities
    ) {
      return false;
    }

    if (differenceInFullDays(dateRange.start, dateRange.end) != bookingDays) {
      // not available on all days between the date range
      return false;
    }

    // is ending allowed on the ending day?
    // note that since endDay is one day before leaving, this corresponds to testing !nextDay.checkoutDenied
    if (!endDayBookingStatus.nextDayEndingAllowed) return false;

    // is the booking too short?
    if (bookingDays < startDayBookingStatus.minStay) return false;

    if (
      startDayBookingStatus.maxStay &&
      startDayBookingStatus.maxStay < bookingDays
    ) {
      // moder rule for upper end of stay
      return false;
    }

    // is short booking allowed on the starting day?
    if (bookingDays < 7 && !startDayBookingStatus.lessThanWeekAllowed)
      return false;

    // is extended booking allowed on the starting day?
    if (bookingDays > 7 && !startDayBookingStatus.extendedWeekAllowed)
      return false;

    // trivial case but tested nevertheless: is week booking allowed on starting day?
    if (bookingDays == 7 && !startDayBookingStatus.weekAllowed) return false;

    // moder extra flags
    if (startDayBookingStatus.checkinDenied) return false;

    // note that since endDay is one day before leaving, testing !endDayBookingStatus.checkoutDenied would not work
    return true;
  };

export const notNullOrFalse: Filter<Filterable> = (propertyKey) => (item) =>
  hasOwnProperty(item, propertyKey) && !!item[propertyKey];

export const equals: Filter<Filterable> =
  (propertyKey, valueToCompare) => (item) =>
    hasOwnProperty(item, propertyKey) && valueToCompare === item[propertyKey];

export const matchesAny: Filter<string[]> =
  (propertyKey, valueToCompare) => (item) =>
    valueToCompare.length === 0 ||
    (hasOwnProperty(item, propertyKey) &&
      ((isArray(item[propertyKey]) &&
        valueToCompare.some((i) =>
          (item[propertyKey] as string[]).includes(i),
        )) ||
        (isString(item[propertyKey]) &&
          valueToCompare.some((i) => item[propertyKey] === i))));

export const matchesAll: Filter<string[]> =
  (propertyKey, valueToCompare) => (item) =>
    valueToCompare.length === 0 ||
    (hasOwnProperty(item, propertyKey) &&
      ((isArray(item[propertyKey]) &&
        valueToCompare.every((i) =>
          (item[propertyKey] as string[]).includes(i),
        )) ||
        (isString(item[propertyKey]) &&
          valueToCompare.every((i) => item[propertyKey] === i))));

export const isLessThanOrEqualToAny: Filter<number[]> =
  (propertyKey, valueToCompare) => (item) =>
    valueToCompare.length === 0 ||
    (hasOwnProperty(item, propertyKey) &&
      valueToCompare.some((i) => (item[propertyKey] as number) <= i));

export const equalsByPropertyKeyPath: Filter<Filterable> = (
  propertyKey,
  valueToCompare,
) => matchesProperty(propertyKey, valueToCompare);

export const isOperatingOrTemporarilyClosed = (): FilterFunc => {
  const operating = matchesProperty('open.operating', true);
  const temporarilyClosed = matchesProperty('open.temporarilyClosed', true);

  return (item) => operating(item) || temporarilyClosed(item);
};

const useFilteredData = <T extends Record<string, unknown>>({
  data,
  filters,
}: FilteredDataInput<T>): FilteredDataOutput<T> => {
  if (filters.length === 0) {
    return {
      filteredData: [...data],
    };
  }
  const filteredData = data.filter((i) => filters.every((f) => f(i)));

  return {
    filteredData,
  };
};

export default useFilteredData;
