import React from 'react';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';
import Button, { ButtonLinkWrapper } from './Button';

const ButtonLink = ({
  callToAction,
}: {
  callToAction: ContentfulCallToActionTypes;
}): JSX.Element | null => {
  const linkProps = useContentfulCallToAction(callToAction);
  if (!linkProps) return null;
  return (
    <ButtonLinkWrapper {...linkProps}>
      <Button>{linkProps.label}</Button>
    </ButtonLinkWrapper>
  );
};

export default ButtonLink;
