import { CardType } from '../../../components/common/card/Card';
import { Theme } from '../../../styles/themes';
import { DataTransformer } from '../cardDataTransformers';
import { rentalCategories } from '../rentalCategories';
import {
  resolveLinks,
  resolveOpenStatus,
  resolveTags,
  trimLeadingWhiteSpace,
} from './utils';

const contentKey = 'rentals';

const rentalFieldsTransformer: DataTransformer<CardType.Rental> = (props) => {
  // Rental properties might be wrapped with node property (fetched directly / linked entries)
  // I have no idea how to type this for typescript
  const rental = props.hasOwnProperty('node') ? props.node : props;
  const {
    id,
    name: title,
    ingress: description,
    categories = [],
    contact,
    openingHours,
    images,
    location,
    leviBlackCard,
    hubDbId,
    hideFromLocalization
  } = rental;

  return {
    type: CardType.Rental,
    id,
    title: trimLeadingWhiteSpace(title),
    description,
    categories,
    address: contact?.address,
    openStatus: resolveOpenStatus(openingHours?.openingHours),
    image: images && images[0],
    location,
    links: resolveLinks(id, contentKey),
    tags: resolveTags(categories, contentKey, rentalCategories),
    theme: leviBlackCard ? Theme.LeviBlack : Theme.Primary,
    hubDbId,
    hideFromLocalization
  };
};

export default rentalFieldsTransformer;
