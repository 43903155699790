import React from 'react';
import Styled, { StyledGridProps } from './Grid.styled';

type GridProps = {
  children: React.ReactNode;
  className?: string;
} & StyledGridProps;

type GridChildProps = {
  [key: string]: StyledGridProps;
};

const gridPropsByChild: GridChildProps = {
  ContentfulCarousel: { overflowHidden: true },
};

const getGridPropsByChildTypes = (children: string[]): StyledGridProps =>
  children.reduce((acc: StyledGridProps, curr) => {
    if (gridPropsByChild[curr]) {
      acc = {
        ...acc,
        ...gridPropsByChild[curr],
      };
    }
    return acc;
  }, {});

const Grid = ({ children, className, noGutter }: GridProps): JSX.Element => {
  const childTypes = React.Children.toArray(children).map((child) =>
    React.isValidElement(child) &&
    typeof child !== 'string' &&
    child.type &&
    typeof child.type !== 'string'
      ? child.type.name
      : '',
  );

  const gridProps = getGridPropsByChildTypes(childTypes);

  return (
    <Styled.Grid className={className} noGutter={noGutter} {...gridProps}>
      {children}
    </Styled.Grid>
  );
};

export default Grid;
