import styled, { keyframes } from 'styled-components';
import Mount from '../../transitions/Mount';
import { NavLinkLi } from '../main/MainNavigationLink.styled';

const Weather = styled(NavLinkLi)``;

const WeatherSummary = styled.div`
  width: 5rem;
  height: 1.25rem;
  overflow: hidden;
`;

const WeatherStationSummary = styled.div`
  display: flex;
  align-items: center;
  height: 1.25rem;
  gap: 0.3125rem;
`;

const toggleAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  47% {
    transform: translateY(0);
  }
  53% {
    transform: translateY(-1.25rem);
  }
  100% {
    transform: translateY(-1.25rem);
  }
`;

const WeatherSpinner = styled(Mount)`
  position: absolute;
`;

const WeatherSummaryAnimContainer = styled(Mount)`
  animation-name: ${toggleAnimation};
  animation-duration: 10s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
`;

export default {
  Weather,
  WeatherSpinner,
  WeatherSummary,
  WeatherStationSummary,
  WeatherSummaryAnimContainer,
};
