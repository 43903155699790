import React from 'react';
import useBreakpoints from '../../../hooks/useBreakpoints';
import AccordionList, { AccordionListItemData } from './AccordionList';
import { CardProps, CardType } from '../card/Card';
import CardGrid from '../cardgrid/CardGrid';
import CardList from '../cardlist/CardList';
import { HorizontalCardType } from '../horizontal-card/HorizontalCard';
import SecondaryTabs from '../tabs/SecondaryTabs';
import StaticAccordionListItem from './StaticAccordionListItem';
import StaticAccordionList from './StaticAccordionList';
import withTwoPassRendering from '../withTwoPassRendering';

export type AccordionListingData<T extends CardType> = {
  accordionItem: AccordionListItemData;
  relatedCards: CardProps<T>[];
};

export type AccordionListingProps<T extends CardType = CardType.Default> = {
  data: AccordionListingData<T>[];
};

const AccordionListing = <T extends CardType>({
  data = [],
}: AccordionListingProps<T>): JSX.Element => {
  const { isMobile } = useBreakpoints();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const accordionItems = React.useMemo(
    () => data.map(({ accordionItem }) => accordionItem),
    [data],
  );

  const randomRelatedItems = React.useMemo(
    () =>
      data.map(({ relatedCards }) => [
        ...relatedCards.sort(() => 0.5 - Math.random()).slice(0, 5),
      ]),
    [data],
  );

  const onAccordionItemSelect = React.useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  // Implement two-pass rendering to fix rehydration issue in restaurant CardList (VLD-440)
  // https://github.com/gatsbyjs/gatsby/discussions/17914
  const CardListWithTwoPass = withTwoPassRendering(CardList);

  return !isMobile ? (
    <>
      <AccordionList
        items={accordionItems}
        selectedIndex={selectedIndex}
        onSelect={onAccordionItemSelect}
      />
      <CardListWithTwoPass
        type={HorizontalCardType.Compact}
        cards={randomRelatedItems[selectedIndex]}
      />
    </>
  ) : (
    <StaticAccordionList>
      <SecondaryTabs
        items={accordionItems}
        selectedIndex={selectedIndex}
        onSelect={onAccordionItemSelect}
      />
      <StaticAccordionListItem {...accordionItems[selectedIndex]} />
      <CardGrid cards={randomRelatedItems[selectedIndex]} />
    </StaticAccordionList>
  );
};

export default AccordionListing;
