import { ApiPath } from "..";
import { ActivityTrailResponseDataEntry, ActivityTrailResponse } from "../types/types";
import { ApiDataParser } from "./ApiDataParser";
import { difficultyToColor, locationToSlopeLocation, SlopeDifficulty, statusToStatusIcon } from "./slopeDataParser";

export const activityDifficultyToSlopeDifficulty = (difficulty:string): SlopeDifficulty => {
    switch(difficulty){
        case "Vihreä"   : return SlopeDifficulty.ErittäinHelppo;
        case "Sininen"  : return SlopeDifficulty.Helppo;
        case "Punainen" : return SlopeDifficulty.Keskivaikea;
        case "Musta"    : return SlopeDifficulty.Vaikea;
    }

    return difficulty as SlopeDifficulty; // TODO
}

export const activityTrailDataParser: ApiDataParser<ApiPath.BikeTrails> = 
    (data)=>data.map(entry=>({
        displayName             : "",
        difficultyColor         : difficultyToColor(activityDifficultyToSlopeDifficulty(entry.difficulty)), //entry.difficulty,
        statusIcon              : statusToStatusIcon({
            temporarilyClosed   : entry.open.temporarilyClosed,
            open                : entry.open.now
        }),
        slopeLocation           : locationToSlopeLocation("Eturinne"), // TODO

        id                      : entry.id,
        number                  : entry.number,
        name                    : entry.name,
        comment                 : entry.comment,
        open                    : {...entry.open, reasonForClosed: {fi: "TODO", en: "TODO"}}, // TODO
        temporarilyClosed       : entry.open.temporarilyClosed,
        openSchedule            : entry.open.hours,
        location                : "TODO", // TODO
        length                  : entry.length,
        difficulty              : SlopeDifficulty.Helppo, // TODO
        hasLighting             : false, // TODO
        inSnowPreparation       : false, // TODO
        riskOfAvalanche         : "TODO", // TODO
        dontCount               : 0, // TODO
        bubbles                 : {bubble_position:[]}, // TODO
        originating             : {lifts:[]}, // TODO
        naturalSnow             : "TODO", // TODO
        weatherSensors          : {bottom:null, top:null}, // TODO
        seasonalAvailability    : entry.seasonalAvailability,
    }));
