import rgba from 'polished/lib/color/rgba';
import styled from 'styled-components';
import { AppColor } from '../../styles/colors';
import { Typography } from '../common/typography/Typography.styled';

const SkiBusSchedule = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  grid-column: 1 / -1;
`;

const Stop = styled(Typography.BodyLarge).attrs({ as: 'div' })`
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: ${(props) => props.color};
    content: '';
  }

  //min-width: 2160px;
  min-width: 1640px;
  display: grid;
  grid-template-columns: 7.5% 2rem auto;
  grid-column: 1 / -1;
  grid-gap: 1rem;
  padding: 0.5rem;

  & + & {
    margin-top: 0;
  }

  &:nth-of-type(odd) {
    background-color: ${rgba(AppColor.Neutral200, 0.25)};
  }
  &:nth-of-type(even) {
    background-color: ${rgba(AppColor.Neutral300, 0.5)};
  }
`;

const StopName = styled(Typography.BodyLarge).attrs({ as: 'span' })`
  & + & {
    margin-top: 0;
  }
  padding-left: 3px;
`;

const BusLocation = styled.div``;

const StopSchedule = styled(StopName)`
  grid-template-columns: repeat(22, 1fr);
  display: grid;
  text-align: center;
  padding-left: 0;
`;

export default {
  SkiBusSchedule,
  Stop,
  StopName,
  BusLocation,
  StopSchedule,
};
