import React from 'react';
import { Slope } from '../../network/skiresort-api/types/SlopeDataTypes';
import { AppColor } from '../../styles/colors';
import { Icons } from '../../types/icon-types';
import RowStatusIcon from './RowStatusIcon';
import Styled from './SkiResortAmenities.styled';
import { RowProps } from './SkiResortAmenitiesList';

const SlopeRow = ({ data, locale }: RowProps<Slope>) => {
  const {
    difficultyColor,
    // displayName,
    name,
    comment,
    length,
    hasLighting,
    inSnowPreparation,
    open,
    statusIcon,
  } = data;
  return (
    <Styled.Row>
      <Styled.SlopeDisplayName difficultyColor={difficultyColor} />
      <Styled.Info>
        <span>{name[locale]}</span>
        {length && <span>{length}</span>}
      </Styled.Info>
      <Styled.StatusCell>
        {inSnowPreparation && (
          <RowStatusIcon type={Icons.Snowmaking} color={AppColor.LeviBlue} />
        )}
        {hasLighting && (
          <RowStatusIcon
            type={Icons.SlopeHasLighting}
            color={AppColor.LeviDarkBlue}
          />
        )}
        <Styled.Info>{open.hours}</Styled.Info>
        <RowStatusIcon {...statusIcon} />
      </Styled.StatusCell>
      {comment?.[locale] && (
        <Styled.AdditionalInfo>
          {comment[locale]}
        </Styled.AdditionalInfo>
      )}
    </Styled.Row>
  );
};

export default SlopeRow;
