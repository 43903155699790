import React from 'react';
import Styled from './InfoGIS.styled';

export type InfoGISProps = {
  id: string;
};

const InfoGIS = ({ id }: InfoGISProps) => {
  if (!id) return null;

  return (
    <Styled.InfoGIS>
      <iframe src={`/infogis.html?id=${id}`} />
    </Styled.InfoGIS>
  );
};

export default InfoGIS;
