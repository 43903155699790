import styled from 'styled-components';
import { AppColor, SocialMediaColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover, touchActive } from '../../../styles/utils';
import { SocialMediaButtonProps } from './SocialMediaButton';

type ButtonProps = Pick<SocialMediaButtonProps, 'media'>;

const SocialMediaButton = styled.button<ButtonProps>`
  border: 1px solid ${AppColor.Neutral300};
  border-radius: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  color: ${AppColor.Neutral600};

  ${hover} {
    &:hover {
      cursor: pointer;
      background-color: ${(props) => SocialMediaColor[props.media]};
      color: ${AppColor.White};
    }
  }
  ${touchActive} {
    &:active {
      background-color: ${(props) => SocialMediaColor[props.media]};
      color: ${AppColor.White};
    }
  }

  ${transitions.ButtonHover}
`;

export default {
  SocialMediaButton,
};
