import styled from 'styled-components';
import { AppColor } from '../../styles/colors';
import MapInfoBoxStyled from './MapInfoBox.styled';

export type MarkerVariants = 'primary' | 'secondary' | 'tertiary';
type VariantStyleIndex = {
  [key in MarkerVariants]: string;
};

type MarkerProps = {
  variant: MarkerVariants;
};

const Marker = styled.div<MarkerProps>`
  width: 2rem;
  height: 2rem;

  ${MapInfoBoxStyled.MapInfoBox} {
    display: none;
  }
  &:hover {
    ${MapInfoBoxStyled.MapInfoBox} {
      display: block;
    }
  }
  ${(props) => variantStyles[props.variant]}
`;

const variantStyles: VariantStyleIndex = {
  primary: ``,
  secondary: `
  border-radius: 50%;
  background-color: ${AppColor.LeviDarkBlue};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  `,
  tertiary: `
  border-radius: 50%;
  background-color: ${AppColor.HighlightOrange};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  `,
};

export default {
  Marker,
};
