import { ApiPath } from '..';
import {
  LocaleType,
  LocalizedString,
} from '../../../contentful/templates/types';
import { ApiDataParser } from './ApiDataParser';
import { parseOpeningHours, stripHtmlString } from './utils';

export const restaurantDataParser: ApiDataParser<ApiPath.Restaurant> = ({
  menu,
  open,
}) => {
  const openingHours =
    open?.openingHours && parseOpeningHours(open.openingHours);

  return {
    menu: Object.keys(menu).reduce((acc, locale) => {
      const localeKey = locale as LocaleType;
      return {
        ...acc,
        [localeKey]: stripHtmlString(menu[localeKey]),
      };
    }, {} as LocalizedString),
    open: { ...open, openingHours },
  };
};
