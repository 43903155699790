import { InfoGridProps } from '../components/common/infogrid/InfoGrid';
import { ContentfulTableTypes } from '../contentful/fragments/contentfulTable';

type HookProps = {
  groups: ContentfulTableTypes[];
};

const useContentfulTableGroupsData = ({
  groups = [],
}: HookProps): InfoGridProps[] =>
  groups?.map(({ id, title, content }) => ({
    id,
    title,
    data: content.map(({ key, value }) => ({
      label: key,
      statistic: value,
    })),
  }));
export default useContentfulTableGroupsData;
