import React from 'react';
import { ContentfulCategoriesTypes } from '../contentful/sections/contentfulCategories';
import { ContentfulCardTypes } from '../contentful/sections/types';
import { Icons } from '../types/icon-types';

type HookProps = Pick<ContentfulCategoriesTypes, 'title' | 'categories'>;

export type MappedContentfulCategory = {
  title: string;
  content: string[];
  icon?: Icons;
};

type ContentfulCategoryData = {
  title: string;
  categories: MappedContentfulCategory[];
  cards: ContentfulCardTypes[];
};

const useContentfulCategoryData = ({
  title,
  categories: originalCategories,
}: HookProps): ContentfulCategoryData => {
  const { categories, cards } = React.useMemo(
    () => ({
      categories: originalCategories.map((category) => ({
        ...category,
        content: category.content?.map((item) => item.id),
      })),
      cards: originalCategories
        .flatMap((category) => category.content || [])
        .reduce(
          (acc: ContentfulCardTypes[], curr) =>
            acc.find((item) => item.id === curr.id) ? acc : [...acc, curr],
          [],
        ),
    }),
    [originalCategories],
  );

  return {
    title,
    categories,
    cards,
  };
};

export default useContentfulCategoryData;
