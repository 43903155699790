import { addMonths } from 'date-fns';
import React from 'react';
import Styled from './Calendar.styled';
import CalendarDay from './CalendarDay';
import useCalendarDays from './useCalendarDays';
import useCalendarSelectedMonth from './useCalendarSelectedMonth';

const CalendarDays = ({ offset = 0 }): JSX.Element => {
  const days = useCalendarDays(offset);
  const selectedMonth = useCalendarSelectedMonth();
  const calendarMonth = React.useMemo(
    () => addMonths(selectedMonth, offset),
    [offset, selectedMonth],
  );

  return (
    <Styled.CalendarDays>
      {days.map((day) => (
        <CalendarDay key={day.key} day={day} month={calendarMonth} />
      ))}
    </Styled.CalendarDays>
  );
};

export default CalendarDays;
