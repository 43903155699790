import { CardType } from '../../../components/common/card/Card';
import { DataTransformer } from '../cardDataTransformers';
import {
  resolveLinks,
  resolveTagByKey,
  resolveTags,
  trimLeadingWhiteSpace,
} from './utils';
import { accommodationCategories } from '../accommodationCategories';

const contentKey = 'accommodations';

const accommodationFieldsTransformer: DataTransformer<
  CardType.Accommodation
> = ({ node }) => {
  const {
    id,
    title,
    description,
    areaCode,
    distance,
    rating,
    ratingRaw,
    accType,
    rooms,
    beds,
    extraBeds,
    capacity,
    maxPeople,
    amenities,
    image,
    location,
    address,
    bookingStatus,
    accommodationId,
    resolvedPrice,
    resolvedPriceDateRange,
    fromModerAPI,
  } = node;

  const amenitiesTags = resolveTags(
    amenities,
    contentKey,
    accommodationCategories,
  );

  const roomsTag =
    rooms > 0 &&
    resolveTagByKey(
      String(rooms),
      contentKey,
      'capacity',
      rooms > 1 ? 'Rooms' : 'Room',
      accommodationCategories,
    );

  // const maxPeopleTag =
  //   maxPeople > 0 &&
  //   resolveTagByKey(
  //     String(maxPeople),
  //     contentKey,
  //     'capacity',
  //     maxPeople > 1 ? 'Persons' : 'Person',
  //     accommodationCategories,
  //   );

  const bedsTag =
    beds > 0 &&
    resolveTagByKey(
      extraBeds > 0 ? `${beds}+${extraBeds}` : beds.toString(),
      contentKey,
      'capacity',
      beds > 1 ? 'Beds' : 'Bed',
      accommodationCategories,
    );

  // collect all non-null tags
  const tags = [...amenitiesTags, bedsTag, roomsTag].filter(
    (value) => !!value,
  );

  const links = resolveLinks(id, 'accommodations');

  return {
    title: trimLeadingWhiteSpace(title),
    id,
    accommodationId,
    type: CardType.Accommodation,
    areaCode,
    distance,
    image: image?.childImageSharp,
    description,
    location,
    address,
    rating,
    ratingRaw,
    tags,
    accType,
    rooms,
    beds,
    capacity,
    extraBeds,
    amenities,
    bookingStatus,
    callToAction: links[0],
    resolvedPriceDateRange,
    resolvedPrice,
    fromModerAPI,
  };
};

export default accommodationFieldsTransformer;
