import {
  OpeningHoursDayType,
  OpeningHoursTypes,
} from '../contentful/templates/types';
import { localize } from '../localization/i18n';

type OpeningHours = {
  shortDayLength: number;
  closedText: string;
  openAllDayText: string;
  abbreviations: Abbreviations;
};

type Abbreviations = {
  [key: string]: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
};

export const openingHoursToShortString = (
  openingHours: OpeningHoursTypes,
): string => {
  const { shortDayLength, closedText, openAllDayText, abbreviations } =
    localize('openingHours') as unknown as OpeningHours;

  const result = Object.entries(openingHours).reduce(
    (acc: string[][], cur: [string, OpeningHoursDayType]) => {
      const [dayName, data] = cur;
      let key = abbreviations[dayName];
      let value;

      if (data.closed) {
        value = closedText;
      } else if (data.from === data.to) {
        value = openAllDayText;
      } else {
        value = `${data.from}-${data.to}`;
      }

      const prev = acc[acc.length - 1];

      if (!prev) {
        // no previous value
        acc.push([key, value]);
        return acc;
      }

      const [prevKey, prevValue] = prev;

      if (value === prevValue) {
        if (prevKey && prevKey.length > shortDayLength) {
          key = `${prevKey.slice(0, shortDayLength)}-${key}`;
        } else {
          key = `${prevKey}-${key}`;
        }
        acc[acc.length - 1] = [key, value];
      } else {
        acc.push([key, value]);
      }
      return acc;
    },
    [],
  );

  return result.map((i) => i.join(': ')).join(', ');
};

export const isOpen = (openingHours: OpeningHoursTypes): boolean => {
  const now = new Date();
  const weekday = now
    .toLocaleDateString('en', { weekday: 'long' })
    .toLocaleLowerCase();
  const { from, to, closed } = openingHours[weekday];
  if (closed || !from || !to) {
    return false;
  }
  if (from === to) {
    return true;
  }

  const nowMinutes = now.getHours() * 60 + now.getMinutes();

  const [fh, fm] = from.split(':');
  const fromMinutes = parseInt(fh) * 60 + parseInt(fm);
  const [th, tm] = to.split(':');
  const toMinutes = parseInt(th) * 60 + parseInt(tm);

  if (fromMinutes < toMinutes) {
    if (nowMinutes > fromMinutes && nowMinutes < toMinutes) {
      return true;
    }
  }
  //TODO: handle situations where place is open after midnight

  return false;
};
