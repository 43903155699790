import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover } from '../../../styles/utils';
import { Typography } from '../typography/Typography.styled';

const Calendar = styled.div`
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  max-width: 322px;
  min-width: 280px;
  width: 100%;
`;

const CalendarHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  color: ${AppColor.Neutral700};

  button {
    color: ${AppColor.LeviBlue};
    ${hover} {
      figure {
        transform: translate3d(0, 0, 0);
        ${transitions.ArrowLinkHover}
      }
      &:not([disabled]) {
        cursor: pointer;
        &:first-of-type {
          &:hover {
            figure {
              transform: translate3d(-0.5rem, 0, 0);
            }
          }
        }
        &:last-of-type {
          &:hover {
            figure {
              transform: translate3d(0.5rem, 0, 0);
            }
          }
        }
      }
    }
  }
`;

const CalendarWeekDays = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 0.875rem;

  color: ${AppColor.LeviBlue};
`;

const CalendarDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 2px;
`;

const CalendarDayLabel = styled(Typography.SubLink)`
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  align-items: center;
  display: flex;
`;

const CalendarDay = styled.button`
  /*
  width: 2.875rem;
  height: 2.75rem;
  */
  padding: 0;
  width: 100%;
  padding-top: 100%;

  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${AppColor.Neutral700};

  &[disabled] {
    color: ${AppColor.Neutral500};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    border-radius: 100%;
    left: 1px;
    right: 1px;
    top: 0;
    bottom: 0;
  }

  &.blocked {
    &:before {
      background-color: ${AppColor.Neutral300};
    }
  }
  &.first {
    &:before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      right: 0;
    }
  }
  &.last {
    &:before {
      left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.in-range {
    &:not(.first, .last) {
      background-color: ${AppColor.Neutral300};
      &.selected {
        background-color: ${AppColor.LeviBlue};
      }
    }
  }

  &.active {
    &:not([disabled], .selected) {
      &:before {
        background-color: ${AppColor.Neutral300};
      }
    }
  }

  &.selected,
  &.first,
  &.last {
    color: ${AppColor.White};
    &:before {
      background-color: ${AppColor.LeviBlue};
    }
  }

  ${hover} {
    &:not([disabled], .selected):hover {
      color: ${AppColor.White};
      &:before {
        background-color: ${AppColor.LeviBlueHover};
        cursor: pointer;
      }
    }
  }

  &.hidden {
    visibility: hidden;
  }
`;

export default {
  Calendar,
  CalendarHeader,
  CalendarWeekDays,
  CalendarDays,
  CalendarDay,
  CalendarDayLabel,
};
