import { graphql, useStaticQuery } from 'gatsby';
import { NavLinkProps } from '../components/navigation/main/MainNavigationPropTypes';
import { LocaleType } from '../contentful/templates/types';
import useLocale from '../localization/useLocale';
import useContentfulNavigationData from './useContentfulNavigationData';

type PageType = {
  path: string;
  context: {
    pageId: string;
    locale: LocaleType;
  };
};

const query = graphql`
  query LocaleLinkData {
    allSitePage {
      edges {
        node {
          path
          context {
            pageId
            locale
          }
        }
      }
    }
  }
`;

const useContentfulLocaleLink = (pageId: string | null): string | undefined => {
  const { allSitePage } = useStaticQuery(query);
  const locale = useLocale();
  if (!pageId) {
    return;
  }

  const translatedPage = allSitePage.edges.find(
    ({ node }: { node: PageType }) =>
      node.context?.pageId === pageId && node.context?.locale !== locale,
  );

  return translatedPage?.node?.path;
};

type StaticPageKeys = {
  [key: string]: {
    [key in LocaleType]: string;
  };
};
const staticPageKeyMap: StaticPageKeys = {
  search: {
    fi: '/haku',
    en: '/en/search',
  },
};

export const staticPageSlugToLocaleLink = (
  locale: LocaleType,
  staticPageKey: string | undefined,
): string | undefined => {
  if (!staticPageKey) {
    return undefined;
  }
  const foundKey = staticPageKeyMap[staticPageKey];
  if (foundKey) {
    return foundKey[locale];
  }
};

const getLocalizedLink = (
  locale: LocaleType,
  pageId: string | null,
  resolvedLink: string | undefined,
  localeLinkPath: string | undefined
): string => {
  const page = useContentfulNavigationData(pageId || undefined);
  const shouldHideLocalizedPage = page?.hideFromLocalization && locale === 'fi';
  const pageLinkNotResolved = page?.slug !== 'en' && !resolvedLink && !localeLinkPath;

  if (shouldHideLocalizedPage || pageLinkNotResolved) return '/en/404';
  if (resolvedLink) return resolvedLink;
  if (localeLinkPath) return localeLinkPath;
  if (locale === 'fi') return '/en';

  return '/';
};

const useLocaleLink = (
  pageId: string | null,
  staticPageKey?: string,
  localeLinkPath?: string,
): NavLinkProps => {
  const locale = useLocale();
  const label = locale === 'fi' ? 'en' : 'fi';
  const contentfulLink = useContentfulLocaleLink(pageId);
  const resolvedLink = contentfulLink ? contentfulLink : staticPageSlugToLocaleLink(label, staticPageKey);
  const to = getLocalizedLink(locale, pageId, resolvedLink, localeLinkPath);

  return {
    to,
    label,
  };
};

export default useLocaleLink;
