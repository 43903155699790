import styled from 'styled-components';
import UnstyledMap from '../../map/Map';
import { GridElement } from '../layout/Grid.styled';
import { Typography } from '../../../components/common/typography/Typography.styled';

const Map = styled(UnstyledMap)`
  width: 100%;
  height: 34.625rem;

  * + & {
    margin-top: 1.5rem;
  }
`;

const MapContainer = styled(GridElement).attrs({ full: true })`
  ${Typography.SubHeadingLarge} + ${Typography.BodyLarge} {
    margin-top: 0.625rem;
  }
`;

export default {
  MapContainer,
  Map,
};
