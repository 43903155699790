import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../styles/themes';
export interface ThemeContainerProps {
  theme?: Theme;
}

export const ThemeContext = React.createContext(Theme.Default);

const ThemeWrapper = styled.div.attrs<ThemeContainerProps>((props) => {
  return {
    'data-theme': typeof props.theme === 'string' ? props.theme : Theme.Default,
  };
})``;

const ThemeContainer = ({
  theme,
  children,
  ...rest
}: ThemeContainerProps &
  React.ComponentPropsWithoutRef<typeof ThemeWrapper>): JSX.Element => {
  return (
    <ThemeContext.Provider value={theme || Theme.Default}>
      <ThemeWrapper theme={theme} {...rest}>
        {children}
      </ThemeWrapper>
    </ThemeContext.Provider>
  );
};

export default ThemeContainer;
