import React from 'react';
import { SocialMedia } from '../../../types/social-media';
import SocialMediaButton from './SocialMediaButton';

export type SocialMediaLink = {
  type: SocialMedia;
  link: string;
};

type SocialMediaButtonsProps = {
  socialMediaLinks: SocialMediaLink[];
};

const SocialMediaButtons = ({
  socialMediaLinks,
}: SocialMediaButtonsProps): JSX.Element => (
  <ul>
    {socialMediaLinks.map(({ type, link }) => (
      <li key={type}>
        <SocialMediaButton media={type} link={link} />
      </li>
    ))}
  </ul>
);

export default SocialMediaButtons;
