import { css } from 'styled-components';
import { Breakpoint, breakpoints } from '../breakpoints';

const debugLayoutGrid = css`
  /* Settings */
  :root {
    //--debug-offset: 1rem;
    --debug-offset: calc(
      100vw - (var(--max-section-width) - var(--max-content-width) * 0.5px)
    );

    --debug-color: rgb(255 0 0 / 0.1);
    --media-query: 'base ${Breakpoint.base}';
  }

  ${breakpoints.sm} {
    :root {
      //--debug-offset: 3rem;
      --media-query: 'sm ${Breakpoint.sm}';
    }
  }
  ${breakpoints.md} {
    :root {
      //--debug-offset: 2rem;
      --media-query: 'md ${Breakpoint.md}';
    }
  }
  ${breakpoints.lg} {
    :root {
      //--debug-offset: 3.3475rem;
      --media-query: 'lg ${Breakpoint.lg}';
    }
  }

  /* Helper variables */
  :root {
    --debug-repeating-width: calc(100% / var(--columns));
    --debug-column-width: calc((100% / var(--columns)) - var(--gutter));
    --debug-background-width: calc(100% + var(--gutter));
    --debug-background-columns: repeating-linear-gradient(
      to right,
      var(--debug-color),
      var(--debug-color) var(--debug-column-width),
      transparent var(--debug-column-width),
      transparent var(--debug-repeating-width)
    );
  }

  html {
    position: relative;
  }

  html::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    width: calc(100% - (2 * var(--debug-offset)));
    max-width: var(--max-content-width);
    min-height: 100vh;
    content: '';
    background-image: var(--debug-background-columns);
    background-size: var(--debug-background-width) 100%;
    background-position: 0 0;
    z-index: 1000;
    pointer-events: none;
  }

  html::after {
    content: var(--media-query);
    position: fixed;
    top: 0.25rem;
    left: 0.25rem;
    background-color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
    padding: 0.25rem;
    color: white;
    z-index: 9999;
  }
`;

export default debugLayoutGrid;
