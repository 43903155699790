import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import { hover } from '../../../styles/utils';
import Icon from '../icon/Icon';
import {
  LinkUnderlineCSS,
  LinkUnderLineHoverCSS,
  Typography,
} from '../typography/Typography.styled';

const ContactDetails = styled.div`
  padding: 1.5rem;
  border: 1px solid ${AppColor.Neutral300};
  > address {
    display: flex;
    flex-direction: column;

    ${Typography.SubLink} {
      margin-top: 0.85rem;
    }

    ${Typography.SubHeadingSmall} {
      color: ${AppColor.LeviBlue};
    }

    ${Typography.SubHeadingSmall} + ${Typography.SubLink} {
      margin-top: 1.5rem;
    }
  }
  // SOME-links
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.125rem;
    ${breakpoints.md} {
      gap: var(--gutter);
    }
    margin-top: 2rem;
  }

  ${breakpoints.sm} {
    width: 100%;
  }
`;

type RowProps = {
  color?: string;
};

const Row = styled.div<RowProps>`
  display: flex;
  column-gap: 0.75rem;
  width: 100%;
  overflow-wrap: anywhere;
  padding: 1rem 0;
  border-bottom: 1px solid ${AppColor.Neutral300};
  ${Icon}, ${Typography.BodyLarge} {
    color: ${({ color }) => color ?? AppColor.LeviBlue};
  }

  ${hover} {
    a {
      ${LinkUnderlineCSS}
      &:hover {
        ${LinkUnderLineHoverCSS}
      }
    }
  }
`;

const LogoContainer = styled.div`
  padding-bottom: 2rem;
  border-bottom: 1px solid ${AppColor.Neutral300};
  text-align: center;
`;

export default {
  ContactDetails,
  Row,
  LogoContainer,
};
