import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import { Icons } from '../../../../types/icon-types';
import DefaultCard from './DefaultCard';
import { EventCardProps } from '../types';
import CardTag from '../CardTag';
import CardFooterLink from '../CardFooterLink';
import useFormattedDate from '../../../../hooks/useFormattedDate';

const EventCard = ({
  title,
  image,
  description,
  date,
  links = [],
  theme,
}: EventCardProps): JSX.Element => {
  const formattedDate = useFormattedDate({ date });
  const cardLink = links && links.length === 1 ? links[0] : undefined;
  return (
    <DefaultCard
      title={title}
      image={image}
      theme={theme}
      callToAction={cardLink}
    >
      <Styled.CardInfoRow>
        <CardTag icon={Icons.Calendar}>{formattedDate}</CardTag>
      </Styled.CardInfoRow>
      <Typography.BodySmall>{description}</Typography.BodySmall>
      <Styled.CardFooter>{links.map(CardFooterLink)}</Styled.CardFooter>
    </DefaultCard>
  );
};

export default EventCard;
