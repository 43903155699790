import { CardType } from '../../../components/common/card/Card';
import { DataTransformer } from '../cardDataTransformers';
import { trimLeadingWhiteSpace } from './utils';

const serviceFieldsTransformer: DataTransformer<CardType.Service> = (props) => {
  // Service properties might be wrapped with node property (fetched directly / linked entries)
  // I have no idea how to type this for typescript
  const service = props.hasOwnProperty('node') ? props.node : props;
  const {
    id,
    name: title,
    ingress: description,
    contact,
    images,
    location,
    hubDbId,
    hideFromLocalization
  } = service;

  if (service.internal?.type === 'ContentfulRestaurant') {
    service.categories = ['Food'];
  } else if (service.internal?.type === 'ContentfulRental') {
    service.categories = ['Rentals'];
  }
  return {
    type: CardType.Service,
    id,
    title: trimLeadingWhiteSpace(title),
    description,
    categories: service.categories,
    address: contact?.address,
    openStatus: undefined,
    image: images && images[0],
    location,
    callToAction: { destination: { pageId: id } },
    hubDbId,
    hideFromLocalization
  };
};

export default serviceFieldsTransformer;
