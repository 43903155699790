import React from 'react';

import MainNavigationLink from '../MainNavigationLink';
import MainNavigationDropdown from './MainNavigationDropdown';
import Styled from '../MainNavigation.styled';
import {
  MainNavigationProps,
  NavLinkPropsWithSubpages,
} from '../MainNavigationPropTypes';
import SiteSearch from '../../../site-search/SiteSearch';
import Weather from '../../weather/Weather';
import { Typography } from '../../../common/typography/Typography.styled';
import { Link } from "@Levi/components/common/link";

import { property } from 'lodash';

type DesktopNavigationProps = Pick<
  MainNavigationProps,
  | 'pageLinks'
  | 'weatherCamerasLink'
  | 'localeLink'
  | 'hideLocaleLink'
  | 'hideWeather'
  | 'hideSearch'
>;

export const useDropdownCalculations = ({
  ref,
  query: { path, selector } = { path: 'parentElement.parentElement' },
}: {
  ref: React.RefObject<HTMLElement>;
  query?: {
    path: string;
    selector?: string;
  };
}) => {
  const recalculate = React.useCallback(() => {
    const prop = property(path)(ref.current);
    if (!ref.current || !prop) {
      return;
    }

    const referenceRect = selector
      ? (() => {
          const rect: { left?: number; right?: number } = {
            left: undefined,
            right: undefined,
          };
          (prop as HTMLElement).querySelectorAll(selector).forEach((item) => {
            const childRect = item.getBoundingClientRect();
            rect.left = !!rect.left
              ? Math.min(rect.left, childRect.left)
              : childRect.left;
            rect.right = !!rect.right
              ? Math.max(rect.right, childRect.right)
              : childRect.right;
          });
          return rect;
        })()
      : (prop as HTMLElement).getBoundingClientRect();

    if (!referenceRect) {
      return;
    }

    const clientRect = ref.current.getBoundingClientRect();
    ref.current.style.setProperty(
      '--offset-mid',
      `${clientRect?.left + clientRect?.width * 0.5}px`,
    );
    ref.current.style.setProperty('--offset-min', referenceRect.left + 'px');
    ref.current.style.setProperty('--offset-max', referenceRect.right + 'px');
  }, [path, ref, selector]);

  React.useLayoutEffect(() => {
    recalculate();
  });

  React.useEffect(() => {
    const localRef = ref.current;
    if (!localRef) {
      return;
    }

    localRef.addEventListener('mouseover', recalculate);

    return () => {
      if (localRef) {
        localRef.removeEventListener('mouseover', recalculate);
      }
    };
  }, [ref, recalculate]);

  return {
    recalculate,
  };
};

const PageLink = ({ to, label, subpages }: NavLinkPropsWithSubpages) => {
  const ref = React.useRef<HTMLLIElement>(null);

  const { recalculate } = useDropdownCalculations({
    ref,
    query: { path: 'parentElement.parentElement', selector: ':scope > ul' },
  });

  const [shouldRender, setShouldRender] = React.useState(false);
  const onMouseEnter = React.useCallback(() => {
    if (shouldRender) {
      return;
    }
    setShouldRender(true);
  }, [shouldRender]);

  React.useEffect(() => {
    if (shouldRender) {
      recalculate();
    }
  }, [shouldRender, recalculate]);

  return (
    <MainNavigationLink
      key={to}
      to={to}
      label={label}
      ref={ref}
      onMouseEnter={!shouldRender ? onMouseEnter : undefined}
      onPointerEnter={!shouldRender ? onMouseEnter : undefined}
    >
      {subpages && subpages.length > 0 && (
        <MainNavigationDropdown
          pageLinks={subpages}
          shouldRender={shouldRender}
        />
      )}
    </MainNavigationLink>
  );
};

const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
  pageLinks = [],
  weatherCamerasLink,
  localeLink,
  hideWeather,
  hideLocaleLink,
  hideSearch,
}) => (
  <>
    <Styled.NavLinksUl>
      {pageLinks.map(PageLink)}
      {!hideSearch && <SiteSearch />}
    </Styled.NavLinksUl>
    <Styled.NavLinksUl>
      {!hideWeather && <Weather weatherCamerasLink={weatherCamerasLink} />}
      {!hideLocaleLink && (
        <li>
          <Typography.NavigationLink as={Link} to={localeLink.to}>
            {localeLink.label}
          </Typography.NavigationLink>
        </li>
      )}
    </Styled.NavLinksUl>
  </>
);

export default DesktopNavigation;
