import React from 'react';
import MapInfoBox, { MapInfoBoxPosition } from './MapInfoBox';
import Styled, { MarkerVariants } from './Marker.styled';

const infoBoxInitialPosition: MapInfoBoxPosition = {
  offsetLeft: -115,
  offsetBottom: 0,
  offsetTop: 'unset',
};

type MarkerProps = {
  lat: number;
  lng: number;
  variant?: MarkerVariants;
  children: React.ReactNode;
  infoBox?: React.ReactNode;
  parentRect?: DOMRect | undefined;
};

const Marker = ({
  variant = 'primary',
  children,
  infoBox,
  parentRect = undefined,
}: MarkerProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  const [infoBoxPosition, setInfoBoxPosition] =
    React.useState<MapInfoBoxPosition>(infoBoxInitialPosition);

  const handleMouseEnter = React.useCallback(() => {
    if (parentRect && ref && ref.current) {
      const currentRect = ref.current.getBoundingClientRect();
      const offsetLeft =
        currentRect.left - parentRect.left < 115 &&
        parentRect.right - currentRect.right > 230
          ? -1 * (currentRect.left - parentRect.left) + 20
          : parentRect.right - currentRect.right < 115 &&
            currentRect.left - parentRect.left > 230
          ? -1 * (250 - (parentRect.right - currentRect.right))
          : infoBoxInitialPosition.offsetLeft;

      const offsetBottom = currentRect.top < 255 ? 'unset' : 0;
      const offsetTop = offsetBottom === 'unset' ? 30 : 'unset';
      setInfoBoxPosition({
        offsetBottom,
        offsetTop,
        offsetLeft,
      });
    }
  }, [parentRect]);

  if (!infoBox) {
    return <Styled.Marker variant={variant}>{children}</Styled.Marker>;
  }

  return (
    <Styled.Marker ref={ref} onMouseEnter={handleMouseEnter} variant={variant}>
      {children}

      <MapInfoBox infoBoxPosition={infoBoxPosition}>{infoBox}</MapInfoBox>
    </Styled.Marker>
  );
};

export default Marker;
