import { Link } from "@Levi/components/common/link";
import React from 'react';
import { ContentfulCallToActionProps } from '../contentful/callToActions/contentfulCallToAction';
import {
  InternalPageLink,
  ExternalPageLink,
} from '../contentful/callToActions/types';
import useSitePageData from './useSitePageData';

export type LinkComponentTypes = 'a' | typeof Link;

type LinkTypeProps<T extends React.ElementType = 'a'> = {
  as?: T;
  label?: string;
};

export type LinkProps<T extends React.ElementType> = LinkTypeProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof LinkTypeProps | 'type'>;

const isInternalLink = (
  destination: InternalPageLink | ExternalPageLink,
): destination is InternalPageLink => {
  return (destination && !!destination.pageId) || false;
};

export const isInternalCTA = (
  linkProps: LinkProps<LinkComponentTypes>,
): linkProps is LinkProps<typeof Link> =>
  !!(linkProps as LinkProps<typeof Link>).to;

const useContentfulCallToAction = (
  link?: ContentfulCallToActionProps,
): LinkProps<LinkComponentTypes> | undefined => {
  const sitePageData = useSitePageData();
  const { destination, title: label, target: contentfulTarget } = link || {};
  const target = contentfulTarget === 'tab' ? '_blank' : '_self';
  if (!destination) return undefined;

  if (isInternalLink(destination)) {
    const to = sitePageData.get(destination.pageId);
    if (!to) return undefined;

    if (target === '_self') {
      return {
        to,
        as: Link,
        label,
      } as LinkProps<typeof Link>;
    } else {
      return {
        href: to,
        as: 'a',
        label,
        target,
      } as LinkProps<'a'>;
    }
  }

  return {
    href: destination.externalUrl,
    as: 'a',
    label,
    target,
  } as LinkProps<'a'>;
};

export default useContentfulCallToAction;
