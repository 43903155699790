import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '../../components/common/typography/Typography.styled';
import Styled from '../../components/common/textContent/TextContent.styled';
import { ContentfulMarkdownTypes } from '../templates/types';
import useHtmlAst from '../../hooks/useHtmlAst';

export type ContentfulEmbedTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  subtitle: string;
  embedAst: ContentfulMarkdownTypes;
  embedDirect: { content: string };
  embedDirectly: boolean;
};

const ContentfulEmbed = ({
  title,
  subtitle,
  embedAst,
  embedDirect,
  embedDirectly,
}: ContentfulEmbedTypes): JSX.Element | null => {
  const bodyText = useHtmlAst(embedAst?.childMarkdownRemark?.htmlAst);

  if (embedDirectly) {
    return <div dangerouslySetInnerHTML={{ __html: embedDirect.content }} />;
  }

  if (!bodyText) return null;
  return (
    <>
      <Styled.TextContent>
        {(subtitle || title) && (
          <Styled.TextContentHeader>
            {subtitle && (
              <Typography.SubHeadingSmall>
                {subtitle}
              </Typography.SubHeadingSmall>
            )}
            {title && <Typography.Display>{title}</Typography.Display>}
          </Styled.TextContentHeader>
        )}
        <div>{bodyText}</div>
      </Styled.TextContent>
    </>
  );
};

export default ContentfulEmbed;

export const contentfulEmbedQuery = graphql`
  fragment contentfulEmbedFields on ContentfulEmbed {
    id
    internal {
      type
    }
    title
    subtitle
    embedAst: content {
      childMarkdownRemark {
        htmlAst
      }
    }
    embedDirect: content { 
      content
    }
    embedDirectly
  }
`;
