import React from 'react';
import { Icons } from '../../../types/icon-types';
import Icon from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';
import Styled from './Dropdown.styled';

export type DropdownButtonProps = {
  label: string;
  onClick: () => void;
};

const DropdownButton = ({
  onClick,
  label,
}: DropdownButtonProps): JSX.Element => (
  <Styled.DropdownButton onClick={onClick}>
    <Typography.LabelSmall>{label}</Typography.LabelSmall>
    <Icon type={Icons.ChevronDownLargeFlat} />
  </Styled.DropdownButton>
);

export default DropdownButton;
