import React from 'react';
import SecondaryTab from './SecondaryTab';

import Styled from './SecondaryTabs.styled';

export type TabItem = { title: string };

type SecondaryTabsProps = {
  items: TabItem[];
  onSelect?: (index: number) => void;
  selectedIndex: number;
};

const SecondaryTabs = ({
  items,
  onSelect,
  selectedIndex,
}: SecondaryTabsProps): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <Styled.SecondaryTabs ref={containerRef}>
      {items.map((tabItem, index) => {
        const { title } = tabItem;
        return (
          <SecondaryTab
            key={title}
            selected={selectedIndex === index}
            onClick={(event: React.PointerEvent<HTMLElement>) => {
              if (event.currentTarget) {
                if (containerRef.current) {
                  containerRef.current.scrollLeft =
                    event.currentTarget.offsetLeft;
                }
              }
              onSelect && onSelect(index);
            }}
          >
            {title}
          </SecondaryTab>
        );
      })}
    </Styled.SecondaryTabs>
  );
};

export default SecondaryTabs;
