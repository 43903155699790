import { css } from 'styled-components';
import { parseThemeDefs, Theme } from '../themes';

const theming = Object.keys(Theme).map(
  (key) => css`
    ${key === 'Default' && `:root, `}
    [data-theme='${key}'] {
      ${parseThemeDefs(Theme[key as keyof typeof Theme])}
    }
  `,
);

export default theming;
