import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

import { SearchBoxProvided } from 'react-instantsearch-core';
import SearchBarStyled from '../search/SearchBar.styled';
import Button from '../common/button/Button';
import { ButtonSize } from '../common/button/ButtonSize';
import ThemeContainer from '../common/ThemeContainer';
import { Theme } from '../../styles/themes';
import { Icons } from '../../types/icon-types';
import Input from '../common/input/Input';
import { localize } from '../../localization/i18n';

interface SearchFieldProps extends SearchBoxProvided {
  onFocus: () => void;
  hasFocus: boolean;
}

const SearchField = ({
  refine,
  currentRefinement,
  onFocus,
}: SearchFieldProps): JSX.Element => {
  const initialized = React.useRef(false);
  React.useEffect(() => {
    if (initialized.current) {
      return;
    }
    refine(currentRefinement || '');
    initialized.current = true;
  }, [currentRefinement, refine]);
  return (
    <SearchBarStyled.SiteSearchBar>
      <Input
        type="text"
        placeholder={localize('search.form.inputPlaceholder')}
        onChange={(e) => refine(e.target.value)}
        onFocus={onFocus}
        value={currentRefinement}
        leadingIcon={{
          type: Icons.SearchLarge,
          visible: !currentRefinement,
        }}
        trailingIcon={{
          type: Icons.Close,
          visible: !!currentRefinement,
          onClick: () => refine(''),
        }}
      />
      <ThemeContainer theme={Theme.Primary}>
        <Button size={ButtonSize.Small}>
          {localize('search.form.buttonLabel')}
        </Button>
      </ThemeContainer>
    </SearchBarStyled.SiteSearchBar>
  );
};

const ConnectedSearchField = connectSearchBox(SearchField);

export default ConnectedSearchField;
