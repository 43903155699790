import React from 'react';
import { SlopeLocation } from '../../network/skiresort-api/parsers/slopeDataParser';
import GridHeader from '../common/layout/GridHeader';
import SkiResortAmenities, {
  SkiResortAmenityFilterProps,
} from './SkiResortAmenenities';
import SlopeLocationTabs from './SlopeLocationTabs';

export type SkiResortAmenitiesWithTabsProps = Pick<
  SkiResortAmenityFilterProps,
  'openOnly'
>;

const SkiResortAmenitiesWithTabs = (props: SkiResortAmenitiesWithTabsProps) => {
  const [slopeLocation, setSlopeLocation] = React.useState(SlopeLocation.All);
  return (
    <>
      <GridHeader>
        <SlopeLocationTabs onSelect={setSlopeLocation} />
      </GridHeader>
      <SkiResortAmenities
        groupBy="type"
        {...props}
        slopeLocation={slopeLocation}
      />
    </>
  );
};

export default SkiResortAmenitiesWithTabs;
