import styled from 'styled-components';

type MapProps = {
  width?: string;
  height?: string;
};

const Map = styled.div<MapProps>`
  ${({ width, height }) => `
    width: ${width ?? '100%'};
    height: ${height ?? '34.625rem'};
  `}

  isolation: isolate;
`;

export default {
  Map,
};
