import React from 'react';
import { useLocation } from '@reach/router';
import { useMobileNavigationState } from '../components/navigation/main/mobile/MobileNavigationState';

const useNavigationSheet = (ref: React.RefObject<HTMLDivElement>) => {
  const { state, actions } = useMobileNavigationState();

  const { pathname } = useLocation();
  const currentPathName = React.useRef(pathname);

  React.useEffect(() => {
    if (pathname !== currentPathName.current) {
      if (state.open) {
        actions.close();
      }
    }
    currentPathName.current = pathname;
  }, [actions, pathname, state.open]);

  const onOpen = React.useCallback(() => {
    if (!window || typeof window === 'undefined') return;

    const scrollY =
      document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  }, []);

  const onClose = React.useCallback(() => {
    if (!window || typeof window === 'undefined') return;

    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';

    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }, []);

  const onScroll = React.useCallback(() => {
    document.documentElement.style.setProperty(
      '--scroll-y',
      `${window.scrollY}px`,
    );
  }, []);

  React.useEffect(() => {
    if (!window || typeof window === 'undefined') return;

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  React.useEffect(() => {
    if (ref.current) {
      if (state.open) {
        ref.current.classList.add('open');
        onOpen();
      } else {
        onClose();
        ref.current.classList.remove('open');
      }
    }
  }, [state.open, onOpen, onClose, ref]);

  return {
    state,
    actions,
  };
};

export default useNavigationSheet;
