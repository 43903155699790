import { Link } from "@Levi/components/common/link";
import React from 'react';
import AccordionListing from '../../components/common/accordionlisting/AccordionListing';
import { CardProps, CardType } from '../../components/common/card/Card';
import { Geolocation } from '../../components/common/card/types';
import Grid from '../../components/common/layout/Grid';
import Section from '../../components/section/Section';
import { localize } from '../../localization/i18n';
import { ContentfulGatsbyImage } from '../../types/images';
import { CardDataType } from '../data/cardDataTransformers';
import { restaurantCategoriesToLocalizedList } from '../data/restaurantCategories';
import { restaurantFieldsTransformer } from '../data/transformers';

// Custom section for displaying restaurantCategories at eat-and-drink-page
export type RestaurantCategoriesRestaurantTypes = {
  name: string;
  categories: string[];
  ingress: string;
  images: ContentfulGatsbyImage[];
  id: string;
  location: Geolocation;
};
type RestaurantCategoriesSectionProps = {
  searchPagePath: string;
  restaurants: { edges: { node: RestaurantCategoriesRestaurantTypes }[] };
};

const RestaurantCategoriesSection: React.FC<
  RestaurantCategoriesSectionProps
> = ({ searchPagePath, restaurants }) => {
  const categories = restaurantCategoriesToLocalizedList().map((category) => {
    const { icon, label: title, description: body, value } = category;
    const accordionItem = {
      icon,
      title,
      body,
      linkProps: {
        to: `${searchPagePath}?category=${value}`,
        label: localize('common.browse'),
        as: Link,
      },
    };

    const relatedRestaurants = restaurants.edges.filter(({ node }) =>
      node.categories.includes(value),
    );
    // Transform restaurants' data for horizontal card
    const transformer = restaurantFieldsTransformer;
    const relatedCards: CardProps<CardType.Restaurant>[] =
      relatedRestaurants?.map((card) => {
        const transformedData = transformer(
          card as CardDataType<CardType.Restaurant>,
        ) as CardProps<CardType.Restaurant>;

        return {
          ...transformedData,
          links: [
            {
              destination: {
                pageId: card.node.id,
              },
            },
          ],
        };
      });
    return {
      accordionItem,
      relatedCards,
    };
  });
  /*
  debugger;
  //const sitePageData = useSitePageData();
  const categories = restaurantCategories[locale].map(
    (category: RestaurantCategoryTypes) => {
      const accordionItem = {
        icon: category.icon,
        title: category.label,
        body: category.description,
        linkProps: {
          to: `${searchPagePath}?category=${category.value}`,
          label: 'Browse',
          as: Link,
        },
      };

      // Find restaurants with category
      //TODO: Limit to 4? Randomize?
      const relatedRestaurants = restaurants.edges.filter(({ node }) =>
        node.categories.includes(category.value),
      );

      // Transform restaurants' data for horizontal card
      const transformer = restaurantFieldsTransformer;
      const relatedCards: CardProps<CardType.Restaurant>[] =
        relatedRestaurants?.map((card) => {
          const transformedData = transformer(
            card as CardDataType<CardType.Restaurant>,
          ) as CardProps<CardType.Restaurant>;

          return {
            ...transformedData,
            links: [
              {
                destination: {
                  pageId: card.node.id,
                },
              },
            ],
          };
        });
      return {
        accordionItem,
        relatedCards,
      };
    },
  );*/

  return (
    <Section>
      <Grid noGutter>
        <AccordionListing data={categories} />
      </Grid>
    </Section>
  );
};

export default RestaurantCategoriesSection;
