import React from 'react';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';
import ArrowLink from '../link/ArrowLink';
import { Typography } from '../typography/Typography.styled';
import Styled from './CardGrid.styled';

export type CardGridHeaderProps = {
  title: string;
  subtitle?: string;
  count?: number | undefined;
  callToAction?: ContentfulCallToActionTypes;
  children?: React.ReactNode;
  className?: string;
  centered?: boolean;
};

const CardGridHeader = (props: CardGridHeaderProps): JSX.Element => (
  <Styled.CardGridHeader centered={props.centered}>
    <CardGridHeaderTitle {...props} />
    <CardGridHeaderSubtitle {...props} />
  </Styled.CardGridHeader>
);

const CardGridHeaderTitle = ({ title, subtitle }: CardGridHeaderProps) => {
  if (!(title && subtitle)) return null;
  return <Typography.HeadingLarge>{title}</Typography.HeadingLarge>;
};

const CardGridHeaderSubtitle = ({
  title,
  subtitle,
  count,
  callToAction,
  children,
}: CardGridHeaderProps) => {
  const titleString = subtitle || title;
  const linkProps = useContentfulCallToAction(callToAction);
  return (
    <Styled.CardGridHeaderRow>
      <Typography.SubHeadingLarge>
        {titleString}
        {count !== undefined && <span> ({count})</span>}
      </Typography.SubHeadingLarge>
      {children}
      {linkProps && <ArrowLink {...linkProps} />}
    </Styled.CardGridHeaderRow>
  );
};

export default CardGridHeader;
