import React from 'react';
import { Typography } from '../typography/Typography.styled';
import Styled from './Calendar.styled';

const getDayNames = (locale = 'fi') => {
  return Array(7)
    .fill(0)
    .map((_, index) => {
      const d = new Date(0);
      d.setDate(5 + index);
      return d.toLocaleDateString(locale, { weekday: 'short' });
    });
};

const CalendarWeekDays = ({
  locale = 'fi',
}: {
  locale?: string;
}): JSX.Element => {
  const dayNames = React.useMemo(() => getDayNames(locale), [locale]);
  return (
    <Styled.CalendarWeekDays>
      {dayNames.map((dayName) => (
        <Typography.SubLink key={dayName}>{dayName}</Typography.SubLink>
      ))}
    </Styled.CalendarWeekDays>
  );
};

export default React.memo(CalendarWeekDays);
