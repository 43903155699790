import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from './types';
import { ChildMeta, MetaImage } from '../meta';
import { getSrc } from 'gatsby-plugin-image';

/**
 * This page uses Comgate's activity UI widget
 * https://teams.hiq.fi/confluence/pages/viewpage.action?pageId=97420767
 */
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'wacs-widget': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

type ActivityFields = {
  id: string;
  activityId: string;
  title: string;
  description: string;
  metaImage?: MetaImage;
};

type ActivityPageProps = PageProps & {
  data: {
    activity: ActivityFields;
  };
};

const Activity: React.FC<ActivityPageProps> = ({ data, pageContext }) => {
  const { title, description, activityId, metaImage } = data.activity;

  const meta = {
    lang: pageContext.locale,
    description,
    image: metaImage && getSrc(metaImage?.childImageSharp?.image),
    title,
    hiddenFromSearchEngines: false, // or is it?  🤔
  };

  return (
    <>
      <ChildMeta {...meta}>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.6.10/vue.min.js"></script>
        <script src="https://komponentit.matkaverkko.fi/levi-widget/production/wacs-widget.min.js"></script>
      </ChildMeta>
      <main>{activityId && <wacs-widget data-id={activityId} />}</main>
    </>
  );
};

export default Activity;

export const contentfulPageQuery = graphql`
  query ActivityById($id: String!) {
    activity(id: { eq: $id }) {
      id
      activityId
      title
      description
      metaImage: cardImage {
        childImageSharp {
          image: gatsbyImageData(
            layout: FIXED
            width: 1200
            height: 630
            formats: [JPG]
            placeholder: NONE
          )
        }
      }
    }
  }
`;
