import React from 'react';
import SocialMediaButtons, {
  SocialMediaLink,
} from '../common/social-media-button/SocialMediaButtons';
import { Typography } from '../common/typography/Typography.styled';

export type FooterContactInfoProps = {
  id: string;
  title: string;
  phone: string;
  email: string;
  address: string;
  socialMediaLinks: SocialMediaLink[];
};

const FooterContactInfo = ({
  title,
  phone,
  email,
  address,
  socialMediaLinks,
}: FooterContactInfoProps): JSX.Element => (
  <div>
    <address>
      <Typography.SubHeadingSmall>{title}</Typography.SubHeadingSmall>
      <Typography.SubLink as="a" href={`tel: ${phone}`}>
        {phone}
      </Typography.SubLink>
      <Typography.SubLink as="a" href={`mailto:${email}`}>
        {email}
      </Typography.SubLink>
      <Typography.SubLink>{address}</Typography.SubLink>
    </address>
    {socialMediaLinks && (
      <SocialMediaButtons socialMediaLinks={socialMediaLinks} />
    )}
  </div>
);

export default FooterContactInfo;
