import { ApiPath } from '..';
import { ApiDataParser } from './ApiDataParser';
import { statusToStatusIcon } from './slopeDataParser';

export const otherActivitiesDataParser: ApiDataParser<ApiPath.OtherActivities> = (
  data,
) =>
  data.map((activity) => ({
    ...activity,
    statusIcon: statusToStatusIcon({
      open: activity.open.now,
      temporarilyClosed: activity.open.temporarilyClosed,
    }),
  }));
