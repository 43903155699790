import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover, touchActive } from '../../../styles/utils';
import Icon from '../icon/Icon';
import { CategoryTabProps } from './CategoryTab';

type StyledTabProps = Pick<CategoryTabProps, 'selected'>;

const Tab = styled.button.attrs({ type: 'button' })<StyledTabProps>`
  padding: 0.75rem 1.5rem 0.75rem;

  &:first-of-type {
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;
  }

  &:last-of-type {
    border-top-right-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  background-color: ${({ selected }) =>
    selected ? AppColor.LeviBlue : 'transparent'};
  color: ${({ selected }) => (selected ? AppColor.White : AppColor.LeviBlue)};
  border: 1px solid ${AppColor.LeviBlue};

  & + & {
    border-left-width: 0px;
  }

  ${Icon} + * {
    margin-left: 0.5rem;
  }

  ${hover} {
    &:hover {
      cursor: pointer;
      background-color: ${AppColor.LeviBlueHover};
      color: white;
    }
  }
  ${touchActive} {
    &:active {
      background-color: ${AppColor.LeviBlueHover};
      color: white;
    }
  }

  ${transitions.ButtonHover}
`;

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  * + & {
    margin-top: 1.6875rem;
    ${breakpoints.lg} {
      margin-top: 3rem;
    }
  }
`;

export default {
  Tabs,
  Tab,
};
