import i18n from 'i18n-js';
import { LocaleType } from '../contentful/templates/types';
import { useLocation } from "@reach/router";

// Import all locales
import en from './data/en.json';
import fi from './data/fi.json';

i18n.defaultLocale = 'fi';
i18n.missingTranslation = () => undefined;

i18n.translations = {
  en,
  fi,
};

// Todo validate scope
export const localize = (
  scope: i18n.Scope,
  options?: i18n.TranslateOptions,
): string => i18n.t(scope, options);

export const updateLocale = (locale: LocaleType) => (i18n.locale = locale);

export const resolveLocaleFromLocation = (location: Location) =>
  location.pathname.substring(1, 3) === 'en' ? 'en' : 'fi';

/** Hook to return current page locale */
export const useLocale = ()=>{
  const location = useLocation();
  return resolveLocaleFromLocation(location);
}