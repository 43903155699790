import { LocaleType } from '../contentful/templates/types';
import {
  isDateRange,
  isSameDayDateRange,
  isSameMonthDateRange,
  isSameYearDateRange,
} from '../utils/date-utils';

type FormattedDateProps = {
  date?: Date | { start: Date; end: Date };
  locale?: LocaleType;
  options?: Intl.DateTimeFormatOptions;
};

const useFormattedDate = ({
  date,
  options,
  locale = 'fi',
}: FormattedDateProps): string => {
  const formatter = new Intl.DateTimeFormat(locale, options);
  if (!date) {
    return '';
  }
  if (isDateRange(date)) {
    const startParts = formatter.formatToParts(date.start);
    const endParts = formatter.formatToParts(date.end);

    if (!isSameDayDateRange(date)) {
      if (isSameMonthDateRange(date)) {
        const [startDay, separator] = startParts;
        const [endDay, ...restEnd] = endParts;
        return [
          `${startDay.value}${separator.value}-${endDay.value}`,
          ...restEnd.map(({ value }) => value),
        ].join('');
      }
      if (isSameYearDateRange(date)) {
        return [
          ...startParts.slice(0, -1).map(({ value }) => value),
          '-',
          ...endParts.map(({ value }) => value),
        ].join('');
      }
    } else {
      return formatter.format(date.start);
    }

    return `${formatter.format(date.start)} - ${formatter.format(date.end)}`;
  }

  return formatter.format(date);
};

export default useFormattedDate;
