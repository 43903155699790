import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import { ContentfulAsset } from '../../../../graphql-types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import TextContent from '../../../components/common/textContent/TextContent';
import RichText from '../../richText/richText';
import { ChildMeta } from '../../meta';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import Section from '../../../components/section/Section';
import MainHeader from '../../../components/header/PageHeader';
import { ContentfulGatsbyImage } from '../../../types/images';
import { Geolocation } from '../../../components/common/card/types';
import { EventFields } from './eventsPage';
import Grid from '../../../components/common/layout/Grid';
import useFormattedDate from '../../../hooks/useFormattedDate';
import useStructuredDataForEvent from '../../../hooks/useStructuredDataForEvent';

export type EventBaseFields = {
  id: string;
  contentful_id: string;
  name: string;
  ingress?: string;
  startDate: string;
  endDate?: string;
  hideFromLocalization?: boolean;
};

type EventPageFields = EventBaseFields & {
  metaImage: ContentfulAsset;
  image: ContentfulGatsbyImage;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  categories: string[];
  location?: Geolocation;
};

type EventPageProps = PageProps & {
  data: {
    contentfulEvent: EventPageFields;
    relatedEvents: EventFields[];
  };
};

const ContentfulEvent: React.FC<EventPageProps> = ({ data, pageContext }) => {
  const {
    name,
    ingress,
    body,
    metaImage,
    categories = [],
    image,
    startDate,
    endDate,
  } = data.contentfulEvent;

  const structuredMetaData = useStructuredDataForEvent({
    name,
    startDate,
    endDate,
    ingress,
    image: metaImage,
  });

  const meta = {
    lang: pageContext.locale,
    description: ingress || '',
    image: metaImage,
    title: name,
    hiddenFromSearchEngines: false,
    structuredMetaData,
  };

  const rawDate = React.useMemo(
    () =>
      (endDate && {
        start: new Date(startDate),
        end: new Date(endDate),
      }) ||
      new Date(startDate),
    [startDate, endDate],
  );

  const formattedDate = useFormattedDate({ date: rawDate });

  return (
    <>
      <ChildMeta {...meta} />
      <main>
        <MainHeader image={image} />
        <Section subPage>
          <Grid>
            <TextContent title={name}>
              <Typography.BodyIngres>{ingress}</Typography.BodyIngres>
              <Typography.LinkBig>
                {formattedDate} | {categories?.join(', ')}
              </Typography.LinkBig>
              {body && <RichText data={body} />}
            </TextContent>
          </Grid>
        </Section>
      </main>
    </>
  );
};

export default ContentfulEvent;

export const contentfulPageQuery = graphql`
  query EventById($id: String!, $locale: String!) {
    contentfulEvent(id: { eq: $id }, node_locale: { eq: $locale }) {
      ...contentfulBaseEventFields
      body {
        ...contentfulEventBodyRichTextFields
      }
      metaImage: image {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: [JPG]
          placeholder: NONE
          resizingBehavior: FILL
        )
      }
      categories
      location {
        lat
        lon
      }
      image {
        image: gatsbyImageData(layout: FULL_WIDTH)
        description
        title
        id
      }
      analyticsTags
    }
  }
  # Event' fields needed on both card and on eventPage
  fragment contentfulBaseEventFields on ContentfulEvent {
    id
    contentful_id
    name
    ingress
    startDate
    endDate
    leviBlackCard
    hideFromLocalization
  }
`;
