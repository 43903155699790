import rgba from 'polished/lib/color/rgba';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import { GridElement } from '../layout/Grid.styled';
import { Typography } from '../typography/Typography.styled';

const TableContainer = styled(GridElement)`
  text-align: left;
  grid-column-start: 1;
  grid-column-end: -1;
  ${breakpoints.md} {
    grid-column-start: 3;
    grid-column-end: -3;
  }
`;

const Table = styled(Typography.BodyLarge).attrs({ as: 'table' })`
  width: 100%;
`;

const TableRow = styled.tr`
  &:not(:first-of-type) {
    border-top: 1px solid ${AppColor.White};
    &:nth-of-type(odd) {
      background-color: ${rgba(AppColor.Neutral200, 0.25)};
    }
    &:nth-of-type(even) {
      background-color: ${rgba(AppColor.Neutral300, 0.5)};
    }
  }
`;

const TableCol = styled.td`
  padding: 0.5rem;
`;

const TableHeader = styled(TableCol).attrs({ as: 'th' })`
  ${FontRules.TradeGothicNext.Bold}
`;

export default {
  TableContainer,
  Table,
  TableRow,
  TableHeader,
  TableCol,
};
