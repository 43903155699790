import React from 'react';
import { useDropdownCalculations } from '../../navigation/main/desktop/DesktopNavigation';
import MultiSelectDropdown, {
  MultiSelectDropdownProps,
} from '../dropdown/MultiSelectDropdown';
import Styled from './ListFilters.styled';

export type ListFiltersProps = {
  label: string;
  children?: React.ReactNode;
  filters: MultiSelectDropdownProps[];
  onResetClick?: () => void;
  resetLabel: string;
};

const ListFilters = ({
  label,
  children,
  filters = [],
  onResetClick,
  resetLabel,
}: ListFiltersProps): JSX.Element | null => {
  const ref = React.useRef<HTMLDivElement>(null);

  useDropdownCalculations({ ref });

  const renderDropdown = React.useCallback(
    (filter: MultiSelectDropdownProps) => (
      <MultiSelectDropdown key={filter.name} {...filter} />
    ),
    [],
  );

  if (filters.length === 0) {
    return null;
  }

  return (
    <Styled.ListFiltersContainer ref={ref}>
      <Styled.ListFiltersLabel>{label}</Styled.ListFiltersLabel>
      <Styled.ListFilters>
        {children}
        {filters.map(renderDropdown)}
        {onResetClick && (
          <Styled.ResetButton onClick={onResetClick}>
            {resetLabel}
          </Styled.ResetButton>
        )}
      </Styled.ListFilters>
    </Styled.ListFiltersContainer>
  );
};

export default ListFilters;
