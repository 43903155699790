import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulCardTypes } from './types';
import CardList from '../../components/common/cardlist/CardList';
import useContentfulCardListData from '../../hooks/useContentfulCardListData';
import { HorizontalCardType } from '../../components/common/horizontal-card/HorizontalCard';
import { ContentfulCallToActionTypes } from '../callToActions/types';

export type ContentfulCardListTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  layout: 'default' | 'compact';
  cards: ContentfulCardTypes[];
  callToAction?: ContentfulCallToActionTypes;
};

const ContentfulCardList = ({
  title,
  layout,
  cards,
  callToAction,
}: ContentfulCardListTypes): JSX.Element => {
  if (!cards) cards = [];
  const cardsData = useContentfulCardListData({ cards });

  return (
    <CardList
      title={title}
      cards={cardsData}
      type={
        layout === 'compact'
          ? HorizontalCardType.Compact
          : HorizontalCardType.Default
      }
      callToAction={callToAction}
    />
  );
};

export default ContentfulCardList;

export const contentfulCardListQuery = graphql`
  fragment contentfulCardListFields on ContentfulCardList {
    id
    internal {
      type
    }
    title
    layout
    cards {
      ...contentfulCardFields
      image {
        ...contentfulHorizontalCardImage
      }
    }
    callToAction {
      ...contentfulCallToActionFields
    }
  }
`;
