import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulTableTypes } from '../fragments/contentfulTable';
import InfoGridGrouped from '../../components/common/infogrid/InfoGridGrouped';
import useContentfulTableGroupsData from '../../hooks/useContentfulTableGroupsData';

export type ContentfulTableGroupsTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  groups: ContentfulTableTypes[];
};

const ContentfulTableGroups = ({
  title,
  groups,
}: ContentfulTableGroupsTypes): JSX.Element => {
  const data = useContentfulTableGroupsData({ groups });
  return <InfoGridGrouped title={title} groups={data || []} />;
};

export default ContentfulTableGroups;

export const contentfulLiftUpQuery = graphql`
  fragment contentfulTableGroupsFields on ContentfulTableGroups {
    id
    internal {
      type
    }
    title
    groups {
      ...contentfulTableFields
    }
  }
`;
