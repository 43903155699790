import React from 'react';
import Styled from './Card.styled';
import { Icons } from '../../../types/icon-types';
import CardTag from './CardTag';
import { OpenStatus } from './types';

const CardOpenStatusInfo = ({
  isOpen,
  description,
}: OpenStatus): JSX.Element => (
  <Styled.CardInfoRow>
    <CardTag icon={Icons.Time}>
      <Styled.CardTagTypography variant={`status-${isOpen ? 'green' : 'red'}`}>
        {isOpen ? 'Open' : 'Closed'}
      </Styled.CardTagTypography>
      {description && (
        <>
          <Styled.CardTagTypography variant="separator">
            •
          </Styled.CardTagTypography>
          <Styled.CardTagTypography>{description}</Styled.CardTagTypography>
        </>
      )}
    </CardTag>
  </Styled.CardInfoRow>
);

export default CardOpenStatusInfo;
