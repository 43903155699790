import { ApiPath } from '..';
import { ApiDataParser } from './ApiDataParser';
import { locationToSlopeLocation, statusToStatusIcon } from './slopeDataParser';

export const restaurantsDataParser: ApiDataParser<ApiPath.Restaurants> = (
  data,
) =>
  data.map((restaurant) => ({
    ...restaurant,
    statusIcon: statusToStatusIcon({
      open: restaurant.open.now,
      temporarilyClosed: restaurant.open.temporarilyClosed,
    }),
    slopeLocation: locationToSlopeLocation(restaurant.location['fi']),
  }));
