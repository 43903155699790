import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import Icon, { IconSize } from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${AppColor.Neutral700};
  position: relative;
  flex: 1;
`;

const Hint = styled(Typography.SubLink)`
  * + & {
    margin-top: 0.25rem;
  }
  display: block;
  color: ${AppColor.Neutral600};
`;

const Label = styled(Typography.SubHeadingSmall).attrs({
  as: 'label',
})`
  color: ${AppColor.Neutral600};

  & + ${InputContainer} {
    margin-top: 0.25rem;
  }
`;

const Input = styled.input`
  ${FontRules.TradeGothicNext.Regular}
  color: inherit;

  border-radius: 0.25rem;
  background-color: ${AppColor.White};
  border: 1px solid ${AppColor.Neutral300};
  padding: 0.6875rem 1rem;
  line-height: 1rem;
  flex: 1;

  &:disabled {
    background-color: rgb(158 159 161 / 0.07);
    color: ${AppColor.Neutral500};

    &::placeholder {
      color: ${AppColor.Neutral500};
    }
  }

  &:focus {
    outline: 2px solid ${AppColor.LeviBlue};
    outline-offset: -2px;
    border-color: transparent;
  }

  &::placeholder {
    color: ${AppColor.Neutral600};
  }
`;

type IconProps = {
  position: 'leading' | 'trailing';
};

const StyledIcon = styled(Icon).attrs({
  size: IconSize.Size16,
})<IconProps>`
  color: ${AppColor.Neutral600};
  position: absolute;

  ${(props) =>
    props.position === 'trailing'
      ? `
    & ~ ${Input} {
      padding-right: 3rem;
    }
    right: 0.75rem;
  `
      : `
    & ~ ${Input} {
      padding-left: 3rem;
    }
    left: 1.125rem;
  `}

  ${Input} + & {
    left: unset;
  }
`;

const Wrapper = styled.div`
  flex: 1;
`;

export default {
  InputContainer,
  Input,
  Icon: StyledIcon,
  Label,
  Hint,
  Wrapper,
};
