import { css } from 'styled-components';
import { Breakpoint, breakpoints } from '../breakpoints';

const layoutGrid = css`
  :root {
    --gutter: 1rem;
    --columns: 1;

    --max-section-width: 100vw;
    --max-content-width: calc(100vw - 2rem);

    ${breakpoints.sm} {
      --columns: 6;

      --max-section-width: ${Breakpoint.md}px;
      --max-content-width: calc(100vw - (1.5rem * 2));
    }
    ${breakpoints.md} {
      --columns: 12;

      --max-section-width: ${Breakpoint.lg}px;
      --max-content-width: calc(100vw - (2rem * 2));
    }
    ${breakpoints.lg} {
      --gutter: 1.5rem;

      --max-section-width: 1440px;
      --max-content-width: calc(100vw - (3.4375rem * 2));
    }

    ${breakpoints.max} {
      --max-content-width: 1330px;
    }
  }
`;

export default layoutGrid;
