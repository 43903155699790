import { ApiPath } from '..';
import { ApiDataParser } from './ApiDataParser';
import { locationToSlopeLocation, statusToStatusIcon } from './slopeDataParser';

export const servicesDataParser: ApiDataParser<ApiPath.Services> = (data) =>
  data.map((service) => ({
    ...service,
    statusIcon: statusToStatusIcon({
      open: service.open.now,
      temporarilyClosed: service.open.temporarilyClosed,
    }),
    slopeLocation: locationToSlopeLocation(service.location),
  }));
