import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Breakpoint } from '../styles/breakpoints';

type Breakpoints = {
  hasPointer: boolean;
  isValid: boolean;
  isDesktop: boolean;
  isMobile: boolean;
  isPhone: boolean;
  isBelowMd: boolean;
};

const useBreakpoints = (): Breakpoints => {
  const isValid = useMediaQuery({
    query: `(min-width: 1px)`,
  });
  const [initialized, setInitialized] = React.useState(false);
  const isDesktop = useMediaQuery({
    query: `(min-width: ${Breakpoint.lg}px)`,
  });
  const isPhone = useMediaQuery({
    query: `(max-width: ${Breakpoint.sm}px)`,
  });

  const isBelowMd = useMediaQuery({
    query: `(max-width: ${Breakpoint.md - 0.2}px)`,
  });

  const hasPointer = useMediaQuery({
    query: `(hover: hover) and (pointer: fine)`,
  });

  React.useEffect(() => setInitialized(true), []);

  return {
    isValid,
    hasPointer: hasPointer && initialized,
    isDesktop: isDesktop && initialized,
    isMobile: !isDesktop && initialized,
    isPhone: isPhone && initialized,
    isBelowMd: isBelowMd && initialized,
  };
};

export default useBreakpoints;
