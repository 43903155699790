import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import { DefaultCardProps } from '../types';
import useContentfulCallToAction from '../../../../hooks/useContentfulCallToAction';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Theme } from '../../../../styles/themes';
import useContentfulGatsbyImage from '../../../../hooks/useContentfulGatsbyImage';
import { ContentfulCallToActionTypes } from '../../../../contentful/callToActions/types';

export type DefaultMapInfoProps = Pick<
  DefaultCardProps,
  'id' | 'title' | 'image'
> & {
  location: Geolocation;
  links: ContentfulCallToActionTypes[];
};

const DefaultMapInfoCard = ({
  title,
  image,
  links,
}: DefaultMapInfoProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(links[0]);
  const imageProps = useContentfulGatsbyImage(image);

  return (
    <Styled.Card theme={Theme.Primary} {...(linkProps || {})}>
      {imageProps && (
        <Styled.CardImageWrapper>
          <GatsbyImage {...imageProps} />
        </Styled.CardImageWrapper>
      )}
      <Styled.MapInfoCardContent>
        <Styled.CardHeading>
          <Typography.SubLink>{title}</Typography.SubLink>
        </Styled.CardHeading>
      </Styled.MapInfoCardContent>
    </Styled.Card>
  );
};

export default DefaultMapInfoCard;
