import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { Typography } from '../typography/Typography.styled';

const PlayerContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;

  ${breakpoints.sm} {
    grid-column-start: 2;
    grid-column-end: -2;
  }

  ${breakpoints.md} {
    grid-column-start: 3;
    grid-column-end: -3;
  }
  ${Typography.HeadingLarge} {
    text-align: center;
    margin-bottom: 2rem;
  }

  > div + div {
    margin-top: 2rem;
  }

  > img {
    width: 100%;
    height: auto;
  }
`;

const VimeoPlayer = styled.div`
  width: 100%;
`;

const YoutubePlayer = styled.div`
  width: 100%;

  .youtubeContainer {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  .youtubeContainer iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export default {
  PlayerContainer,
  VimeoPlayer,
  YoutubePlayer,
};
