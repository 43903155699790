import styled from 'styled-components';
import { below, Breakpoint } from '../../../styles/breakpoints';

const AvailabilitySearchForm = styled.div`
  padding: 1rem 2rem;
  position: relative;
  height: unset;
`;

type CalendarContainerProps = {
  isOpen?: boolean;
};

const DateSelector = styled.button`
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
`;

const CalendarContainer = styled.div<CalendarContainerProps>`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  gap: 1.5rem;
  background-color: white;
  position: absolute;
  top: 100%;
  margin-top: 0.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  box-shadow: rgb(45 81 123 / 25%) 0.625rem 0.625rem 3.125rem 0;
  padding: 1.5rem;
  ${below(Breakpoint.md)} {
    max-width: 90vw;
    flex-direction: column;
  }
  ${below(Breakpoint.sm)} {
    left: -4vw;
  }
`;

export default {
  AvailabilitySearchForm,
  CalendarContainer,
  DateSelector,
};
