import * as React from 'react';
import HorizontalCard, {
  HorizontalCardType,
} from '../horizontal-card/HorizontalCard';
import SearchResultHorizontalCard from '../horizontal-card/variants/SearchResultHorizontalCard';
import ServiceHorizontalCard from '../horizontal-card/variants/ServiceHorizontalCard';
import AccommodationCard from './variants/AccommodationCard';
import ActivityCard from './variants/ActivityCard';
import CourseCard from './variants/CourseCard';
import DefaultCard from './variants/DefaultCard';
import EventCard from './variants/EventCard';
import RentalCard from './variants/RentalCard';
import RestaurantCard from './variants/RestaurantCard';
import useLocale from '../../../localization/useLocale';

type HorizontalLayoutProps = boolean | HorizontalCardType;

export const enum CardType {
  Default = 'default',
  Accommodation = 'accommodation',
  Activity = 'activity',
  Course = 'course',
  Event = 'event',
  Rental = 'rental',
  Restaurant = 'restaurant',
  Service = 'service',
  SearchResult = 'searchresult',
  BlogPost = 'blogPost',
}

const cardComponents = {
  [CardType.Default]: DefaultCard,
  [CardType.Accommodation]: AccommodationCard,
  [CardType.Activity]: ActivityCard,
  [CardType.Course]: CourseCard,
  [CardType.Event]: EventCard,
  [CardType.Rental]: RentalCard,
  [CardType.Restaurant]: RestaurantCard,
  [CardType.SearchResult]: SearchResultHorizontalCard,
  [CardType.Service]: ServiceHorizontalCard,
  [CardType.BlogPost]: DefaultCard,
};

type CardComponentTypes = typeof cardComponents;

type CardTypeProps<T extends CardType> = {
  type?: T;
  horizontal?: HorizontalLayoutProps;
  hideFromLocalization?: boolean;
};

type CardComponentProps<T extends CardType> = React.ComponentPropsWithoutRef<
  CardComponentTypes[T]
>;

export type CardProps<T extends CardType> = CardTypeProps<T> &
  Omit<CardComponentProps<T>, keyof CardTypeProps<T>>;

const Card = <T extends CardType>({
  type,
  horizontal = false,
  hideFromLocalization,
  ...props
}: CardProps<T>): JSX.Element => {

  const locale = useLocale();
  if (locale !== 'fi' && hideFromLocalization) return <></>;

  if (horizontal) {
    return (
      <HorizontalCard
        type={horizontal === true ? HorizontalCardType.Default : horizontal}
        {...props}
      />
    );
  }

  const Component = cardComponents[type || CardType.Default] as React.FC;

  return <Component {...props} />;
};

export default Card;
