/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import {
  BookingStatus,
  CardTagProps,
  Geolocation,
} from '../../../components/common/card/types';
import {
  DateRangeValid,
} from '../../../utils/date-utils';
import {
  RatingProps,
} from '../../../components/common/rating/StarRating';
import { ContentfulGatsbyImage } from '../../../types/images';

import { MetaImage } from '../../meta';
import ContentfulEmbed, { ContentfulEmbedTypes } from '@Levi/contentful/sections/contentfulEmbed';
import { Helmet } from 'react-helmet';

export type NumRange = {
  min: number;
  max: number;
};

export type AccommodationImage = {
  childImageSharp: ContentfulGatsbyImage;
};

export type AccommodationFields = {
  id: string;
  accommodationId: string;
  destinationCode: string;
  productCode: string;
  title: string;
  description?: string;
  mainContent?: string;
  areaName?: string;
  areaCode: string;
  distance: number;
  accType: string;
  rooms: number;
  rating?: RatingProps;
  ratingRaw: string;
  location?: Geolocation;
  address?: string;
  postCode?: string;
  tags?: CardTagProps[];
  image?: AccommodationImage;
  images?: [string] | string[];
  metaImage?: MetaImage;
  capacity: NumRange;
  maxPeople: number;
  beds: number;
  extraBeds: number;
  amenities: [string] | string[];
  bookingStatus?: BookingStatus[];
  fromModerAPI: boolean;
};

type AccommmodationPriceForDateRange = {
  // Accommodation DTO's cache resolved price for certain date range on runtime
  resolvedPrice?: number | undefined;
  resolvedPriceDateRange?: DateRangeValid | undefined;
};

export type AccommodationFieldsWithPrices = AccommodationFields &
  AccommmodationPriceForDateRange;

type SearchAccommodationsPageProps = PageProps & {
  data: {
    contentfulPage: {
      sections: ContentfulEmbedTypes[];
    }
  }
};

const COMPONENT_SCRIPT = "Winres search component script";
const CLOUD_SCRIPT = "Winres cloud script";
const REQUIRED_SECTIONS = [COMPONENT_SCRIPT, CLOUD_SCRIPT];

const SearchAccommodationsPage: React.FC<SearchAccommodationsPageProps> = ({
  data: {
    contentfulPage: {
      sections
    }
  },
}) => {
  if (!REQUIRED_SECTIONS.every(section => sections.find(s => s.title === section))) {
    throw new Error("Missing a required section in search accommodation page sections");
  }

  const componentScript = sections.find(section => section.title === COMPONENT_SCRIPT)!;
  const cloudScript = sections.find(section => section.title === CLOUD_SCRIPT)!;
  const bodySections = sections.filter(section => !REQUIRED_SECTIONS.find(s => section.title === s) && section.internal.type === "ContentfulEmbed");

  return (
    <>
      <Helmet>
        <script src={componentScript.embedDirect.content} />
        <script type="text/javascript" dangerouslySetInnerHTML={{ __html: cloudScript.embedDirect.content }} ></script>
        <link rel="stylesheet" type="text/css" href="//komponentit.matkaverkko.fi/webcomponents/feature-test-3/assets/webcomponents.css"/>
      </Helmet>
      <main style={{ width: "100%" }} >
        {bodySections.map((section, i) => 
          <ContentfulEmbed {...section} id={`contentful_embed_${i}`} key={`contentful_embed_${i}`}></ContentfulEmbed>
        )}
      </main>
    </>
  );
};

export default SearchAccommodationsPage;

export const contentfulPageQuery = graphql`
  query SearchAccommodationsPageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      meta {
        ...contentfulPageMetaFields
      }
      sections {
        ...allPageSections
      }
      heroCarousel {
        ...contentfulHeroFields
      }
    }
  }
`;
