import React from 'react';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import { IndexProps } from 'react-instantsearch-core';
import useUrlParamAwareState from '../../hooks/useUrlParamAwareState';
import Section from '../../components/section/Section';
import { SearchPageGrid } from '../../components/common/layout/Grid.styled';
import { Typography } from '../../components/common/typography/Typography.styled';
import ConnectedSearchField from '../../components/site-search/SearchField';
import SearchResults from '../../components/site-search/SearchResults';

import Styled from './serviceListPages/ServiceListPage.styled';

export type SearchPageProps = {
  title: string;
  index: IndexProps;
};

export type PageProps = {
  location: Location;
};

const algoliaAppID = process.env.GATSBY_ALGOLIA_APP_ID || '';
const alogilaSearchKey = process.env.GATSBY_ALGOLIA_SEARCH_KEY || '';

const SearchPageTemplate = ({
  title,
  index,
  location,
}: SearchPageProps & PageProps) => {
  const rootRef = React.createRef<HTMLDivElement>();
  const [query, setQuery] = useUrlParamAwareState<string>({
    location,
    paramName: 'q',
    initialValue: '',
  });
  const [hasFocus, setFocus] = React.useState(false);
  const searchClient = React.useMemo(
    () => algoliasearch(algoliaAppID, alogilaSearchKey),
    [],
  );
  return (
    <main ref={rootRef}>
      <Section>
        <SearchPageGrid noAside>
          <Styled.MainSection centered>
            <Typography.Display>
              {title}
              {query && query.length > 0 ? ':' : ''}{' '}
              <span style={{ opacity: 0.5 }}>{query || ''}</span>
            </Typography.Display>
            <InstantSearch
              searchClient={searchClient}
              indexName={index.indexName}
              onSearchStateChange={({ query }) => setQuery(query)}
            >
              <ConnectedSearchField
                onFocus={() => setFocus(true)}
                hasFocus={hasFocus}
                defaultRefinement={query}
              />
              <SearchResults
                indexName={index.indexName}
                show={!!(query && query.length > 0)}
              />
            </InstantSearch>
          </Styled.MainSection>
        </SearchPageGrid>
      </Section>
    </main>
  );
};

export default SearchPageTemplate;
