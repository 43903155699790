import React from 'react';
import useContentfulCallToAction, {
  LinkComponentTypes,
  LinkProps,
} from '../../../../hooks/useContentfulCallToAction';
import { HorizontalCardType } from '../HorizontalCard';
import Styled from '../HorizontalCard.styled';
import HorizontalCardImageColumn from '../HorizontalCardImageColumn';
import SearchResultHorizontalCardContent from '../SearchResultHorizontalCardContent';
import { SearchResultHorizontalCardProps } from '../types';

const SearchResultHorizontalCard = ({
  image,
  cardLink,
  ...props
}: SearchResultHorizontalCardProps & {
  cardLink?: LinkProps<LinkComponentTypes>;
}): JSX.Element => {
  const maybeLink = props.callToAction
    ? props.callToAction
    : props.links && props.links.length === 1
    ? props.links[0]
    : undefined;

  const linkProps = useContentfulCallToAction(maybeLink);
  const cardLinkProps = cardLink || linkProps || {};

  return (
    <Styled.Card
      type={HorizontalCardType.SearchResult}
      hasImage={!!image}
      $hasLink={cardLinkProps !== {}}
      role="listitem"
    >
      {image && (
        <HorizontalCardImageColumn
          image={image}
          cardLinkProps={cardLinkProps}
        />
      )}
      <SearchResultHorizontalCardContent
        {...props}
        cardLinkProps={cardLinkProps}
      />
    </Styled.Card>
  );
};

export default SearchResultHorizontalCard;
