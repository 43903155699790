import styled from 'styled-components';
import { Typography } from '../common/typography/Typography.styled';
import { AppColor } from '../../styles/colors';
import { default as BaseGridHeader } from '../common/layout/GridHeader';
import transitions from '../../styles/transitions';
import { hover } from '../../styles/utils';

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${AppColor.LeviBlue};

  > button {
    padding: 0;
    ${hover} {
      figure {
        transform: translate3d(0, 0, 0);
        ${transitions.ArrowLinkHover}
      }
      &:not([disabled]) {
        cursor: pointer;
        &:first-of-type {
          &:hover {
            figure {
              transform: translate3d(-0.5rem, 0, 0);
            }
          }
        }
        &:last-of-type {
          &:hover {
            figure {
              transform: translate3d(0.5rem, 0, 0);
            }
          }
        }
      }
    }
  }

  > * + * {
    margin-left: 2rem;
  }
`;

const GridHeader = styled(BaseGridHeader)`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;

  ${Typography.SubHeadingLarge} {
    grid-column: 2;
  }
`;

const ContentContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  overflow: hidden;
`;

const ItemTrack = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--max-section-width) - var(--max-content-width));

  ${transitions.CarouselTrack}
`;

export default {
  ActionsContainer,
  GridHeader,
  ContentContainer,
  ItemTrack,
};
