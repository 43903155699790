import React from 'react';
import ContentfulCallToAction from './contentfulCallToAction';

type MarkdownLinkProps = {
  text: string;
  linkto: string;
  target: 'self' | 'tab';
  type: string;
};

const MarkdownLink = ({ text, linkto, type, target }: MarkdownLinkProps) => {
  return (
    <ContentfulCallToAction
      title={text}
      destination={{ externalUrl: linkto }}
      target={target}
      type={type === 'button' ? 'button' : 'inline-link'}
    />
  );
};

export default MarkdownLink;
