import styled from 'styled-components';
import { below, Breakpoint, breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import { Icons, ICONS_CODEPOINTS } from '../../../types/icon-types';
import Icon from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';
import FontRules from '../../../styles/fontRules';
import { Link } from "@Levi/components/common/link";
import { hover, touchActive } from '../../../styles/utils';
import { LinkProps } from '../../../hooks/useContentfulCallToAction';
import transitions from '../../../styles/transitions';

export type CardLinkProps = Partial<
  Pick<LinkProps<'a'>, 'href'> &
    Pick<LinkProps<typeof Link>, 'to'> & {
      as: typeof Link | 'a';
    }
>;

const CardLink = styled.a.attrs<CardLinkProps>((props) => {
  if (!(props.to || props.href)) {
    return {
      as: 'div',
    };
  }
})<CardLinkProps>`
  position: relative;
  ${(props) =>
    (props.to || props.href) &&
    `
    ${hover} {
      cursor: pointer;
    }
  `}
`;

type StyledCardProps = {
  $hasLink?: boolean;
};

const Card = styled.div<StyledCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  background-color: ${AppColor.White};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    box-shadow: rgb(31 55 69 / 12%) 0px 5px 14px 0;

    opacity: 1;
    ${transitions.CardShadowHover}
  }

  ${(props) =>
    props.$hasLink &&
    `
    ${hover} {
      &:hover {
        &::before {
          opacity: 0.35;
        }
        ${CardHeading} {
          ${Typography.HeadingLarge} {
            &:after {
              transform: translate3d(0,0,0);
              opacity: 1;
            }
          }
        }
        ${CardImageWrapper} {
          .gatsby-image-wrapper {
            transform: translate3d(0, 0, 0) scale(1.05);
          }
        }
      }
    }
    ${touchActive} {
      &:active {
        &::before {
          opacity: 0.35;
        }
        ${CardHeading} {
          ${Typography.HeadingLarge} {
            &:after {
              transform: translate3d(0,0,0);
              opacity: 1;
            }
          }
        }
        ${CardImageWrapper} {
          .gatsby-image-wrapper {
            transform: translate3d(0, 0, 0) scale(1.05);
          }
        }
      }
    }
  `}
`;

const CardImageContainer = styled.div`
  position: relative;
`;

const CardImageWrapper = styled.div`
  max-height: 240px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  > div {
    max-height: 240px;
    overflow: hidden;
  }

  .gatsby-image-wrapper {
    transform: translate3d(0, 0, 0) scale(1);
    ${transitions.CardImageHover}
    width: 100%;
  }
`;

const CardTags = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
`;

const CardInfoRow = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  color: ${AppColor.Neutral600};
  gap: 0.75rem;
  ${Icon} {
    --card-color-heading: ${AppColor.Neutral500};
  }
`;

type CardTagTypographyProps = {
  variant?: 'default' | 'status-green' | 'status-red' | 'separator';
};

const CardTagTypography = styled(
  Typography.SubLink,
).attrs<CardTagTypographyProps>(({ variant = 'default' }) => {
  return {
    className: variant,
  };
})<CardTagTypographyProps>`
  &.separator {
    color: ${AppColor.Neutral500};
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  &.status-green,
  &.status-red {
    text-transform: uppercase;
    font-weight: 700;
  }
  &.status-green {
    color: ${AppColor.SustainableGreen};
  }
  &.status-red {
    color: ${AppColor.ErrorRed};
  }
`;

const CardTag = styled.div`
  display: flex;
  align-items: center;
  color: ${AppColor.Neutral600};

  ${Typography.SubLink} {
    ${below(Breakpoint.lg)} {
      font-size: 0.875rem;
    }
  }

  > ${Icon} {
    margin-right: 0.5rem;
    ${breakpoints.lg} {
      margin-right: 0.75rem;
    }
  }
`;

const LocationMark = styled.div`
  background-color: ${AppColor.LeviDarkBlue};
  color: ${AppColor.White};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  flex-shrink: 0;
  align-self: flex-start;

  ${FontRules.TradeGothicLTStd.Bold}
  font-size: 0.9375rem;
  line-height: 1.2;
`;

const CardLocationMark = styled.div`
  display: flex;
  align-items: center;
  color: ${AppColor.Neutral600};
  ${Typography.SubLink} {
    ${below(Breakpoint.lg)} {
      font-size: 0.875rem;
    }
  }

  > ${LocationMark} {
    margin-right: 0.5rem;
    ${breakpoints.lg} {
      margin-right: 0.75rem;
    }
  }
`;

const CardPriceInfo = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-left: 0.5rem;
`;

type CardHeadingProps = {
  centered?: boolean;
  icon?: Icons;
};

const CardHeading = styled.header<CardHeadingProps>`
  ${Typography.HeadingLarge} {
    position: relative;
    text-align: ${(props) => (props.centered ? 'center' : 'unset')};
    word-break: break-word;
    ${breakpoints.sm} {
      &:after {
        ${(props) =>
          props.icon &&
          `
          content: '${String.fromCodePoint(+ICONS_CODEPOINTS[props.icon])}';
          font-size: 1rem;
          font-family: icons !important;
          position: absolute;
          bottom: 0.625rem;
          right: 0;
          color: ${AppColor.Neutral500};
          opacity: 0;
          transform: translate3d(-1rem,0,0);
          ${transitions.CardHeadingArrow}
        `}
      }
    }
  }
`;

const CardContent = styled.div`
  padding: 1rem;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  color: var(--card-color);

  ${Typography.HeadingLarge},
  ${Typography.NumberMedium},
  ${CardTag} > ${Icon} {
    color: var(--card-color-heading, var(--base-primary-heading-color));
  }

  ${breakpoints.lg} {
    padding: 1.5rem;
  }

  * + ${Typography.BodySmall} {
    margin-top: 1rem;
  }

  * + ${CardLocationMark} {
    margin-top: 0.75rem;
  }
  > * + ${CardTag} {
    margin-top: 0.75rem;
  }
  * + ${CardInfoRow} {
    margin-top: 0.75rem;
  }
  ${CardInfoRow} + ${CardInfoRow} {
    margin-top: 0.375rem;
  }

  * + ${CardTags} {
    margin-top: 1rem;
  }
`;

type CardFooterProps = { horizontal?: boolean };

const CardFooter = styled.div<CardFooterProps>`
  padding: 1rem;

  display: flex;
  flex-grow: 1;

  color: var(--card-color);

  ${Typography.NumberMedium} {
    color: var(--card-color-heading, var(--base-primary-heading-color));
  }

  ${breakpoints.lg} {
    padding: 1.5rem;
  }

  * + & {
    margin-top: auto;
    padding-top: 1rem;
  }

  display: flex;

  ${(props) =>
    props.horizontal
      ? `
    align-items: flex-end;
    justify-content: space-between;
    `
      : `
    flex-direction: column;
    `}
`;

// Do not change
CardFooter.displayName = 'CardFooter';

const MapInfoCardContent = styled(CardContent)`
  padding: 0.8rem;
  * + ${CardInfoRow} {
    margin: 0.5rem 0;
  }
`;

export default {
  Card,
  CardLink,
  CardContent,
  MapInfoCardContent,
  CardHeading,
  CardImageContainer,
  CardImageWrapper,
  CardFooter,
  CardInfoRow,
  CardLocationMark,
  LocationMark,
  CardTags,
  CardTag,
  CardTagTypography,
  CardPriceInfo,
};
