import styled from 'styled-components';
import Icon from '../icon/Icon';
import { AppColor } from '../../../styles/colors';
import { breakpoints } from '../../../styles/breakpoints';
import { hover } from '../../../styles/utils';
import transitions from '../../../styles/transitions';
import { Typography } from '../typography/Typography.styled';

const Accordion = styled.div`
  text-align: left;
  grid-column-start: 1;
  grid-column-end: -1;
  ${breakpoints.md} {
    grid-column-start: 3;
    grid-column-end: -3;
  }
`;

const Summary = styled.summary`
  display: block;
  outline: none;
  padding: 1rem 2rem;
  &:focus-visible {
    outline: auto;
  }
  ${Typography.LabelBig}, ${Icon} {
    ${transitions.AccordionColorHover}
  }
  ${hover} {
    cursor: pointer;
    &:hover {
      color: ${AppColor.LeviBlueHover};
    }
  }
`;

// needed for safari to allow display: flex
const SummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Details = styled.details`
  margin: 0 auto 0.5rem auto;
  background-color: ${AppColor.Neutral200};
  &[open] ${Summary} ${Icon} {
    transform: rotate(90deg);
  }
  & > div {
    padding: 0 2rem 1rem 2rem;
  }
`;

export default {
  Accordion,
  Details,
  Summary,
  SummaryContent,
};
