import React from 'react';
import { CardProps, CardType } from '../components/common/card/Card';
import {
  CardDataType,
  DataTransformer,
  dataTransformers,
} from '../contentful/data/cardDataTransformers';
import useLocale from '../localization/useLocale';

type HookProps<Type extends CardType> = {
  cards: CardDataType<Type>[];
  type: Type;
};

const useContentfulTransformedCardGridData = <Type extends CardType>({
  cards,
  type,
}: HookProps<Type>): CardProps<Type>[] => {
  const locale = useLocale();
  const data = React.useMemo(() => {
    const transformer = dataTransformers[type] as DataTransformer<Type>;
    return cards
      .map((card) => transformer(card) as CardProps<Type>)
      .filter((card) => !(locale !== 'fi' && card.hideFromLocalization));
  }, [cards, type]);

  return data;
};

export default useContentfulTransformedCardGridData;
