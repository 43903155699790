import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import DefaultCard from './DefaultCard';
import CardTags from '../CardTags';
import { RentalCardProps } from '../types';
import CardFooterLink from '../CardFooterLink';
import CardLocationInfo from '../CardLocationInfo';
import CardOpenStatusInfo from '../CardOpenStatusInfo';
import { ApiPath } from '../../../../network/skiresort-api';
import { useSkiResortDataByApiPath } from '../../../../hooks/useSkiResortDataByApiPath';

const RentalCard = ({
  title,
  image,
  description,
  address,
  locationMarkerIndex,
  openStatus,
  tags = [],
  links = [],
  hubDbId,
  theme,
}: RentalCardProps): JSX.Element => {
  const hubDbRental = useSkiResortDataByApiPath(
    ApiPath.Service,
    { id: hubDbId?.hubspotId },
    !!hubDbId?.hubspotId,
  );

  const cardLink = links && links.length === 1 ? links[0] : undefined;

  return (
    <DefaultCard
      title={title}
      image={image}
      theme={theme}
      callToAction={cardLink}
    >
      <CardLocationInfo markerIndex={locationMarkerIndex} address={address} />
      {!hubDbId
        ? openStatus.isOpen && <CardOpenStatusInfo {...openStatus} />
        : hubDbRental &&
          hubDbRental.open &&
          !hubDbRental.open.hidden &&
          hubDbRental.open.openingHours && (
            <CardOpenStatusInfo
              isOpen={hubDbRental.open?.now}
              description={hubDbRental.open.hours}
            />
          )}
      <CardTags tags={tags} />
      <Typography.BodySmall>{description}</Typography.BodySmall>
      <Styled.CardFooter>{links.map(CardFooterLink)}</Styled.CardFooter>
    </DefaultCard>
  );
};

export default RentalCard;
