import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import { below, Breakpoint, breakpoints } from '../../../styles/breakpoints';
import ThemeContainer from '../../common/ThemeContainer';
import { Theme } from '../../../styles/themes';
import { MainNavigationProps } from './MainNavigationPropTypes';
import Icon from '../../common/icon/Icon';
import { hover } from '../../../styles/utils';

type NavContainerProps = Pick<
  MainNavigationProps,
  'hideLocaleLink' | 'hideLogo'
>;

const NavContainer = styled(ThemeContainer)<NavContainerProps>`
  position: ${(props) =>
    props.theme === Theme.PrimaryInverse ? 'absolute' : 'relative'};
  z-index: 99;
  left: 0;
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: space-between;

  background-color: var(--nav-bg-color, ${AppColor.White});
  box-shadow: var(--nav-bg-shadow, rgb(0 0 0 / 7%) 0px 1px 0px);
  color: var(--nav-color, ${AppColor.LeviBlue});
  align-items: center;

  ${below(Breakpoint.md)} {
    --nav-logo-size: ${(props) =>
      props.theme === Theme.PrimaryInverse ? 3.375 : 2.875}rem;
  }

  ${(props) =>
    props.theme === Theme.PrimaryInverse
      ? `
    padding: 0.5625rem 1rem 0.5625rem 0.5625rem;
  `
      : `
    padding: 0.6875rem 1rem 0.6875rem 0.5625rem;
  `};

  ${breakpoints.lg} {
    align-items: var(--nav-vertical-align, center);
    padding: var(--nav-vertical-padding, 0.5625rem) 3rem
      var(--nav-vertical-padding, 0.5625rem) 1.5rem;

    ${(props) =>
      props.hideLogo &&
      `
      ${NavContentContainer} {
        margin-left: auto;
        justify-content: ${props.hideLocaleLink ? 'center' : 'end'};
      }
    `}
  }
`;

const NavLinksUl = styled.ul`
  display: flex;
  padding: 0.625rem;
  gap: 1rem;

  &:last-child {
    padding: 0.625rem 0;
  }

  > li {
    white-space: nowrap;

    li {
      white-space: normal;
    }
  }
`;

const NavContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  max-width: var(--max-section-width);
  margin: 0 auto;

  justify-content: space-between;

  > ${Icon} {
    margin-left: auto;
  }

  ${NavLinksUl} + ${Icon} {
    margin-left: unset;
  }

  ${NavLinksUl}:first-of-type {
    margin-left: auto;
  }

  ${breakpoints.lg} {
    ${hover} {
      ${NavLinksUl}:first-of-type {
        margin: 0 auto;

        &:before {
          content: '';
          width: 4rem;
        }
      }
      a + ${NavLinksUl}:first-of-type {
        &:before {
          content: unset;
          width: unset;
        }
      }
    }
  }
`;

export default { NavContainer, NavContentContainer, NavLinksUl };
