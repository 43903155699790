import React from 'react';
import { ContentfulAsset } from '../../graphql-types';
import { resolveImageUrl, StructuredMetaData } from '../contentful/meta';
import { EventBaseFields } from '../contentful/templates/eventPages/eventPage';

type InputType = Pick<
  EventBaseFields,
  'name' | 'ingress' | 'startDate' | 'endDate'
> & {
  image?: ContentfulAsset;
};

const useStructuredDataForEvent = ({
  name,
  startDate,
  endDate,
  ingress,
  image,
}: InputType): StructuredMetaData => {
  const structuredData = React.useMemo(() => {
    return {
      '@context': 'http://schema.org',
      '@type': 'Event',
      name,
      startDate,
      endDate,
      location: {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Levi, Kittilä',
          addressCountry: 'Finland',
        },
      },
      image: image && resolveImageUrl(image),
      description: ingress,
    };
  }, [endDate, image, ingress, name, startDate]);

  return structuredData;
};

export default useStructuredDataForEvent;
