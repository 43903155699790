import React from 'react';
import { LocaleType } from '../contentful/templates/types';
import { localize } from '../localization/i18n';
import LeviMarketingClient from '../network/levi-marketing/LeviMarketingClient';
import SkiResortAPI from '../network/skiresort-api/SkiResortAPI';

export type NewsletterSubscriptionPayload = {
  email: string;
  locale: LocaleType;
  visitLevi: {
    selected: boolean;
    targetList: string[];
  };
  leviSkiResort: {
    selected: boolean;
    targetList: string[];
  };
};

export type SingleNewsLetterServiceResponse = {
  serviceName: string;
  success: boolean;
};

export type NewsletterSubscriptionResponse = {
  data: SingleNewsLetterServiceResponse[];
  error?: string;
};

export type NewsletterSubscriptionStatus = "idle" | "fetching" | "fetched" | "fail";

const initialData = {
  data: [],
  error: '',
};

const useNewsletterSubmitForm = (): [
  (data: NewsletterSubscriptionPayload) => void,
  NewsletterSubscriptionStatus,
  NewsletterSubscriptionResponse,
  ()=>void,
] => {
  const [status, setStatus] = React.useState<NewsletterSubscriptionStatus>('idle');
  const [responseData, setData] =
    React.useState<NewsletterSubscriptionResponse>(initialData);

  const onSubmit = (data: NewsletterSubscriptionPayload): void => {
    setStatus("fetching"); // Set status to fetching immediately, to disable button

    executeCaptcha()
      .then(() => {
        const promises = buildRequests(data);
        if (promises.length > 0) {
          postData(promises);
        }
      })
      .catch(() => {
        setStatus('fail');
        setData({
          ...responseData,
          error: localize('newsletter.captchaError'),
        });
      });
  };

  const executeCaptcha = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(() =>
        grecaptcha
          .execute(`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`, {
            action: 'submit',
          })
          .then((token) => {
            SkiResortAPI.post('/miscellaneous/v1/recaptha/verify', {
              secret: `${process.env.GATSBY_RECAPTCHA_SITE_KEY}`,
              response: token,
            })
              .then(() => resolve(true))
              .catch(() => reject(false));
          }),
      );
    });
  };

  const buildRequests = (data: NewsletterSubscriptionPayload) => {
    const promises = [];
    promises.push(
      LeviMarketingClient.post({
        email: data.email,
        language: data.locale,
        skiResort: data.leviSkiResort.selected,
        visitLevi: data.visitLevi.selected,
      }),
    );
    return promises;
  };

  const postData = async (
    promises: Promise<SingleNewsLetterServiceResponse>[],
  ) => {
    setStatus('fetching');
    try {
      const responses = await Promise.all(promises);
      setData({
        data: responses,
        error: '',
      });
      setStatus('fetched');
    } catch (err: unknown) {
      setStatus('fail');
      setData({
        ...responseData,
        error: localize('newsletter.errorMessage'),
      });
    }
  };

  const resetStatus = ()=>setStatus("idle");

  return [onSubmit, status, responseData, resetStatus];
};

export default useNewsletterSubmitForm;
