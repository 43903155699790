import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import Button, { ButtonLinkWrapper } from '../../button/Button';
import StarRating from '../../rating/StarRating';
import DefaultCard from './DefaultCard';
import CardTags from '../CardTags';
import { AccommodationCardProps } from '../types';
import useContentfulCallToAction, {
  isInternalCTA,
  LinkProps,
} from '../../../../hooks/useContentfulCallToAction';
import CardLocationInfo from '../CardLocationInfo';
import {
  differenceInFullDays,
  getLocalSQLDate,
  isValidDateRange,
} from '../../../../utils/date-utils';
import { Theme } from '../../../../styles/themes';
import ThemeContainer from '../../ThemeContainer';
import { Link } from "@Levi/components/common/link";
import CardPriceInfo from '../CardPriceInfo';
import { localize, useLocale } from '../../../../localization/i18n';
//TODO Remove the commented block below if the new pricing works ok
// import { resolvePriceForAccommodationReservation } from '../../../../hooks/useAccommodationData';
import CardNoPriceInfo from '../CardNoPriceInfo';
import { moderURL } from '../../../../utils/env-utils';

const AccommodationCard = ({
  title,
  image,
  description,
  callToAction,
  location,
  rating,
  tags = [],
  locationMarkerIndex,
  address,
  guests,
  selectedDateRange,
  // bookingStatus,
  theme,
  noAvailabilitiesAvailable,
  noPriceAvailable,
  resolvedPriceDateRange,
  resolvedPrice,
  fromModerAPI,
  accommodationId,
}: AccommodationCardProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);
  const locale = useLocale();

  const price = resolvedPrice;

  const linkPropsWithParams = React.useMemo(() => {
    if (!linkProps) {
      return undefined;
    }

    let external: boolean = false;
    let url: string;
    let target: React.HTMLAttributeAnchorTarget = "_blank";
    const params: Record<string, any> = {};

    if (guests?.adults) params['adults'] = guests.adults;
    if (guests?.children) params['children'] = guests.children;

    if (isInternalCTA(linkProps)) {
      if (fromModerAPI) {
        if (
          resolvedPrice !== undefined &&
          resolvedPriceDateRange !== undefined
        ) {
          //https://dev-app.moder.fi/visit-levi?start=2022-12-01&end=2022-12-04&adults=3&children=1&lang=en          
          params['start'] = getLocalSQLDate(resolvedPriceDateRange.start);
          params['end'] = getLocalSQLDate(resolvedPriceDateRange.end);          
        }
        external = true;
        params["lang"] = locale;
        target = "_self"; // Open moder in same tab
        url = moderURL(accommodationId);
      } else {
        if (selectedDateRange && isValidDateRange(selectedDateRange)) {
          params['start'] = selectedDateRange.start.getTime();
          params['end'] = selectedDateRange.end.getTime();
        }
        url = linkProps.to;
      }
    } else return undefined;

    url = [url, new URLSearchParams(params).toString()]
      .filter((s) => s.length > 0)
      .join('?');

    const label = !selectedDateRange || price
      ? localize('accommodations.cardLinkBookLabel')
      : localize('accommodations.cardLinkQueryLabel');

    // External links cant use gatsby Link Element
    if(external){
      const linkProps: LinkProps<"a"> = {
        label: label,
        href: url,
        as: "a",
        target: target,
      };
      return linkProps;
    }

    // adjust label based on whether price and availability are available or not
    return {
      ...linkProps,
      label: label,
      to: url,
    } as LinkProps<typeof Link>;
  }, [
    linkProps,
    guests,
    selectedDateRange,
    price,
    fromModerAPI,
    accommodationId,
    resolvedPrice,
    resolvedPriceDateRange,
  ]);

  const nights = React.useMemo(
    () =>
      (selectedDateRange &&
        differenceInFullDays(selectedDateRange.start, selectedDateRange.end)) ||
      1,
    [selectedDateRange],
  );

  return (
    <DefaultCard
      title={title}
      image={image}
      theme={theme}
      cardLink={linkPropsWithParams}
    >
      <Styled.CardInfoRow>
        {location && (
          <CardLocationInfo
            markerIndex={locationMarkerIndex}
            address={address}
          />
        )}
        <StarRating rating={rating} />
      </Styled.CardInfoRow>
      <CardTags tags={tags} />
      <Typography.BodySmall>{description}</Typography.BodySmall>
      <Styled.CardFooter horizontal>
        {linkProps && (
          <ThemeContainer theme={Theme.Primary}>
            <ButtonLinkWrapper {...linkPropsWithParams}>
              <Button>{(linkPropsWithParams || linkProps).label}</Button>
            </ButtonLinkWrapper>
          </ThemeContainer>
        )}
        {!!selectedDateRange &&
          ((!noPriceAvailable && !noAvailabilitiesAvailable && !!price && (
            <CardPriceInfo
              price={
                (!!price &&
                  `${localize('common.startingFromPrice')} ${(
                    (price || 0) / nights
                  ).toFixed(2)}`) ||
                0
              }
              unit={localize('common.night')}
              totalPrice={price}
            />
          )) || (
            <CardNoPriceInfo
              info={localize(
                (noPriceAvailable || !price) && !noAvailabilitiesAvailable
                  ? 'common.priceNotAvailable'
                  : (!noPriceAvailable || !!price) && noAvailabilitiesAvailable
                  ? 'common.availabilityNotAvailable'
                  : 'common.priceAndAvailabilityNotAvailable',
              )}
            />
          ))}
      </Styled.CardFooter>
    </DefaultCard>
  );
};

export default AccommodationCard;
