import React from 'react';
import { graphql } from 'gatsby';
import { BusRoute } from '../../network/skiresort-api/types/BusScheduleDataTypes';
import SkiBusSchedule from '../../components/skibus/SkiBusSchedule';

export type ContentfulSkiBusScheduleTypes = {
  id: string;
  internal: {
    type: string;
  };
  routes: BusRoute[];
};

const ContentfulSkiBusSchedule = ({
  routes,
}: ContentfulSkiBusScheduleTypes): JSX.Element | null => {
  if (!routes?.length) return null;
  return (
    <SkiBusSchedule showRoute={routes.length > 1 ? undefined : routes[0]} />
  );
};

export default ContentfulSkiBusSchedule;

export const contentfulSkiBusScheduleQuery = graphql`
  fragment contentfulSkiBusScheduleFields on ContentfulSkiBusSchedule {
    id
    internal {
      type
    }
    routes
  }
`;
