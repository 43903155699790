import React from 'react';
import styled from 'styled-components';
import {
  LinkComponentTypes,
  LinkProps,
} from '../../../hooks/useContentfulCallToAction';
import StyledButton, {
  ButtonProps,
  StyledRoundButton,
  StyledTertiaryButton,
} from './Button.styled';

const Button: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
);

export default Button;

export const TertiaryButton: React.FC<ButtonProps> = ({
  children,
  ...props
}) => <StyledTertiaryButton {...props}>{children}</StyledTertiaryButton>;

export const RoundButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <StyledRoundButton {...props}>{children}</StyledRoundButton>
);

const StyledAsC = styled.div``;

export const ButtonLinkWrapper: React.FC<LinkProps<LinkComponentTypes>> = ({
  children,
  ...props
}: LinkProps<LinkComponentTypes>): JSX.Element => (
  <StyledAsC {...props}>{children}</StyledAsC>
);
