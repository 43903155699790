import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import { hover } from '../../../styles/utils';
import Icon from '../../common/icon/Icon';
import { Typography } from '../../common/typography/Typography.styled';
import Dropdown from '../main/desktop/MainNavigationDropdown.styled';

const DropdownContainer = styled(Dropdown.DropdownContainer)`
  background-color: white;
  padding: 0;
  left: -50%;
  &::after {
    left: 69%;
  }
`;

const WeatherStationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  gap: 2rem;

  ${DropdownContainer} & {
    display: grid;
    gap: unset;
    height: unset;
    grid-template-columns: repeat(2, 1fr);
  }

  padding: 2rem 1.5rem 1.5rem;
`;

const Title = styled(Typography.LinkSmall)`
  color: ${AppColor.LeviBlue};
`;

const WeatherInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-areas:
    'icon temperature'
    'icon windspeed';
  column-gap: 0.75rem;
  color: ${AppColor.Neutral700};
  white-space: nowrap;

  ${Icon} {
    grid-area: icon;
  }
`;

const Temperature = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;

  grid-area: temperature;

  ${Typography.NumberSmall} {
    margin-top: 0.3em;
  }
`;

const WindSpeed = styled(Typography.NumberSmall)`
  grid-area: windspeed;
`;

const WeatherStation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${DropdownContainer} & {
    display: block;
    flex-direction: unset;
    align-items: unset;

    & + & {
      border-left: 1px solid ${AppColor.Neutral300};
    }

    &:nth-child(odd) {
      padding-right: 1.5rem;
    }

    &:nth-child(even) {
      padding-left: 1.5rem;
    }
  }

  ${Title} + ${WeatherInfo} {
    margin-top: 0.75rem;
  }
`;

const DropdownFooter = styled.div`
  padding: 0 1.5rem 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${Typography.LinkBig} {
    color: ${AppColor.LeviBlue};
  }
`;

const UnitSelector = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

type UnitProps = {
  selected?: boolean;
};

const Unit = styled(Typography.NavigationLink).attrs({
  as: 'button',
})<UnitProps>`
  color: ${AppColor.LeviBlue};
  border-radius: 4px;
  background-color: unset;
  height: 1.625rem;

  ${hover} {
    cursor: pointer;
  }

  ${(props) =>
    props.selected &&
    `
    border: 1px solid #0092c8;
    pointer-events: none;
  `}
`;

export default {
  DropdownContainer,
  WeatherStationsContainer,
  WeatherStation,
  Title,
  WeatherInfo,
  Temperature,
  WindSpeed,
  DropdownFooter,
  UnitSelector,
  Unit,
};
