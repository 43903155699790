import styled, { css } from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import AccordionStyled from '../accordion/Accordion.styled';
import { GridElement } from '../layout/Grid.styled';
import { Typography } from '../typography/Typography.styled';
import { Icons, ICONS_CODEPOINTS } from '../../../types/icon-types';

const icon = String.fromCodePoint(+ICONS_CODEPOINTS[Icons.ChevronRightBold]);

const TextContentHeader = styled.header`
  justify-content: center;
  text-align: center;
  margin-bottom: 2.5rem;
`;

type TextContentProps = {
  leftAligned?: boolean;
  narrow?: boolean;
};

const cssRules = css`
  ${FontRules.TradeGothicNext.Light}

  color: var(--base-body-color);

  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.0625rem;

  > :not(${TextContentHeader}) {
    --base-primary-heading-color: ${AppColor.Neutral700};
  }

  > * {
    &:not(${AccordionStyled.Accordion}, ${TextContentHeader}) {
      & + * {
        margin-top: 1.5rem;
      }
    }
  }

  a.cta {
    margin-left: auto;
    margin-right: auto;
  }

  ol {
    counter-reset: index;

    li > ul,
    li > ol {
      margin-top: 0.5rem;
      margin-left: 1.25rem;
    }

    li + li {
      margin-top: 0.5rem;
    }

    > li {
      position: relative;
      > li {
        padding-left: 2rem;
      }
      counter-increment: index;
      &:before {
        content: counters(index, '.') '.';
        position: relative;
        min-width: 1.25rem;
        display: inline-block;
      }
    }
  }

  ul {
    padding-left: 0.75rem;

    li > ul,
    li > ol {
      margin-top: 0.5rem;
    }

    li + li {
      margin-top: 0.5rem;
    }

    > li {
      position: relative;
      padding-left: 0.5rem;

      &:before {
        font-size: 0.875rem;
        font-family: icons !important;
        line-height: 1;
        color: ${AppColor.Neutral600};
        content: '${icon}';
        position: absolute;
        top: 0;
        left: -0.75rem;
        top: 0.375rem;
      }
    }
  }
/*
  ul {
    li {
      position: relative;
      padding-left: 1rem;

      &:before {
        display: block;
        content: '';
        background-color: ${AppColor.Neutral700};
        border-radius: 3px;
        height: 6px;
        width: 6px;
        position: absolute;
        top: 0.5em;
        left: 0;
      }
    }
    */
  }

  ul,
  ol {
    ul {
      margin-top: 0;
    }

    ol {
      margin-top: 0;
    }
  }

  > * + ${Typography.HeadingLarge} {
    margin-top: 5rem;
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 1.5rem 0;
    background-color: ${AppColor.Neutral300};
    border: 0;
  }
`;

const TextContent = styled(GridElement)<TextContentProps>`
  display: flex;
  flex-direction: column;
  &:first-child:nth-last-child(1) {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  &:first-child:nth-last-child(2),
  &:first-child:nth-last-child(2) ~ & {
    grid-column: 1 / -1;
    ${breakpoints.md} {
      grid-column: span ${(props) => (props.leftAligned ? 8 : 6)};
    }
  }

  ${cssRules}

  ${(props) =>
    props.leftAligned &&
    `
    ${TextContentHeader} {
      text-align: left;
    }
  `}

  ${breakpoints.md} {
    &:first-child:nth-last-child(1) {
      grid-column-start: ${(props) =>
        !props.leftAligned ? (!props.narrow ? 3 : 4) : 1};
      grid-column-end: ${(props) =>
        !props.leftAligned ? (!props.narrow ? -3 : -4) : 8};
    }
  }

  ${(props) => props.narrow && 'text-align: center'};
`;

const Aside = styled.aside`
  grid-column: 1 / -1;
  * + & {
    margin-top: 1.5rem;
  }
  ${breakpoints.md} {
    margin-top: 0;
    & + & {
      margin-top: 1.5rem;
    }
    grid-row: main;
    grid-column-start: -5;
    grid-column-end: -1;
  }

  ${cssRules}
`;

export default {
  Aside,
  TextContent,
  TextContentHeader,
};
