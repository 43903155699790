import React, { NamedExoticComponent } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Theme } from '../../../../styles/themes';
import { Typography } from '../../typography/Typography.styled';
import Styled from '../Card.styled';
import { DefaultCardProps } from '../types';
import useContentfulGatsbyImage from '../../../../hooks/useContentfulGatsbyImage';
import { Icons } from '../../../../types/icon-types';
import useContentfulCallToAction, {
  LinkComponentTypes,
  LinkProps,
} from '../../../../hooks/useContentfulCallToAction';
import LeviBlackCard from '../../levi-black-card/LeviBlackCard';
import { LeviBlackCardSize } from '../../levi-black-card/LeviBlackCardSize';
import { LeviBlackCardPosition } from '../../levi-black-card/LeviBlackCardPosition';

export const transformCardLinkProps = (
  props: LinkProps<LinkComponentTypes> = {},
) =>
  Object.keys(props).reduce((acc, key) => {
    const transformedKey = key === 'as' ? key : `$${key}`;
    return {
      ...acc,
      [transformedKey]: props[key as keyof typeof props],
    };
  }, {});

type ChildType = React.ReactNode;
type ChildAccumulator = {
  content: ChildType[];
  footer: ChildType[] | undefined;
};

const isExoticComponent = (
  child: React.ReactNode,
): child is NamedExoticComponent =>
  !!(child as NamedExoticComponent).displayName;

const isFooterChild = (child: React.ReactNode) =>
  React.isValidElement(child) &&
  typeof child !== 'string' &&
  isExoticComponent(child.type) &&
  child.type.displayName === 'CardFooter';

const DefaultCard = ({
  title,
  image,
  description,
  children,
  callToAction,
  theme,
  cardLink,
}: DefaultCardProps & {
  cardLink?: LinkProps<LinkComponentTypes>;
}): JSX.Element => {
  const imageProps = useContentfulGatsbyImage(image);
  const linkProps = useContentfulCallToAction(callToAction);
  const { content, footer } = React.useMemo(
    () =>
      React.Children.toArray(children).reduce(
        (acc: ChildAccumulator, child) => {
          if (isFooterChild(child)) {
            acc.footer = acc.footer || [];
            acc.footer = [...acc.footer, child];
          } else {
            acc.content = [...acc.content, child];
          }
          return acc;
        },
        { content: [], footer: undefined },
      ),
    [children],
  );

  const cardLinkProps = cardLink || linkProps || {};
  return (
    <Styled.Card
      theme={theme || Theme.Primary}
      $hasLink={cardLinkProps !== {}}
      role="listitem"
    >
      <Styled.CardLink {...cardLinkProps}>
        {imageProps && (
          <Styled.CardImageContainer>
            <Styled.CardImageWrapper>
              <GatsbyImage {...imageProps} />
            </Styled.CardImageWrapper>
            {theme === Theme.LeviBlack && (
              <LeviBlackCard
                size={LeviBlackCardSize.Small}
                position={LeviBlackCardPosition.Middle}
              />
            )}
          </Styled.CardImageContainer>
        )}
        <Styled.CardContent>
          <Styled.CardHeading
            centered={!children}
            icon={children || description ? undefined : Icons.ArrowRightMedium}
          >
            <Typography.HeadingLarge>{title}</Typography.HeadingLarge>
          </Styled.CardHeading>
          {description && !children && (
            <Typography.BodySmall>{description}</Typography.BodySmall>
          )}
          {content}
        </Styled.CardContent>
      </Styled.CardLink>
      {footer ? <> {footer} </> : null}
    </Styled.Card>
  );
};

export default DefaultCard;

export const contentfulCardImage = graphql`
  fragment contentfulCardImage on ContentfulAsset {
    image: gatsbyImageData(
      width: 428
      aspectRatio: 1.78333
      breakpoints: [428]
      layout: CONSTRAINED
      placeholder: BLURRED
      quality: 50
      resizingBehavior: FILL
      backgroundColor: "transparent"
    )
    description
    title
  }
`;
