import React from 'react';
import { addMonths, isBefore, isEqual, isWithinInterval } from 'date-fns';
import {
  DateRange,
  calendarMonthsToDays,
  calendarMonthDates,
} from '../../../utils/date-utils';
import {
  CalendarState,
  useCalendarStore,
  useCalendarStoreAPI,
} from './calendarStore';
import {
  resolveDayHoverStates,
  resolveDayStates,
} from './calendarStateResolver';

type CalendarAPI = {
  changeSelectedMonth: (direction: -1 | 1) => void;
  setHoverDate: (date: Date) => void;
  setSelectedDate: (date: Date) => void;
};

const allowDeselectSelector = (state: CalendarState) => state.allowDeselect;
const allowRangeSelector = (state: CalendarState) => state.allowRangeSelection;

const useCalendarAPI = (): CalendarAPI => {
  const api = useCalendarStoreAPI();
  const allowDeselect = useCalendarStore(allowDeselectSelector);
  const allowRangeSelection = useCalendarStore(allowRangeSelector);

  const setHoverDate = React.useCallback(
    (date: Date) => {
      if (!allowRangeSelection) {
        return;
      }
      const curr = api.getState().hoverDate;
      const same = curr && isEqual(date, curr);
      if (same) {
        return;
      }
      api.setState((state) => {
        const newState = {
          ...state,
          hoverDate: date,
        };
        const days = resolveDayHoverStates(newState);
        return {
          ...newState,
          days,
        };
      });
    },
    [api, allowRangeSelection],
  );

  const setSelectedDate = React.useCallback(
    (date: Date) => {
      if (allowRangeSelection) {
        return;
      }
      api.setState((state) => {
        const newState = {
          ...state,
          hoverDate: undefined,
          selectedDate: state.selectedDate
            ? isEqual(date, state.selectedDate)
              ? allowDeselect
                ? undefined
                : date
              : date
            : date,
        };
        const days = resolveDayStates(newState);
        return {
          ...newState,
          days,
        };
      });
    },
    [api, allowDeselect, allowRangeSelection],
  );

  const setSelectedDateRange = React.useCallback(
    (date: Date) => {
      if (!allowRangeSelection) {
        return;
      }
      const currentRange = api.getState().selectedDateRange;
      const { start, end } = currentRange;
      const selectedDateRange: DateRange = { start, end };
      if (!start && !end) {
        selectedDateRange.start = date;
      } else {
        if (start && end) {
          if (isEqual(start, date) || isEqual(end, date)) {
            selectedDateRange.start = undefined;
            selectedDateRange.end = undefined;
          } else {
            selectedDateRange.start = date;
            selectedDateRange.end = undefined;
          }
        } else {
          if (start) {
            if (isBefore(date, start)) {
              selectedDateRange.start = date;
              selectedDateRange.end = start;
            } else {
              selectedDateRange.start = start;
              selectedDateRange.end = date;
            }
          }
        }
      }

      api.setState((state) => {
        const newState = {
          ...state,
          hoverDate: undefined,
          selectedDateRange: { ...selectedDateRange },
        };

        const days = resolveDayStates(newState);

        return {
          ...newState,
          days,
        };
      });
    },
    [api, allowRangeSelection],
  );

  const changeSelectedMonth = React.useCallback(
    (direction: -1 | 1) => {
      api.setState((state) => {
        const selectedMonth = new Date(
          state.selectedMonth.getFullYear(),
          state.selectedMonth.getMonth() + direction,
        );

        const newState = {
          ...state,
          selectedMonth,
          days: calendarMonthsToDays(selectedMonth, state.numberOfMonths),
        };

        const days = resolveDayStates(newState);

        const resolvedState = {
          ...newState,
          days,
        };

        const offsetDays = Array(state.numberOfMonths)
          .fill(0)
          .reduce((acc, curr, currentIndex) => {
            const daysForOffsetMonth = calendarMonthDates(
              addMonths(selectedMonth, currentIndex),
            );
            acc[currentIndex] = resolvedState.days
              .filter((day) => {
                const interval = {
                  start: daysForOffsetMonth[0],
                  end: daysForOffsetMonth[daysForOffsetMonth.length - 1],
                };
                return isWithinInterval(day.date, interval);
              })
              .map((day) => day.key);
            return acc;
          }, {});

        return {
          ...resolvedState,
          offsetDays,
        };
      });
    },
    [api],
  );

  return {
    changeSelectedMonth,
    setHoverDate,
    setSelectedDate: allowRangeSelection
      ? setSelectedDateRange
      : setSelectedDate,
  };
};

export default useCalendarAPI;
