import React from 'react';
import { ChildMeta, ContentfulPageMetaFields } from '../../contentful/meta';
import NotFoundPageTemplate, {
  NotFoundPageProps,
} from '../../contentful/templates/NotFoundPageTemplate';

const meta: ContentfulPageMetaFields = {
  title: 'Page not found - Levi',
  description: '',
  image: undefined,
  lang: 'en',
  hiddenFromSearchEngines: true,
};

const notFoundPageTemplateProps: NotFoundPageProps = {
  title: 'Page not found',
  body: 'The resource you requested does not exist.',
  link: {
    label: 'To frontpage',
    to: '/en',
  },
};

const NotFoundPage: React.FC = () => (
  <>
    <ChildMeta {...meta} />
    <NotFoundPageTemplate {...notFoundPageTemplateProps} />
  </>
);

export default NotFoundPage;
