import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { AppColor } from '../../styles/colors';
import ThemeContainer from '../common/ThemeContainer';
import { SectionProps } from './Section';

const Section = styled(ThemeContainer).attrs({
  forwardedAs: 'section',
})<SectionProps>`
  background-color: ${(props) =>
    !props.fullWidth ? AppColor.Neutral100 : AppColor.Neutral200};
  width: ${(props) => (!props.fullWidth ? '100%' : '100vw')};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${({ fullWidth, subPage }) =>
    subPage ? `padding: 2rem 0;` : !fullWidth && `padding: 3rem 0;`}
  gap: var(--gutter);

  ${breakpoints.sm} {
    max-width: ${(props) =>
      !props.fullWidth ? 'var(--max-section-width)' : 'unset'};
    flex-direction: row;

    ${({ fullWidth, subPage }) =>
      subPage ? `padding: 2rem 0;` : !fullWidth && `padding: 5rem 0;`}
  }
`;

export default {
  Section,
};
