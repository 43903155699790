import { isEqual } from 'date-fns';
import { CalendarState, useCalendarStore } from './calendarStore';

const selector = (state: CalendarState) => state.selectedMonth;
const comparison = (prev: Date, curr: Date) => isEqual(prev, curr);

const useCalendarSelectedMonth = (): Date =>
  useCalendarStore(selector, comparison);

export default useCalendarSelectedMonth;
