import React from 'react';
import { AppColor } from '../../styles/colors';
import RowStatusIcon from './RowStatusIcon';
import Styled from './SkiResortAmenities.styled';
import { Icons } from '../../types/icon-types';
import { Typography } from '../common/typography/Typography.styled';
import { localize } from '../../localization/i18n';

const ListLegendItem = ({
  identifier,
  definition,
}: {
  identifier: JSX.Element;
  definition: string;
}) => (
  <Styled.ListLegendItem>
    <dt>{identifier}</dt>
    <Styled.ListLegendDef>{definition}</Styled.ListLegendDef>
  </Styled.ListLegendItem>
);

const SkiResortAmenitiesListLegend = () => (
  <Styled.ListLegendContainer>
    <Typography.SubHeadingSmall>
      {localize('slopes.legend.title')}
    </Typography.SubHeadingSmall>
    <Styled.ListLegend>
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.EasyGreen} />
        }
        definition={localize('slopes.legend.very_easy')}
      />
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.LeviBlue} />
        }
        definition={localize('slopes.legend.easy')}
      />
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.ErrorRed} />
        }
        definition={localize('slopes.legend.intermediate')}
      />
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.Neutral700} />
        }
        definition={localize('slopes.legend.hard')}
      />
      <ListLegendItem
        identifier={<Styled.LiftIdentifier />}
        definition={localize('slopes.legend.lift')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon type={Icons.Snowmaking} color={AppColor.LeviBlue} />
        }
        definition={localize('slopes.legend.snowmaking')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon
            type={Icons.SlopeOpen}
            color={AppColor.SustainableGreen}
          />
        }
        definition={localize('slopes.legend.open')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon
            type={Icons.SlopeTemporarilyClosed}
            color={AppColor.FunctionalYellow}
          />
        }
        definition={localize('slopes.legend.temporarily_closed')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon type={Icons.SlopeClosed} color={AppColor.ErrorRed} />
        }
        definition={localize('slopes.legend.closed')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon
            type={Icons.SlopeHasLighting}
            color={AppColor.LeviDarkBlue}
          />
        }
        definition={localize('slopes.legend.has_lighting')}
      />
    </Styled.ListLegend>
  </Styled.ListLegendContainer>
);

export default SkiResortAmenitiesListLegend;


export const BikeTrailAmenitiesListLegend = () => (
  <Styled.ListLegendContainer>
    <Styled.ListLegend>
    <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.EasyGreen} />
        }
        definition={localize('slopes.legend.very_easy')}
      />
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.LeviBlue} />
        }
        definition={localize('slopes.legend.easy')}
      />
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.ErrorRed} />
        }
        definition={localize('slopes.legend.intermediate')}
      />
      <ListLegendItem
        identifier={
          <Styled.SlopeDisplayName difficultyColor={AppColor.Neutral700} />
        }
        definition={localize('slopes.legend.hard')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon
            type={Icons.SlopeOpen}
            color={AppColor.SustainableGreen}
          />
        }
        definition={localize('slopes.legend.open')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon
            type={Icons.SlopeTemporarilyClosed}
            color={AppColor.FunctionalYellow}
          />
        }
        definition={localize('slopes.legend.temporarily_closed')}
      />
      <ListLegendItem
        identifier={
          <RowStatusIcon type={Icons.SlopeClosed} color={AppColor.ErrorRed} />
        }
        definition={localize('slopes.legend.closed')}
      />
    </Styled.ListLegend>
  </Styled.ListLegendContainer>
);
