import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Styled from './HorizontalCard.styled';
import { HorizontalCardImageColumnProps } from './types';
import useContentfulGatsbyImage from '../../../hooks/useContentfulGatsbyImage';
import CardStyled from '../card/Card.styled';

const HorizontalCardImageColumn = ({
  image,
  cardLinkProps = {},
}: HorizontalCardImageColumnProps): JSX.Element => {
  const imageProps = useContentfulGatsbyImage(image);

  return (
    <Styled.CardImageColumn>
      {imageProps && (
        <CardStyled.CardLink {...cardLinkProps}>
          <Styled.CardImageWrapper>
            <GatsbyImage {...imageProps} />
          </Styled.CardImageWrapper>
        </CardStyled.CardLink>
      )}
    </Styled.CardImageColumn>
  );
};

export default HorizontalCardImageColumn;
