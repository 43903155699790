import React from 'react';
import styled from 'styled-components';
import { Link } from "@Levi/components/common/link";
import useCookieConsent, { getCookieConsentValue } from './useCookieConsent';
import Button from '../common/button/Button';
import { Typography } from '../common/typography/Typography.styled';
import { ButtonSize } from '../common/button/ButtonSize';
import ThemeContainer from '../common/ThemeContainer';
import { Theme } from '../../styles/themes';
import { AppColor } from '../../styles/colors';
import { localize } from '../../localization/i18n';
import { hostURL, isProduction } from '../../utils/env-utils';

const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 999;
`;

type CenteredProps = { centered: boolean };

const Wrapper = styled(ThemeContainer)
  .attrs({ theme: Theme.Primary })
  .attrs<CenteredProps>(props => ({ centered: props.centered ?? true }))
`
  align-items: baseline;
  box-shadow: rgb(45 81 123 / 25%) 0.625rem 0.625rem 3.125rem 0;
  background: ${AppColor.White};
  display: flex;
  flex-direction: column;
  ${
    props => props.centered ?
      "justify-content: center; width: 800px;" : 
      "left: 0px; position: fixed; width: 100%; z-index: 999; bottom: 0px;"
  }
`;

const Container = styled.div`
  max-width: var(--max-section-width);
  width: 100%;
  margin: 0 auto;
`;

const ContentContainer = styled.div
  .attrs<CenteredProps>(props => ({ centered: props.centered ?? true }))<CenteredProps>
`
  max-width: var(--max-content-width);
  margin: 0 auto;
  ${
   props => props.centered ? 
    "padding: 4rem calc((var(--max-section-width) - var(--max-content-width)) * 0.5) 4rem;" :
    "padding: 1.5rem calc((var(--max-section-width) - var(--max-content-width)) * 0.5) 3rem;"
  }
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  ${Typography.BodySmall} {
    text-align: center;
  }
`;

const ActionsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const _hsq: any[];

// Construct custom domain for consent cookie
const consentDomain = isProduction ?
  // In prod, construct domain no subdomains
  new URL(hostURL).hostname.split(".").reduceRight<string[]>((parts, current) => {
    if(parts.length < 2) parts.unshift(current);
    return parts;
  }, []).join(".")
  :
  undefined; // non-prod: Use default domain

const CookieConsentWrapper = () => {
  const onAccept = React.useCallback(() => {
    gtag('consent', 'update', {
      analytics_storage: 'granted',
    });
    _hsq.push(['doNotTrack', { track: true }]);
    window.fbq?.('consent', 'grant');
    loadHotjar();
    dataLayer.push({ 'event': 'consent_accepted' });
  }, []);

  const onDecline = React.useCallback(() => {
    gtag('consent', 'default', {
      analytics_storage: 'denied',
    });
    _hsq.push(['doNotTrack']);
    window.fbq?.('consent', 'revoke');
  }, []);

  const { accept, decline, visible } = useCookieConsent({
    onAccept,
    onDecline,
    extraCookieOptions: {
      domain: consentDomain,
    }
  });

  React.useEffect(() => {
    const consent = getCookieConsentValue();
    if (consent === 'true') {
      onAccept();
    }
  }, [onAccept]);

  if (!visible) {
    return null;
  }

  const nonCenteredPages = [
    "/info/yleista/rekisteriselosteet",
    "/en/info/general/register-descriptions",
    "/info/visit-levi/rekisteriseloste-vl",
    "/en/info/visit-levi/register-description",
    "/info/levi-ski-resort/rekisteriseloste",
    "/en/info/levi-ski-resort/privacy-policy",
    "/info/yleista/rekisteriselosteet/",
    "/en/info/general/register-descriptions/",
    "/info/visit-levi/rekisteriseloste-vl/",
    "/en/info/visit-levi/register-description/",
    "/info/levi-ski-resort/rekisteriseloste/",
    "/en/info/levi-ski-resort/privacy-policy/",
  ]
  const centered = !nonCenteredPages.includes(window.location.pathname);
  const CenterWrapper = centered ? Center : React.Fragment;

  console.log(centered, window.location.pathname)

  return (
    <CenterWrapper>
      <Wrapper centered={centered} >
        <Container>
          <ContentContainer centered={centered} >
            <Typography.BodySmall>
              {localize('cookieConsent.description')}{' '}
              <Link to={localize('cookieConsent.readMoreLink')}>
                {localize('cookieConsent.readMoreLinkLabel')}
              </Link>
            </Typography.BodySmall>
            <ActionsContainer>
              <Button size={ButtonSize.Small} onClick={accept}>
                {localize('cookieConsent.acceptLabel')}
              </Button>
              <ThemeContainer theme={Theme.Default}>
                <Button size={ButtonSize.Small} onClick={decline}>
                  {localize('cookieConsent.declineLabel')}
                </Button>
              </ThemeContainer>
            </ActionsContainer>
          </ContentContainer>
        </Container>
      </Wrapper>
    </CenterWrapper>
  );
};

export default CookieConsentWrapper;