import React from 'react';
import { graphql } from 'gatsby';
import {
  ContentfulSectionContainerTypes,
  getComponentByType,
} from '../containers/contentfulSectionContainer';

// Handle SplitSection content-type
// Idea here is to allow displaying two "sections" side by side.

export type ContentfulPartTypes = ContentfulSectionContainerTypes;

export type ContentfulSplitSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  part1: ContentfulPartTypes;
  part2: ContentfulPartTypes;
};

const getComponent = (entry: ContentfulPartTypes) => {
  if (!entry) {
    return null;
  }
  if (
    entry?.internal?.type === 'ContentfulLiftUp' ||
    entry?.internal?.type === 'ContentfulBlogsSection'
  ) {
    // override Liftup's layout to 'tight' since we cannot display other variations inside splitSection
    entry = { ...entry, layout: 'tight' };
  }

  return getComponentByType(entry);
};

//TODO @saario Some adjacent components should be handled differently on mobile sizes
const ContentfulSplitSection = ({
  part1,
  part2,
}: ContentfulSplitSectionTypes): JSX.Element | null => {
  if (!part1 || !part2) return null;

  return (
    <>
      {getComponent(part1)}
      {getComponent(part2)}
    </>
  );
};

export default ContentfulSplitSection;

export const contentfulSplitSectionQuery = graphql`
  fragment contentfulSplitSectionFields on ContentfulSplitSection {
    id
    internal {
      type
    }
    part1 {
      ...contentfulLiftUpFields
      ...contentfulCardListFields
      ...contentfulTextContentFields
      ...contentfulWeeklyProgrammeFields
      ...contentfulEventListSectionFields
      ...contentfulEmbedFields
      ...contentfulImageFields
      ...contentfulBlogsSectionFields
    }
    part2 {
      ...contentfulLiftUpFields
      ...contentfulCardListFields
      ...contentfulTextContentFields
      ...contentfulWeeklyProgrammeFields
      ...contentfulEventListSectionFields
      ...contentfulEmbedFields
      ...contentfulImageFields
    }
  }
`;
