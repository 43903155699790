import styled from 'styled-components';
import transitions from '../../styles/transitions';
import Button from '../common/button/Button';
import Icon from '../common/icon/Icon';
import MobileNavigationStyled from '../navigation/main/mobile/MobileNavigation.styled';
import NavigationSheet from '../navigation/main/mobile/NavigationSheet';

type OpenButtonProps = {
  elevated?: boolean;
};

const OpenButton = styled(Button)<OpenButtonProps>`
  background: white;
  transform: translateY(-50%);
  width: 100%;
  margin: -2rem 0 0.5rem;
  ${({ elevated }) =>
    elevated &&
    `
    box-shadow: 0px 5px 14px 0px #1F37451F;
  `}
  ${Icon} {
    margin-right: 1rem;
  }
`;

const SlidesContainer = styled.div`
  display: block;
  overflow: hidden;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
`;

type SlideProps = {
  distance: string;
};

const Slide = styled.div<SlideProps>`
  width: 100vw;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  ${({ distance }) => `
    transform: translate3d(${distance}, 0, 0);
  `}

  ${transitions.CarouselTrack}
`;
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  object-fit: 'contain';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;

const AccommodationImage = styled.img`
  max-height: 100vh;
  width: 100%;
  object-fit: contain;
`;

type NavElementProps = {
  positionX?: 'left' | 'right';
  positionY?: 'top' | 'bottom';
};

const NavElement = styled.button<NavElementProps>`
  position: absolute;
  ${({ positionX }) => (positionX ? `${positionX}: 0;` : `right: 0; left: 0;`)}
  ${({ positionY }) => (positionY ? `${positionY}: 0;` : `top: 0; bottom: 0;`)}
  margin: auto;
  z-index: 8;
  cursor: pointer;
  background: transparent;
  height: 5rem;
  ${Icon} {
    color: white;
    font-size: 3rem;
  }
`;

const MobileGalleryNavigationSheet = styled(NavigationSheet)`
  z-index: 99;
  max-width: unset;

  ${MobileNavigationStyled.NavigationSheetHeader} {
    background-color: #000;
    color: #fff;
    justify-content: flex-end;
  }
  ${MobileNavigationStyled.NavigationSheetContent} {
    background-color: #000;
    padding: 0;
  }
`;

export default {
  OpenButton,
  SlidesContainer,
  Slide,
  ImageContainer,
  AccommodationImage,
  NavElement,
  MobileGalleryNavigationSheet,
};
