import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import useBreakpoints from '../../hooks/useBreakpoints';
import { Typography } from '../common/typography/Typography.styled';

import Styled, { LiftUpLayout } from './LiftUp.styled';
import { graphql } from 'gatsby';
import ArrowLink from '../common/link/ArrowLink';
import { ContentfulGatsbyImage } from '../../types/images';
import useContentfulGatsbyImage from '../../hooks/useContentfulGatsbyImage';

import { Theme } from '../../styles/themes';
import { ThemeContext } from '../common/ThemeContainer';
import { ContentfulCallToActionTypes } from '../../contentful/callToActions/types';
import useContentfulCallToAction from '../../hooks/useContentfulCallToAction';
import Button, { ButtonLinkWrapper } from '../common/button/Button';
import LeviBlackCard from '../common/levi-black-card/LeviBlackCard';
import ContentfulVideoSection, { ContentfulVideoSectionTypes } from '../../contentful/sections/contentfulVideoSection';
import { LeviBlackCardSize } from '../common/levi-black-card/LeviBlackCardSize';
import { LeviBlackCardPosition } from '../common/levi-black-card/LeviBlackCardPosition';

export type ContentfulLiftupImage = {
  imageDefault: IGatsbyImageData;
  imageTight: IGatsbyImageData;
  description: string;
  title: string;
};

export type LiftupSectionProps = {
  title?: string;
  subtitle?: string;
  body?: string;
  callToAction?: ContentfulCallToActionTypes;
  image?: ContentfulLiftupImage;
  video?: ContentfulVideoSectionTypes;
};

type LiftUpProps = LiftupSectionProps & {
  layout?: LiftUpLayout;
};

const imageByLayout = (
  imageProp?: ContentfulLiftupImage,
  layout?: LiftUpLayout,
): ContentfulGatsbyImage | undefined => {
  if (!imageProp) return undefined;
  const { description, title, imageDefault, imageTight } = imageProp;
  return {
    description,
    title,
    image: layout !== 'tight' ? imageDefault : imageTight,
  };
};

const LiftUp = ({
  title,
  subtitle,
  body,
  callToAction,
  image,
  video,
  layout = 'default',
}: LiftUpProps): JSX.Element => {
  const { isDesktop } = useBreakpoints();
  const imageProps = useContentfulGatsbyImage(imageByLayout(image, layout));
  const linkProps = useContentfulCallToAction(callToAction);
  const theme = React.useContext(ThemeContext);

  return (
    <Styled.Liftup layout={layout}>
      {imageProps && (
        <Styled.ImageContainer>
          {theme === Theme.Sustainability && (
            <Styled.SustainabilityStamp alt="" />
          )}
          {theme === Theme.LeviBlack && (
            <LeviBlackCard
              size={LeviBlackCardSize.Large}
              position={LeviBlackCardPosition.TopRight}
            />
          )}
          <GatsbyImage {...imageProps} />
        </Styled.ImageContainer>
      ) || video && (
        <Styled.VideoContainer>
          <ContentfulVideoSection {...video}/>
        </Styled.VideoContainer>
      )}

      <Styled.Content>
        {(isDesktop || layout === 'tight') && subtitle && (
          <Typography.SubHeadingSmall>{subtitle}</Typography.SubHeadingSmall>
        )}
        {title && <Typography.Display>{title}</Typography.Display>}
        {body && <Typography.BodyLarge as="div">{body}</Typography.BodyLarge>}
        {linkProps && (
          <Styled.ContentFooter>
            {layout === 'narrow' || layout === 'tight' ? (
              <ArrowLink {...linkProps} />
            ) : (
              <ButtonLinkWrapper {...linkProps}>
                <Button>{linkProps.label}</Button>
              </ButtonLinkWrapper>
            )}
          </Styled.ContentFooter>
        )}
      </Styled.Content>
    </Styled.Liftup>
  );
};

export default LiftUp;

export const contentfulLiftupImage = graphql`
  fragment contentfulLiftupImage on ContentfulLiftUp {
    image {
      imageDefault: gatsbyImageData(width: 854, aspectRatio: 0.935, breakpoints: [854])
      imageTight: gatsbyImageData(width: 900, aspectRatio: 0.63, breakpoints: [900])
      description
      title
    }
  }
`;
