import { ApiPath } from '..';
import { BusStop } from '../types/BusScheduleDataTypes';
import { ApiDataParser } from './ApiDataParser';
import { BusScheduleRowsByRoute, stopsToRows } from './busScheduleUtils';

export type BusScheduleSlot = { time?: string };

export type BusScheduleParserReturnType = {
  rows: BusScheduleRowsByRoute;
  stops: BusStop[];
};

export const skiBusScheduleDataParser: ApiDataParser<
  ApiPath.SkiBusSchedule
> = ({ stops }): BusScheduleParserReturnType => {
  const rows = stopsToRows(stops);

  return {
    rows,
    stops,
  };
};
