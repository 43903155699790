import React from 'react';
import { graphql } from 'gatsby';
import Accordion from '../../components/common/accordion/Accordion';
import useHtmlAst from '../../hooks/useHtmlAst';
import { ContentfulMarkdownTypes } from '../templates/types';

export type ContentfulAccordionTypes = {
  title: string;
  body: ContentfulMarkdownTypes;
};

const ContentfulAccordion = ({
  title,
  body,
}: ContentfulAccordionTypes): JSX.Element | null => {
  const bodyText = useHtmlAst(body?.childMarkdownRemark?.htmlAst);
  if (!title || !bodyText) return null;
  return <Accordion title={title} body={bodyText} />;
};

export default ContentfulAccordion;

export const contentfulAccordionQuery = graphql`
  fragment contentfulAccordionFields on ContentfulAccordion {
    title
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
  }
`;
