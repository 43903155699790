import React from 'react';
import { graphql } from 'gatsby';
import InfoGIS from '../../components/infogis/InfoGIS';

export type ContentfulInfogisTypes = {
  id: string;
  internal: {
    type: string;
  };
  infogisId: string;
};

const ContentfulInfogis = ({
  infogisId,
}: ContentfulInfogisTypes): JSX.Element | null => {
  if (!infogisId) return null;
  return <InfoGIS id={infogisId} />;
};

export default ContentfulInfogis;

export const contentfulInfogisQuery = graphql`
  fragment contentfulInfogisFields on ContentfulInfogis {
    id
    internal {
      type
    }
    infogisId
  }
`;
