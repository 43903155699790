import React from 'react';
import { graphql } from 'gatsby';
import LiftUp, { ContentfulLiftupImage } from '../../components/liftup/LiftUp';
import { ContentfulCallToActionProps } from '../callToActions/contentfulCallToAction';
import { LiftUpLayout } from '../../components/liftup/LiftUp.styled';
import { Theme } from '../../styles/themes';
import useHtmlAst from '../../hooks/useHtmlAst';
import { ContentfulMarkdownTypes } from '../templates/types';
import { ContentfulVideoSectionTypes } from './contentfulVideoSection';
import useLocale from '../../localization/useLocale';

export type ContentfulLiftUpTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  subtitle: string;
  body: ContentfulMarkdownTypes;
  image: ContentfulLiftupImage;
  video: ContentfulVideoSectionTypes;
  link: ContentfulCallToActionProps;
  layout: LiftUpLayout; // default, default-right, narrow, narrow-right, tight
  theme: Theme;
  hideFromLocalization?: boolean;
};

const ContentfulLiftUp = ({
  title,
  subtitle,
  body,
  image,
  link,
  layout,
  video,
  hideFromLocalization
}: ContentfulLiftUpTypes): JSX.Element => {

  const locale = useLocale();
  if (locale !== 'fi' && hideFromLocalization) return <></>;

  const bodyText = useHtmlAst(body?.childMarkdownRemark?.htmlAst);
  
  return (
    <LiftUp
      title={title}
      subtitle={subtitle}
      body={bodyText}
      image={image}
      layout={layout}
      callToAction={link}
      video={video}
    />
  );
};

export default ContentfulLiftUp;

export const contentfulLiftUpQuery = graphql`
  fragment contentfulLiftUpFields on ContentfulLiftUp {
    id
    internal {
      type
    }
    title
    subtitle
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
    ...contentfulLiftupImage
    link {
      ...contentfulCallToActionFields
    }
    layout
    theme
    video {
      ...contentfulVideoSectionFields
    }
    hideFromLocalization
  }
`;
