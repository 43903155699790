import React from 'react';
import { GridElementProps } from './Grid.styled';
import Styled from './GridHeader.styled';

type GridHeaderProps = {
  children?: React.ReactNode;
  className?: string;
  centered?: boolean;
} & GridElementProps;

const GridHeader = ({ children, ...rest }: GridHeaderProps): JSX.Element => (
  <Styled.GridHeader {...rest}>{children}</Styled.GridHeader>
);

export default GridHeader;
