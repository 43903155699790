import {
  CardTagProps,
  OpenStatus,
} from '../../../components/common/card/types';
import { localize } from '../../../localization/i18n';
import { Icons } from '../../../types/icon-types';
import { isOpen } from '../../../utils/time-utils';
import { ContentfulCallToActionTypes } from '../../callToActions/types';
import { OpeningHoursTypes } from '../../templates/types';

export const resolveLinks = (
  id: string,
  contentKey:
    | 'events'
    | 'restaurants'
    | 'rentals'
    | 'courses'
    | 'activities'
    | 'accommodations'
    | 'blogPosts',
): ContentfulCallToActionTypes[] => [
  {
    destination: { pageId: id },
    title: localize(`${contentKey}.cardLinkLabel`),
  },
];

export const resolveOpenStatus = (
  openingHours: OpeningHoursTypes,
): OpenStatus => ({
  isOpen: openingHours ? isOpen(openingHours) : false,
});

export const resolveTags = (
  categories: string[],
  contentKey: 'rentals' | 'accommodations',
  iconMap: { [key: string]: { icon: Icons } },
): CardTagProps[] => {
  return (categories || []).flatMap((category: string) => {
    const label = localize(`${contentKey}.categories.${category}`);
    if (!label || !iconMap[category]) return [];
    return { label, icon: iconMap[category].icon };
  });
};

export const resolveTagByKey = (
  labelPrefix: string,
  contentKey: 'accommodations',
  childKey: 'capacity',
  itemKey: string,
  iconMap: { [key: string]: { icon: Icons } },
): CardTagProps => {
  const key = `${contentKey}.${childKey}.${itemKey}`;
  const label = `${labelPrefix} ${localize(key)}`;
  return { label, icon: iconMap[itemKey].icon };
};

export const trimLeadingWhiteSpace = (str = '') =>
  str ? str.replace(/^\s*/, '') : str;
