import styled from 'styled-components';
import { below, Breakpoint, breakpoints } from '../../../styles/breakpoints';
import { GridElement } from './Grid.styled';

const GridHeader = styled(GridElement)`
  justify-content: center;
  text-align: center;
  margin-bottom: 2.5rem;

  ${breakpoints.sm} {
    display: grid;
    grid-column-start: 1;
    grid-column-end: -1;
  }
  ${breakpoints.md} {
    grid-row: header;
  }

  ${GridElement} + & {
    ${below(Breakpoint.md)} {
      margin-top: 3rem;
    }
    grid-column-end: -1;
    ${breakpoints.md} {
      grid-column-start: -7;
    }
  }
`;

export default {
  GridHeader,
};
