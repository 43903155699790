import styled from 'styled-components';
import { below, Breakpoint } from '../../styles/breakpoints';
import { AppColor } from '../../styles/colors';
import FontRules from '@Levi/styles/fontRules';
import transitions from '@Levi/styles/transitions';

const HeaderStyled = styled.header`
  text-align: left;
  h1 {
    color: ${AppColor.LeviBlue};
  }
`;

const MetaStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
`;

const AreaLabelStyled = styled.div`
  margin-right: 1rem;
`;

const Divider = styled.div`
  height: 1px;
  border-bottom: 1px solid ${AppColor.Neutral300};
`;

const MainContentStyled = styled.div`
  text-align: left;
  > p:first-child {
    margin-bottom: 1rem;
  }
`;

const TagsWrapperStyled = styled.div`
  > div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    ${below(Breakpoint.md)} {
      grid-template-columns: repeat(3, 1fr);
    }
    ${below(Breakpoint.sm)} {
      grid-template-columns: repeat(2, 1fr);
    }
    > div {
      flex-direction: column;
      margin: 0 0.5rem;
      ${below(Breakpoint.md)} {
        margin-bottom: 1rem;
      }
    }
  }
  figure {
    color: ${AppColor.LeviBlue};
    margin-left: 0.75rem;
    margin-bottom: 0.5rem;
  }
`;

const AmenitiesListWrapper = styled.div`
  padding: 1rem 0 0;
  text-align: left;
`;

const AmenitiesList = styled.div`
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`;

const AmenitiesListItem = styled.div`
  display: flex;
`;

const AmenitiesListIcon = styled.div`
  color: ${AppColor.LeviBlue};
`;

const AmenitiesListItemContent = styled.div`
  margin-left: 1rem;
`;

const GalleryButtonWrapper = styled.div`
  padding: 0 2rem;
`;

type StyledIframeContainerProps = {
  elevated?: boolean;
};

const IframeContainer = styled.div<StyledIframeContainerProps>`
  padding: 1.5rem 0;
  max-width: 322px;
  min-width: 280px;
  margin: 0 auto;

  ${({ elevated }) =>
    elevated &&
    `
    box-shadow: 0px 5px 14px 0px #1F37451F;
    `}
`;

const IframeWrapper = styled.div`
  position: relative;
  padding-top: 250%;
  max-width: 100%;
  overflow-x: hidden;
`;

const Iframe = styled.iframe`
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
`;

const TabsContainer = styled.div`
  margin-top: 2.5rem;
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 1rem;
`;

const TabSelector = styled.div<{ active?: boolean }>`
  ${FontRules.TradeGothicLTStd.Bold}
  color: ${AppColor.LeviBlue};
  padding: 1rem 2rem;
  border-radius: 0.25rem;
  cursor: pointer;

  ${({ active }) => 
    active ? 
      `background-color: ${AppColor.LeviBlue}; color: white;` :
      `&:hover {
        background-color: ${AppColor.LeviBlue}; color: white;
      }`
  }

  ${transitions.ButtonHover}
`;

const TabContent = styled.div`
  margin-top: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
  gap: 1rem;
  align-items: flex-start;
`;

const StickyAreaContainer = styled.div`
  margin-top: 1rem;
`;

const ShoppingCartContainer = styled.div`
  position: sticky;
  top: 1rem;
  flex-grow: 1;
`;

export default {
  TabsContainer,
  TabsWrapper,
  TabSelector,
  TabContent,
  Row,
  StickyAreaContainer,
  ShoppingCartContainer,
};
