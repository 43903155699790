import React, { useEffect, useLayoutEffect } from 'react';
import { DropdownProps } from './Dropdown';
import Styled from './Dropdown.styled';
import DropdownButton from './DropdownButton';
import useDropdownToggle from './useDropdownToggle';
import { isEqual } from 'lodash';
import { useDropdownContainerCalculations } from '../../../hooks/useDropdownContainerCalculations';

export type MultiSelectDropdownProps = Omit<
  DropdownProps,
  'defaultValue' | 'onChange' | 'value'
> & {
  onChange?: (value: string[]) => void;
  value?: string[] | null;
};

const MultiSelectDropdown = ({
  label,
  value = [],
  onChange,
  items = [],
  variant = 'primary',
}: MultiSelectDropdownProps): JSX.Element | null => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const ddRef = React.useRef<HTMLDivElement | null>(null);
  const { isOpen, onDropdownClick } = useDropdownToggle({ ref });

  const { recalculate } = useDropdownContainerCalculations({
    refWrapper: ref,
    refContainer: ddRef,
    anchorPoint: 'left',
  });

  const values = React.useRef<string[]>(value || []);

  useLayoutEffect(() => {
    recalculate();
  }, [recalculate, isOpen]);

  useEffect(() => {
    const destValue = value
      ? value instanceof Array
        ? [...value]
        : [value]
      : [];
    if (!isEqual(values.current, destValue)) {
      values.current = destValue;
    }

    if (values.current.length > 0) {
      if (!ref.current?.classList.contains('hasValue')) {
        ref.current?.classList.add('hasValue');
      }
    } else {
      if (ref.current?.classList.contains('hasValue')) {
        ref.current?.classList.remove('hasValue');
      }
    }
  }, [value]);

  const onCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.currentTarget;

      values.current = checked
        ? [...values.current, value]
        : values.current.filter((prevValue) => prevValue !== value);
      onChange && onChange(values.current);
    },
    [onChange],
  );

  const isChecked = React.useCallback(
    (value: string) => values.current.includes(value),
    [values],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <Styled.Dropdown
      ref={ref}
      className={values.current.length > 0 ? 'hasValue' : ''}
      isOpen={isOpen}
      variant={variant}
    >
      <DropdownButton label={label} onClick={onDropdownClick} />
      <Styled.DropdownOptions ref={ddRef}>
        {items.map(({ value, label, component }) => (
          <Styled.DropdownOptionLabel key={label}>
            <input
              type="checkbox"
              name={label}
              value={value}
              onChange={onCheckboxChange}
              checked={isChecked(value)}
            />
            {component ? component : label}
          </Styled.DropdownOptionLabel>
        ))}
      </Styled.DropdownOptions>
    </Styled.Dropdown>
  );
};

export default MultiSelectDropdown;
