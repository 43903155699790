export const hover = `@media (hover: hover) and (pointer: fine)`;
export const touchActive = `@media (pointer: coarse)`;
import { isDomAvailable } from '../utils/env-utils';

export const getScrollbarWidth = () => {
  if (isDomAvailable()) {
    return window.innerWidth - document.documentElement.clientWidth;
  } else {
    return 0;
  }
};

export const setScrollBarWidth = () => {
  if (isDomAvailable()) {
    document.documentElement.style.setProperty('--scrollbar-width', `${getScrollbarWidth()}px`);
  }
}
