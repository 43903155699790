import create, { GetState, SetState, UseBoundStore } from 'zustand';
import createContext from 'zustand/context';
import {
  StoreApiWithSubscribeWithSelector,
  subscribeWithSelector,
} from 'zustand/middleware';
import { addMonths, isWithinInterval, startOfToday } from 'date-fns';
import {
  DateRange,
  DateRangeValid,
  extractTimeFrom,
  isDateRangeSelectable,
  isDateSelectable,
  calendarMonthsToDays,
  calendarMonthDates,
} from '../../../utils/date-utils';
import { resolveDayStates } from './calendarStateResolver';

export interface DayType {
  key: number;
  date: Date;
  displayDate: number;
  isActive: boolean;
  isDisabled: boolean;
  isSelected: boolean;
  isFirst: boolean;
  isLast: boolean;
  isInRange: boolean;
  isVisible: boolean;
}

export type Days = DayType[];

export interface CalendarState {
  hideOverflowDates?: boolean;
  activeDates?: Date[];
  allowDeselect?: boolean;
  allowRangeSelection?: boolean;
  hoverDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  maxRange?: number;
  selectedDate?: Date;
  selectedDateRange: DateRange;
  selectedMonth: Date;
  disabledDates?: Date[];
  today: Date;
  days: Days;
  offsetDays: {
    [key: number]: number[];
  };
  numberOfMonths?: number;
}

const today = startOfToday();

const initialState: CalendarState = {
  activeDates: [],
  allowDeselect: true,
  allowRangeSelection: false,
  hideOverflowDates: false,
  days: [],
  disabledDates: [],
  selectedDateRange: { start: undefined, end: undefined },
  selectedMonth: today,
  today,
  numberOfMonths: 1,
  offsetDays: {},
};

export type CalendarInitialProps = {
  initialDate?: Date;
  initialDateRange?: DateRangeValid;
} & Pick<
  CalendarState,
  | 'activeDates'
  | 'allowDeselect'
  | 'allowRangeSelection'
  | 'disabledDates'
  | 'hideOverflowDates'
  | 'maxDate'
  | 'maxRange'
  | 'minDate'
  | 'numberOfMonths'
>;

export const createCalendarStore =
  (state: CalendarInitialProps) =>
  (): UseBoundStore<
    CalendarState,
    StoreApiWithSubscribeWithSelector<CalendarState>
  > =>
    create<
      CalendarState,
      SetState<CalendarState>,
      GetState<CalendarState>,
      StoreApiWithSubscribeWithSelector<CalendarState>
    >(
      subscribeWithSelector(() => {
        const { initialDate, initialDateRange, numberOfMonths, ...rest } =
          state;

        //This needs to be reworked to take disabled dates and such into account
        const selectedDate =
          initialDate && isDateSelectable(initialDate)
            ? extractTimeFrom(initialDate)
            : undefined;

        //This needs to be reworked to take disabled dates and such into account
        const selectedDateRange =
          initialDateRange &&
          initialDateRange.start &&
          isDateRangeSelectable(initialDateRange)
            ? {
                start: extractTimeFrom(initialDateRange.start),
                end: extractTimeFrom(initialDateRange.end),
              }
            : { start: undefined, end: undefined };

        const m = selectedDate || selectedDateRange.start || today;

        const selectedMonth = new Date(m.getFullYear(), m.getMonth());
        const days = calendarMonthsToDays(selectedMonth, numberOfMonths);

        const stateToSet = {
          ...initialState,
          ...rest,
          days,
          numberOfMonths,
          selectedDate,
          selectedDateRange,
          selectedMonth,
        } as CalendarState;

        const resolvedState = {
          ...stateToSet,
          days: resolveDayStates(stateToSet),
        };

        const offsetDays = Array(numberOfMonths)
          .fill(0)
          .reduce((acc, curr, currentIndex) => {
            const daysForOffsetMonth = calendarMonthDates(
              addMonths(selectedMonth, currentIndex),
            );
            acc[currentIndex] = resolvedState.days
              .filter((day) => {
                const interval = {
                  start: daysForOffsetMonth[0],
                  end: daysForOffsetMonth[daysForOffsetMonth.length - 1],
                };
                return isWithinInterval(day.date, interval);
              })
              .map((day) => day.key);
            return acc;
          }, {});

        return {
          ...resolvedState,
          offsetDays,
        };
      }),
    );

const { Provider, useStore, useStoreApi } = createContext<
  CalendarState,
  UseBoundStore<CalendarState, StoreApiWithSubscribeWithSelector<CalendarState>>
>();

export { Provider as CalendarProvider };
export { useStore as useCalendarStore };
export { useStoreApi as useCalendarStoreAPI };
