import { DateRange } from '../../utils/date-utils';

export const decodeURIParams = (params: string[]) => {
  return JSON.parse(decodeURI(params[0]));
};

export const encodeAsURIParams = (obj: unknown): string => {
  return encodeURI(JSON.stringify(obj));
};

export const encodeDateRangeParams = (dateRange: DateRange) => {
  if (!dateRange.start || !dateRange.end) {
    return encodeURI(JSON.stringify(dateRange));
  }

  return encodeURI(
    JSON.stringify({
      start: dateRange.start.getTime(),
      end: dateRange.end.getTime(),
    }),
  );
};

export const dateParamsToDateRange = (
  params: string[],
): DateRange | undefined => {
  if (params.length === 0) {
    return undefined;
  }

  const datesObj = JSON.parse(decodeURI(params[0]));
  if (!datesObj.start || !datesObj.end) {
    return undefined;
  }
  return {
    start: new Date(datesObj.start),
    end: new Date(datesObj.end),
  };
};

type DummyCardFilterProps = Array<{ node: any }>

// Filters out dummy content required by the schema inferer in production.
// TODO: Move filtering to graphql queries and separate dummy and test content.
export const filterOutDummyCards = (
  cards: DummyCardFilterProps,
): DummyCardFilterProps => {
  if (process.env.TARGET_ENV === 'production') {
    return cards.filter(card => !card?.node?.name?.startsWith("__DUMMY"));
  }

  return cards;
};
