import styled from 'styled-components';
import Button from '../common/button/Button';
import { ButtonSize } from '../common/button/ButtonSize';
import MainNavigationDropdownStyled from '../navigation/main/desktop/MainNavigationDropdown.styled';

const SiteSearch = styled(MainNavigationDropdownStyled.DropdownContainer)`
  padding: 2rem 1.5rem 1.5rem;
`;

const SiteSearchForm = styled.form`
  display: flex;
  gap: 0.25rem;
`;

const SiteSearchButton = styled(Button).attrs({
  size: ButtonSize.Small,
  type: 'submit',
})`
  padding: 0 2rem;
`;

export default {
  SiteSearch,
  SiteSearchForm,
  SiteSearchButton,
};
