import styled from 'styled-components';
import rgba from 'polished/lib/color/rgba';
import { AppColor } from '../../../styles/colors';
import { SecondaryTabProps } from './SecondaryTab';

type StyledTabProps = Pick<SecondaryTabProps, 'selected'>;

const SecondaryTab = styled.button<StyledTabProps>`
  padding: 0.90625rem 2rem 0.78125rem;
  background-color: ${rgba(AppColor.Neutral200, 0.3)};
  color: ${AppColor.LeviBlue};
  position: relative;
  white-space: nowrap;
  scroll-snap-align: start;
  flex: 1;

  & + & {
    border-left: 1px solid;
    border-color: inherit;
  }

  ${(props) =>
    props.selected &&
    `
    color: ${AppColor.Neutral700};

    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: #0092C8;
      bottom: -1px;
      left: 0;
      position: absolute;
    }
  `}
`;

const SecondaryTabs = styled.div`
  display: flex;
  background-color: ${AppColor.Neutral100};
  border: 1px solid;
  border-color: ${AppColor.Neutral300};
  grid-row: header;

  overflow-x: auto;
  overflow-y: visible;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  position: relative;
  padding-bottom: 1px;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default {
  SecondaryTab,
  SecondaryTabs,
};
