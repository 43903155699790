import { CardType } from '../components/common/card/Card';
import { CardDataType } from '../contentful/data/cardDataTransformers';
import { ContentfulCardGridTypes } from '../contentful/sections/contentfulCardGrid';
import useLocale from '../localization/useLocale';

type HookProps = Pick<ContentfulCardGridTypes, 'items'>;
type HookReturnType = {
  cards: CardDataType<CardType>[];
  type: CardType;
};

const cardTypes = {
  ContentfulCard: CardType.Default,
  ContentfulEvent: CardType.Event,
  ContentfulService: CardType.Service,
  ContentfulRestaurant: CardType.Restaurant,
  ContentfulCourse: CardType.Course,
  ContentfulRental: CardType.Rental,
  ContentfulBlogPost: CardType.BlogPost,
};

const useContentfulCardGridData = ({ items }: HookProps): HookReturnType => {
  const locale = useLocale();
  if (!items?.length) return { cards: [], type: CardType.Default };
  const typeString = items[0].internal.type;
  const cardType = cardTypes[typeString];

  const cards = items.filter((item) => item.internal.type === typeString && !(locale !== 'fi' && item.hideFromLocalization));
  // return only cards with same type
  return {
    cards,
    type: cardType,
  };
};
export default useContentfulCardGridData;
