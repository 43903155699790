import React from 'react';
import { graphql } from 'gatsby';
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../containers/contentfulSectionContainer';
import { PageProps } from './types';

const ContentfulPage: React.FC<PageProps> = ({ data }) => {
  const { sections } = data.contentfulPage;

  return (
    <main>
      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}
    </main>
  );
};

export default ContentfulPage;

export const contentfulPageQuery = graphql`
  query PageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
      analyticsTags
    }
  }
  fragment allPageSections on ContentfulEntry {
    ...contentfulLiftUpFields
    ...contentfulTextContentFields
    ...contentfulCardGridFields
    ...contentfulCardListFields
    ...contentfulSplitSectionFields
    ...contentfulCategoriesFields
    ...contentfulLeviTodayFields
    ...contentfulTableGroupsFields
    ...contentfulCarouselFields
    ...contentfulVideoSectionFields
    ...contentfulHighlightItemListFields
    ...contentfulLocationSectionFields
    ...contentfulEmbedFields
    ...contentfulTableSectionFields
    ...contentfulSkiBusScheduleFields
    ...contentfulInfogisFields
    ...contentfulSkiResortAmenitiesConfigFields
    ...contentfulWeeklyProgrammeFields
    ...contentfulNewsletterSectionFields
    ...contentfulEventListSectionFields
    ...contentfulBlogsSectionFields
    ...contentfulCourseSectionFields
  }
`;
