import React from 'react';
import Icon, { IconSize } from '../icon/Icon';
import { Icons } from '../../../types/icon-types';
import styled from 'styled-components';
import { Typography } from '../typography/Typography.styled';
import {
  LinkComponentTypes,
  LinkProps,
} from '../../../hooks/useContentfulCallToAction';
import HorizontalCardStyled from '../horizontal-card/HorizontalCard.styled';
import CardStyled from '../card/Card.styled';
import { hover } from '../../../styles/utils';
import transitions from '../../../styles/transitions';
import { localize } from '../../../localization/i18n';

const StyledArrowLink = styled.a`
  color: var(--base-link-color);
  position: relative;

  ${Typography.LinkSmall} + ${Icon} {
    margin-left: 0.375rem;
  }

  ${hover} {
    ${Icon} {
      transform: translate3d(0, 0, 0);
      ${transitions.ArrowLinkHover}
    }
    &:hover {
      ${Icon} {
        transform: translate3d(0.5rem, 0, 0);
      }
    }

    ${HorizontalCardStyled.Card}:hover & {
      ${Icon} {
        transform: translate3d(0.5rem, 0, 0);
      }
    }

    ${CardStyled.Card}:hover & {
      ${Icon} {
        transform: translate3d(0.5rem, 0, 0);
      }
    }
  }
`;

const ArrowLink = ({
  label,
  ...rest
}: LinkProps<LinkComponentTypes>): JSX.Element | null => {
  return (
    <StyledArrowLink
      {...rest}
      aria-label={label || localize('common.readMore')}
    >
      {label && <Typography.LinkSmall>{label}</Typography.LinkSmall>}
      <Icon
        type={label ? Icons.ArrowRightSmall : Icons.ArrowRightMedium}
        size={label ? IconSize.Size08 : IconSize.Size16}
      />
    </StyledArrowLink>
  );
};

export default ArrowLink;
