import styled, { css } from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import transitions from '../../../styles/transitions';
import { hover } from '../../../styles/utils';
import { Icons, ICONS_CODEPOINTS } from '../../../types/icon-types';

export const LinkUnderlineCSS = css`
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 0% var(--link-underline-height, 1px);
  padding-bottom: calc(var(--link-underline-height, 1px) + 1px);

  ${transitions.NavLinkHover}
`;

export const LinkUnderLineHoverCSS = css`
  background-size: 100% var(--link-underline-height, 1px);
`;

const icon = String.fromCodePoint(+ICONS_CODEPOINTS[Icons.ChevronRightBold]);

function HeadingIdValue(props: any){
  // Ignore if content is not text
  if(typeof(props.children) !== "string")
    return undefined;
  
  // Only add anchor to these tags
  const validTags = ["h1", "h2", "h3"];
  if(!validTags.includes(props.as))
    return undefined;
  
  return props.children;
}

const BaseHeading = styled.span.attrs(props => ({
  id: HeadingIdValue(props),
}))`
  ${FontRules.TradeGothicNextCondensed.Bold};

  text-transform: uppercase;
`;

const BaseDecoHeading = styled.span`
  ${FontRules.PlayfairDisplay.Bold};

  font-size: 1.5rem;

  line-height: 1.23;
  letter-spacing: 0.03125rem;

  color: var(--base-primary-heading-color);
`;

const Display = styled(BaseHeading)`
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: 0.078125rem;

  color: var(--base-primary-heading-color);

  ${breakpoints.lg} {
    font-size: 3.5rem;
    line-height: 1.23;
    letter-spacing: 0.21875rem;
  }
`;

const HeadingDecoLarge = styled(BaseDecoHeading)`
  ${breakpoints.lg} {
    font-size: 3.25rem;
  }
`;

const HeadingDecoMedium = styled(BaseDecoHeading)`
  ${breakpoints.lg} {
    font-size: 2rem;
  }
`;

const HeadingDecoSmall = styled(BaseDecoHeading)``;

const HeadingLarge = styled(BaseHeading)`
  font-size: 1.3125rem;
  letter-spacing: 0.0625rem;
  line-height: 1.25;

  color: var(--base-primary-heading-color);

  ${breakpoints.lg} {
    font-size: 2rem;
    letter-spacing: 0.125rem;
  }
`;

const HeadingSmall = styled(HeadingLarge)`
  ${breakpoints.lg} {
    font-size: 1.5rem;
    letter-spacing: 0.09375rem;
  }
`;

const SubHeadingLarge = styled(BaseHeading)`
  ${FontRules.TradeGothicLTStdExtended.Bold};

  color: var(--base-secondary-heading-color);

  font-size: 0.75rem;
  line-height: 1.83;
  letter-spacing: 0.3125rem;

  ${breakpoints.lg} {
    font-size: 1rem;
    line-height: 1.125;
    letter-spacing: 0.5rem;
  }
`;

const SubHeadingSmall = styled(SubHeadingLarge)`
  ${breakpoints.lg} {
    font-size: 0.6875rem;
    line-height: 1.36;
    letter-spacing: 0.125rem;
  }
`;

const BodyIngres = styled.p`
  ${FontRules.TradeGothicLTStd.Regular}

  font-size: 1rem;
  line-height: 1.45;
  letter-spacing: 0.03125rem;

  & + &,
  p + p {
    margin-top: 2rem;
  }

  ${breakpoints.lg} {
    font-size: 1.4375rem;
  }
`;

const BodySmall = styled.p`
  ${FontRules.TradeGothicNext.Light}

  i {
    ${FontRules.TradeGothicNext.LightItalic}
  }

  b,
  strong {
    ${FontRules.TradeGothicNext.Bold}
  }

  u {
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px solid;
  }

  color: var(--base-body-color);

  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.03125rem;

  > * + h3,
  & + &,
  p + p,
  ul + p,
  ol + p,
  & + ul,
  p + ul,
  & + ol,
  p + ol,
  > * + ul,
  > * + ol {
    margin-top: 1.5em;
  }

  ol {
    counter-reset: index;

    li > ul,
    li > ol {
      margin-top: 0.5rem;
      margin-left: 1.25rem;
    }

    li + li {
      margin-top: 0.5rem;
    }

    > li {
      position: relative;
      > li {
        padding-left: 2rem;
      }
      counter-increment: index;
      &:before {
        content: counters(index, '.') '.';
        position: relative;
        min-width: 1.25rem;
        display: inline-block;
      }
    }
  }

  ul {
    padding-left: 0.75rem;

    li > ul,
    li > ol {
      margin-top: 0.5rem;
    }

    li + li {
      margin-top: 0.5rem;
    }

    > li {
      position: relative;
      padding-left: 0.5rem;

      &:before {
        font-size: 0.875rem;
        font-family: icons !important;
        line-height: 1;
        color: ${AppColor.Neutral600};
        content: '${icon}';
        position: absolute;
        top: 0;
        left: -0.75rem;
        top: 0.375rem;
      }
    }
  }

  a {
    color: var(--base-link-color);
    ${LinkUnderlineCSS}
    ${transitions.NavLinkHover}
    ${hover} {
      &:hover {
        ${LinkUnderLineHoverCSS}
      }
    }
  }
`;

const BodyLarge = styled(BodySmall)`
  ${breakpoints.lg} {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.0625rem;
  }
`;

const BaseLink = styled.span`
  ${FontRules.TradeGothicLTStd.Bold};

  text-transform: uppercase;
`;

const Button = styled(BaseLink)`
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.125rem;

  ${breakpoints.lg} {
    font-size: 1rem;
    line-height: 1.23;
  }
`;

const MainNavigationLinkMobile = styled(BaseLink)`
  ${FontRules.TradeGothicLTStd.Regular};
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.03125rem;
  text-transform: none;
`;

const NavigationLink = styled(BaseLink)`
  font-size: 1rem;
  line-height: 1.31;
  letter-spacing: 0.0625rem;

  ${breakpoints.lg} {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`;

const SubLink = styled(BaseLink)`
  ${FontRules.TradeGothicNext.Regular}

  font-size: 0.9375rem;
  line-height: 1.25;
  letter-spacing: 0.03125rem;
  text-transform: none;

  ${breakpoints.lg} {
    font-size: 0.875rem;
  }
`;

const SubLinkBold = styled(SubLink)`
  font-weight: 700;
`;

const LinkSmall = styled(BaseLink)`
  ${FontRules.TradeGothicLTStdExtended.Bold};
  font-size: 0.625rem;
  line-height: 1.3;
  letter-spacing: 0.09375rem;

  ${breakpoints.lg} {
    line-height: 1.5;
    letter-spacing: 0.125rem;
  }
`;

const LinkBig = styled(LinkSmall)`
  ${breakpoints.lg} {
    font-size: 0.75rem;
    line-height: 1.25;
    letter-spacing: 0.125rem;
  }
`;

const BaseLabel = styled.span`
  ${FontRules.TradeGothicNext.Bold}

  font-size: 0.8125rem;
  line-height: 1.23;
  letter-spacing: 0.0625rem;
`;

const DecorativeLabel = styled.span`
  ${FontRules.PlayfairDisplay.RegularItalic}
  font-size: 1rem;
  line-height: 1.23;
`;

const LabelBig = styled(BaseLabel)`
  ${breakpoints.lg} {
    font-size: 1rem;
    letter-spacing: 0.03125rem;
  }
`;

const LabelSmall = styled(BaseLabel)`
  ${breakpoints.lg} {
    font-size: 0.875rem;
  }
`;

const BaseNumber = styled(BaseLabel)`
  ${FontRules.TradeGothicNextCondensed.Bold}

  letter-spacing: 0.03125rem;
  line-height: 1.25;
`;

const NumberBig = styled(BaseNumber)`
  text-transform: uppercase;
  font-size: 2rem;
`;

const NumberMedium = styled(BaseNumber)`
  font-size: 1.25rem;

  ${breakpoints.lg} {
    font-size: 1.5625rem;
  }
`;

const NumberSmall = styled(BaseNumber)`
  font-size: 1rem;
  line-height: 1.31;

  ${breakpoints.lg} {
    font-size: 0.875rem;
    line-height: 1.5;
  }
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
Display.defaultProps = { as: 'h1' };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
HeadingLarge.defaultProps = { as: 'h2' };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
HeadingSmall.defaultProps = { as: 'h3' };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
SubHeadingLarge.defaultProps = { as: 'h4' };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
SubHeadingSmall.defaultProps = { as: 'h5' };

export const Typography = {
  Display,
  HeadingDecoLarge,
  HeadingDecoMedium,
  HeadingDecoSmall,
  HeadingLarge,
  HeadingSmall,
  SubHeadingLarge,
  SubHeadingSmall,
  BodyIngres,
  BodyLarge,
  BodySmall,
  Button,
  MainNavigationLinkMobile,
  NavigationLink,
  SubLink,
  SubLinkBold,
  LinkBig,
  LinkSmall,
  DecorativeLabel,
  LabelBig,
  LabelSmall,
  NumberBig,
  NumberMedium,
  NumberSmall,
};
