import { localize } from '../../localization/i18n';
import { Icons } from '../../types/icon-types';
import { AccommodationCategoryTypes } from './types';

export const accommodationCategories: AccommodationCategoryTypes = {
  AT: {
    icon: Icons.AccommodationCottageSmall,
  },
  BB: {
    icon: Icons.AccommodationCottageSmall,
  },
  DP: {
    icon: Icons.AccommodationHotelsSmall,
  },
  IG: {
    icon: Icons.AccommodationCottageSmall,
  },
  HM: {
    icon: Icons.AccommodationHotelsSmall,
  },
  MÖ: {
    icon: Icons.AccommodationCottageSmall,
  },
  PT: {
    icon: Icons.AccommodationCottageSmall,
  },
  RT: {
    icon: Icons.AccommodationCottageSmall,
  },
  Rooms: {
    icon: Icons.AccommodationRoomsSmall,
  },
  Room: {
    icon: Icons.AccommodationRoomsSmall,
  },
  Persons: {
    icon: Icons.AccommodationFamilySmall,
  },
  Person: {
    icon: Icons.AccommodationFamilySmall,
  },  
  Bed: {
    icon: Icons.AccommodationBedsSmall,
  },
  Beds: {
    icon: Icons.AccommodationBedsSmall,
  },
  Animals: {
    icon: Icons.AccommodationAnimalsSmall,
  },
  NoAnimals: {
    icon: Icons.AccommodationNoAnimalsSmall,
  },
  Family: {
    icon: Icons.AccommodationFamilySmall,
  },
};

export const accommodationSubmenuElements = [
  {
    key: 'cabins',
    value: ['AT', 'MÖ', 'PT', 'RT', 'HM', 'BB'],
    icon: Icons.AccommodationCottageSmall,
  },
  {
    key: 'hotels',
    value: ['DP'],
    icon: Icons.AccommodationHotelsSmall,
  },
  {
    key: 'special',
    value: ['IG'],
    icon: Icons.AccommodationNorthernLightsSmall,
  },
];

export const accommodationCategoriesToLocalizedList = () =>
  Object.keys(accommodationCategories).map((key) => ({
    value: key,
    label: localize(`accommodations.categories.${key}`),
    icon: accommodationCategories[key],
  }));
