import React from 'react';
import Styled from './MobileNavigation.styled';

const NavigationSheet = React.forwardRef<
  HTMLDivElement,
  {
    headerContent: React.ReactNode;
    children: React.ReactNode;
    className?: string;
  }
>(({ headerContent, children, className }, ref) => (
  <Styled.NavigationSheet ref={ref} className={className}>
    <Styled.NavigationSheetHeader>{headerContent}</Styled.NavigationSheetHeader>
    <Styled.NavigationSheetContent>{children}</Styled.NavigationSheetContent>
  </Styled.NavigationSheet>
));

export default NavigationSheet;
