import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulMarkdownTypes, PageProps } from '../types';
import { ContentfulGatsbyImage } from '../../../types/images';
import { ContentfulAsset } from '../../../../graphql-types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Section from '../../../components/section/Section';
import TextContent from '../../../components/common/textContent/TextContent';
import {
  SubpageGrid,
  SubpageGridElement,
} from '../../../components/common/layout/Grid.styled';
import ContactDetails from '../../../components/common/contactDetails/ContactDetails';
import MainHeader from '../../../components/header/PageHeader';
import MapContainer from '../../../components/common/map/MapContainer';
import Gallery from '../../../components/gallery/Gallery';
import { ServiceFields } from '../serviceListPages/contentfulSearchServicesPage';
import Grid from '../../../components/common/layout/Grid';
import { useSkiResortDataByApiPath } from '../../../hooks/useSkiResortDataByApiPath';
import { ApiPath } from '../../../network/skiresort-api';
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../../containers/contentfulSectionContainer';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { ChildMeta } from '../../meta';
import { localize } from '../../../localization/i18n';
import useHtmlAst from '../../../hooks/useHtmlAst';

type AllRentalFields = ServiceFields & {
  body?: ContentfulMarkdownTypes;
  sections: ContentfulSectionContainerTypes[];
  images: ContentfulAsset[];
  galleryImages: ContentfulGatsbyImage[];
  logo: ContentfulGatsbyImage;
};

type RentalPageProps = PageProps & {
  data: {
    contentfulRental: AllRentalFields;
  };
};

const ContentfulRental: React.FC<RentalPageProps> = ({ data, pageContext }) => {
  const {
    name,
    ingress,
    body,
    sections,
    location: serviceLocation,
    //categories,
    openingHours,
    images,
    galleryImages,
    contact,
    logo,
    hubDbId,
  } = data.contentfulRental;

  const meta = {
    lang: pageContext.locale,
    description: ingress,
    image: images && images[0],
    title: name,
    hiddenFromSearchEngines: false,
  };

  const hubDbRental = useSkiResortDataByApiPath(
    ApiPath.Service,
    { id: hubDbId?.hubspotId },
    !!hubDbId?.hubspotId,
  );

  const { isBelowMd } = useBreakpoints();
  const bodyText = useHtmlAst(body?.childMarkdownRemark?.htmlAst);
  return (
    <>
      <ChildMeta {...meta} />
      <main>
        {galleryImages && <MainHeader image={galleryImages[0]} />}
        <Section subPage>
          <SubpageGrid>
            <SubpageGridElement padded>
              {isBelowMd && galleryImages && <Gallery items={galleryImages} />}
              <TextContent title={name} leftAligned>
                <Typography.BodyIngres>{ingress}</Typography.BodyIngres>
                {bodyText && (
                  <Typography.BodyLarge as="div">
                    {bodyText}
                  </Typography.BodyLarge>
                )}
              </TextContent>
            </SubpageGridElement>
            <SubpageGridElement column="aside" padded>
              {!isBelowMd && galleryImages && <Gallery items={galleryImages} />}
              {contact && (
                <ContactDetails
                  logo={logo}
                  openingHours={
                    (hubDbRental && hubDbRental?.open) || openingHours
                  }
                  {...contact}
                />
              )}
            </SubpageGridElement>
          </SubpageGrid>
        </Section>
        {sections?.map((section: ContentfulSectionContainerTypes) => (
          <ContentfulSectionContainer key={section.id} {...section} />
        ))}
        {serviceLocation && (
          <Section>
            <Grid>
              <MapContainer
                title={localize('common.location')}
                address={contact?.address || ''}
                location={serviceLocation}
              />
            </Grid>
          </Section>
        )}
      </main>
    </>
  );
};

export default ContentfulRental;

export const contentfulPageQuery = graphql`
  query RentalById($id: String!) {
    contentfulRental(id: { eq: $id }) {
      ...contentfulBaseRentalFields
      body {
        childMarkdownRemark {
          htmlAst
        }
      }
      sections {
        ...allPageSections
      }
      images {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: [JPG]
          placeholder: NONE
          resizingBehavior: FILL
        )
      }
      galleryImages: images {
        image: gatsbyImageData(layout: FULL_WIDTH)
        description
        title
        id
      }
      contact {
        ...contentfulCompanyFields
      }
      logo {
        image: gatsbyImageData(width: 300, breakpoints: [300])
        description
        title
      }
      analyticsTags
    }
  }
  fragment contentfulBaseRentalFields on ContentfulRental {
    id
    contentful_id
    name
    categories
    contact {
      address
    }
    ingress
    location {
      lat
      lon
    }
    hubDbId {
      hubspotId
    }
    leviBlackCard
    hideFromLocalization
    openingHours {
      exceptions
      hidden

      openingHours {
        monday {
          to
          from
          closed
        }
        tuesday {
          closed
          from
          to
        }
        wednesday {
          to
          from
          closed
        }
        thursday {
          closed
          from
          to
        }
        friday {
          to
          from
          closed
        }
        saturday {
          closed
          from
          to
        }
        sunday {
          closed
          from
          to
        }
      }
    }
  }
`;
