import { addDays, startOfToday } from 'date-fns';
import React from 'react';
import useBreakpoints from '../../../hooks/useBreakpoints';
import useFormattedDate from '../../../hooks/useFormattedDate';
import { localize } from '../../../localization/i18n';
import {
  DateRange,
  DateRangeValid,
  isValidDateRange,
} from '../../../utils/date-utils';
import Calendar from '../calendar/Calendar';
import useDropdownToggle from '../dropdown/useDropdownToggle';
import { Typography } from '../typography/Typography.styled';
import Styled from './AvailabilitySearchForm.styled';

const defaultMinDate = addDays(startOfToday(), 2);
interface AvailabilitySearchFormProps {
  onSelectedDateRangeChange: (range: DateRangeValid | undefined) => void;
  selectedRange: DateRangeValid | undefined;
  minDate?: Date;
}

const AvailabilitySearchForm = ({
  onSelectedDateRangeChange: handleSelectedDateRangeChange,
  selectedRange,
  minDate = defaultMinDate,
}: AvailabilitySearchFormProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { isOpen, onDropdownClick } = useDropdownToggle({ ref });

  const displayDate = useFormattedDate({ date: selectedRange });

  const onSelectedDateRangeChange = React.useCallback(
    (range: DateRange) => {
      if (isValidDateRange(range)) {
        handleSelectedDateRangeChange(range);
      } else {
        handleSelectedDateRangeChange(undefined);
      }
    },
    [handleSelectedDateRangeChange],
  );

  const { isPhone } = useBreakpoints();

  return (
    <Styled.AvailabilitySearchForm ref={ref}>
      <Styled.DateSelector onClick={onDropdownClick}>
        <Typography.SubLink>
          {localize('filters.availability.buttonLabel')}
        </Typography.SubLink>
        <Typography.BodySmall>
          {displayDate
            ? displayDate
            : localize('filters.availability.buttonHelper')}
        </Typography.BodySmall>
      </Styled.DateSelector>
      <Styled.CalendarContainer isOpen={isOpen}>
        <Calendar
          minDate={minDate}
          maxRange={15}
          numberOfMonths={isPhone ? 1 : 2}
          allowRangeSelection
          hideOverflowDates
          selectedDateRange={selectedRange}
          onSelectedDateRangeChange={onSelectedDateRangeChange}
        />
      </Styled.CalendarContainer>
    </Styled.AvailabilitySearchForm>
  );
};

export default AvailabilitySearchForm;
