import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import { Typography } from '../typography/Typography.styled';

const NewsLetterContainer = styled.div`
  grid-column-start: 3;
  grid-column-end: -3;
  padding: 2rem 0;
  background: ${AppColor.Neutral200};
`;

const NewsLetterInner = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const SubscribeNewsletterFormStyled = styled.div`
  padding: 1rem 2rem;
`;

const FieldContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${AppColor.Neutral700};
`;

const Label = styled(Typography.BodySmall).attrs({
  as: 'label',
})`
  color: ${AppColor.Neutral600};
  > input {
    margin-right: 1rem;
  }
`;

const ErrorNoticeContainer = styled.div`
  p {
    color: ${AppColor.ErrorRed};
  }
  margin: 1rem 0;
`;

const SuccessNoticeContainer = styled.div`
  p {
    color: ${AppColor.LeviBlue};
  }
  margin: 1rem 0;
`;

export default {
  NewsLetterContainer,
  NewsLetterInner,
  SubscribeNewsletterFormStyled,
  FieldContainer,
  ErrorNoticeContainer,
  SuccessNoticeContainer,
  Label,
};
