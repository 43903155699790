import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import { RestaurantCardProps } from '../types';
import useContentfulCallToAction from '../../../../hooks/useContentfulCallToAction';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Theme } from '../../../../styles/themes';
import useContentfulGatsbyImage from '../../../../hooks/useContentfulGatsbyImage';
import { useSkiResortDataByApiPath } from '../../../../hooks/useSkiResortDataByApiPath';
import { ApiPath } from '../../../../network/skiresort-api';
import CardOpenStatusInfo from '../CardOpenStatusInfo';

export type RestaurantMapInfoProps = Pick<
  RestaurantCardProps,
  'id' | 'title' | 'image' | 'links' | 'hubDbId' | 'openStatus'
>;

const RestaurantMapInfoCard = ({
  title,
  image,
  links,
  hubDbId,
  openStatus,
}: RestaurantMapInfoProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(links[0]);
  const imageProps = useContentfulGatsbyImage(image);

  const hubDbRestaurant = useSkiResortDataByApiPath(
    ApiPath.Restaurant,
    { id: hubDbId?.hubspotId },
    !!hubDbId?.hubspotId,
  );

  return (
    <Styled.Card theme={Theme.Primary} {...(linkProps || {})}>
      {imageProps && (
        <Styled.CardImageWrapper>
          <GatsbyImage {...imageProps} />
        </Styled.CardImageWrapper>
      )}
      <Styled.MapInfoCardContent>
        <Styled.CardHeading>
          <Typography.SubLink>{title}</Typography.SubLink>
        </Styled.CardHeading>
        {!hubDbId
          ? openStatus.isOpen && <CardOpenStatusInfo {...openStatus} />
          : hubDbRestaurant &&
            hubDbRestaurant.open &&
            !hubDbRestaurant.open.hidden &&
            hubDbRestaurant.open.openingHours && (
              <CardOpenStatusInfo
                isOpen={hubDbRestaurant.open?.now}
                description={hubDbRestaurant.open.hours}
              />
            )}
      </Styled.MapInfoCardContent>
    </Styled.Card>
  );
};

export default RestaurantMapInfoCard;
