import React from 'react';
import { Typography } from '../typography/Typography.styled';
import { InfoGridProps } from './InfoGrid';
import Styled from './InfoGrid.styled';
import InfoGridElement from './InfoGridElement';

type InfoGridGroupedProps = {
  title?: string;
  groups: InfoGridProps[];
};

const InfoGridGrouped = ({
  title,
  groups = [],
}: InfoGridGroupedProps): JSX.Element => {
  return (
    <>
      {title && (
        <Styled.InfoGridHeader>
          <Typography.HeadingLarge>{title}</Typography.HeadingLarge>
        </Styled.InfoGridHeader>
      )}
      <Styled.InfoGridGrouped>
        {groups.map(({ title, data }, index) => (
          <Styled.InfoGridGroup key={title || index}>
            {title && (
              <Typography.SubHeadingSmall>{title}</Typography.SubHeadingSmall>
            )}
            {data.map((itemProps, index) => (
              <InfoGridElement key={index} {...itemProps} />
            ))}
          </Styled.InfoGridGroup>
        ))}
      </Styled.InfoGridGrouped>
    </>
  );
};

export default InfoGridGrouped;
