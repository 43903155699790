import React from 'react';
import { StructuredMetaData } from '../contentful/meta';

type InputType = {
  pathname?: string;
  origin?: string;
};

const useStructuredDataForPage = ({
  pathname,
  origin,
}: InputType): StructuredMetaData | undefined => {
  const structuredData = React.useMemo(() => {
    if (pathname === '/' || pathname === '/en') {
      return {
        '@context': 'http://schema.org',
        '@type': 'LocalBusiness',
        name: 'Levi',
        image: `${origin}/images/levi_logo_rgb_syva-cc-88tty.png`,
        telephone: '+ 358 16 639 3300',
        email: 'info@visitlevi.fi',
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Myllyjoentie 2, 99130 Levi',
        },
        url: 'https://www.levi.fi/',
      };
    }
    return;
  }, [origin, pathname]);

  return structuredData;
};

export default useStructuredDataForPage;
