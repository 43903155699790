import React from 'react';
import { LocaleType } from '../contentful/templates/types';
import { updateLocale } from './i18n';

type LocalizationContextType = {
  locale: LocaleType;
};

type LocalizationContextProviderProps = LocalizationContextType & {
  children: React.ReactNode;
};

export const LocalizationContext = React.createContext<LocalizationContextType>(
  { locale: 'fi' },
);

export const LocalizationContextProvider = ({
  children,
  locale,
}: LocalizationContextProviderProps) => {
  updateLocale(locale);
  return (
    <LocalizationContext.Provider value={{ locale }}>
      {children}
    </LocalizationContext.Provider>
  );
};
