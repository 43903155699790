import React from 'react';
import Button, { ButtonLinkWrapper } from '../button/Button';
import ButtonLink from '../button/ButtonLink';
import { Typography } from '../typography/Typography.styled';
import { AccordionListItemData } from './AccordionList';
import Styled from './AccordionList.styled';

const StaticAccordionListItem = ({
  title,
  body,
  linkProps,
  callToAction,
}: AccordionListItemData): JSX.Element => (
  <Styled.StaticAccordionListItem>
    <Typography.HeadingDecoSmall>{title}</Typography.HeadingDecoSmall>
    <Styled.Content>
      <Typography.BodySmall>{body}</Typography.BodySmall>
      {(linkProps || callToAction) && (
        <Styled.ContentFooter>
          {linkProps ? (
            <ButtonLinkWrapper {...linkProps}>
              <Button>{linkProps.label}</Button>
            </ButtonLinkWrapper>
          ) : callToAction ? (
            <ButtonLink callToAction={callToAction} />
          ) : undefined}
        </Styled.ContentFooter>
      )}
    </Styled.Content>
  </Styled.StaticAccordionListItem>
);

export default StaticAccordionListItem;
