import styled from 'styled-components';
import { Typography } from '../common/typography/Typography.styled';
import { GridElement } from '../common/layout/Grid.styled';
import { AppColor } from '../../styles/colors';
import FontRules from '../../styles/fontRules';
import transitions from '../../styles/transitions';
import {
  below,
  between,
  Breakpoint,
  breakpoints,
  only,
} from '../../styles/breakpoints';
import sustainabilityStamp from '@static/images/sustainability-stamp.png';
import leviBlackCard from '@static/images/levi-black-card.png';

export type LiftUpLayout = 'default' | 'default-right' | 'narrow' | 'tight';

type LiftUpLayoutProps = { layout: LiftUpLayout };

type VariantStyleIndex = {
  [key in LiftUpLayout]: string;
};

const ContentFooter = styled.footer`
  padding-top: 2rem;
  ${breakpoints.lg} {
    padding-top: 3rem;
  }
  margin-top: auto;
`;

const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${Typography.SubHeadingSmall} + ${Typography.Display} {
    margin-top: 0.25rem;
    ${breakpoints.md} {
      margin-top: 1rem;
    }
  }

  * + ${Typography.BodyLarge} {
    margin-top: 1rem;
  }
`;

const SustainabilityStamp = styled.img.attrs({
  src: sustainabilityStamp,
  loading: 'lazy',
})`
  position: absolute;
  z-index: 1;
  width: 6rem;
  height: 6rem;
  top: -1.75rem;
  right: -2rem;

  ${breakpoints.md} {
    width: 7.875rem;
    height: 7.875rem;
    right: -1.1875rem;
  }
`;

const LeviBlackCard = styled.img.attrs({
  src: leviBlackCard,
  loading: 'lazy',
})`
  cursor: pointer;
`;

const LeviBlackCardTooltip = styled.div`
  position: absolute;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 8.5rem;
  background-color: ${AppColor.Neutral200};
  cursor: default;

  span {
    ${FontRules.TradeGothicNext.Regular};
    color: ${AppColor.Neutral700};
  }

  ${transitions.TooltipHover};

  &:after {
    content: '';
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 0.5rem;
    border-left-width: 0.5rem;
    border-bottom: 0.4375rem solid ${AppColor.Neutral200};
    position: absolute;
    top: -1.375rem;
    left: 50%;

    transform: translate(var(--transform-x, -50%), 1rem);
  }
`;

const LeviBlackCardWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0.8125rem;
  right: -1rem;
  --black-card-width: 4.125rem;
  --black-card-height: 2.75rem;
  --black-card-tooltip-width: 8.5rem;

  ${only(Breakpoint.sm)} {
    top: 0.75rem;
    right: 0.75rem;
  }

  ${breakpoints.lg} {
    top: 1.125rem;
    right: -1.125rem;
    --black-card-width: 6rem;
    --black-card-height: 4rem;
  }

  ${LeviBlackCard} {
    width: var(--black-card-width);
    height: var(--black-card-height);

    &:hover ~ ${LeviBlackCardTooltip} {
      opacity: 1;
    }
  }

  ${LeviBlackCardTooltip} {
    opacity: 0;
    top: calc(var(--black-card-height) + 0.5rem);
    right: calc(-1 * ((var(--black-card-tooltip-width) - var(--black-card-width)) / 2));
  }
`

const ImageContainer = styled.div`
  grid-area: image;
  width: 100%;
  position: relative;

  ${only(Breakpoint.base)} {
    padding-bottom: 53.6%;
    width: calc(100% - 1rem);
    max-width: 427px;
    > div.gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      height: 100%;
    }
  }
`;

const VideoContainer = styled.div`
  padding: 4em 0;
  width: 100%;
  grid-area: image;

  ${below(Breakpoint.sm)} {
    padding: initial;
    max-width: initial;
  }

  & > * {
    position: initial;
    width: 100%;
  }
`

const Liftup = styled(GridElement)<LiftUpLayoutProps>`
  margin: 0 auto;

  ${Content} {
    padding: 2rem 0;
  }

  ${only(Breakpoint.base)} {
    & + & {
      margin-top: 3rem;
    }
  }

  ${breakpoints.sm} {
    align-items: flex-start;
    align-self: flex-start;
    display: grid;

    grid-column-start: 1;
    grid-column-end: -1;

    grid-gap: inherit;
    grid-template-columns: inherit;

    grid-template-columns:
      [content-start] repeat(3, 1fr) [content-end image-start] repeat(3, 1fr)
      [image-end];
  }

  ${breakpoints.md} {
    grid-template-columns:
      [content-start] repeat(8, 1fr) [content-end image-start] repeat(4, 1fr)
      [image-end];
  }
  ${breakpoints.lg} {
    grid-template-columns:
      [content-start] repeat(7, 1fr) [content-end] 1fr [image-start] repeat(
        4,
        1fr
      )
      [image-end];
  }

  ${breakpoints.sm} {
    ${(props) => variantStyles[props.layout]}
  }
`;

const variantStyles: VariantStyleIndex = {
  default: `
  ${breakpoints.sm} {
      grid-template-columns: [image-start] repeat(3, 1fr) [image-end content-start] repeat(3, 1fr) [content-end];
    }
    ${breakpoints.md} {
      grid-template-columns: [image-start] repeat(4, 1fr) [image-end content-start] repeat(8, 1fr) [content-end];
      ${Content} {
        padding: 2rem;
      }
    }
    ${breakpoints.lg} {
      grid-template-columns: [image-start] repeat(4, 1fr) [image-end content-start] repeat(7, 1fr) [content-end] 1fr;
      ${Content} {
        padding: 3rem 2rem;
      }
    }

    `,
  'default-right': `
    ${breakpoints.md} {
      ${Content} {
        padding: 3rem 2rem 3rem 2rem;
      }
    }
    ${breakpoints.lg} {
      ${Content} {
        padding: 3rem 0 3rem 3rem;
      }
    }
  `,
  narrow: `
    ${breakpoints.md} {
      grid-column-start: 3;
      grid-column-end: -3;
      grid-template-columns: [content-start] repeat(4,1fr) [content-end image-start] repeat(4,1fr) [image-end];
    }

    ${Content} {
      padding: 3rem 1rem 3rem 0;
    }
  `,
  tight: `
  
  ${between(Breakpoint.sm, Breakpoint.md)} {
    grid-template-columns: 1fr;
    grid-template-columns: unset;
    grid-template-rows: [image] min-content [content] max-content;
    grid-gap: 0;
    ${ImageContainer} {
      padding-bottom: 53.6%;
      > div {
        position: absolute !important;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  ${breakpoints.sm} {
    grid-column: span 3;
  }
  ${breakpoints.md} {
    grid-column: span 6;
    grid-template-columns: [content-start] repeat(3,1fr) [content-end image-start] repeat(3,1fr) [image-end];
    
    ${Content} {
      z-index: 1;
      
      ${Typography.Display} {
        margin-right: -100% ;
        word-break: break-word;
      }
      
      ${breakpoints.lg} {
        padding: 3rem 0 3rem 3rem;
        ${Typography.Display} {
          margin-right: calc(-100% - 4.5rem);
        }
      }
    }
  
    ${ImageContainer} {
      max-height: 500px;
    }
  `,
};

export default {
  Liftup,
  Content,
  ContentFooter,
  ImageContainer,
  VideoContainer,
  SustainabilityStamp,
  LeviBlackCard,
  LeviBlackCardWrapper,
  LeviBlackCardTooltip,
};
