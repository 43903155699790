import styled from 'styled-components';
import { AppColor } from '../../../../styles/colors';
import transitions from '../../../../styles/transitions';
import { hover } from '../../../../styles/utils';
import Icon from '../../../common/icon/Icon';
import {
  LinkUnderlineCSS,
  LinkUnderLineHoverCSS,
  Typography,
} from '../../../common/typography/Typography.styled';

const DropdownWrapper = styled.div`
  position: absolute;
  cursor: auto;
  pointer-events: none;
  top: 1.25rem;
  padding-top: 1.075rem;
  left: 50%;
  transform: translate(var(--transform-x, -50%), 0);
`;

const DropdownContainer = styled.div`
  left: 0;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: rgb(45 81 123 / 25%) 0.625rem 0.625rem 3.125rem 0;
  opacity: 0;
  transform: translate(0, 1rem);
  padding: 2rem 2.25rem 2.5rem;

  ${transitions.NavLinkHoverDropdown}
`;

const DropdownLi = styled.li`
  color: ${AppColor.LeviBlue};
  ${Typography.NavigationLink} {
    --nav-color: ${AppColor.LeviBlue};
  }

  ${Typography.SubLink} {
    color: ${AppColor.Neutral700};
    --nav-color: ${AppColor.Neutral700};
    --link-underline-height: 1px;
  }
  ${Typography.NavigationLink}, ${Typography.SubLink} {
    position: relative;
    display: inline;

    ${LinkUnderlineCSS}

    ${hover} {
      &:hover {
        ${LinkUnderLineHoverCSS}
      }
    }
  }
`;

type DropdownUlProps = {
  withIcons?: boolean;
  numColumns?: number;
};

const DropdownUl = styled.ul<DropdownUlProps>`
  ${(props) =>
    props.withIcons
      ? `
    display: grid;
    grid-template-columns: repeat(${props.numColumns ?? 5}, 7rem);
    gap: 1.5rem;

    ${DropdownLi} {
      text-align: center;

      ${Icon} {
        color: ${AppColor.LeviBlue};
        display: block;
        margin-bottom: 0.5rem;
      }

      ${Typography.SubLink} {
        color: ${AppColor.Neutral700};
      }
    }
  `
      : `
    &:first-child {  
      display: grid;
      grid-template-columns: ${
        props.numColumns === 1
          ? `max-content`
          : `repeat(${props.numColumns ?? 4}, minmax(10rem, max-content))`
      };
      gap: 2rem 3rem;
    }
  `}

  & & {
    margin-top: 0.5625rem;

    > ${DropdownLi} + ${DropdownLi} {
      margin-top: 0.875rem;
    }
  }
`;

export default {
  DropdownWrapper,
  DropdownContainer,
  DropdownUl,
  DropdownLi,
};
