import React from 'react';
import { useSkiResortDataByApiPath } from '../../hooks/useSkiResortDataByApiPath';
import { useSortedSelectedWeekProgramData } from '../../hooks/useSortedWeekProgrammeData';
import { localize } from '../../localization/i18n';
import useLocale from '../../localization/useLocale';
import { ApiPath } from '../../network/skiresort-api';
import Dropdown from '../common/dropdown/Dropdown';
import { Typography } from '../common/typography/Typography.styled';
import Mount from '../transitions/Mount';
import DayProgramme from './DayProgramme';
import Styled from './WeeklyProgrammeList.styled';

export type WeeklyProgrammeListProps = {
  programmeId: string;
  onlyToday?: boolean;
  title?: string;
};

const WeeklyProgrammeList = ({
  programmeId,
  onlyToday = false,
  title,
}: WeeklyProgrammeListProps) => {
  const locale = useLocale();

  const [selectedWeek, setSelectedWeek] = React.useState<number>();

  const programmeData = useSkiResortDataByApiPath(ApiPath.Programme, {
    id: programmeId,
  });

  const sortedData = useSortedSelectedWeekProgramData(
    programmeData,
    selectedWeek,
  );

  const handleSelectChange = React.useCallback((value: string) => {
    setSelectedWeek(parseInt(value));
  }, []);

  const dropDownItems = React.useMemo(
    () =>
      programmeData && programmeData.allProgrammes
        ? programmeData?.allProgrammes.map((item) => {
            return {
              value: '' + item.week,
              label: `${localize('weeklyProgramme.week')} ${item.week} (${
                item.dates
              })`,
            };
          })
        : [],
    [programmeData],
  );

  const titleToUse = (!title ? programmeData?.name[locale] : title) || '';

  if (onlyToday) {
    return <DayProgramme data={sortedData?.data} title={titleToUse} />;
  }

  return (
    <Styled.WeeklyProgrammeListContainer>
      <Styled.ListTitle>
        {titleToUse}
        {!programmeData && <Styled.TitleSpinner $margin={!!titleToUse} />}
      </Styled.ListTitle>
      <Mount show={!!programmeData}>
        <Styled.WeekSelectContainer>
          <Dropdown
            onChange={handleSelectChange}
            defaultValue={
              !selectedWeek
                ? '' + programmeData?.currentWeek.week
                : '' + selectedWeek
            }
            ariaLabel={localize('weeklyProgramme.selectLabel')}
            name={localize('weeklyProgramme.selectLabel')}
            label={
              !selectedWeek
                ? `${localize('weeklyProgramme.week')} ${
                    programmeData?.currentWeek.week
                  } (${sortedData?.dates})`
                : `${localize('weeklyProgramme.week')} ${selectedWeek} (${
                    sortedData?.dates
                  })`
            }
            items={dropDownItems}
          />
        </Styled.WeekSelectContainer>
      </Mount>
      <Mount show={!!programmeData}>
        {sortedData ? (
          <Styled.List role="list">
            {sortedData.data
              .filter((item) => item.data[locale].length > 0)
              .map((item, index) => {
                return (
                  <Styled.ListItem
                    role="list-item"
                    key={`weekday-programme-${index}`}
                  >
                    <Styled.DayTitle>
                      {localize(`weeklyProgramme.${item.day}`)}
                    </Styled.DayTitle>

                    <Styled.DayContent
                      as="div"
                      className="contentfulMarkdown"
                      dangerouslySetInnerHTML={{
                        __html: item.data[locale],
                      }}
                    />
                  </Styled.ListItem>
                );
              })}
          </Styled.List>
        ) : (
          <Typography.BodyLarge>
            {localize('weeklyProgramme.noProgram')}
          </Typography.BodyLarge>
        )}
      </Mount>
    </Styled.WeeklyProgrammeListContainer>
  );
};

export default WeeklyProgrammeList;
