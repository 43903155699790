import React from 'react';
import create, { GetState, SetState, UseBoundStore } from 'zustand';
import createContext from 'zustand/context';
import {
  StoreApiWithSubscribeWithSelector,
  subscribeWithSelector,
} from 'zustand/middleware';

export interface HeaderState {
  renderProp?: React.ReactNode;
}

export const createHeaderStore = (): UseBoundStore<
  HeaderState,
  StoreApiWithSubscribeWithSelector<HeaderState>
> =>
  create<
    HeaderState,
    SetState<HeaderState>,
    GetState<HeaderState>,
    StoreApiWithSubscribeWithSelector<HeaderState>
  >(
    subscribeWithSelector(() => {
      return {};
    }),
  );

const { Provider, useStore, useStoreApi } = createContext<
  HeaderState,
  UseBoundStore<HeaderState, StoreApiWithSubscribeWithSelector<HeaderState>>
>();

export { Provider as HeaderStateProvider };
export { useStore as useHeaderStore };
export { useStoreApi as useHeaderStoreAPI };
