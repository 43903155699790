import { ActivityFields } from '../contentful/templates/serviceListPages/searchActivitiesPage';
import React from 'react';

type ActivityData = Array<{ node: ActivityFields }>;

export type ActivityFieldsRaw = Omit<
  ActivityFields,
  'bookingStatus'
> & {
  bookingStatus: number[][];  
};
export type ActivityDataRaw = Array<{ node: ActivityFieldsRaw }>;

const useActivityData = (
  data: ActivityDataRaw,
): ActivityData => {
  const [results, setResults] = React.useState<ActivityData>([]);
  const [done, setDone] = React.useState<boolean>(false);

  const reFormatData = React.useCallback((data: ActivityDataRaw) => {
    return data.map((nodeItem) => {
      const item = nodeItem.node;
      return {
        node: {
          ...item,
          bookingStatus: (item.bookingStatus || [])
            .map((statusParts: number[]) => {
              // booking status format
              //  [yy, mm, dd] for each day available
              // where
              //  yy = 2-digit year
              //  mm = 2-digit month 01 = jan
              //  dd = 2-digit day of month

              // parse date parts
              const [year, month, day] = statusParts;
              
              // convert into BookingStatus
              return new Date(2000 + year, month - 1, day);
            }),
        },
      };
    });
  }, []);

  React.useEffect(() => {
    if (done) {
      return;
    }
    const formattedData = reFormatData(data);
    setResults(formattedData);
    setDone(true);
  }, [data, done, reFormatData]);

  return results;
};

export default useActivityData;
