import React from 'react';
import { RowProps } from './SkiResortAmenitiesList';
import Styled from './SkiResortAmenities.styled';
import RowStatusIcon from './RowStatusIcon';
import { Restaurant } from '../../network/skiresort-api/types/RestaurantDataTypes';

const RestaurantRow = ({ data, locale }: RowProps<Restaurant>) => {
  const { name, statusIcon, address, phone, open } = data;
  return (
    <Styled.Row>
      <Styled.Info>
        <span>{name[locale]}</span>
        <span>{address}</span>
        <span>
          <a href={`tel:${phone}`}>{phone}</a>
        </span>
      </Styled.Info>
      <Styled.StatusCell>
        <Styled.Info>{open.hours}</Styled.Info>
        <RowStatusIcon {...statusIcon} />
      </Styled.StatusCell>
    </Styled.Row>
  );
};

export default RestaurantRow;
