import React from 'react';
import { graphql } from 'gatsby';
import WeeklyProgrammeList from '../../components/weekly-programme/WeeklyProgrammeList';

export type ContentfulWeeklyProgrammeTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  programmeId: string;
  onlyToday: boolean;
};

const ContentfulWeeklyProgrammeSection = ({
  title,
  programmeId,
  onlyToday,
}: ContentfulWeeklyProgrammeTypes): JSX.Element => {
  return (
    <WeeklyProgrammeList
      programmeId={programmeId}
      title={title}
      onlyToday={onlyToday}
    />
  );
};

export default ContentfulWeeklyProgrammeSection;

export const contentfulWeeklyProgrammesQuery = graphql`
  fragment contentfulWeeklyProgrammeFields on ContentfulWeeklyProgrammeSection {
    id
    internal {
      type
    }
    title
    programmeId
    onlyToday
  }
`;
