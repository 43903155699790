import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover } from '../../../styles/utils';
import { Icons, ICONS_CODEPOINTS } from '../../../types/icon-types';
import {
  LinkUnderlineCSS,
  LinkUnderLineHoverCSS,
} from '../../common/typography/Typography.styled';

type BreadCrumbContainerProps = {
  noBackgroundColor?: boolean;
};

const BreadCrumbContainer = styled.nav<BreadCrumbContainerProps>`
  background-color: ${(props) =>
    props.noBackgroundColor ? 'transparent' : AppColor.White};
  width: 100%;
  max-width: var(--max-section-width);
  margin: 0 auto;
  padding: 1rem 0;
`;

const BreadCrumbList = styled.ul`
  display: flex;
  gap: 1.5rem;

  width: 100%;
  margin: 0 auto;
  ${breakpoints.sm} {
    max-width: var(--max-content-width);
  }
`;

const icon = String.fromCodePoint(+ICONS_CODEPOINTS[Icons.ChevronRightBold]);

const BreadCrumbListItem = styled.li`
  position: relative;

  a[href] {
    color: ${AppColor.LeviBlue};
    ${LinkUnderlineCSS}
    ${transitions.NavLinkHover}
    ${hover} {
      &:hover {
        ${LinkUnderLineHoverCSS}
      }
    }
  }

  & + &:before {
    font-size: 1rem;
    font-family: icons !important;
    color: ${AppColor.Neutral600};
    content: '${icon}';
    position: absolute;
    left: -1.25rem;
    top: 0.0625rem;
  }
`;

export default {
  BreadCrumbContainer,
  BreadCrumbList,
  BreadCrumbListItem,
};
