import React from 'react';
import CardList from '../common/cardlist/CardList';
import { HorizontalCardType } from '../common/horizontal-card/HorizontalCard';
import { Typography } from '../common/typography/Typography.styled';
import Button, { ButtonLinkWrapper } from '../common/button/Button';
import Styled from './HighlightItem.styled';
import { CardProps, CardType } from '../common/card/Card';
import { ContentfulCallToActionTypes } from '../../contentful/callToActions/types';
import { ContentfulGatsbyImage } from '../../types/images';
import useContentfulCallToAction from '../../hooks/useContentfulCallToAction';
import Grid from '../common/layout/Grid';
import useContentfulGatsbyImage from '../../hooks/useContentfulGatsbyImage';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GridElement } from '../common/layout/Grid.styled';

export type HighlightListItem<T extends CardType = CardType.Event> = {
  title: string;
  body: string;
  callToAction?: ContentfulCallToActionTypes;
  image: ContentfulGatsbyImage;
  relatedCards: CardProps<T>[];
};

const HighlightItem = ({
  title,
  body,
  callToAction,
  image,
  relatedCards,
}: HighlightListItem) => {
  const imageProps = useContentfulGatsbyImage(image);
  const linkProps = useContentfulCallToAction(callToAction);
  return (
    <Grid noGutter key={title}>
      <Styled.HeaderColumn column={4}>
        <Typography.HeadingDecoLarge>{title}</Typography.HeadingDecoLarge>
        <Typography.BodyLarge>{body}</Typography.BodyLarge>
        {linkProps && (
          <ButtonLinkWrapper {...linkProps}>
            <Button>{linkProps.label}</Button>
          </ButtonLinkWrapper>
        )}
      </Styled.HeaderColumn>
      <Styled.ImageColumn column={4}>
        {imageProps && <GatsbyImage {...imageProps} />}
      </Styled.ImageColumn>
      <GridElement column={4}>
        <CardList cards={relatedCards} type={HorizontalCardType.Date} />
      </GridElement>
    </Grid>
  );
};

export default HighlightItem;
