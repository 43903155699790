import { isSameMonth } from 'date-fns';
import React from 'react';
import {
  CalendarState,
  DayType,
  useCalendarStore,
  useCalendarStoreAPI,
} from './calendarStore';
import useCalendarAPI from './useCalendarAPI';

type HookProps = {
  day: DayType;
  ref: React.MutableRefObject<HTMLButtonElement | null>;
  month: Date;
};

type HookReturnType = {
  onClick: React.MouseEventHandler;
  onHover: React.MouseEventHandler;
};

type ClassListAction = 'add' | 'remove';

const combinedComparison = (prev: DayType, curr: DayType) =>
  curr &&
  prev.key === curr.key &&
  prev.isDisabled === curr.isDisabled &&
  prev.isFirst === curr.isFirst &&
  prev.isLast === curr.isLast &&
  prev.isInRange === curr.isInRange &&
  prev.isSelected === curr.isSelected;

const action = (condition: boolean): ClassListAction =>
  condition ? 'add' : 'remove';

const apply = (
  target: HTMLButtonElement,
  action: ClassListAction,
  className: string,
) => target.classList[action](className);

const allowRangeSelectionSelector = (state: CalendarState) =>
  state.allowRangeSelection;

const hideOverflowDatesSelector = (state: CalendarState) =>
  state.hideOverflowDates;

const useCalendarDayState = ({
  day,
  ref,
  month,
}: HookProps): HookReturnType => {
  const { setHoverDate, setSelectedDate } = useCalendarAPI();
  const { subscribe } = useCalendarStoreAPI();
  const allowRangeSelection = useCalendarStore(allowRangeSelectionSelector);
  const hideOverflowDates = useCalendarStore(hideOverflowDatesSelector);

  const selector = React.useCallback(
    (state: CalendarState) =>
      state.days.filter(
        ({ key }) => key === day.key && day.key !== undefined,
      )[0],
    [day.key],
  );

  const checkState = React.useCallback(
    (day: DayType) => {
      if (!day || !ref.current) return;

      ref.current.disabled = day.isDisabled;
      apply(ref.current, action(day.isActive), 'active');
      const isVisible = !hideOverflowDates
        ? true
        : isSameMonth(day.date, month);
      apply(ref.current, action(!isVisible), 'hidden');
      apply(ref.current, action(day.isSelected), 'selected');
      if (allowRangeSelection) {
        apply(ref.current, action(day.isFirst), 'first');
        apply(ref.current, action(day.isLast), 'last');
        apply(ref.current, action(day.isInRange), 'in-range');
      }
    },
    [ref, hideOverflowDates, month, allowRangeSelection],
  );

  const onClick = React.useCallback(
    () => setSelectedDate(day.date),
    [day.date, setSelectedDate],
  );

  const onHover = React.useCallback(
    () => setHoverDate(day.date),
    [day.date, setHoverDate],
  );

  React.useLayoutEffect(() => {
    checkState(day);

    return subscribe(selector, checkState, {
      equalityFn: combinedComparison,
    });
  }, [checkState, subscribe, selector, day]);

  return {
    onClick,
    onHover,
  };
};

export default useCalendarDayState;
