import SkiResortAPI from './SkiResortAPI';
import { BusScheduleDataType } from './types/BusScheduleDataTypes';
import { Lift } from './types/LiftDataTypes';
import { OtherActivityData } from './types/OtherActivitiesTypes';
import { ProgrammeData } from './types/ProgrammeDataTypes';
import { RestaurantData } from './types/RestaurantDataTypes';
import { Service, ServiceData } from './types/ServiceDataTypes';
import { SlopeCameraData } from './types/SlopeCameraDataTypes';
import { SlopeData } from './types/SlopeDataTypes';
import {
  ActivityTrailResponse,
  ActivityTrailResponseDataEntry,
  LeviTodaySummaryDataType,
  WeatherForecastDataType,
  WeatherStationType,
} from './types/types';

export const enum ApiPath {
  BikeTrails = 'activities/v1/trails/bike',
  LeviToday = 'statistics/v1/today',
  Lifts = 'amenities/v1/lifts',
  OtherActivities = 'activities/v1/others',
  Programme = 'activities/v1/programmes/:id',
  Programmes = 'activities/v1/programmes',
  Restaurant = 'amenities/v1/restaurants/:id',
  Restaurants = 'amenities/v1/restaurants',
  Service = 'amenities/v1/services/:id',
  Services = 'amenities/v1/services',
  SkiBusSchedule = 'transportation/v1/skibus/schedule',
  SlopeCameras = 'miscellaneous/v1/slope_cameras',
  Slopes = 'activities/v1/slopes',
  Weather = 'miscellaneous/v1/weather',
  WeatherForecast = 'miscellaneous/v1/weather/forecast',
}

export type ApiDataTypes = {
  [ApiPath.BikeTrails]: ActivityTrailResponseDataEntry[];
  [ApiPath.LeviToday]: LeviTodaySummaryDataType;
  [ApiPath.Lifts]: Lift[];
  [ApiPath.OtherActivities]: OtherActivityData[];
  [ApiPath.Programme]: ProgrammeData;
  [ApiPath.Programmes]: ProgrammeData[];
  [ApiPath.Restaurant]: RestaurantData;
  [ApiPath.Restaurants]: RestaurantData[];
  [ApiPath.Service]: ServiceData;
  [ApiPath.Services]: Service[];
  [ApiPath.SkiBusSchedule]: BusScheduleDataType;
  [ApiPath.SlopeCameras]: SlopeCameraData[];
  [ApiPath.Slopes]: SlopeData[];
  [ApiPath.Weather]: WeatherStationType[];
  [ApiPath.WeatherForecast]: WeatherForecastDataType[];
};

type ApiPathParamKeys = {
  [key in API_PATH]: API_PARAMS | undefined;
};

export type ApiPathParamTypes = ApiPathParamKeys & {
  [ApiPath.Restaurant]: { id: number | undefined };
  [ApiPath.Service]: { id: number | undefined };
};

export type API_PATH = keyof ApiDataTypes;
export type API_PARAMS = Record<string, string | number | undefined>;

function invariant(cond: boolean, message: string): asserts cond {
  if (!cond) throw new Error(message);
}

const generatePath = (path: string, params: API_PARAMS): string => {
  return path.replace(/:(\w+)/g, (_, key) => {
    key = key.replace(':', '');
    invariant(params[key] !== undefined, `Missing ":${key}" param`);
    return String(params[key]);
  });
};

const GET = <T extends API_PATH>(path: T, params?: ApiPathParamTypes[T]) => {
  const pathWithParams = params ? generatePath(path, params) : path;

  return SkiResortAPI.get<ApiDataTypes[T]>(`/${pathWithParams}`);
};

export { GET };
