import React from 'react';
import { CalendarState, useCalendarStoreAPI } from './calendarStore';
import { DateRange } from '../../../utils/date-utils';
import isEqual from 'date-fns/isEqual';

export type CalendarListenerProps = {
  onSelectedMonthChange?: (selectedMonth: Date) => void;
  onSelectedDateChange?: (selectedDate: Date | undefined) => void;
  onSelectedDateRangeChange?: (selectedDateRange: DateRange) => void;
};

const selectedMonthSelector = (state: CalendarState) => state.selectedMonth;
const selectedMonthComparison = (prev: Date, curr: Date) => isEqual(prev, curr);
const selectedDateSelector = (state: CalendarState) => state.selectedDate;
const selectedDateComparison = (
  prev: Date | undefined,
  curr: Date | undefined,
) =>
  prev !== undefined && curr !== undefined
    ? isEqual(prev, curr)
    : prev === curr;
const selectedDateRangeSelector = (state: CalendarState) =>
  state.selectedDateRange;
const selectedDateRangeComparison = (prev: DateRange, curr: DateRange) =>
  prev.start && curr.start
    ? prev.end && curr.end
      ? isEqual(prev.end, curr.end) && isEqual(prev.start, curr.start)
      : isEqual(prev.start, curr.start) && prev.end === curr.end
    : prev.start === curr.start;

const CalendarListener = (props: CalendarListenerProps): null => {
  const { subscribe } = useCalendarStoreAPI();
  const {
    onSelectedMonthChange,
    onSelectedDateChange,
    onSelectedDateRangeChange,
  } = props;

  React.useEffect(
    () =>
      onSelectedMonthChange
        ? subscribe(selectedMonthSelector, onSelectedMonthChange, {
            equalityFn: selectedMonthComparison,
          })
        : () => null,
    [onSelectedMonthChange, subscribe],
  );

  React.useEffect(
    () =>
      onSelectedDateChange
        ? subscribe(selectedDateSelector, onSelectedDateChange, {
            equalityFn: selectedDateComparison,
          })
        : () => null,
    [onSelectedDateChange, subscribe],
  );

  React.useEffect(() => {
    return onSelectedDateRangeChange
      ? subscribe(selectedDateRangeSelector, onSelectedDateRangeChange, {
          equalityFn: selectedDateRangeComparison,
        })
      : () => null;
  }, [onSelectedDateRangeChange, subscribe]);

  return null;
};

export default CalendarListener;
