import { PageContextTypes } from '../src/contentful/templates/types';

type StaticPageContextProps = {
  [key: string]: PageContextTypes;
};

const staticPageContextMap: StaticPageContextProps = {
  '/haku': {
    locale: 'fi',
    pageId: null,
    staticPageKey: 'search',
    breadcrumbs: [{ to: '/', label: 'Etusivu' }],
  },
  '/en/search': {
    locale: 'en',
    pageId: null,
    staticPageKey: 'search',
    breadcrumbs: [{ to: '/en', label: 'Home' }],
  },
  '/*': {
    locale: 'fi',
    pageId: null,
    breadcrumbs: [{ to: '/', label: 'Etusivu' }],
  },
  '/en/*': {
    locale: 'en',
    pageId: null,
    breadcrumbs: [{ to: '/en', label: 'Home' }],
  },
};

export const resolveStaticPageContext = (path: string) =>
  staticPageContextMap[path];
