import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import { hover } from '../../../styles/utils';
import { CardLinkProps } from '../card/Card.styled';
import Icon from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';

const InfoGridElement = styled.a.attrs<CardLinkProps>((props) => {
  if (!(props.to || props.href)) {
    return {
      as: 'div',
    };
  }
})<CardLinkProps>`
  ${(props) =>
    (props.to || props.href) &&
    `
    ${hover} {
      cursor: pointer;
    }
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${Icon} + ${Typography.LinkSmall} {
    margin-top: 1.5rem;
  }

  ${Typography.LinkSmall} {
    color: ${AppColor.LeviBlue};
  }

  ${Icon} + ${Typography.LinkSmall} + ${Typography.NumberBig} {
    margin-top: 1rem;
  }

  ${Typography.NumberBig} {
    color: ${AppColor.Neutral700};
  }
`;

export default {
  InfoGridElement,
};
