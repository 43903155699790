export enum Icons {
  AccommodationAnimalsLarge = 'accommodation-animals-large',
  AccommodationAnimalsSmall = 'accommodation-animals-small',
  AccommodationBedsLarge = 'accommodation-beds-large',
  AccommodationBedsSmall = 'accommodation-beds-small',
  AccommodationCottageLarge = 'accommodation-cottage-large',
  AccommodationCottageSmall = 'accommodation-cottage-small',
  AccommodationFamilyLarge = 'accommodation-family-large',
  AccommodationFamilySmall = 'accommodation-family-small',
  AccommodationHotelsLarge = 'accommodation-hotels-large',
  AccommodationHotelsSmall = 'accommodation-hotels-small',
  AccommodationNoAnimalsLarge = 'accommodation-no-animals-large',
  AccommodationNoAnimalsSmall = 'accommodation-no-animals-small',
  AccommodationNorthernLightsLarge = 'accommodation-northern-lights-large',
  AccommodationNorthernLightsSmall = 'accommodation-northern-lights-small',
  AccommodationRoomsLarge = 'accommodation-rooms-large',
  AccommodationRoomsSmall = 'accommodation-rooms-small',
  ActivityBike = 'activity-bike',
  ActivityCanooing = 'activity-canooing',
  ActivityChild = 'activity-child',
  ActivityClimbing = 'activity-climbing',
  ActivityCrossCountrySki = 'activity-cross-country-ski',
  ActivityFishing = 'activity-fishing',
  ActivityGolf = 'activity-golf',
  ActivityHiking = 'activity-hiking',
  ActivityHorse = 'activity-horse',
  ActivityHusky = 'activity-husky',
  ActivityMountain = 'activity-mountain',
  ActivityNature = 'activity-nature',
  ActivityNavigation = 'activity-navigation',
  ActivityReindeer = 'activity-reindeer',
  ActivityRiver = 'activity-river',
  ActivityRunning = 'activity-running',
  ActivitySkiLift = 'activity-ski-lift',
  ActivitySki = 'activity-ski',
  ActivitySledding = 'activity-sledding',
  ActivitySnowMobile = 'activity-snow-mobile',
  ActivitySnowShoe = 'activity-snow-shoe',
  ActivitySnowboarding = 'activity-snowboarding',
  ActivitySurfing = 'activity-surfing',
  ActivitySwimming = 'activity-swimming',
  ActivityYoga = 'activity-yoga',
  ArrowLeftLarge = 'arrow-left-large',
  ArrowLeftMedium = 'arrow-left-medium',
  ArrowLeftSmall = 'arrow-left-small',
  ArrowRightLarge = 'arrow-right-large',
  ArrowRightMedium = 'arrow-right-medium',
  ArrowRightSmall = 'arrow-right-small',
  Calendar = 'calendar',
  Camera = 'camera',
  Check = 'check',
  ChevronDownLargeBold = 'chevron-down-large-bold',
  ChevronDownLargeFlat = 'chevron-down-large-flat',
  ChevronDownLarge = 'chevron-down-large',
  ChevronDownXl = 'chevron-down-xl',
  ChevronLeftLargeBoldRound = 'chevron-left-large-bold-round',
  ChevronLeftLarge = 'chevron-left-large',
  ChevronRightBoldRound = 'chevron-right-bold-round',
  ChevronRightBold = 'chevron-right-bold',
  ChevronRightLarge = 'chevron-right-large',
  ChevronUpLargeBold = 'chevron-up-large-bold',
  ChevronUpLarge = 'chevron-up-large',
  Close = 'close',
  ExternalLink = 'external-link',
  Location = 'location',
  Mail = 'mail',
  Menu = 'menu',
  Phone = 'phone',
  RentalCar = 'rental-car',
  RestaurantsCafeLarge = 'restaurants-cafe-large',
  RestaurantsCafeSmall = 'restaurants-cafe-small',
  RestaurantsCateringLarge = 'restaurants-catering-large',
  RestaurantsCateringSmall = 'restaurants-catering-small',
  RestaurantsClubLarge = 'restaurants-club-large',
  RestaurantsClubSmall = 'restaurants-club-small',
  RestaurantsRestaurantLarge = 'restaurants-restaurant-large',
  RestaurantsRestaurantSmall = 'restaurants-restaurant-small',
  RestaurantsSkicafesLarge = 'restaurants-skicafes-large',
  RestaurantsSkicafesSmall = 'restaurants-skicafes-small',
  RestaurantsTakeawayLarge = 'restaurants-takeaway-large',
  SearchLarge = 'search-large',
  Search = 'search',
  SeasonSummerLarge = 'season-summer-large',
  SeasonSummer = 'season-summer',
  SeasonWinterLarge = 'season-winter-large',
  SeasonWinter = 'season-winter',
  Skibus = 'skibus',
  SlopeClosed = 'slope-closed',
  SlopeHasLighting = 'slope-has-lighting',
  SlopeOpen = 'slope-open',
  SlopeTemporarilyClosed = 'slope-temporarily-closed',
  Snowmaking = 'snowmaking',
  SocialMediaFacebook = 'social-media-facebook',
  SocialMediaInstagram = 'social-media-instagram',
  SocialMediaMail = 'social-media-mail',
  SocialMediaPinterest = 'social-media-pinterest',
  SocialMediaTripadvisor = 'social-media-tripadvisor',
  SocialMediaTwitter = 'social-media-twitter',
  SocialMediaYoutube = 'social-media-youtube',
  Star = 'star',
  Time = 'time',
  User = 'user',
  WeatherCleardayBig = 'weather-clearday-big',
  WeatherClearday = 'weather-clearday',
  WeatherClearnightBig = 'weather-clearnight-big',
  WeatherClearnight = 'weather-clearnight',
  WeatherCloudyBig = 'weather-cloudy-big',
  WeatherCloudy = 'weather-cloudy',
  WeatherFogBig = 'weather-fog-big',
  WeatherFog = 'weather-fog',
  WeatherLightrainBig = 'weather-lightrain-big',
  WeatherLightrain = 'weather-lightrain',
  WeatherLightsnowBig = 'weather-lightsnow-big',
  WeatherLightsnow = 'weather-lightsnow',
  WeatherMountain = 'weather-mountain',
  WeatherPartlycloudyDayBig = 'weather-partlycloudy-day-big',
  WeatherPartlycloudyDay = 'weather-partlycloudy-day',
  WeatherPartlycloudyNightBig = 'weather-partlycloudy-night-big',
  WeatherPartlycloudyNight = 'weather-partlycloudy-night',
  WeatherRainBig = 'weather-rain-big',
  WeatherRain = 'weather-rain',
  WeatherSleetBig = 'weather-sleet-big',
  WeatherSleet = 'weather-sleet',
  WeatherSnowBig = 'weather-snow-big',
  WeatherSnow = 'weather-snow',
  WeatherThunderBig = 'weather-thunder-big',
  WeatherThunder = 'weather-thunder',
  WeatherValley = 'weather-valley',
  WeatherWindBig = 'weather-wind-big',
  WeatherWind = 'weather-wind',
  Website = 'website',
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.AccommodationAnimalsLarge]: '61697',
  [Icons.AccommodationAnimalsSmall]: '61698',
  [Icons.AccommodationBedsLarge]: '61699',
  [Icons.AccommodationBedsSmall]: '61700',
  [Icons.AccommodationCottageLarge]: '61701',
  [Icons.AccommodationCottageSmall]: '61702',
  [Icons.AccommodationFamilyLarge]: '61703',
  [Icons.AccommodationFamilySmall]: '61704',
  [Icons.AccommodationHotelsLarge]: '61705',
  [Icons.AccommodationHotelsSmall]: '61706',
  [Icons.AccommodationNoAnimalsLarge]: '61707',
  [Icons.AccommodationNoAnimalsSmall]: '61708',
  [Icons.AccommodationNorthernLightsLarge]: '61709',
  [Icons.AccommodationNorthernLightsSmall]: '61710',
  [Icons.AccommodationRoomsLarge]: '61711',
  [Icons.AccommodationRoomsSmall]: '61712',
  [Icons.ActivityBike]: '61713',
  [Icons.ActivityCanooing]: '61714',
  [Icons.ActivityChild]: '61715',
  [Icons.ActivityClimbing]: '61716',
  [Icons.ActivityCrossCountrySki]: '61717',
  [Icons.ActivityFishing]: '61718',
  [Icons.ActivityGolf]: '61719',
  [Icons.ActivityHiking]: '61720',
  [Icons.ActivityHorse]: '61721',
  [Icons.ActivityHusky]: '61722',
  [Icons.ActivityMountain]: '61723',
  [Icons.ActivityNature]: '61724',
  [Icons.ActivityNavigation]: '61725',
  [Icons.ActivityReindeer]: '61726',
  [Icons.ActivityRiver]: '61727',
  [Icons.ActivityRunning]: '61728',
  [Icons.ActivitySkiLift]: '61729',
  [Icons.ActivitySki]: '61730',
  [Icons.ActivitySledding]: '61731',
  [Icons.ActivitySnowMobile]: '61732',
  [Icons.ActivitySnowShoe]: '61733',
  [Icons.ActivitySnowboarding]: '61734',
  [Icons.ActivitySurfing]: '61735',
  [Icons.ActivitySwimming]: '61736',
  [Icons.ActivityYoga]: '61737',
  [Icons.ArrowLeftLarge]: '61738',
  [Icons.ArrowLeftMedium]: '61739',
  [Icons.ArrowLeftSmall]: '61740',
  [Icons.ArrowRightLarge]: '61741',
  [Icons.ArrowRightMedium]: '61742',
  [Icons.ArrowRightSmall]: '61743',
  [Icons.Calendar]: '61744',
  [Icons.Camera]: '61745',
  [Icons.Check]: '61746',
  [Icons.ChevronDownLargeBold]: '61747',
  [Icons.ChevronDownLargeFlat]: '61748',
  [Icons.ChevronDownLarge]: '61749',
  [Icons.ChevronDownXl]: '61750',
  [Icons.ChevronLeftLargeBoldRound]: '61751',
  [Icons.ChevronLeftLarge]: '61752',
  [Icons.ChevronRightBoldRound]: '61753',
  [Icons.ChevronRightBold]: '61754',
  [Icons.ChevronRightLarge]: '61755',
  [Icons.ChevronUpLargeBold]: '61756',
  [Icons.ChevronUpLarge]: '61757',
  [Icons.Close]: '61758',
  [Icons.ExternalLink]: '61759',
  [Icons.Location]: '61760',
  [Icons.Mail]: '61761',
  [Icons.Menu]: '61762',
  [Icons.Phone]: '61763',
  [Icons.RentalCar]: '61764',
  [Icons.RentalCar]: '61765',
  [Icons.RestaurantsCafeLarge]: '61766',
  [Icons.RestaurantsCafeSmall]: '61767',
  [Icons.RestaurantsCateringLarge]: '61768',
  [Icons.RestaurantsCateringSmall]: '61769',
  [Icons.RestaurantsClubLarge]: '61770',
  [Icons.RestaurantsClubSmall]: '61771',
  [Icons.RestaurantsRestaurantLarge]: '61772',
  [Icons.RestaurantsRestaurantSmall]: '61773',
  [Icons.RestaurantsSkicafesLarge]: '61774',
  [Icons.RestaurantsSkicafesSmall]: '61775',
  [Icons.RestaurantsTakeawayLarge]: '61776',
  [Icons.SearchLarge]: '61777',
  [Icons.Search]: '61778',
  [Icons.SeasonSummerLarge]: '61779',
  [Icons.SeasonSummer]: '61780',
  [Icons.SeasonWinterLarge]: '61781',
  [Icons.SeasonWinter]: '61782',
  [Icons.Skibus]: '61783',
  [Icons.SlopeClosed]: '61784',
  [Icons.SlopeHasLighting]: '61785',
  [Icons.SlopeOpen]: '61786',
  [Icons.SlopeTemporarilyClosed]: '61787',
  [Icons.Snowmaking]: '61788',
  [Icons.SocialMediaFacebook]: '61789',
  [Icons.SocialMediaInstagram]: '61790',
  [Icons.SocialMediaMail]: '61791',
  [Icons.SocialMediaPinterest]: '61792',
  [Icons.SocialMediaTripadvisor]: '61793',
  [Icons.SocialMediaTwitter]: '61794',
  [Icons.SocialMediaYoutube]: '61795',
  [Icons.Star]: '61796',
  [Icons.Time]: '61797',
  [Icons.User]: '61798',
  [Icons.WeatherCleardayBig]: '61799',
  [Icons.WeatherClearday]: '61800',
  [Icons.WeatherClearnightBig]: '61801',
  [Icons.WeatherClearnight]: '61802',
  [Icons.WeatherCloudyBig]: '61803',
  [Icons.WeatherCloudy]: '61804',
  [Icons.WeatherFogBig]: '61805',
  [Icons.WeatherFog]: '61806',
  [Icons.WeatherLightrainBig]: '61807',
  [Icons.WeatherLightrain]: '61808',
  [Icons.WeatherLightsnowBig]: '61809',
  [Icons.WeatherLightsnow]: '61810',
  [Icons.WeatherMountain]: '61811',
  [Icons.WeatherPartlycloudyDayBig]: '61812',
  [Icons.WeatherPartlycloudyDay]: '61813',
  [Icons.WeatherPartlycloudyNightBig]: '61814',
  [Icons.WeatherPartlycloudyNight]: '61815',
  [Icons.WeatherRainBig]: '61816',
  [Icons.WeatherRain]: '61817',
  [Icons.WeatherSleetBig]: '61818',
  [Icons.WeatherSleet]: '61819',
  [Icons.WeatherSnowBig]: '61820',
  [Icons.WeatherSnow]: '61821',
  [Icons.WeatherThunderBig]: '61822',
  [Icons.WeatherThunder]: '61823',
  [Icons.WeatherValley]: '61824',
  [Icons.WeatherWindBig]: '61825',
  [Icons.WeatherWind]: '61826',
  [Icons.Website]: '61827',
};
