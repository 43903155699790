import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import ThemeContainer from '../common/ThemeContainer';
import { SimpleHeaderProps } from './PageHeader';

const Header = styled(ThemeContainer)<SimpleHeaderProps>`
  width: 100%;
  max-height: ${(props) => (props.simpleLayout ? 256 : 512)}px;
  min-height: ${(props) => (props.simpleLayout ? 256 : 450)}px;
  color: white;
  position: relative;
  flex-grow: 1;
  isolation: isolate;

  ${breakpoints.lg} {
    max-height: ${(props) => (props.simpleLayout ? 450 : 728)}px;
  }
`;

const HeroImage = styled.div`
  max-height: inherit;
  min-height: inherit;

  > * {
    max-height: inherit;
    min-height: inherit;
    height: 100%;
  }

  > .gatsby-image-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 34.18%
      ),
      rgba(0, 0, 0, 0.2);
  }

  .gatsby-image-wrapper > div[aria-hidden="true"] {
    display: inline-block;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0.625rem 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;

  * + * {
    margin-left: 0.1875rem;
  }

  ${breakpoints.lg} {
    padding: 1.0625rem 1.125rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: var(--max-content-width);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  > * {
    max-width: 784px;
  }
`;

const TextContainer = styled.div`
  max-width: 700px;
  text-align: center;

  * + * {
    margin-top: 1rem;
    ${breakpoints.lg} {
      margin-top: 1.5rem;
    }
  }
`;

const ActionsContainer = styled.div`
  margin-top: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: center;

  ${breakpoints.lg}  {
    margin-top: 5rem;
  }
`;

export default {
  Header,
  HeroImage,
  ContentContainer,
  TextContainer,
  ActionsContainer,
  Footer,
};
