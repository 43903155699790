import { CardType } from '../../../components/common/card/Card';
import { DataTransformer } from '../cardDataTransformers';
import { resolveLinks, trimLeadingWhiteSpace } from './utils';

const activityFieldsTransformer: DataTransformer<CardType.Activity> = ({
  node,
}) => {
  const { id, title, image, description, price, categories, bookingStatus } =
    node;

  // All activities does not have an image.
  // Image is created with gatsby-source-activities -plugin
  const gatsbyImageData = image?.childImageSharp;
  // Activity's node-id is used as pageId.
  const links = resolveLinks(id, 'activities');

  return {
    title: trimLeadingWhiteSpace(title),
    id,
    type: CardType.Activity,
    image: gatsbyImageData,
    description: description || '',
    callToAction: links[0],
    categories: categories?.map((cat) => String(cat)),
    priceInfo: {
      price: price?.amount,
      //totalPrice: price?.amount,
      //unit: price?.currency,
    },
    bookingStatus,
  };
};

export default activityFieldsTransformer;
