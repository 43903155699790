import useFilteredData, {
  FilteredDataInput,
  FilteredDataOutput,
} from './useFilteredData';
import useSortedData, {
  SortedDataInput,
  SortedDataOutput,
} from './useSortedData';

type InputType<T extends Record<string, unknown>> = SortedDataInput<T> &
  Pick<FilteredDataInput<T>, 'filters'>;
type OutputType<T extends Record<string, unknown>> = FilteredDataOutput<T> &
  Pick<SortedDataOutput<T>, 'setSortOrder'>;

const useSortedFilteredData = <T extends Record<string, unknown>>({
  data,
  sortBy,
  initialSortOrder,
  filters,
}: InputType<T>): OutputType<T> => {
  const { sortedData, setSortOrder } = useSortedData({
    data,
    sortBy,
    initialSortOrder,
  });

  const { filteredData } = useFilteredData({ data: sortedData, filters });

  return {
    filteredData,
    setSortOrder,
  };
};

export default useSortedFilteredData;
