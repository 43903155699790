import { Link } from "@Levi/components/common/link";
import React from 'react';
import { Typography } from '../../../common/typography/Typography.styled';
import Styled from './MainNavigationDropdown.styled';
import {
  NavLinkProps,
  NavLinkPropsWithSubpages,
} from '../MainNavigationPropTypes';
import Icon, { IconSize } from '../../../common/icon/Icon';
import { useDropdownContainerCalculations } from '../../../../hooks/useDropdownContainerCalculations';

const renderSubPageLink = ({ to, label }: NavLinkProps) => (
  <Styled.DropdownLi key={label}>
    <Typography.SubLink as={Link} to={to}>
      {label}
    </Typography.SubLink>
  </Styled.DropdownLi>
);

const renderPageLink = ({ to, label, subpages }: NavLinkPropsWithSubpages) => (
  <Styled.DropdownLi key={to}>
    <Typography.NavigationLink as={Link} to={to}>
      {label}
    </Typography.NavigationLink>
    {subpages && subpages.length > 0 && (
      <Styled.DropdownUl>{subpages.map(renderSubPageLink)}</Styled.DropdownUl>
    )}
  </Styled.DropdownLi>
);

const renderPageIconLink = ({ to, label, icon }: NavLinkPropsWithSubpages) => (
  <Styled.DropdownLi key={to}>
    <Typography.SubLink as={Link} to={to}>
      {icon && <Icon type={icon} size={IconSize.Size48} />}
      {label}
    </Typography.SubLink>
  </Styled.DropdownLi>
);

const renderLinks = (pageLinks: NavLinkPropsWithSubpages[]) => (
  <Styled.DropdownUl numColumns={pageLinks.length < 4 ? pageLinks.length : 4}>
    {pageLinks.map(renderPageLink)}
  </Styled.DropdownUl>
);

const renderIconLinks = (pageLinks: NavLinkPropsWithSubpages[]) => (
  <Styled.DropdownUl
    withIcons
    numColumns={pageLinks.length < 4 ? pageLinks.length : 4}
  >
    {pageLinks.map(renderPageIconLink)}
  </Styled.DropdownUl>
);

const MainNavigationDropdown = ({
  pageLinks,
  shouldRender,
}: {
  pageLinks: NavLinkPropsWithSubpages[];
  shouldRender: boolean;
}): JSX.Element => {
  const hasIcons = !!pageLinks.find(({ icon }) => !!icon);

  const refWrapper = React.useRef<HTMLDivElement>(null);
  const refContainer = React.useRef<HTMLDivElement>(null);

  const { recalculate } = useDropdownContainerCalculations({
    refWrapper,
    refContainer,
  });

  React.useEffect(() => {
    if (shouldRender) {
      recalculate();
    }
  }, [shouldRender, recalculate]);

  return (
    <Styled.DropdownWrapper ref={refWrapper}>
      <Styled.DropdownContainer ref={refContainer}>
        {shouldRender
          ? hasIcons
            ? renderIconLinks(pageLinks)
            : renderLinks(pageLinks)
          : null}
      </Styled.DropdownContainer>
    </Styled.DropdownWrapper>
  );
};

export default MainNavigationDropdown;
