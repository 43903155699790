import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import StarRating from '../../rating/StarRating';
import { AccommodationCardProps } from '../types';
import useContentfulCallToAction, {
  isInternalCTA,
} from '../../../../hooks/useContentfulCallToAction';
import { isValidDateRange } from '../../../../utils/date-utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Theme } from '../../../../styles/themes';
import useContentfulGatsbyImage from '../../../../hooks/useContentfulGatsbyImage';
import { Icons } from '../../../../types/icon-types';
import { IconSize } from '../../icon/Icon';
import CardTag from '../CardTag';
import { localize } from '../../../../localization/i18n';

export type AccommodationMapInfoProps = Pick<
  AccommodationCardProps,
  | 'id'
  | 'title'
  | 'image'
  | 'rating'
  | 'guests'
  | 'selectedDateRange'
  | 'callToAction'
  | 'location'
> & {
  beds: number;
  extraBeds: number;
};

const AccommodationMapInfoCard = ({
  title,
  image,
  callToAction,
  rating,
  beds = 1,
  extraBeds = 0,
  guests,
  selectedDateRange,
}: AccommodationMapInfoProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);

  const linkPropsWithParams = React.useMemo(() => {
    if (!linkProps || !isInternalCTA(linkProps)) {
      return {};
    }
    const guestParams =
      guests && (guests.adults > 0 || guests.children > 0)
        ? `adults=${guests.adults}&children=${guests.children}`
        : '';

    const dateRangeParams =
      selectedDateRange && isValidDateRange(selectedDateRange)
        ? `start=${selectedDateRange.start.getTime()}&end=${selectedDateRange.end.getTime()}`
        : '';

    const params = `${
      guestParams || selectedDateRange ? '?' : ''
    }${guestParams}${
      guestParams && selectedDateRange ? '&' : ''
    }${dateRangeParams}`;

    return {
      ...linkProps,
      to: `${linkProps.to}/${params}`,
    };
  }, [guests, linkProps, selectedDateRange]);

  const imageProps = useContentfulGatsbyImage(image);

  return (
    <Styled.Card theme={Theme.Primary} {...linkPropsWithParams}>
      {imageProps && (
        <Styled.CardImageWrapper>
          <GatsbyImage {...imageProps} />
        </Styled.CardImageWrapper>
      )}
      <Styled.MapInfoCardContent>
        <Styled.CardHeading>
          <Typography.SubLink>{title}</Typography.SubLink>
        </Styled.CardHeading>

        <Styled.CardInfoRow>
          <StarRating rating={rating} />
        </Styled.CardInfoRow>
        <CardTag
          icon={Icons.AccommodationBedsSmall}
          iconSize={IconSize.Size16}
          label={`${beds}${extraBeds > 0 ? `+${extraBeds}` : ''} ${localize(
            beds > 1
              ? 'accommodations.capacity.Beds'
              : 'accommodations.capacity.Bed',
          )}`}
        />
      </Styled.MapInfoCardContent>
    </Styled.Card>
  );
};

export default AccommodationMapInfoCard;
