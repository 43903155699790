import styled from 'styled-components';
import { Typography } from '../common/typography/Typography.styled';
import { AppColor } from '../../styles/colors';
import { GridElement } from '../common/layout/Grid.styled';

const HeaderColumn = styled(GridElement)`
  padding-top: 4.5rem;
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${Typography.HeadingDecoLarge} + ${Typography.BodyLarge} {
    margin-top: 2.125rem;
    &:before {
      content: '';
      width: 56px;
      height: 2px;
      background-color: ${AppColor.Neutral300};
      display: block;
      margin: 0 auto 2rem;
    }
    margin-bottom: 2rem;
  }
`;

const ImageColumn = styled(GridElement)`
  figure {
    width: 100%;
    max-height: 540px;

    img {
      width: 100%;
      height: auto;
    }
  }
`;

export default {
  HeaderColumn,
  ImageColumn,
};
