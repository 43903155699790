import React from 'react';
import { useSkiResortDataByApiPath } from '../../hooks/useSkiResortDataByApiPath';
import { ApiPath } from '../../network/skiresort-api';
import { BusRoute } from '../../network/skiresort-api/types/BusScheduleDataTypes';
import { AppColor } from '../../styles/colors';
import Styled from './SkiBusSchedule.styled';
import { useLocale } from '@Levi/localization/i18n';

// type CurrentStopsState = {
//   [BusRoute.Route1]: BusStop | undefined;
//   [BusRoute.Route2]: BusStop | undefined;
// };

// type RouteTimers = {
//   [BusRoute.Route1]: NodeJS.Timeout | undefined;
//   [BusRoute.Route2]: NodeJS.Timeout | undefined;
// };

const SkiBusSchedule = ({ showRoute }: { showRoute?: BusRoute }) => {
  const locale = useLocale();

  // const [currentStops, setCurrentStops] = React.useState<CurrentStopsState>({
  //   [BusRoute.Route1]: undefined,
  //   [BusRoute.Route2]: undefined,
  // });
  // const timerRefs = React.useRef<RouteTimers>({
  //   [BusRoute.Route1]: undefined,
  //   [BusRoute.Route2]: undefined,
  // });

  const busScheduleTableRows = useSkiResortDataByApiPath(
    ApiPath.SkiBusSchedule,
  );

  const rowsByRoute = React.useMemo(
    () =>
      !busScheduleTableRows
        ? undefined
        : Object.keys(busScheduleTableRows.rows)
            .filter((route) => (showRoute ? showRoute === route : true))
            .map((route) => busScheduleTableRows.rows[route as BusRoute]),
    [busScheduleTableRows, showRoute],
  );

  // const updateLocation = React.useCallback(
  //   (route: BusRoute) => {
  //     const currentTimer = timerRefs.current[route];
  //     if (currentTimer) {
  //       clearTimeout(currentTimer);
  //     }

  //     if (!busScheduleTableRows) {
  //       return [];
  //     }
  //     const { stops } = busScheduleTableRows;
  //     const { currentStop, nextUpdate } =
  //       getCurrentStopWithNextUpdateTime(route, stops) || {};

  //     if (nextUpdate) {
  //       timerRefs.current[route] = setTimeout(() => {
  //         updateLocation(route);
  //       }, nextUpdate * 1000);
  //     }

  //     setCurrentStops((prevState) => ({
  //       ...prevState,
  //       [route]: currentStop,
  //     }));
  //   },
  //   [busScheduleTableRows],
  // );

  // React.useEffect(() => {
  //   if (showRoute === BusRoute.Route1 || showRoute === undefined) {
  //     updateLocation(BusRoute.Route1);
  //   }
  //   if (showRoute === BusRoute.Route2 || showRoute === undefined) {
  //     updateLocation(BusRoute.Route2);
  //   }

  //   const timers = timerRefs.current;
  //   return () => {
  //     const currentTimerR1 = timers[BusRoute.Route1];
  //     const currentTimerR2 = timers[BusRoute.Route2];

  //     if (currentTimerR1) {
  //       clearTimeout(currentTimerR1);
  //     }
  //     if (currentTimerR2) {
  //       clearTimeout(currentTimerR2);
  //     }
  //   };
  // }, [showRoute, updateLocation]);

  if (!busScheduleTableRows || !rowsByRoute) {
    return null;
  }

  return (
    <Styled.SkiBusSchedule>
      {rowsByRoute.map((rows) => {
        // const currentRoute = rows[0].route;
        // const currentStop = currentStops[currentRoute];
        return rows.map((row) => (
          <Styled.Stop
            key={row.id}
            color={
              row.route === BusRoute.Route1
                ? AppColor.SkiBusRoute1
                : AppColor.SkiBusRoute2
            }
          >
            <Styled.StopName>{row.name[locale]}</Styled.StopName>
            <Styled.BusLocation>
              {/* {currentStop && currentStop.id === row.id && (
                <Icon type={Icons.Skibus} />
              )} */}
            </Styled.BusLocation>
            <Styled.StopSchedule>
              {row.showDepartures && row.slots.map((slot, index) => (
                <div key={index}>{slot.time}</div>
              ))}
            </Styled.StopSchedule>
          </Styled.Stop>
        ));
      })}
    </Styled.SkiBusSchedule>
  );
};

export default SkiBusSchedule;
