import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulGatsbyImage } from '../../types/images';
import Carousel from '../../components/common/carousel/Carousel';

export type ContentfulCarouselTypes = {
  id: string;
  internal: {
    type: string;
  };
  images: ContentfulGatsbyImage[];
};

const ContentfulCarousel = ({
  images,
}: ContentfulCarouselTypes): JSX.Element => {
  const imagesWithoutNull = images.filter(({ image }) => !!image);
  return <Carousel images={imagesWithoutNull || []} />;
};

export default ContentfulCarousel;

export const contentfulCarouselQuery = graphql`
  fragment contentfulCarouselFields on ContentfulCarousel {
    id
    internal {
      type
    }
    images {
      id
      image: gatsbyImageData(width: 880, aspectRatio: 1.78, breakpoints: [880])
      description
      title
    }
  }
`;
