import React from 'react';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';
import CardStyled from '../card/Card.styled';
import CardFooterLink from '../card/CardFooterLink';
import ArrowLink from '../link/ArrowLink';
import { Typography } from '../typography/Typography.styled';
import Styled from './HorizontalCard.styled';

import { SearchResultHorizontalCardContentProps } from './types';

const SearchResultHorizontalCardContent = ({
  title,
  resultType,
  description,
  callToAction,
  cardLinkProps = {},
  links = [],
}: SearchResultHorizontalCardContentProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);
  return (
    <Styled.CardContent>
      <CardStyled.CardLink {...cardLinkProps}>
        <Typography.HeadingSmall>{title}</Typography.HeadingSmall>
        {resultType && <Typography.SubLink>{resultType}</Typography.SubLink>}
        {description && (
          <Typography.BodySmall
            as="div"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </CardStyled.CardLink>
      {linkProps && <ArrowLink {...linkProps} />}
      {links.map(CardFooterLink)}
    </Styled.CardContent>
  );
};
export default SearchResultHorizontalCardContent;
