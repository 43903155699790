import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import useContentfulGatsbyImage from '../../hooks/useContentfulGatsbyImage';
import { Theme } from '../../styles/themes';
import { ContentfulGatsbyImage } from '../../types/images';
import { Typography } from '../common/typography/Typography.styled';
import { useHeaderStore } from './headerStore';
import Styled from './PageHeader.styled';

export type SimpleHeaderProps = {
  simpleLayout?: boolean;
};

type PageHeaderProps = {
  title?: string;
  subtitle?: string;
  imageCredits?: string;
  image?: ContentfulGatsbyImage;
} & SimpleHeaderProps;

const MainHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  image,
  imageCredits,
  simpleLayout = false,
  children,
}) => {
  const imageProps = useContentfulGatsbyImage(image);
  const { renderProp } = useHeaderStore();

  return (
    <Styled.Header
      forwardedAs="header"
      theme={Theme.PrimaryInverse}
      simpleLayout={simpleLayout}
    >
      {imageProps && (
        <Styled.HeroImage>
          <GatsbyImage {...imageProps} loading="eager" />
        </Styled.HeroImage>
      )}
      {!simpleLayout && (
        <Styled.ContentContainer>
          <Styled.TextContainer>
            {subtitle && (
              <Typography.SubHeadingSmall>
                {subtitle}
              </Typography.SubHeadingSmall>
            )}
            {title && <Typography.Display>{title}</Typography.Display>}
          </Styled.TextContainer>

          {(renderProp || children) && (
            <Styled.ActionsContainer>
              {renderProp || children}
            </Styled.ActionsContainer>
          )}
        </Styled.ContentContainer>
      )}
      {imageCredits && (
        <Styled.Footer>
          <Typography.SubLinkBold as="small">
            {imageCredits}
          </Typography.SubLinkBold>
        </Styled.Footer>
      )}
    </Styled.Header>
  );
};

export default MainHeader;

export const contentfulHeroImage = graphql`
  fragment contentfulHeroImage on ContentfulAsset {
    image: gatsbyImageData(
      layout: FULL_WIDTH
      aspectRatio: 2.0
      breakpoints: [576, 768, 992, 1440, 1800]
      placeholder: BLURRED
      quality: 90
      backgroundColor: "transparent"
    )
    title
    description
  }
`;
