import React from 'react';
import { Typography } from '../typography/Typography.styled';
import Styled from './InfoTable.styled';

type InfoTableItem = {
  key: string;
  value: string;
};

type InfoTableProps = {
  items: InfoTableItem[];
};

const renderItem = ({ key, value }: InfoTableItem, index: number) => (
  <Styled.InfoTableRow key={index}>
    <Typography.SubHeadingSmall>{key}</Typography.SubHeadingSmall>
    <Typography.BodyLarge>{value}</Typography.BodyLarge>
  </Styled.InfoTableRow>
);

const InfoTable = ({ items = [] }: InfoTableProps): JSX.Element | null => {
  if (items.length === 0) return null;

  return <Styled.InfoTable>{items.map(renderItem)}</Styled.InfoTable>;
};

export default InfoTable;
