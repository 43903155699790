import styled from 'styled-components';
import { below, Breakpoint } from '../../styles/breakpoints';
import { AppColor } from '../../styles/colors';
import { RoundButton } from '../common/button/Button';
import StyledButton from '../common/button/Button.styled';
import Icon from '../common/icon/Icon';
import Input from '../common/input/Input';
import ThemeContainer from '../common/ThemeContainer';
import { Typography } from '../common/typography/Typography.styled';

const SearchBar = styled(ThemeContainer)`
  display: flex;
  background: var(--search-bar-bg);
  padding: 1rem 1rem 1rem 3.5rem;
  border-radius: 3rem;
  height: 6rem;
  align-items: center;
  width: 100%;
  max-width: 580px;
  gap: 1.5rem;
  ${below(Breakpoint.sm)} {
    flex-direction: column;
    height: auto;
    padding: 1rem;
  }
`;

const StyledInput = styled(Input)`
  flex: 0.4;
`;

const SearchButton = styled.button`
  display: flex;
  justify-content: space-between;
  background: rgb(255 255 255 / 0.9);
  padding: 1.25rem 1rem;
  border-radius: 3rem;
  align-items: center;
  justify-content: center;
  width: 100%;

  gap: 0.75rem;

  ${Icon} {
    color: ${AppColor.LeviBlue};
  }

  color: ${AppColor.Neutral600};
`;

const SubmitButton = styled(RoundButton)`
  margin-left: auto;
  cursor: pointer;
`;

const SiteSearchBar = styled(SearchBar)`
  padding: 1rem 3.5rem;
  max-width: unset;
  gap: 0.75rem;
  color: ${AppColor.Neutral700};

  ${Typography.Display} + & {
    margin-top: 1.5rem;
  }

  ${StyledButton} {
    padding: 0.75rem 2rem;
    line-height: 1;
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  flex: 1;

  > div:first-child,
  > div:last-child {
    flex: 1;
  }

  ${below(Breakpoint.sm)} {
    max-width: 100%;
    width: 80vw;

    > div:first-child,
    > div:last-child {
      padding: 1rem 0;
    }
  }
`;

const VerticalDivider = styled.div`
  height: 2.5rem;
  width: 1px;
  margin: 0 2rem 0 0rem;
  border-left: 1px solid #ddd;
  ${below(Breakpoint.sm)} {
    margin: 0px 3rem 0 2rem;
  }
`;

export default {
  SearchBar,
  SearchButton,
  SubmitButton,
  SiteSearchBar,
  Input: StyledInput,
  FiltersContainer,
  VerticalDivider,
};
