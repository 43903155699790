import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from './types';
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../containers/contentfulSectionContainer';
import RestaurantCategoriesSection, {
  RestaurantCategoriesRestaurantTypes,
} from '../sections/restaurantCategoriesSection';

type EatAndDrinkPageProps = PageProps & {
  data: {
    searchPageContext: {
      edges: {
        node: {
          path: string;
        };
      }[];
    };
    restaurants: { edges: { node: RestaurantCategoriesRestaurantTypes }[] };
  };
};

const ContentfulEatAndDrinkPage: React.FC<EatAndDrinkPageProps> = ({
  data,
}) => {
  const { sections } = data.contentfulPage;

  return (
    <main>
      <RestaurantCategoriesSection
        searchPagePath={data.searchPageContext.edges[0].node.path}
        restaurants={data.restaurants}
      />
      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}
    </main>
  );
};

export default ContentfulEatAndDrinkPage;

export const contentfulPageQuery = graphql`
  query eatAndDrinkPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
    }
    searchPageContext: allSitePage(
      filter: {
        context: {
          type: { eq: "eat-and-drink-search" }
          locale: { eq: $locale }
        }
      }
    ) {
      edges {
        node {
          path
        }
      }
    }
    # Fetch all restaurants for restaurantCategoriesSection
    restaurants: allContentfulRestaurant(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          ...contentfulRestaurantCardFields
        }
      }
    }
  }
  fragment contentfulRestaurantCardFields on ContentfulRestaurant {
    ...contentfulBaseRestaurantFields
    images {
      ...contentfulCardImage
    }
    internal {
      type
    }
  }
`;
