import React from 'react';
import { Helmet } from 'react-helmet';
import useLocale from '../../../localization/useLocale';
import Grid from '../../common/layout/Grid';
import { GridElement } from '../../common/layout/Grid.styled';
import Section from '../../section/Section';

const WeatherWidgetSection = () => {
  // stupid hack to make the widget reload
  const locale = useLocale();
  return (
    <>
      <Section>
        <Grid>
          <GridElement full>
            <a
              className="weatherwidget-io"
              href="https://forecast7.com/en/67d8124d80/sirkka/"
              data-label_1="Levi"
              data-label_2="Finland"
              data-font="Open Sans"
              data-theme="pure"
              data-accent=""
              data-textcolor="#4B4D50"
              data-highcolor="#FE5065"
              data-lowcolor="#0092C8"
              data-suncolor="#F87328"
              data-mooncolor="#9E9FA1"
              data-cloudcolor="#9E9FA1"
              data-raincolor="#0085BF"
              data-snowcolor="#0092C8"
            >
              Levi Finland
            </a>
          </GridElement>
        </Grid>
      </Section>
      <Helmet>
        <script
          src="https://weatherwidget.io/js/widget.min.js"
          data-locale={locale}
        />
      </Helmet>
    </>
  );
};

export default WeatherWidgetSection;
