import React from 'react';
import { Link } from "@Levi/components/common/link";
import { Typography } from '../common/typography/Typography.styled';
import { NavLinkProps } from '../navigation/main/MainNavigationPropTypes';

export type FooterNavigationProps = {
  links: NavLinkProps[];
};

const renderNavigationLink = ({ to, label }: NavLinkProps) => (
  <li key={label}>
    <Typography.BodyIngres as={Link} to={to}>
      {label}
    </Typography.BodyIngres>
  </li>
);

const FooterNavigation = ({ links }: FooterNavigationProps): JSX.Element => (
  <nav>
    <ul>{links?.map(renderNavigationLink)}</ul>
  </nav>
);

export default FooterNavigation;
