import React from 'react';

// A wrapper to implement two-pass rendering
// https://github.com/gatsbyjs/gatsby/discussions/17914
const withTwoPassRendering = (WrappedComponent: React.FC<any>) => ({
  ...rest
}): JSX.Element => {
  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return <></>;
  }

  return <WrappedComponent {...rest} />;
};

export default withTwoPassRendering;
