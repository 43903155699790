import React from 'react';
import styled from 'styled-components';

import Styled from './MainNavigation.styled';
import { MainNavigationProps } from './MainNavigationPropTypes';
import { Theme } from '../../../styles/themes';
import useBreakpoints from '../../../hooks/useBreakpoints';
import DesktopNavigation from './desktop/DesktopNavigation';

import placeholder from '@static/images/placeholder/levi-logo.png';
import MobileNavigation from './mobile/MobileNavigation';
import { Link } from "@Levi/components/common/link";
import Weather from '../weather/Weather';
import { localize } from '../../../localization/i18n';

const Logo = styled.figure`
  background-image: url(${placeholder});
  background-size: 100%;

  width: var(--nav-logo-size, 4rem);
  height: var(--nav-logo-size, 4rem);
`;

const MainNavigation: React.FC<MainNavigationProps> = ({
  homePageLink,
  localeLink,
  weatherCamerasLink,
  pageLinks = [],
  theme = Theme.PrimaryInverse,
  hideLogo,
  hideWeather,
  hideLocaleLink,
  hideSearch,
}) => {
  const { isMobile, isDesktop, hasPointer } = useBreakpoints();
  return (
    <Styled.NavContainer
      forwardedAs="nav"
      theme={theme}
      hideLogo={hideLogo}
      hideLocaleLink={hideLocaleLink}
    >
      <Styled.NavContentContainer>
        {!hideLogo && (
          <Link to={homePageLink.to} aria-label={localize('common.homePage')}>
            <Logo />
          </Link>
        )}
        {isDesktop && (
          <DesktopNavigation
            pageLinks={pageLinks}
            weatherCamerasLink={weatherCamerasLink}
            localeLink={localeLink}
            hideLocaleLink={hideLocaleLink}
            hideSearch={hideSearch}
            hideWeather={hideWeather}
          />
        )}
        {isMobile && (
          <>
            {!hideWeather && (
              <Styled.NavLinksUl>
                <li>
                  <Weather simple weatherCamerasLink={weatherCamerasLink} />
                </li>
              </Styled.NavLinksUl>
            )}
            <MobileNavigation
              homePageLink={homePageLink}
              localeLink={localeLink}
              pageLinks={pageLinks}
              hideLocaleLink={hideLocaleLink}
              hideSearch={hideSearch}
            />
          </>
        )}
      </Styled.NavContentContainer>
    </Styled.NavContainer>
  );
};

export default MainNavigation;
