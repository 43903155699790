import React from 'react';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Icon, { IconSize } from '../../../components/common/icon/Icon';
import { Icons } from '../../../types/icon-types';
import Marker from '../../../components/map/Marker';
import { Geolocation } from '../card/types';
import Styled from './MapContainer.styled';

const MapContainer = ({
  title,
  address,
  location,
}: {
  title?: string;
  address?: string;
  location: Geolocation;
}): JSX.Element => {
  return (
    <Styled.MapContainer>
      {title && (
        <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
      )}
      {address && <Typography.BodyLarge>{address}</Typography.BodyLarge>}
      <Styled.Map
        settings={{
          defaultZoom: 14,
          defaultCenter: {
            ...location,
            lng: location.lon,
          },
        }}
      >
        <Marker lat={location.lat} lng={location.lon}>
          <Icon type={Icons.Location} size={IconSize.Size32} />
        </Marker>
      </Styled.Map>
    </Styled.MapContainer>
  );
};

export default MapContainer;
