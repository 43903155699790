import { ApiPath } from '..';
import { ApiDataParser } from './ApiDataParser';
import { parseOpeningHours } from './utils';

export const serviceDataParser: ApiDataParser<ApiPath.Service> = ({ open }) => {
  const openingHours =
    open?.openingHours && parseOpeningHours(open.openingHours);

  return { open: { ...open, openingHours } };
};
