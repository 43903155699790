import React from 'react';
import { Icons } from '../../../types/icon-types';
import CardTag from './CardTag';
import CardLocationMark from './CardLocationMark';
import { CardLocationInfoProps } from './types';
import useBreakpoints from '../../../hooks/useBreakpoints';

const CardLocationInfo = ({
  address,
  markerIndex,
}: CardLocationInfoProps): JSX.Element | null => {
  const { isDesktop } = useBreakpoints();
  return address ? (
    <>
      {markerIndex && isDesktop ? (
        <CardLocationMark markerIndex={markerIndex}>{address}</CardLocationMark>
      ) : (
        <CardTag icon={Icons.Location}>{address}</CardTag>
      )}
    </>
  ) : null;
};

export default CardLocationInfo;
