import styled from 'styled-components';
import Section from '../../components/section/Section';
import { breakpoints } from '../../styles/breakpoints';

const ContentContainer = styled(Section)`
  padding: 0 0 5rem;
`;

type MainSectionProps = {
  centered?: boolean;
};

const MainSection = styled.div<MainSectionProps>`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 3rem;
  grid-auto-flow: column;
  grid-area: content;

  ${(props) =>
    props.centered &&
    `
    grid-column-start: 1;
    grid-column-end: -1;
    ${breakpoints.md} {
      grid-column-start: 2;
      grid-column-end: -2;
    }
    ${breakpoints.lg} {
      grid-column-start: 4;
      grid-column-end: -4;
    }
    ${breakpoints.max} {
      grid-column-start: 4;
      grid-column-end: -4;
    }
  `}
`;

export default {
  ContentContainer,
  MainSection,
};
