import React from 'react';
import useFormattedDate from '../../../hooks/useFormattedDate';
import { isDateRange } from '../../../utils/date-utils';
import { Typography } from '../typography/Typography.styled';
import Styled from './HorizontalCard.styled';
import { HorizontalCardDateColumnProps } from './types';

const HorizontalCardDateColumn = ({
  date,
}: HorizontalCardDateColumnProps): JSX.Element => {
  const dateToUseForDisplay = isDateRange(date) ? date.start : date;
  const monthString = useFormattedDate({
    date: dateToUseForDisplay,
    options: { month: 'short' },
  });
  const dateString = dateToUseForDisplay.getDate();

  return (
    <Styled.CardDateColumn>
      <Typography.NumberBig>{dateString}</Typography.NumberBig>
      <Typography.DecorativeLabel>{monthString}</Typography.DecorativeLabel>
    </Styled.CardDateColumn>
  );
};

export default HorizontalCardDateColumn;
