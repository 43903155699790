/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from './types';

import Styled from './accommodationPage.styled';
import { localize } from '../../localization/i18n';
import { Helmet } from 'react-helmet';

const ACCOMMODATION_PICTURE = "Winres accommodation picture";
const ACCOMMODATION_OVERVIEW = "Winres accommodation overview";
const ACCOMMODATION_PROPERTIES = "Winres accommodation properties";
const ACCOMMODATION_LOCATION = "Winres accommodation location";
const ACCOMMODATION_REVIEWS = "Winres accommodation reviews";
const ACCOMMODATION_RESERVATION = "Winres accommodation reservation";
const ACCOMMODATION_SHOPPING_CART = "Winres accommodation shopping cart";
const ACCOMMODATION_SHOPPING_MODAL = "Winres shopping cart modal";
const ACCOMMODATION_LISTING = "Winres accommodation listing";
const RESERVATION_CONFIRMATION = "Winres reservation confirmation";
const COMPONENT_SCRIPT = "Winres component script";
const RESERVATION_CTX_PARAMS = "Winres reservation context parameters";

// type AccommodationProps = PageProps & {
// 
// };

const Accommodation: React.FC<PageProps> = (props) => {
  const sections = props.data.contentfulPage.sections;

  // Ensure that all needed sections are present in contentful
  const neededSectionTitles = [
    ACCOMMODATION_PICTURE,
    ACCOMMODATION_OVERVIEW,
    ACCOMMODATION_PROPERTIES,
    ACCOMMODATION_LOCATION,
    ACCOMMODATION_REVIEWS,
    ACCOMMODATION_RESERVATION,
    ACCOMMODATION_SHOPPING_CART,
    ACCOMMODATION_LISTING,
    RESERVATION_CONFIRMATION,
    COMPONENT_SCRIPT,
    RESERVATION_CTX_PARAMS,
  ];
  const sectionTitles = sections.map(section => section.title);
  if (neededSectionTitles.some(s => !sectionTitles.includes(s))) {
    const missing = neededSectionTitles.filter(s => !sectionTitles.includes(s));
    throw new Error(`Missing section(s) ${missing.join(", ")} in Contentful`);
  }

  const transformedSections = sections
    .map(section => [<div dangerouslySetInnerHTML={{ __html: section.embedDirect.content }} />, section.title]);

  const AccommodationPicture = transformedSections.find(tup => tup[1] === ACCOMMODATION_PICTURE)?.[0];
  const AccommodationReservation = transformedSections.find(tup => tup[1] === ACCOMMODATION_RESERVATION)?.[0];
  const AccommodationShoppingCart = transformedSections.find(tup => tup[1] === ACCOMMODATION_SHOPPING_CART)?.[0];
  const ShoppingCartModal = transformedSections.find(tup => tup[1] === ACCOMMODATION_SHOPPING_MODAL)?.[0];
  const AccommodationListing = transformedSections.find(tup => tup[1] === ACCOMMODATION_LISTING)?.[0];
  const AccommodationOverview = transformedSections.find(tup => tup[1] === ACCOMMODATION_OVERVIEW)?.[0];
  const AccommodationProperties = transformedSections.find(tup => tup[1] === ACCOMMODATION_PROPERTIES)?.[0];
  const AccommodationLocation = transformedSections.find(tup => tup[1] === ACCOMMODATION_LOCATION)?.[0];
  const AccommodationReviews = transformedSections.find(tup => tup[1] === ACCOMMODATION_REVIEWS)?.[0];
  const ReservationConfirmation = transformedSections.find(tup => tup[1] === RESERVATION_CONFIRMATION)?.[0];
  const ComponentScript = sections.find(section => section.title === COMPONENT_SCRIPT)!;
  // const ReservationCtxParams = sections.find(section => section.title === RESERVATION_CTX_PARAMS)!;

  // const paramsCtxObj = ReservationCtxParams.embedDirect.content
  //   .split("\n")
  //   .map(line => line.split("="))
  //     // Sanity check that every line is in "key=value" format with no additional or missing equal signs
  //   .map(line => { if (line.length !== 2) { console.log(line); throw new Error("Invalid parameters for reservation context"); } return line; })
  //     // Transform the [key, value][] array into an object
  //   .reduce((acc, line) => { acc[line[0]] = line[1]; return acc; }, ({} as Record<string, string>));

  const [activeTab, setActiveTab] = React.useState(0);

  const activeTabSections = [AccommodationOverview, AccommodationProperties, AccommodationLocation, AccommodationReviews];
  const activeTabContent = activeTabSections[activeTab];

  return (
    <>
      <Helmet>
        <script src={ComponentScript.embedDirect.content} />
      </Helmet>

      {/* <winres-reservation-context {...paramsCtxObj} > */}
        {ReservationConfirmation}

        {AccommodationPicture}

        <Styled.TabsContainer>
          <Styled.TabsWrapper>
            <Styled.TabsWrapper>
              <Styled.TabSelector active={activeTab === 0} onClick={() => setActiveTab(0)} >{localize("accommodations.tabTitles.overview")}</Styled.TabSelector>
              <Styled.TabSelector active={activeTab === 1} onClick={() => setActiveTab(1)} >{localize("accommodations.tabTitles.properties")}</Styled.TabSelector>
            </Styled.TabsWrapper>
            <Styled.TabsWrapper>
              <Styled.TabSelector active={activeTab === 2} onClick={() => setActiveTab(2)} >{localize("accommodations.tabTitles.location")}</Styled.TabSelector>
              <Styled.TabSelector active={activeTab === 3} onClick={() => setActiveTab(3)} >{localize("accommodations.tabTitles.reviews")}</Styled.TabSelector>
            </Styled.TabsWrapper>
          </Styled.TabsWrapper>
        </Styled.TabsContainer>

        <Styled.TabContent >
          {activeTabContent}
        </Styled.TabContent>

        <Styled.StickyAreaContainer>
          <Styled.Row>
            {AccommodationReservation}
            <Styled.ShoppingCartContainer>
              {AccommodationShoppingCart}
            </Styled.ShoppingCartContainer>
          </Styled.Row>
        </Styled.StickyAreaContainer>

        {AccommodationListing}

        {ShoppingCartModal}

      {/* </winres-reservation-context> */}
    </>
  );
};

export default Accommodation;

export const accommodationPageQuery = graphql`
  query AccommodationPageContent {
    contentfulPage(type: { eq: "single-accommodation-page" }) {
      name
      slug
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
      analyticsTags
    }
  }
`;
