import styled from 'styled-components';
import { below, Breakpoint } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover, touchActive } from '../../../styles/utils';
import { Typography } from '../typography/Typography.styled';

export type DropdownVariants = 'primary' | 'secondary';
type VariantStyleIndex = {
  [key in DropdownVariants]: string;
};

type DropdownProps = {
  isOpen?: boolean;
  variant: DropdownVariants;
};

const DropdownOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  background: white;
  background-color: white;

  max-height: 9rem;
  overflow-y: auto;
  transform: translate(var(--transform-x, 0), 0);

  position: absolute;
  z-index: 1;
  border-radius: 0.5rem;
  box-shadow: rgb(45 81 123 / 25%) 0.625rem 0.625rem 3.125rem 0;
`;

const Dropdown = styled.div<DropdownProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    display: none;
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    ${DropdownOptions} {
      display: none;
    }
  `}

  ${(props) => variantStyles[props.variant]};
`;

type DropdownOptionProps = {
  selected?: boolean;
};

const DropdownOption = styled(Typography.LabelSmall)<DropdownOptionProps>`
  white-space: nowrap;
  line-height: 1;
  color: ${({ selected }) =>
    !selected ? AppColor.Neutral700 : AppColor.LeviBlue};

  ${hover} {
    cursor: pointer;
  }
`;

const DropdownOptionLabel = styled(DropdownOption).attrs({ as: 'label' })`
  color: ${AppColor.LeviBlue};
  display: flex;
  align-items: center;
  gap: 0.25rem;

  input[type='checkbox'] {
    appearance: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 3px;
      border: 1px solid ${AppColor.LeviBlue};
      color: white !important;
    }

    &:checked:before {
      background: ${AppColor.LeviBlue};
    }

    &:checked:after {
      content: '';
      display: block;
      width: 0.325rem;
      height: 0.675rem;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform-origin: 50% 50%;
      transform: rotate(45deg);
      position: absolute;
      margin-top: -2px;
    }
  }
`;

const DropdownButton = styled.button`
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  > ${Typography.LabelSmall} {
    height: 1rem;
    line-height: 1;
  }

  ${hover} {
    cursor: pointer;
  }

  ${transitions.ButtonHover}
`;

const LabeledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  grid-column-start: 1;
  grid-column-end: -1;

  color: ${AppColor.Neutral600};

  ${below(Breakpoint.md)} {
    > ${Typography.SubLink} {
      display: none;
    }
  }
`;

const variantStyles: VariantStyleIndex = {
  primary: `
    ${DropdownOptions} {
      top: 2.5rem;
      min-width: 100%;
      margin: -1px;
    }
    ${DropdownButton} {
      padding: calc(0.5rem - 1px) 1rem calc(0.5rem - 1px) 1.5rem;
      
      background-color: ${AppColor.White};
      border: 1px solid ${AppColor.LeviBlue};
      border-radius: 100px;
      color: ${AppColor.LeviBlue};

      ${hover} {
        &:hover {
          color: ${AppColor.White};
          background-color: ${AppColor.LeviBlueHover};
        }
      }
      ${touchActive} {
        &:active {
          color: ${AppColor.White};
          background-color: ${AppColor.LeviBlueHover};
        }
      }
    } 
    &.hasValue {
      ${DropdownButton} {
        color: ${AppColor.White};
        background-color: ${AppColor.LeviBlue};
      }
    }
  `,
  secondary: `
    ${DropdownOptions} {
      top: 2rem;
    }
    ${DropdownButton} {
      background-color: transparent;
      color: ${AppColor.Neutral700};
    }
  `,
};

export default {
  DropdownButton,
  Dropdown,
  DropdownOptions,
  DropdownOption,
  DropdownOptionLabel,
  LabeledContainer,
};
