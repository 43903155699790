import React from 'react';
import { Typography } from '../typography/Typography.styled';
import Styled from './Table.styled';

type TableData = TableRowData[];
type TableRowData = TableColData[];
type TableColData = string;

type TableHeadingData = { content: string; span?: number };

interface TableProps {
  data: TableData;
  ingress?: string;
}

const TableHeader = (data: TableHeadingData) => (
  <Styled.TableHeader key={data.content} colSpan={data.span}>
    {data.content}
  </Styled.TableHeader>
);

const TableCol = (data: TableColData, rowIndex: number) => (
  <Styled.TableCol key={data + rowIndex}>{data}</Styled.TableCol>
);

const dataToHeading = (data: TableRowData): TableHeadingData[] => {
  return data
    .map((i, index) => {
      const sliced = data.slice(index + 1);
      let span = 0;
      if (!!i) {
        span += 1;
        for (let idx = 0, l = sliced.length; idx < l; idx++) {
          if (!!sliced[idx]) {
            break;
          }
          span++;
        }
      }
      return {
        content: i,
        span,
      };
    })
    .filter((i) => !!i.content);
};

const TableRow = (data: TableRowData, rowIndex: number) => (
  <Styled.TableRow key={rowIndex}>
    {rowIndex > 0 ? data.map(TableCol) : dataToHeading(data).map(TableHeader)}
  </Styled.TableRow>
);

const Table = ({ data, ingress }: TableProps) => (
  <>
    <Styled.TableContainer>
      {ingress && (
        <Typography.BodyLarge as="div">{ingress}</Typography.BodyLarge>
      )}
      <Styled.Table>
        <tbody>{data.map(TableRow)}</tbody>
      </Styled.Table>
    </Styled.TableContainer>
  </>
);

export default Table;
