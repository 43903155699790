import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SiteNotification from '../components/site-notification/SiteNotification';
import useLocale from '../localization/useLocale';
import { ContentfulCallToActionTypes } from './callToActions/types';
import useHtmlAst from '../hooks/useHtmlAst';
import { ContentfulMarkdownTypes } from './templates/types';

type ContentfulInfoBannerTypes = {
  body?: ContentfulMarkdownTypes;
  callToAction?: ContentfulCallToActionTypes;
};

type SiteInfoBannerQueryTypes = {
  fi: {
    infoBanner?: ContentfulInfoBannerTypes;
  };
  en: {
    infoBanner?: ContentfulInfoBannerTypes;
  };
};

const query = graphql`
  query InfoBannerQuery {
    fi: contentfulSite(
      contentful_id: { eq: "1guLuNt5b3mO9jB13rrnfR" }
      node_locale: { eq: "fi" }
    ) {
      infoBanner {
        ...contentfulInfoBanner
      }
    }
    en: contentfulSite(
      contentful_id: { eq: "1guLuNt5b3mO9jB13rrnfR" }
      node_locale: { eq: "en" }
    ) {
      infoBanner {
        ...contentfulInfoBanner
      }
    }
  }

  fragment contentfulInfoBanner on ContentfulInfoBanner {
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
    callToAction {
      id
      title
      destination {
        ... on ContentfulPage {
          pageId: id
        }
        ... on ContentfulExternalUrl {
          externalUrl: url
        }
      }
    }
  }
`;

const InfoBanner = () => {
  const [hidden, setHidden] = useState<boolean>(true);
  const site: SiteInfoBannerQueryTypes = useStaticQuery(query);
  const locale = useLocale();

  useEffect(() => {
    if (!sessionStorage.getItem('infoBannerHidden')) {
      setHidden(false);
    }
  }, []);

  const { infoBanner } = site[locale];
  const bodyText = useHtmlAst(infoBanner?.body?.childMarkdownRemark?.htmlAst);
  if (hidden || !infoBanner || !bodyText) return null;
  return (
    <SiteNotification
      body={bodyText}
      callToAction={infoBanner.callToAction}
      onClose={() => {
        sessionStorage.setItem('infoBannerHidden', 'true');
        setHidden(true);
      }}
    />
  );
};

export default InfoBanner;
