import { Geolocation } from '../../../components/common/card/types';

export type BusScheduleDataType = {
  stops: BusStop[];
};

export interface BusStop {
  id: number;
  name: Record<string, string>;
  schedule: string[];
  pause: string;
  geolocation: Geolocation;
  route: BusRoute;
  showDepartures: boolean;
}

export enum BusRoute {
  Route1 = 'route1',
  Route2 = 'route2',
}
