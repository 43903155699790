import React from 'react';
import { graphql } from 'gatsby';
import SkiResortAmenities, {
  SkiResortAmenityFilterProps,
} from '../../components/skiresort-amenities/SkiResortAmenenities';
import { SkiResortAmenityType } from '../../hooks/useSkiResortAmenitiesData';
import SkiResortAmenitiesWithTabs from '../../components/skiresort-amenities/SkiResortAmenitiesWithTabs';

export type ContentfulSkiResortAmenitiesSectionTypes =
  SkiResortAmenityFilterProps & {
    id: string;
    internal: {
      type: string;
    };
    types: SkiResortAmenityType;
    withTabs: boolean;
  };

const ContentfulSkiResortAmenitiesSection = (
  props: ContentfulSkiResortAmenitiesSectionTypes,
): JSX.Element => {
  return props.withTabs ? (
    <SkiResortAmenitiesWithTabs type={props.types} {...props} />
  ) : (
    <SkiResortAmenities type={props.types} {...props} />
  );
};

export default ContentfulSkiResortAmenitiesSection;

export const contentfulSkiResortAmenitiesQuery = graphql`
  fragment contentfulSkiResortAmenitiesConfigFields on ContentfulSkiResortAmenitiesSection {
    id
    internal {
      type
    }
    openOnly
    groupBy
    types: type #type is reserved field with pageSections
    slopeLocation
    withTabs
  }
`;
