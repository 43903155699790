import React from "react"
import { navigate } from "gatsby";

export interface LinkProps {
  to          : string
  className?  : string;
}

/** Alternative to Gatsby Link tag that has prefetching (this one doesn't) */
export const Link: React.FC<React.PropsWithChildren<LinkProps>> = ({to, children, ...rest})=>{
  const doNavigate: React.MouseEventHandler<HTMLAnchorElement> = (e)=>{
    e.stopPropagation();
    e.preventDefault();

    navigate(to);
  }
  return (
    <a href={to} {...rest} onClick={doNavigate}>
      {children}
    </a>
  )
}