import React from 'react';
import Styled from './AccordionList.styled';

const StaticAccordionList = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <Styled.StaticAccordionList>{children}</Styled.StaticAccordionList>
);

export default StaticAccordionList;
