import React from 'react';
import styled from 'styled-components';
import Button, { ButtonLinkWrapper } from '../common/button/Button';
import { Typography } from '../common/typography/Typography.styled';
import ThemeContainer from '../common/ThemeContainer';
import { Theme } from '../../styles/themes';
import { AppColor } from '../../styles/colors';
import Icon from '../common/icon/Icon';
import { Icons } from '../../types/icon-types';
import useContentfulCallToAction from '../../hooks/useContentfulCallToAction';
import { ContentfulCallToActionTypes } from '../../contentful/callToActions/types';
import { below, Breakpoint, breakpoints } from '../../styles/breakpoints';

const Wrapper = styled(ThemeContainer).attrs({ theme: Theme.Alert })`
  background: ${AppColor.ErrorRed};
  display: flex;
  flex-direction: column;
  left: 0px;
  width: 100%;
  z-index: 999;

  color: ${AppColor.White};
`;

const Container = styled.div`
  max-width: var(--max-section-width);
  width: 100%;
  margin: 0 auto;
`;

const ContentContainer = styled.div`
  max-width: var(--max-content-width);
  margin: 0 auto;
  padding: 0.75rem 0;
  flex-direction: column;
  ${breakpoints.md} {
    flex-direction: row;
  }
  display: flex;
  gap: 1.5rem 3.5rem;

  ${Typography.BodyLarge} {
    flex: 1;
  }
`;

const CloseButton = styled.button`
  padding: 1rem 0.875rem;
  margin-right: -1rem;
  color: inherit;
  cursor: pointer;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: inherit;
  align-items: flex-start;

  ${below(Breakpoint.md)} {
    justify-content: space-between;
    gap: 1rem;
    &:before {
      content: '';
      display: block;
      width: 3rem;
    }

    ${CloseButton} {
      margin-right: unset;
    }
  }
`;

export type SiteNotificationProps = {
  body: string;
  callToAction?: ContentfulCallToActionTypes;
  onClose: () => void;
};

const SiteNotification = ({
  body,
  callToAction,
  onClose,
}: SiteNotificationProps) => {
  const linkProps = useContentfulCallToAction(callToAction);
  return (
    <Wrapper>
      <Container>
        <ContentContainer>
          <Typography.BodyLarge as="div">{body}</Typography.BodyLarge>
          <ActionsContainer>
            {linkProps && (
              <ButtonLinkWrapper {...linkProps}>
                <Button>{linkProps.label}</Button>
              </ButtonLinkWrapper>
            )}
            <CloseButton onClick={onClose}>
              <Icon type={Icons.Close} size={1.25} />
            </CloseButton>
          </ActionsContainer>
        </ContentContainer>
      </Container>
    </Wrapper>
  );
};

export default SiteNotification;
