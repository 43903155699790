import React, { FormEvent } from 'react';
import { Icons } from '../../types/icon-types';
import Icon from '../common/icon/Icon';
import MainNavigationLinkStyled from '../navigation/main/MainNavigationLink.styled';
import MainNavigationDropdownStyled from '../navigation/main/desktop/MainNavigationDropdown.styled';
import Styled from './SiteSearch.styled';
import { navigate } from 'gatsby';
import { useDropdownCalculations } from '../navigation/main/desktop/DesktopNavigation';
import ThemeContainer from '../common/ThemeContainer';
import { Theme } from '../../styles/themes';
import Input from '../common/input/Input';
import { localize } from '../../localization/i18n';
import { staticPageSlugToLocaleLink } from '../../hooks/useLocaleLink';
import useLocale from '../../localization/useLocale';
import { useDropdownContainerCalculations } from '../../hooks/useDropdownContainerCalculations';

type SearchFieldProps = {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  setValue: (value: string) => void;
  value: string;
};

const SearchField = ({ onSubmit, setValue, value }: SearchFieldProps) => (
  <Styled.SiteSearchForm onSubmit={onSubmit}>
    <Input
      type="text"
      placeholder={localize('search.form.inputPlaceholder')}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      leadingIcon={{
        type: Icons.SearchLarge,
        visible: !value,
      }}
      trailingIcon={{
        type: Icons.Close,
        visible: !!value,
        onClick: () => setValue(''),
      }}
    />
    <Styled.SiteSearchButton>
      {localize('search.form.buttonLabel')}
    </Styled.SiteSearchButton>
  </Styled.SiteSearchForm>
);

const SiteSearch = () => {
  const [query, setQuery] = React.useState<string>('');
  const ref = React.useRef<HTMLLIElement>(null);
  const refWrapper = React.useRef<HTMLDivElement>(null);
  const refContainer = React.useRef<HTMLDivElement>(null);
  const locale = useLocale();
  const localizedLink = staticPageSlugToLocaleLink(locale, 'search');

  useDropdownContainerCalculations({ refWrapper, refContainer });
  useDropdownCalculations({ ref });

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    navigate(`${localizedLink}?q=${query}`);
  };

  return (
    <MainNavigationLinkStyled.NavLinkLi ref={ref} hasSubPages>
      <Icon type={Icons.Search} />
      <MainNavigationDropdownStyled.DropdownWrapper ref={refWrapper}>
        <Styled.SiteSearch ref={refContainer}>
          <ThemeContainer theme={Theme.Primary}>
            <SearchField
              onSubmit={onSubmit}
              value={query}
              setValue={setQuery}
            />
          </ThemeContainer>
        </Styled.SiteSearch>
      </MainNavigationDropdownStyled.DropdownWrapper>
    </MainNavigationLinkStyled.NavLinkLi>
  );
};

export default SiteSearch;
