import React from 'react';
import { WeekDayProgrammeDataItem } from '../../hooks/useSortedWeekProgrammeData';
import { useTodaysProgramme } from '../../hooks/useTodaysProgramme';
import { localize } from '../../localization/i18n';
import useLocale from '../../localization/useLocale';
import { Typography } from '../common/typography/Typography.styled';
import Mount from '../transitions/Mount';
import Styled from './WeeklyProgrammeList.styled';

export type DayProgrammeProps = {
  data: WeekDayProgrammeDataItem[] | undefined;
  title: string;
};

const DayProgramme = ({ data, title }: DayProgrammeProps) => {
  const locale = useLocale();
  const programme = useTodaysProgramme(data);

  return (
    <Styled.WeeklyProgrammeListContainer>
      <Styled.ListTitle>
        {title}
        {!programme && <Styled.TitleSpinner $margin={!!title} />}
      </Styled.ListTitle>

      <Mount show={!!programme}>
        {programme &&
          (programme[locale].length === 0 ? (
            <Typography.BodySmall>
              {localize('weeklyProgramme.noProgramToday')}
            </Typography.BodySmall>
          ) : (
            <Styled.DayContent
              as="div"
              className="contentfulMarkdown"
              dangerouslySetInnerHTML={{
                __html: programme[locale],
              }}
            />
          ))}
      </Mount>
    </Styled.WeeklyProgrammeListContainer>
  );
};

export default DayProgramme;
