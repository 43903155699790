import styled from 'styled-components';
import { breakpoints } from '../../styles/breakpoints';
import { AppColor } from '../../styles/colors';
import { GridElement } from '../common/layout/Grid.styled';
import Spinner from '../common/loader/Spinner';
import { Typography } from '../common/typography/Typography.styled';

const ListTitle = styled(Typography.HeadingSmall)``;

const WeeklyProgrammeListContainer = styled(GridElement)`
  grid-column-start: 1;
  grid-column-end: -1;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  ${breakpoints.md} {
    grid-column: span 6 / auto;
  }
`;

type TitleSpinnerProps = {
  $margin?: boolean;
};
const TitleSpinner = styled(Spinner)<TitleSpinnerProps>`
  ${(props) =>
    props.$margin
      ? `
      margin-left: 1rem;
  `
      : ``}
`;

const WeekSelectContainer = styled.div`
  max-width: 240px;
`;

const DayTitle = styled(Typography.SubHeadingLarge)``;
const DayContent = styled(Typography.BodySmall).attrs({ as: 'div' })``;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const ListItem = styled.div`
  ${DayTitle} + ${DayContent} {
    margin-top: 1rem;
  }

  & + & {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      transform: translateY(-2rem);
      background: ${AppColor.Neutral300};
    }
  }
`;

export default {
  WeeklyProgrammeListContainer,
  WeekSelectContainer,
  ListTitle,
  DayTitle,
  DayContent,
  List,
  ListItem,
  TitleSpinner,
};
