import React from 'react';
import { CalendarState, Days, useCalendarStore } from './calendarStore';
import { difference } from 'lodash';

const comparison = (prev: Days, curr: Days) =>
  difference(
    prev.map(({ key }) => key),
    curr.map(({ key }) => key),
  ).length === 0;

const useCalendarDays = (offset = 0): Days => {
  const days = useCalendarStore(
    React.useCallback(
      (state: CalendarState) => {
        return state.offsetDays[offset].map(
          (key) => state.days.filter((day) => day.key === key)[0],
        );
      },
      [offset],
    ),
    comparison,
  );

  return days;
};

export default useCalendarDays;
