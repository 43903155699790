import React from 'react';
import { useSkiResortDataByApiPath } from '../../hooks/useSkiResortDataByApiPath';
import useLocale from '../../localization/useLocale';
import { ApiPath } from '../../network/skiresort-api';
import Grid from '../common/layout/Grid';
import { Typography } from '../common/typography/Typography.styled';
import VideoPlayer from '../common/video/VideoPlayer';
import VideoPlayerStyled from '../common/video/VideoPlayer.styled';
import Section from '../section/Section';

const SlopeCameras = () => {
  const slopeCameras = useSkiResortDataByApiPath(ApiPath.SlopeCameras);
  const locale = useLocale();
  if (!slopeCameras) {
    return null;
  }

  return (
    <>
      {slopeCameras.liveCameras?.map(({ name, videoId }) => {
        const id = videoId.slice(0, 11);
        return (
          <Section key={id}>
            <Grid>
              <VideoPlayer headline={name[locale]} youtubeId={id} />
            </Grid>
          </Section>
        )
      })}
      {slopeCameras.stillCameras?.map(({ name, stillUrl }) => (
        <Section key={stillUrl}>
          <Grid>
            <VideoPlayerStyled.PlayerContainer>
              <Typography.HeadingLarge>{name[locale]}</Typography.HeadingLarge>
              <img src={stillUrl} alt={name[locale]} />
            </VideoPlayerStyled.PlayerContainer>
          </Grid>
        </Section>
      ))}
      <Section>
        <Grid>
          <VideoPlayerStyled.PlayerContainer>
          <Typography.HeadingLarge>Tuikku web cam</Typography.HeadingLarge>
            <iframe 
              src="https://flycam.panomax.com/tuikku"
              name="Tuikku web cam"
              width="100%"
              height="25%"
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              frameBorder={0}
              // style={{float:"left", marginLeft:"0px"}}
              style={{width: "100%", height: "600px"}}
              allowFullScreen={true}
              // webkitallowfullscreen="true"
              // mozallowfullscreen="true"
            ></iframe>
          </VideoPlayerStyled.PlayerContainer>
        </Grid>
      </Section>
    </>
  );
};

export default SlopeCameras;
