import { LocaleType } from '../../contentful/templates/types';
import { SingleNewsLetterServiceResponse } from '../../hooks/useNewsletterSubmitForm';

const serviceName = 'leviSkiResort';
const serviceDisplayName = 'Levi Ski Resort';

const defaultHeader: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const postOptions: RequestInit = {
  method: 'PUT',
  headers: defaultHeader,
};

export type LeviMarketingPayLoad = {
  email: string;
  language: LocaleType;
  skiResort: boolean;
  visitLevi: boolean;
};

class LeviMarketingApi {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected constructor() {}

  protected static _API_URL: string;
  private static get API_URL() {
    return this._API_URL;
  }

  static async post(
    formData: LeviMarketingPayLoad,
  ): Promise<SingleNewsLetterServiceResponse> {
    if (this.API_URL) {
      try {
        const response = await fetch(this.API_URL, {
          ...postOptions,
          body: JSON.stringify(formData),
        });

        if (response.status !== 204) {
          return this.handleErrors(response);
        }

        return {
          serviceName,
          success: true,
        };
      } catch (err: unknown) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(new Error(`Undefined _API_URL in LeviMarketingAPI`));
  }

  static async handleErrors(response: Response) {
    if (response.status === 401) {
      return Promise.reject(
        new Error(`${serviceDisplayName}: ${response.statusText}`),
      );
    }

    const responseData = await response.json();
    return Promise.reject(
      new Error(`${serviceDisplayName}: ${responseData.message}`),
    );
  }
}

export default LeviMarketingApi;
