import React from 'react';
import Styled from './MapInfoBox.styled';

export type MapInfoBoxPosition = {
  offsetLeft: number;
  offsetBottom: number | string;
  offsetTop: number | string;
};

export type MapInfoBoxProps = {
  children: React.ReactNode;
  infoBoxPosition: MapInfoBoxPosition;
};

const MapInfoBox = React.forwardRef<HTMLDivElement, MapInfoBoxProps>(
  (props, ref) => {
    return (
      <Styled.MapInfoBox
        ref={ref}
        offsetLeft={props.infoBoxPosition.offsetLeft}
        offsetBottom={props.infoBoxPosition.offsetBottom}
        offsetTop={props.infoBoxPosition.offsetTop}
      >
        {props.children}
      </Styled.MapInfoBox>
    );
  },
);

export default MapInfoBox;
