import timingFunctions from 'polished/lib/mixins/timingFunctions';
export const enum TransitionDuration {
  Fast = `0.125s`,
  Moderate = `0.175s`,
  Default = `0.25s`,
  Slow = `0.3s`,
}

export const TransitionTimingFunction = {
  Default: timingFunctions('easeInOutQuad'),
};

const AccordionColorHover = `
  transition-property: color;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const ArrowLinkHover = `
  transition-property: transform;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const ButtonHover = `
  &:not([disabled]) {
    transition-property: color, background-color, border-color;
    transition-duration: ${TransitionDuration.Moderate};
    transition-timing-function: ${TransitionTimingFunction.Default};
  }
`;

const CardHeadingArrow = `
  transition-property: opacity, transform;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const CardShadowHover = `
  transition-property: opacity;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const CardImageHover = `
  transition-property: transform;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const NavLinkHover = `
  transition-property: background-size;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const NavLinkHoverDropdown = `
  transition-property: transform, opacity;
  transition-duration: ${TransitionDuration.Default};
  transition-timing-function: ${TransitionTimingFunction.Default};
`;

const NavigationSheet = `
  transition-property: opacity, transform;
  transition-duration: ${TransitionDuration.Slow};
  transition-timing-function: ${TransitionTimingFunction.Default};
  `;

const CarouselTrack = `
  transition-property: transform;
  transition-duration:  ${TransitionDuration.Slow};
  transition-timing-function: ${TransitionTimingFunction.Default};
  `;

const CarouselItem = `
  transition-property: opacity;
  transition-duration: inherit;
  transition-timing-function: inherit;
`;

const TooltipHover = `
  transition-property: opacity;
  transition-duration: ${TransitionDuration.Slow};
  transition-delay: ${TransitionDuration.Moderate};
  transition-timing-function: inherit;
`;

export default {
  AccordionColorHover,
  ArrowLinkHover,
  ButtonHover,
  CardHeadingArrow,
  CardImageHover,
  CardShadowHover,
  CarouselItem,
  CarouselTrack,
  NavigationSheet,
  NavLinkHover,
  NavLinkHoverDropdown,
  TooltipHover,
};
