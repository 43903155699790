import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import WeatherWidgetSection from '../../../components/navigation/weather/WeatherWidgetSection';

const WeatherPage: React.FC<PageProps> = ({}) => {
  return (
    <>
      <main>
        {/** Some custom hero here.
         * This img is just copy-paste from levi.ski/saa
         */}
        <img
          style={{
            position: 'relative',
            border: 0,
            marginBottom: '-6px',
            minWidth: '100%',
            width: '100%',
            height: 'auto',
            minHeight: 'auto',
          }}
          src="http://panoraama.com/levi/weather/naytto.jpg"
        />
        <WeatherWidgetSection />
      </main>
    </>
  );
};

export default WeatherPage;

export const contentfulPageQuery = graphql`
  query WeatherPageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      # Skip Hero, we use custom hero for this page
      # hero {
      #   ...contentfulHeroFields
      # }
      # we might skip sections as well
      # sections {
      #   ...allPageSections
      # }
      meta {
        ...contentfulPageMetaFields
      }
    }
  }
`;
