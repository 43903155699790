import React from 'react';
import {
  ApiPathParamTypes,
  API_PARAMS,
  API_PATH,
  GET,
} from '../network/skiresort-api';
import { ApiDataParser } from '../network/skiresort-api/parsers/ApiDataParser';
import parsers from '../network/skiresort-api/parsers';
import { isEqual } from 'lodash';

export const useSkiResortDataByApiPath = <T extends API_PATH>(
  path: T,
  params?: ApiPathParamTypes[T],
  callCondition = true,
) => {
  const [data, setData] = React.useState<ReturnType<ApiDataParser<T>>>();
  const prevParams = React.useRef<API_PARAMS>();

  React.useEffect(() => {
    if (!callCondition) return;
    const fetchData = () => {
      if (params && isEqual(params, prevParams.current)) {
        return;
      }

      prevParams.current = params ? { ...params } : undefined;

      try {
        const parser = parsers[path] as unknown as ApiDataParser<T>;
        const request: Promise<ReturnType<ApiDataParser<T>>> = new Promise(() =>
          GET(path, params).then(parser).then(setData),
        );
        return request;
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();
  }, [path, params, callCondition]);

  return data;
};
