import React from 'react';
import { Typography } from '../typography/Typography.styled';
import Styled from './SecondaryTabs.styled';

export type SecondaryTabProps = {
  children: string;
  selected?: boolean;
  onClick: (event: React.PointerEvent<HTMLElement>) => void;
};

const SecondaryTab = ({
  children,
  selected,
  onClick,
}: SecondaryTabProps): JSX.Element => {
  return (
    <Styled.SecondaryTab selected={selected} onPointerUp={onClick}>
      <Typography.NavigationLink>{children}</Typography.NavigationLink>
    </Styled.SecondaryTab>
  );
};

export default SecondaryTab;
