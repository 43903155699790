import { weatherDataParser } from './weatherDataParser';
import { todayDataParser } from './todayDataParser';
import { restaurantDataParser } from './restaurantDataParser';
import { restaurantsDataParser } from './restaurantsDataParser';
import { serviceDataParser } from './serviceDataParser';
import { servicesDataParser } from './servicesDataParser';
import { skiBusScheduleDataParser } from './skiBusScheduleDataParser';
import { ApiPath } from '..';
import { weatherForecastDataParser } from './weatherForecastDataParser';
import { slopeCamerasParser } from './slopeCamerasParser';
import { liftDataParser } from './liftDataParser';
import { slopeDataParser } from './slopeDataParser';
import { programmeDataParser } from './programmeDataParser';
import { programmesDataParser } from './programmesDataParser';
import { activityTrailDataParser } from './activityTrailDataParser';
import { otherActivitiesDataParser } from './otherActivitiesDataParser';

export default {
  [ApiPath.BikeTrails]: activityTrailDataParser,
  [ApiPath.LeviToday]: todayDataParser,
  [ApiPath.Lifts]: liftDataParser,
  [ApiPath.OtherActivities]: otherActivitiesDataParser,
  [ApiPath.Programme]: programmeDataParser,
  [ApiPath.Programmes]: programmesDataParser,
  [ApiPath.Restaurant]: restaurantDataParser,
  [ApiPath.Restaurants]: restaurantsDataParser,
  [ApiPath.Service]: serviceDataParser,
  [ApiPath.Services]: servicesDataParser,
  [ApiPath.SkiBusSchedule]: skiBusScheduleDataParser,
  [ApiPath.SlopeCameras]: slopeCamerasParser,
  [ApiPath.Slopes]: slopeDataParser,
  [ApiPath.Weather]: weatherDataParser,
  [ApiPath.WeatherForecast]: weatherForecastDataParser,
};
