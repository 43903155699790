import React from 'react';
import { Typography } from '../typography/Typography.styled';
import ArrowLink from '../link/ArrowLink';
import Styled from './AccordionList.styled';
import { AccordionListItemData } from './AccordionList';
import { IconSize } from '../icon/Icon';
import { Icons } from '../../../types/icon-types';

type AccordionListItemProps = {
  open?: boolean;
  index: number;
} & AccordionListItemData &
  React.ComponentPropsWithoutRef<'details'>;

const AccordionListItem = ({
  open = false,
  index,
  onToggle,
  icon,
  title,
  body,
  linkProps,
}: AccordionListItemProps): JSX.Element => {
  return (
    <Styled.Details
      onToggle={onToggle}
      open={open}
      disabled={open}
      data-index={index}
    >
      <Styled.Summary>
        {icon && <Styled.TypeIcon type={icon} size={IconSize.Size24} />}
        <Typography.HeadingDecoSmall>{title}</Typography.HeadingDecoSmall>
        <Styled.ToggleIcon
          type={Icons.ChevronRightBold}
          size={IconSize.Size16}
        />
      </Styled.Summary>
      <Styled.Content>
        <Typography.BodySmall>{body}</Typography.BodySmall>
        {linkProps && (
          <Styled.ContentFooter>
            <ArrowLink {...linkProps} />
          </Styled.ContentFooter>
        )}
      </Styled.Content>
    </Styled.Details>
  );
};

export default AccordionListItem;
