import React from 'react';
import * as queryString from 'query-string';

const isBrowser = typeof window !== `undefined`;

type HookProps = {
  location: Location;
};

const useUrlParams = ({ location }: HookProps) => {
  const urlParams = React.useMemo(
    () => (isBrowser ? queryString.parse(location.search) : {}),
    [location.search],
  );

  return urlParams;
};

export default useUrlParams;
