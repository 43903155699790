import React from 'react';
import { isEqual } from 'lodash';
import useUrlParams from './useUrlParams';
import { navigate } from 'gatsby-link';

type ValidUrlParam = string | string[];
type MaybeValidUrlParam = ValidUrlParam | null;

type UrlParamAwareState<T extends ValidUrlParam> = [T, (value: T) => void];

type UrlParamTransformer = <T extends ValidUrlParam>(
  paramValue: MaybeValidUrlParam | null,
  initialValue: T,
) => T;

const defaultTransformer: UrlParamTransformer = <T extends ValidUrlParam>(
  paramValue: MaybeValidUrlParam,
  initialValue: T,
): T => {
  if (!paramValue) {
    return initialValue;
  }

  if (typeof paramValue !== typeof initialValue) {
    if (initialValue instanceof Array) {
      return [paramValue] as T;
    }
    if (paramValue instanceof Array) {
      return paramValue[0] as T;
    }
  }
  return paramValue as T;
};

type UrlParamAwareStateProps = {
  location: Location;
  paramName: string;
  transformer?: UrlParamTransformer;
  initialValue: ValidUrlParam;
  nohistory?: boolean;
};

const useUrlParamAwareState = <T extends ValidUrlParam = string[]>({
  location,
  paramName,
  initialValue = [],
  transformer = defaultTransformer,
  nohistory = false,
}: UrlParamAwareStateProps): UrlParamAwareState<T> => {
  const params = useUrlParams({ location });
  const value = React.useMemo(
    () => transformer(params[paramName], initialValue),
    [params, paramName, transformer, initialValue],
  );

  const setValue = React.useCallback(
    (newValue: T) => {
      if (isEqual(value, newValue)) return;

      const paramsString = location.search;
      const searchParams = new URLSearchParams(paramsString);
      searchParams.delete(paramName);
      if (newValue instanceof Array) {
        newValue.forEach((val) => searchParams.append(paramName, val));
      } else {
        searchParams.append(paramName, newValue);
      }

      const queryString = searchParams.toString();
      const newParams = queryString.length > 0 ? `?${queryString}` : '';

      navigate(`${location.pathname}${newParams}`, {
        state: { disableScrollUpdate: true },
        replace: nohistory,
      });
    },
    [value, location.search, location.pathname, paramName, nohistory],
  );

  return [value as T, setValue];
};

export default useUrlParamAwareState;
