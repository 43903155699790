export const isDomAvailable = (): boolean => {
  return (
    typeof window !== 'undefined' &&
    !!window.document &&
    !!window.document.createElement
  );
};

/** Value of GATSBY_HOST_URL */
export const hostURL = process.env.GATSBY_HOST_URL!;

/** true if TARGET_ENV set as production */
export const isProduction = process.env.TARGET_ENV === 'production';

/** Return corresponding Moder url for accommodationId */
export const moderURL = (accommodationId: string, locale?: string) => {
  const moderID = accommodationId.replace('moder-', '');
  const url = new URL(moderID, process.env.GATSBY_MODER_BASE_URL!);

  locale && url.searchParams.set("lang", locale);

  return url.toString();
};