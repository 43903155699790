import React from 'react';
import { ThemeContainerProps } from '../common/ThemeContainer';
import Styled from './Section.styled';

export type SectionProps = {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  subPage?: boolean;
} & ThemeContainerProps;

const Section = ({
  children,
  className,
  ...rest
}: SectionProps): JSX.Element => (
  <Styled.Section className={className} {...rest}>
    {children}
  </Styled.Section>
);
export default Section;
