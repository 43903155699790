import React from 'react';
import { ContentfulAsset } from '../../graphql-types';
import { resolveImageUrl, StructuredMetaData } from '../contentful/meta';
import { AllRestaurantFields } from '../contentful/templates/servicePages/contentfulRestaurantPage';
import {
  ContentfulOpeningHoursTypes,
  OpeningHoursDayType,
} from '../contentful/templates/types';
import { ParsedOpeningHoursType } from '../network/skiresort-api/types/RestaurantDataTypes';

type InputType = Pick<AllRestaurantFields, 'contact' | 'openingHours'> & {
  hubDbOpeningHours?: ParsedOpeningHoursType | undefined;
  image: ContentfulAsset;
};

const useStructuredDataForRestaurant = ({
  contact,
  openingHours,
  hubDbOpeningHours,
  image,
}: InputType): StructuredMetaData => {
  const resolveOpeningHours = React.useCallback(
    (openingHours: ContentfulOpeningHoursTypes | ParsedOpeningHoursType) => {
      if (!openingHours || !openingHours.openingHours || openingHours.hidden) {
        return;
      }

      return Object.keys(openingHours.openingHours).map((day) => ({
        '@type': 'OpeningHoursSpecification',
        dayOfWeek: capitalizeStr(day),
        opens: resolveClosingHours(openingHours.openingHours[day], 'opens'),
        closes: resolveClosingHours(openingHours.openingHours[day], 'closes'),
      }));
    },
    [],
  );

  const resolveClosingHours = (
    openingHoursDay: OpeningHoursDayType,
    type: string,
  ) => {
    if (openingHoursDay.closed) {
      return '00:00';
    }
    if (
      type === 'closes' &&
      openingHoursDay.from === '00:00' &&
      openingHoursDay.to === '00:00'
    ) {
      return '23:59';
    }
    if (type === 'opens') {
      return openingHoursDay.from;
    }
    return openingHoursDay.to;
  };

  const capitalizeStr = (str: string) => {
    if (!str || str.length === 0) {
      return '';
    }
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const structuredData = React.useMemo(() => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Restaurant',
      image: image && resolveImageUrl(image),
      address: contact?.address,
      telephone: contact?.phone,
      email: contact?.email,
      openingHoursSpecification: resolveOpeningHours(
        hubDbOpeningHours ? hubDbOpeningHours : openingHours,
      ),
      url: contact?.website,
      name: contact?.title,
    };
  }, [image, contact, resolveOpeningHours, hubDbOpeningHours, openingHours]);

  return structuredData;
};

export default useStructuredDataForRestaurant;
