import styled from 'styled-components';
import { above, Breakpoint } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import { Typography } from '../typography/Typography.styled';

const InfoTable = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${above(Breakpoint.sm)} {
    grid-template-columns: repeat(2, 1fr);
  }
  grid-gap: 2.5rem var(--gutter);

  background: ${AppColor.Neutral200};
  padding: 2rem 1rem;

  ${Typography.SubHeadingSmall} {
    color: ${AppColor.LeviBlue};
    margin-bottom: 0.5rem;
  }

  * + & {
    margin-top: 5rem;
  }
`;

const InfoTableRow = styled.div`
  padding: 0 1rem;
`;

export default {
  InfoTable,
  InfoTableRow,
};
