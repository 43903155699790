import React from 'react';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import useStaticImage from './useStaticIcon';
import { ImageIcons } from '../../../types/image-icon-types';

interface IBgImageProps {
  image?: IGatsbyImageData;
  className?: string;
}

const ImageIcon = ({
  type,
  className,
}: {
  type: ImageIcons;
  className: string;
}) => {
  const image = useStaticImage(type);
  const pluginImage = getImage(image);

  const props: IBgImageProps = {
    image: pluginImage,
    className,
  };

  return <BgImage fadeIn={false} {...props} />;
};

export default ImageIcon;
