import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulOpeningHoursTypes, PageProps } from '../types';
import { ContentfulGatsbyImage } from '../../../types/images';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Styled from './ServiceListPage.styled';
import LabeledDropdown from '../../../components/common/dropdown/LabeledDropdown';
import { Company } from '../../footer/types';
import { SortOrder } from '../../../hooks/useSortedData';
import {
  containsString,
  matchesAny,
  notNullOrFalse,
} from '../../../hooks/useFilteredData';
import CardGridHeader from '../../../components/common/cardgrid/CardGridHeader';
import { MultiSelectDropdownProps } from '../../../components/common/dropdown/MultiSelectDropdown';
import useSortedFilteredData from '../../../hooks/useSortedFilteredData';
import { Geolocation } from '../../../components/common/card/types';
import useUrlParamAwareState from '../../../hooks/useUrlParamAwareState';
import useContentfulTransformedCardGridData from '../../../hooks/useContentfulTransformedCardGridData';
import { CardType } from '../../../components/common/card/Card';
import CardList from '../../../components/common/cardlist/CardList';
import { HorizontalCardType } from '../../../components/common/horizontal-card/HorizontalCard';
import SearchFilters from '../../../components/common/list-filters/SearchFilters';
import { localize } from '../../../localization/i18n';
import { serviceCategoriesToLocalizedList } from '../../data/serviceCategories';
import { HubDBIdType } from '../../../network/skiresort-api/types/types';
import { SearchPageGrid } from '../../../components/common/layout/Grid.styled';
import { isDomAvailable } from '../../../utils/env-utils';
import { filterOutDummyCards } from '../utils';

export interface BaseServiceFields {
  id: string;
  name: string;
  ingress?: never | string;
  image?: never | ContentfulGatsbyImage;
  hideFromLocalization?: boolean;
}

export interface ServiceFields extends BaseServiceFields {
  ingress: string;
  categories: string[];
  contact: Company;
  openingHours: ContentfulOpeningHoursTypes;
  images: ContentfulGatsbyImage[];
  location: Geolocation;
  internal: {
    type: string;
  };
  hubDbId?: HubDBIdType;
  leviBlackCard: boolean;
}

type SearchServicesPageProps = PageProps & {
  data: {
    services: {
      edges: Array<{ node: ServiceFields }>;
    };
  };
};

const ContentfulSearchServicesPage: React.FC<SearchServicesPageProps> = ({
  data: {
    services: { edges: cards },
  },
  location,
}) => {
  cards = filterOutDummyCards(cards);

  const data = useContentfulTransformedCardGridData({
    cards,
    type: CardType.Service,
  });
  const [searchTerm, setSearchTerm] = React.useState('');

  const [selectedCategories, setSelectedCategories] = useUrlParamAwareState({
    location,
    paramName: 'category',
    initialValue: [],
  });

  const { filteredData, setSortOrder } = useSortedFilteredData({
    data: isDomAvailable() ? data : [],
    sortBy: (item) => item?.title?.toLowerCase(),
    filters: [
      notNullOrFalse('title', ''),
      containsString('title', searchTerm),
      matchesAny('categories', selectedCategories),
    ],
  });

  const filters = React.useMemo<MultiSelectDropdownProps[]>(
    () => [
      {
        name: 'categoryFilter',
        label: localize('filters.listFilters.category'),
        items: serviceCategoriesToLocalizedList(),
        onChange: setSelectedCategories,
        value: selectedCategories,
        variant: 'primary',
      },
    ],
    [selectedCategories, setSelectedCategories],
  );

  return (
    <main>
      <Styled.ContentContainer>
        <SearchPageGrid noAside>
          <Styled.MainSection centered>
            <Typography.Display>
              {localize('services.headline')}
            </Typography.Display>

            <SearchFilters
              filters={filters}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />

            <CardGridHeader
              title={localize('searchResults.seachResultsTitle')}
              count={filteredData.length}
            >
              <LabeledDropdown
                variant="secondary"
                defaultValue="asc"
                name="sortBy"
                dropdownLabel={localize('searchResults.sort.label')}
                label=""
                onChange={(value) => setSortOrder(value as SortOrder)}
                items={[
                  {
                    value: 'asc',
                    label: localize('searchResults.sort.textAsc'),
                  },
                  {
                    value: 'desc',
                    label: localize('searchResults.sort.textDesc'),
                  },
                ]}
              />
            </CardGridHeader>
            <CardList cards={filteredData} type={HorizontalCardType.Service} />
          </Styled.MainSection>
        </SearchPageGrid>
      </Styled.ContentContainer>
    </main>
  );
};

export default ContentfulSearchServicesPage;

export const contentfulPageQuery = graphql`
  query SearchServicesPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      meta {
        ...contentfulPageMetaFields
      }
    }
    services: allContentfulEntry(
      filter: {
        internal: {
          type: {
            in: [
              "ContentfulService"
              "ContentfulRestaurant"
              "ContentfulRental"
            ]
          }
        }
        node_locale: { eq: $locale }
      }
    ) {
      edges {
        node {
          ... on ContentfulService {
            ...contentfulBaseServiceFields
            internal {
              type
            }
          }
          ... on ContentfulRental {
            ...contentfulBaseRentalFields
            internal {
              type
            }
          }
          ... on ContentfulRestaurant {
            ...contentfulBaseRestaurantFields
            internal {
              type
            }
          }
        }
      }
    }
  }
  fragment contentfulServiceCardFields on ContentfulService {
    ...contentfulBaseServiceFields
    images {
      ...contentfulCardImage
    }
    internal {
      type
    }
  }
`;
