import React from 'react';
import { graphql } from 'gatsby';
import VideoPlayer from '../../components/common/video/VideoPlayer';

export type ContentfulVideoSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  headline: string;
  vimeoUrl: string;
  youtubeUrl: string;
  // fields: {
  //   vimeoId?: number;
  //   youtubeId?: string;
  // };
};

const ContentfulVideoSection = ({
  headline,
  vimeoUrl, youtubeUrl
  // fields: { vimeoId, youtubeId },
}: ContentfulVideoSectionTypes): JSX.Element | null => {
  if (!vimeoUrl && !youtubeUrl) return null;
  return (
    <VideoPlayer headline={headline} vimeoUrl={vimeoUrl} youtubeUrl={youtubeUrl} />
  );
};

export default ContentfulVideoSection;

export const contentfulVideoSectionQuery = graphql`
  fragment contentfulVideoSectionFields on ContentfulVideoSection {
    id
    internal {
      type
    }
    headline: title
    vimeoUrl
    youtubeUrl
    # fields {
    #   vimeoId
    #   youtubeId
    # }
  }
`;
