import React from 'react';
import {
  CalendarInitialProps,
  CalendarProvider,
  createCalendarStore,
} from './calendarStore';
import CalendarListener, { CalendarListenerProps } from './CalendarListener';
import CalendarInstance from './CalendarInstance';
import CalendarPropsListener, {
  CalendarPropsListenerProps,
} from './CalendarPropsListener';
import useLocale from '../../../localization/useLocale';

export type CalendarProps = CalendarInitialProps &
  CalendarListenerProps &
  Pick<CalendarPropsListenerProps, 'selectedDateRange'>;

const Calendar = (props: CalendarProps): JSX.Element => {
  const {
    onSelectedDateChange,
    onSelectedDateRangeChange,
    onSelectedMonthChange,
    numberOfMonths = 1,
    selectedDateRange,
    ...rest
  } = props;

  const locale = useLocale();

  return (
    <CalendarProvider
      createStore={createCalendarStore({ ...rest, numberOfMonths })}
    >
      <CalendarPropsListener selectedDateRange={selectedDateRange} />
      <CalendarListener
        onSelectedDateChange={onSelectedDateChange}
        onSelectedDateRangeChange={onSelectedDateRangeChange}
        onSelectedMonthChange={onSelectedMonthChange}
      />
      {Array(numberOfMonths)
        .fill(0)
        .map((i, index) => (
          <CalendarInstance
            key={index}
            locale={locale}
            offset={index}
            totalInstances={numberOfMonths}
          />
        ))}
    </CalendarProvider>
  );
};

export default Calendar;
