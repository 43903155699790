import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import transitions from '../../../styles/transitions';
import { hover, touchActive } from '../../../styles/utils';
import Icon from '../icon/Icon';
import { ButtonSize } from './ButtonSize';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
}

const BaseButton = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))<ButtonProps>`
  border: 0;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  line-height: 1;
  text-transform: uppercase;
  user-select: none;
  position: relative;
`;

const StyledButton = styled(BaseButton)`
  ${FontRules.TradeGothicLTStd.Bold}

  font-size: 0.875rem;
  padding: 1.1875rem 1.5rem 0.9375rem;
  line-height: 1;
  letter-spacing: ${({ size }) =>
    size === ButtonSize.Small ? 0.0625 : 0.125}rem;

  ${breakpoints.lg} {
    ${({ size }) =>
      size === ButtonSize.Small
        ? `
    font-size: 0.875rem;
    padding: 0.71875rem 2rem 0.46875rem;
    line-height: 1.5;
    `
        : `
    font-size: 1rem;
    padding: 1.125rem 2.5rem;
    line-height: 1.23;
    `}
  }

  color: var(--btn-color, ${AppColor.LeviBlue});
  background-color: var(--btn-bg, ${AppColor.White});

  border: var(--btn-border-width, 1px) solid;
  border-color: var(--btn-border-color, ${AppColor.LeviBlue});

  &[disabled] {
    color: var(--btn-disabled-color, var(--btn-color, ${AppColor.LeviBlue}));
    background-color: var(--btn-disabled-bg, var(--btn-bg, ${AppColor.White}));

    border-color: var(
      --btn-disabled-border-color,
      var(--btn-border-color, ${AppColor.LeviBlue})
    );
  }

  ${hover} {
    &:not([disabled]) {
      cursor: pointer;
      &:hover {
        background-color: var(--btn-bg-hover, ${AppColor.LeviBlueHover});
        border-color: var(--btn-border-color-hover, transparent);
        color: var(--btn-color-hover, ${AppColor.White});
      }
    }
  }
  ${touchActive} {
    &:not([disabled]) {
      &:active {
        background-color: var(--btn-bg-hover, ${AppColor.LeviBlueHover});
        border-color: var(--btn-border-color-hover, transparent);
        color: var(--btn-color-hover, ${AppColor.White});
      }
    }
  }

  ${transitions.ButtonHover}
`;

export const StyledTertiaryButton = styled(BaseButton)`
  ${FontRules.TradeGothicLTStdExtended.Bold}

  text-transform: uppercase;
  font-size: 0.625rem;
  line-height: 1;
  letter-spacing: 0.09375rem;

  padding: 0.90625rem 2.5rem 0.78125rem;

  ${breakpoints.lg} {
    letter-spacing: 0.125rem;
    padding: 0.84375rem 3.5rem 0.71875rem;
  }

  background-color: ${AppColor.SecondaryBlue100};
  border-radius: 3rem;
  color: ${AppColor.LeviBlue};

  &[disabled] {
    background-color: ${AppColor.Neutral300};
    color: ${AppColor.White};
  }

  ${Icon} {
    margin-left: 0.625rem;
  }

  ${hover} {
    &:not([disabled]) {
      cursor: pointer;
      &:hover {
        background-color: ${AppColor.LeviBlueHover};
        color: ${AppColor.White};
      }
    }
  }
  ${touchActive} {
    &:not([disabled]) {
      &:active {
        background-color: ${AppColor.LeviBlueHover};
        color: ${AppColor.White};
      }
    }
  }

  ${transitions.ButtonHover}
`;

export const StyledRoundButton = styled(BaseButton)`
  padding: 1rem;
  border-radius: 2rem;
  width: 4rem;
  height: 4rem;

  color: var(--btn-color, ${AppColor.LeviBlue});
  background-color: var(--btn-bg, ${AppColor.White});
`;

export default StyledButton;
