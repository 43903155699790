import React from 'react';
import { Typography } from '../typography/Typography.styled';
import { CardPriceInfoProps } from './types';
import Styled from './Card.styled';
import { localize } from '../../../localization/i18n';

const CardPriceInfo = ({
  price,
  totalPrice,
  unit,
}: CardPriceInfoProps): JSX.Element =>
  !!price || !!totalPrice ? (
    <Styled.CardPriceInfo>
      {!!price && (
        <Typography.NumberMedium>
          {price} €{unit && <> / {unit}</>}
        </Typography.NumberMedium>
      )}
      {!!totalPrice && (
        <Typography.SubLink>
          {localize('common.totalPrice')} {totalPrice} €
        </Typography.SubLink>
      )}
    </Styled.CardPriceInfo>
  ) : (
    <Styled.CardPriceInfo>
      <Typography.SubLink>
        {localize('common.priceNotAvailable')}
      </Typography.SubLink>
    </Styled.CardPriceInfo>
  );

export default CardPriceInfo;
