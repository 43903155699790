import React from 'react';
import { Link } from "@Levi/components/common/link";
import {
  MainNavigationProps,
  NavLinkPropsWithSubpages,
} from '../MainNavigationPropTypes';
import Icon, { IconSize } from '../../../common/icon/Icon';
import { Icons } from '../../../../types/icon-types';
import Styled from './MobileNavigation.styled';
import { Typography } from '../../../common/typography/Typography.styled';
import useLocale from '../../../../localization/useLocale';
import { staticPageSlugToLocaleLink } from '../../../../hooks/useLocaleLink';
import { localize } from '../../../../localization/i18n';
import NavigationSheet from './NavigationSheet';
import useNavigationSheet from '../../../../hooks/useNavigationSheet';

type SubMenuSelect = (index: number) => void;

type MobileNavigationProps = Pick<
  MainNavigationProps,
  'pageLinks' | 'homePageLink' | 'localeLink' | 'hideSearch' | 'hideLocaleLink'
>;

const renderPageLink = (
  {
    to,
    label,
    subpages,
    subMenuOpen,
    onSubMenuSelect,
    onSubMenuClose,
    onMenuClose,
  }: NavLinkPropsWithSubpages & {
    subMenuOpen: boolean;
    onSubMenuSelect: SubMenuSelect;
    onSubMenuClose: () => void;
    onMenuClose: () => void;
  },
  index: number,
) => (
  <Styled.NavigationLi key={label}>
    <Styled.NavigationLink as={Link} to={to}>
      {label}
    </Styled.NavigationLink>
    {subpages && subpages.length > 0 && (
      <>
        <Styled.SubMenuButton
          aria-label={localize('common.subMenu')}
          onClick={() => {
            onSubMenuSelect(index);
          }}
        >
          <Icon type={Icons.ChevronRightBoldRound} />
        </Styled.SubMenuButton>
        <SubNavigation
          open={subMenuOpen}
          title={label}
          items={subpages}
          onBack={onSubMenuClose}
          onClose={onMenuClose}
        />
      </>
    )}
  </Styled.NavigationLi>
);

const SubNavigation = ({
  title,
  onBack,
  onClose,
  open = false,
  items = [],
}: {
  open?: boolean;
  items?: NavLinkPropsWithSubpages[];
  title: string;
  onBack: () => void;
  onClose: () => void;
}) => {
  if (items.length === 0) return null;

  return (
    <NavigationSheet
      className={open ? 'open' : undefined}
      headerContent={
        <>
          <Icon
            type={Icons.ChevronLeftLarge}
            size={IconSize.Size24}
            onClick={onBack}
          />
          <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
          <Icon type={Icons.Close} size={IconSize.Size32} onClick={onClose} />
        </>
      }
    >
      <Styled.SubNavigationUl>
        {items.map(({ to, label, subpages = [] }) => {
          return (
            <Styled.SubNavigationLi key={label}>
              <Typography.NavigationLink as={Link} to={to}>
                {label}
              </Typography.NavigationLink>
              <Styled.SubNavigationSecondLevelUl>
                {subpages.map(({ to, label }) => (
                  <Styled.SubNavigationLi key={label}>
                    <Typography.SubLink as={Link} to={to}>
                      {label}
                    </Typography.SubLink>
                  </Styled.SubNavigationLi>
                ))}
              </Styled.SubNavigationSecondLevelUl>
            </Styled.SubNavigationLi>
          );
        })}
      </Styled.SubNavigationUl>
    </NavigationSheet>
  );
};

const MobileNavigation: React.FC<MobileNavigationProps> = ({
  homePageLink,
  pageLinks = [],
  localeLink,
  hideSearch,
  hideLocaleLink,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const pageLinksWithHomePage = React.useMemo(
    () => [homePageLink, ...pageLinks],
    [homePageLink, pageLinks],
  );

  const {
    state,
    actions: { open, close, openSubMenu, closeSubMenu },
  } = useNavigationSheet(ref);

  const locale = useLocale();
  const localizedLink = staticPageSlugToLocaleLink(locale, 'search');

  return (
    <>
      <Icon type={Icons.Menu} onClick={open} size={IconSize.Size32} />
      <NavigationSheet
        ref={ref}
        headerContent={
          <>
            {!hideLocaleLink && (
              <Typography.NavigationLink as={Link} to={localeLink.to}>
                {localeLink.label}
              </Typography.NavigationLink>
            )}
            <Icon
              type={Icons.Close}
              onClick={close}
              size={IconSize.Size32}
              style={{ marginLeft: 'auto' }}
            />
          </>
        }
      >
        <Styled.NavigationUl>
          {pageLinksWithHomePage.map((item, index) =>
            renderPageLink(
              {
                ...item,
                subMenuOpen: state.subMenuIndex === index,
                onSubMenuSelect: openSubMenu,
                onSubMenuClose: closeSubMenu,
                onMenuClose: close,
              },
              index,
            ),
          )}
        </Styled.NavigationUl>
        {!hideSearch && localizedLink && (
          <Styled.NavigationUl>
            <Styled.NavigationSearchLi key={'search'}>
              <Styled.NavigationLink as={Link} to={localizedLink}>
                <Icon type={Icons.Search} />{' '}
                {localize('search.form.buttonLabel')}
              </Styled.NavigationLink>
            </Styled.NavigationSearchLi>
          </Styled.NavigationUl>
        )}
      </NavigationSheet>
    </>
  );
};

export default MobileNavigation;
