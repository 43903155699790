import { ContentfulCardListTypes } from '../contentful/sections/contentfulCardList';
import useLocale from '../localization/useLocale';

type HookProps = Pick<ContentfulCardListTypes, 'cards'>;

const useContentfulCardListData = ({ cards }: HookProps) => {
  const locale = useLocale();

  return cards
    .filter(card => !(locale !== 'fi' && card.hideFromLocalization))
    .map(({ id, title, image, description, callToAction, hideFromLocalization }) => ({
      id,
      title,
      image,
      description: (description && description.text) || '',
      callToAction,
      hideFromLocalization
    }));
}

export default useContentfulCardListData;
