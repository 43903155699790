import { WeatherStationType } from '../network/skiresort-api/types/types';

export type SkiResortWeatherType = {
  leviPeak?: WeatherStationType;
  leviCentre?: WeatherStationType;
};

export type LeviTodayFieldKeys =
  | 'Bike trails'
  | 'Lifts'
  | 'Snowmaking'
  | 'Ski tracks'
  | 'Slope cameras'
  | 'Slopes'
  | 'Snow depth'
  | 'Snowmobile tracks'
  | 'Slope restaurants'
  | 'Trekking routes'
  | 'Weather'
  | LeviTodaySummerFieldKeys
;

export type LeviTodaySummerFieldKeys = 
  | 'Basejump'
  | 'Sled track'
  | 'Traffic park'
  | 'Adventure park'
  | 'Trampoline'
  | 'Zipline'
;

export const ActivityApiMap: {[key:string]: string} = {
  'bicycleTrails'     : 'Bike trails',
  'lifts'             : 'Lifts',
  'SnowPreparations'  : 'Snowmaking',
  'skiTracks'         : 'Ski tracks',
  'slopeCameras'      : 'Slope cameras',
  'slopes'            : 'Slopes',
  'snowDepth'         : 'Snow depth',
  'snowMobileTracks'  : 'Snowmobile tracks',
  'slopeRestaurants'  : 'Slope restaurants',
  'trekkingRoutes'    : 'Trekking routes',
  'weather'           : 'Weather',
};

/** Mapping from Frends activity key to Contentful activity  */
export const SummerActivityApiMap: {[key:string]: LeviTodaySummerFieldKeys} = {
  'basejump_avoinna'        : 'Basejump',
  'kelkkarata_avoinna'      : 'Sled track',
  'liikennepuisto'          : 'Traffic park',
  'seikkailuratoja_avoinna' : 'Adventure park',
  'trampoliini'             : 'Trampoline',
  'zipline_avoinna'         : 'Zipline',
} as const;

export type LeviTodayType = Partial<{
  [key in LeviTodayFieldKeys]: string | undefined;
}>;

export type SkiResortDataTypes = {
  leviToday?: LeviTodayType;
  weather?: SkiResortWeatherType;
};
