import React from 'react';
import Styled from './Calendar.styled';
import CalendarHeader from './CalendarHeader';
import CalendarWeekDays from './CalendarWeekDays';
import CalendarDays from './CalendarDays';
import { LocaleType } from '../../../contentful/templates/types';

type CalendarInstanceProps = {
  offset: number;
  totalInstances?: number;
  locale: LocaleType;
};

const CalendarInstance = ({
  locale,
  offset = 0,
  totalInstances = 1,
}: CalendarInstanceProps) => (
  <Styled.Calendar>
    <CalendarHeader
      locale={locale}
      offset={offset}
      totalInstances={totalInstances}
    />
    <CalendarWeekDays locale={locale} />
    <CalendarDays offset={offset} />
  </Styled.Calendar>
);

export default CalendarInstance;
