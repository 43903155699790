import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Typography } from '../common/typography/Typography.styled';
import { NavLinkProps } from '../navigation/main/MainNavigationPropTypes';
import Styled from './Footer.styled';
import FooterContactInfo, { FooterContactInfoProps } from './FooterContactInfo';
import FooterNavigation from './FooterNavigation';
import FooterPartnersList, { PartnerType } from './FooterPartnersList';
import useBreakpoints from '../../hooks/useBreakpoints';
import { FooterBackgroundImageTypes } from '../../contentful/footer/types';

export type FooterProps = {
  title: string;
  backgroundImage: FooterBackgroundImageTypes;
  navigation: NavLinkProps[];
  companies: FooterContactInfoProps[];
  partnersTitle: string;
  partners: PartnerType[];
};

const Footer: React.FC<FooterProps> = ({
  title,
  backgroundImage,
  navigation,
  companies,
  partnersTitle,
  partners,
}): JSX.Element => {
  const { isValid, isMobile } = useBreakpoints();
  const image =
    isValid && (isMobile ? backgroundImage.mobile : backgroundImage.desktop);
  return (
    <Styled.Footer>
      <Styled.FooterBackgroundImage>
        {image && <GatsbyImage image={image} alt="" />}
      </Styled.FooterBackgroundImage>
      <Styled.FooterHeadContent>
        {title && <Typography.Display as="h2">{title}</Typography.Display>}
        <FooterPartnersList title={partnersTitle} partners={partners} />
      </Styled.FooterHeadContent>
      <Styled.FooterMainContent>
        <FooterNavigation links={navigation} />
        {companies?.map((company) => (
          <FooterContactInfo key={company.id} {...company} />
        ))}
      </Styled.FooterMainContent>
    </Styled.Footer>
  );
};

export default Footer;
