import React from 'react';
import { useCalendarStoreAPI } from './calendarStore';
import {
  DateRange,
  DateRangeValid,
  isValidDateRange,
} from '../../../utils/date-utils';
import { resolveDayStates } from './calendarStateResolver';

export type CalendarPropsListenerProps = {
  selectedDateRange?: DateRangeValid;
};

const CalendarPropsListener = (props: CalendarPropsListenerProps): null => {
  const api = useCalendarStoreAPI();
  const { selectedDateRange: selectedDateRangeProps } = props;

  React.useEffect(() => {
    const { start, end } =
      selectedDateRangeProps && isValidDateRange(selectedDateRangeProps)
        ? selectedDateRangeProps
        : { start: undefined, end: undefined };
    const selectedDateRange: DateRange = {
      start,
      end,
    };

    api.setState((state) => {
      const newState = {
        ...state,
        hoverDate: undefined,
        selectedDateRange: { ...selectedDateRange },
      };

      const days = resolveDayStates(newState);

      return {
        ...newState,
        days,
      };
    });
  }, [api, selectedDateRangeProps]);

  return null;
};

export default CalendarPropsListener;
