import React from 'react';
import { graphql } from 'gatsby';
import Styled from '../HorizontalCard.styled';
import { DefaultHorizontalCardProps } from '../types';
import { HorizontalCardType } from '../HorizontalCard';
import HorizontalCardImageColumn from '../HorizontalCardImageColumn';
import HorizontalCardContent from '../HorizontalCardContent';
import useContentfulCallToAction, {
  LinkComponentTypes,
  LinkProps,
} from '../../../../hooks/useContentfulCallToAction';

export type CardLinkProps = LinkProps<LinkComponentTypes>;

const DefaultHorizontalCard = ({
  image,
  cardLink,
  ...props
}: DefaultHorizontalCardProps & {
  cardLink?: LinkProps<LinkComponentTypes>;
}): JSX.Element => {
  const maybeLink = props.callToAction
    ? props.callToAction
    : props.links && props.links.length === 1
    ? props.links[0]
    : undefined;

  const linkProps = useContentfulCallToAction(maybeLink);
  const cardLinkProps = cardLink || linkProps || {};

  return (
    <Styled.Card
      type={HorizontalCardType.Default}
      role="listitem"
      $hasLink={cardLinkProps !== {}}
    >
      <HorizontalCardImageColumn image={image} cardLinkProps={cardLinkProps} />
      <HorizontalCardContent {...props} cardLinkProps={cardLinkProps} />
    </Styled.Card>
  );
};

export default DefaultHorizontalCard;

export const contentfulHorizontalCardImage = graphql`
  fragment contentfulHorizontalCardImage on ContentfulAsset {
    image: gatsbyImageData(
      width: 202
      height: 202
      breakpoints: [202]
      layout: CONSTRAINED
      placeholder: BLURRED
      quality: 50
      resizingBehavior: FILL
      backgroundColor: "transparent"
    )
    description
    title
  }
`;
