import React from 'react';
import { Icons } from '../../../types/icon-types';
import { SocialMedia } from '../../../types/social-media';
import Icon from '../icon/Icon';
import Styled from './SocialMediaButton.styled';

export type SocialMediaButtonProps = {
  media: SocialMedia;
  link: string;
};

const SocialMediaButton = ({
  media,
  link,
}: SocialMediaButtonProps): JSX.Element => {
  const onClick = React.useCallback(() => {
    window.open(link);
  }, [link]);

  return (
    <Styled.SocialMediaButton
      media={media}
      onClick={onClick}
      aria-label={media}
    >
      <Icon type={Icons[`SocialMedia${media}`]} />
    </Styled.SocialMediaButton>
  );
};

export default SocialMediaButton;
