import React from 'react';
import Icon, { IconSize } from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';
import { Icons } from '../../../types/icon-types';
import Styled from './Accordion.styled';

export type AccordionProps = {
  title: string;
  body: string;
};

const Accordion: React.FC<AccordionProps> = ({ title, body }) => {
  return (
    <Styled.Accordion>
      <Styled.Details>
        <Styled.Summary>
          <Styled.SummaryContent>
            <Typography.LabelBig>{title}</Typography.LabelBig>
            <Icon type={Icons.ChevronRightBoldRound} size={IconSize.Size16} />
          </Styled.SummaryContent>
        </Styled.Summary>
        <Typography.BodySmall as="div">{body}</Typography.BodySmall>
      </Styled.Details>
    </Styled.Accordion>
  );
};

export default Accordion;
