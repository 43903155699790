import styled from 'styled-components';
import {
  below,
  Breakpoint,
  breakpoints,
  only,
} from '../../../styles/breakpoints';

export type StyledGridProps = {
  overflowHidden?: boolean;
  noGutter?: boolean;
};

const Grid = styled.div<StyledGridProps>`
  width: 100%;
  margin: 0 auto;
  max-width: var(--max-content-width);
  flex-shrink: 0;

  overflow: ${({ overflowHidden }) => (overflowHidden ? 'hidden' : 'unset')};

  ${breakpoints.sm} {
    display: grid;
    grid-gap: 0 ${(props) => (props.noGutter ? 0 : `var(--gutter)`)};
    grid-template-columns: repeat(var(--columns), 1fr);
  }
  ${breakpoints.md} {
    grid-template-rows: [header] auto [main] auto;
  }
`;

export const SubpageGrid = styled(Grid)`
  grid-template-areas: 'content';

  ${only(Breakpoint.sm)} {
    grid-template-areas: 'content content content content content content';
  }

  ${only(Breakpoint.md)} {
    grid-template-areas: 'content content content content content content content aside aside aside aside aside';
  }

  ${breakpoints.lg} {
    grid-template-areas: 'content content content content content content content content aside aside aside aside';
  }
`;

export type StyledSearchPageGridProps = {
  noAside?: boolean;
};

export const SearchPageGrid = styled(SubpageGrid)<StyledSearchPageGridProps>`
  ${only(Breakpoint.md)} {
    grid-template-areas: 'content content content content content content content content content content content content';
  }
  ${breakpoints.lg} {
    grid-template-areas: ${(props) =>
      props.noAside
        ? `"content content content content content content content content content content content content";`
        : `"content content content content content content content content aside aside aside aside";`};
  }
`;

export type GridElementProps = {
  column?: number;
  full?: boolean;
};

export const GridElement = styled.div.attrs<GridElementProps>((props) => ({
  column: props.column,
}))<GridElementProps>`
  ${breakpoints.sm} {
    ${({ column }) => !!column && `grid-column: span ${column};`}
    ${({ full }) => !!full && `grid-column-start: 1; grid-column-end: -1;`}
  }
  ${breakpoints.md} {
    grid-row: main;
  }
`;

export type SubpageGridElementProps = {
  column?: 'content' | 'aside';
  padded?: boolean;
};

export const SubpageGridElement = styled.div.attrs<SubpageGridElementProps>(
  (props) => ({
    column: props.column || 'content',
  }),
)<SubpageGridElementProps>`
  grid-column: content;

  ${below(Breakpoint.md)} {
    ${(props) =>
      props.column === 'aside' &&
      `
      margin-top: 2rem;
    `}
  }

  ${breakpoints.md} {
    grid-column: ${(props) => props.column};
  }
  ${breakpoints.lg} {
    padding: ${(props) =>
      !props.padded
        ? 0
        : props.column === 'content'
        ? '0 2rem 0 3.5rem'
        : '0 2rem'};
  }
`;

export default {
  Grid,
  GridElement,
  SubpageGrid,
  SubpageGridElement,
};
