import { graphql, useStaticQuery } from 'gatsby';

type SitePageMap = Map<string, string>;

type RouteType = {
  path: string;
  context: {
    id: string;
  };
};

const query = graphql`
  query SitePageData {
    allSitePage {
      edges {
        node {
          path
          context {
            id
          }
        }
      }
    }
  }
`;

const data: SitePageMap = new Map();

const useSitePageData = (): SitePageMap => {
  const { allSitePage } = useStaticQuery(query);

  // don't parse everytime
  if (!data.size) {
    allSitePage.edges.forEach(({ node }: { node: RouteType }) => {
      const id = node.context?.id;

      id && data.set(id, node.path);
    });
  }

  return data;
};

export default useSitePageData;
