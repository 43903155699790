import React from 'react';
import { graphql } from 'gatsby';
import SubscribeNewsletterForm from '../../components/common/subscribe-newsletter-form/SubscribeNewsletterForm';
import { ExternalPageLink, InternalPageLink } from '../callToActions/types';
import { Helmet } from 'react-helmet';

export type ContentfulNewsletterSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  redirectDestination: ExternalPageLink | InternalPageLink;
  privacyPolicyPage: ExternalPageLink | InternalPageLink;
  visitLeviTargetList: string[];
  skiResortTargetList: string[];
};

const ContentfulNewsletterSection = ({
  title,
  redirectDestination,
  privacyPolicyPage,
  visitLeviTargetList,
  skiResortTargetList,
}: ContentfulNewsletterSectionTypes): JSX.Element | null => {
  React.useEffect(() => {
    return () => {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      if (nodeBadge && nodeBadge.parentNode) {
        document.body.removeChild(nodeBadge.parentNode);
      }
      const scriptSelector = `script[src='https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}']`;
      const script = document.querySelector(scriptSelector);
      if (script) {
        script.remove();
      }
      const script2 = document.querySelector(
        'script[src^="https://www.gstatic.com/recaptcha"]',
      );
      if (script2) {
        script2.remove();
      }
    };
  }, []);

  if (
    !redirectDestination ||
    !privacyPolicyPage ||
    !visitLeviTargetList ||
    !skiResortTargetList
  ) {
    console.warn('Invalid fields for ContentfulNewletterSection');
    return null;
  }

  return (
    <>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}
        ></script>
      </Helmet>
      <SubscribeNewsletterForm
        title={title}
        visitLeviTargetList={visitLeviTargetList}
        skiResortTargetList={skiResortTargetList}
        redirectDestination={redirectDestination}
        privacyPolicyPage={privacyPolicyPage}
      />
    </>
  );
};

export default ContentfulNewsletterSection;

export const contentfulNewsletterSectionQuery = graphql`
  fragment contentfulNewsletterSectionFields on ContentfulNewsletterSection {
    id
    internal {
      type
    }
    title
    redirectDestination {
      ...destinationFields
    }
    privacyPolicyPage {
      ...destinationFields
    }
    visitLeviTargetList
    skiResortTargetList
  }
  fragment destinationFields on ContentfulEntry {
    ... on ContentfulPage {
      pageId: id
    }
    ... on ContentfulExternalUrl {
      externalUrl: url
    }
  }
`;
