import React from 'react';
import { Icons } from '../../../types/icon-types';
import Icon, { IconSize } from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';
import Styled from './CategoryTabs.styled';

export type CategoryTabProps = {
  children: string;
  icon?: Icons;
  selected?: boolean;
  onClick: () => void;
};

const CategoryTab = ({
  children,
  selected,
  icon,
  onClick,
}: CategoryTabProps): JSX.Element => {
  return (
    <Styled.Tab selected={selected} onClick={onClick}>
      {icon && <Icon type={icon} size={IconSize.Size16} />}
      <Typography.LinkSmall>{children}</Typography.LinkSmall>
    </Styled.Tab>
  );
};

export default CategoryTab;
