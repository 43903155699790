import React from 'react';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import DefaultCard from './DefaultCard';
import { RestaurantCardProps } from '../types';
import CardFooterLink from '../CardFooterLink';
import CardLocationInfo from '../CardLocationInfo';
import CardOpenStatusInfo from '../CardOpenStatusInfo';
import { useSkiResortDataByApiPath } from '../../../../hooks/useSkiResortDataByApiPath';
import { ApiPath } from '../../../../network/skiresort-api';

const RestaurantCard = ({
  title,
  image,
  description,
  address,
  locationMarkerIndex,
  openStatus,
  hideOpeningHours,
  links = [],
  hubDbId,
  theme,
}: RestaurantCardProps): JSX.Element => {
  const hubDbRestaurant = useSkiResortDataByApiPath(
    ApiPath.Restaurant,
    { id: hubDbId?.hubspotId },
    !hideOpeningHours && !!hubDbId?.hubspotId,
  );

  const cardLink = links && links.length === 1 ? links[0] : undefined;

  return (
    <DefaultCard
      title={title}
      image={image}
      theme={theme}
      callToAction={cardLink}
    >
      <CardLocationInfo markerIndex={locationMarkerIndex} address={address} />
      {!hideOpeningHours &&
        (!hubDbId
          ? openStatus &&
            openStatus.isOpen && <CardOpenStatusInfo {...openStatus} />
          : hubDbRestaurant &&
            hubDbRestaurant.open &&
            !hubDbRestaurant.open.hidden &&
            hubDbRestaurant.open.openingHours && (
              <CardOpenStatusInfo
                isOpen={hubDbRestaurant.open?.now}
                description={hubDbRestaurant.open.hours}
              />
            ))}
      <Typography.BodySmall>{description}</Typography.BodySmall>
      <Styled.CardFooter>{links.map(CardFooterLink)}</Styled.CardFooter>
    </DefaultCard>
  );
};

export default RestaurantCard;
