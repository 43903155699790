import styled from 'styled-components';
import { AppColor } from '../../styles/colors';
import {
  LinkUnderlineCSS,
  LinkUnderLineHoverCSS,
  Typography,
} from '../common/typography/Typography.styled';
import { breakpoints } from '../../styles/breakpoints';
import Section from '../section/Section';
import { hover } from '../../styles/utils';

const Footer = styled(Section)`
  flex-direction: column;
  padding: 0;
  position: relative;
  max-width: var(--max-section-width);
`;

const FooterBackgroundImage = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 2rem;
  left: 0;
  right: 0;
  margin: auto;
`;

const FooterHeadContent = styled.div`
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  z-index: 2;
  height: 208vw;
  min-height: 782px;

  align-items: center;
  display: flex;
  flex-direction: column;

  padding-top: min(4rem, 17vw);

  ${Typography.Display} {
    max-width: 14rem;
    text-align: center;
  }

  ${breakpoints.md} {
    height: 74.5vw;
    max-height: 1074px;
    padding-top: min(13rem, 14.5vw);

    ${Typography.Display} {
      max-width: 27rem;
    }
  }
`;

const PartnersContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  margin-bottom: 5.5rem;

  ${Typography.SubHeadingLarge} {
    color: ${AppColor.Neutral500};
  }

  ul {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-top: 3rem;

    flex-direction: column;
    ${breakpoints.md} {
      gap: 6rem;
      flex-direction: row;
    }
  }
`;

const FooterMainContent = styled.div`
  z-index: 2;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  border-top: 1px solid ${AppColor.Neutral300};
  color: ${AppColor.Neutral700};

  display: flex;
  flex-direction: column;
  padding: 4rem 1.5rem 3.625rem;

  ${breakpoints.md} {
    padding: 4.5rem 3.5rem 5rem;
    justify-content: space-between;
    flex-direction: row;
  }

  nav {
    padding-bottom: 4rem;
    ${breakpoints.md} {
      padding-bottom: 0;
      flex-basis: 30.5%;
    }

    --link-underline-height: 2px;

    ul li a {
      ${LinkUnderlineCSS}

      ${hover} {
        &:hover {
          ${LinkUnderLineHoverCSS}
        }
      }
    }
  }

  nav ul li + li {
    margin-top: 1rem;
  }

  > div {
    border-top: 1px solid ${AppColor.Neutral300};
    padding-top: 4rem;
    padding-bottom: 4rem;
    ${breakpoints.md} {
      border-top: unset;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  > div ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1.125rem;
    ${breakpoints.md} {
      gap: var(--gutter);
    }
    margin-top: 1.5rem;
  }

  > div address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${Typography.SubLink} {
      margin-top: 0.85rem;
    }

    ${Typography.SubHeadingSmall} {
      color: ${AppColor.LeviBlue};
    }

    ${Typography.SubHeadingSmall} + ${Typography.SubLink} {
      margin-top: 1.5rem;
    }

    a {
      ${LinkUnderlineCSS}

      ${hover} {
        &:hover {
          ${LinkUnderLineHoverCSS}
        }
      }
    }
  }
`;

export default {
  Footer,
  FooterBackgroundImage,
  FooterHeadContent,
  PartnersContent,
  FooterMainContent,
};
