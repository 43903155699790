import { localize } from '../../localization/i18n';
import { Icons } from '../../types/icon-types';
import { RestaurantCategoryTypes } from './types';

export const restaurantCategories: RestaurantCategoryTypes = {
  Restaurants: {
    icon: Icons.RestaurantsRestaurantLarge,
  },
  'Ski-restaurants': {
    icon: Icons.RestaurantsSkicafesLarge,
  },
  Cafes: {
    icon: Icons.RestaurantsCafeLarge,
  },
  'Ski-cafes': {
    icon: Icons.RestaurantsSkicafesLarge,
  },
  'After ski, pubs, clubs': {
    icon: Icons.RestaurantsClubLarge,
  },
  Catering: {
    icon: Icons.RestaurantsCateringLarge,
  },
  'Take away': {
    icon: Icons.RestaurantsTakeawayLarge,
  },
};

export const restaurantCategoriesToLocalizedList = () =>
  Object.keys(restaurantCategories).map((key) => ({
    value: key,
    label: localize(`restaurants.categories.${key}.label`),
    description: localize(`restaurants.categories.${key}.description`),
    icon: restaurantCategories[key].icon,
  }));
