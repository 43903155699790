import React from 'react';
import CardGridHeader from '../common/cardgrid/CardGridHeader';
import {
  connectInfiniteHits,
  connectStateResults,
  Index,
} from 'react-instantsearch-dom';

import { IndexProps, InfiniteHitsProvided } from 'react-instantsearch-core';
import CardList from '../common/cardlist/CardList';
import { HorizontalCardType } from '../common/horizontal-card/HorizontalCard';
import { localize } from '../../localization/i18n';
interface SearchResultsProps extends IndexProps {
  show?: boolean;
}

const SearchResults = ({ show = false, ...rest }: SearchResultsProps) =>
  show ? (
    <Index {...rest}>
      <ResultsHeader />
      <InfiniteResults />
    </Index>
  ) : null;

const InfiniteResults = connectInfiniteHits(
  ({ hits, hasMore, refineNext }: InfiniteHitsProvided) => (
    <CardList
      type={HorizontalCardType.SearchResult}
      cards={hits.map(({ objectID, title, type, description, image }) => ({
        id: objectID,
        title,
        image,
        resultType:
          (type && localize(`searchResults.resultTypes.${type}`)) || undefined,
        description,
        callToAction: {
          destination: { pageId: objectID },
        },
      }))}
      moreLink={
        hasMore
          ? {
              label: localize('searchResults.showMore'),
              onClick: refineNext,
            }
          : undefined
      }
    />
  ),
);

const ResultsHeader = connectStateResults(({ searchResults }) => {
  const hitCount = searchResults && searchResults.nbHits;
  return (
    <CardGridHeader
      title={localize(
        hitCount > 0
          ? 'searchResults.seachResultsTitle'
          : 'searchResults.noResults',
      )}
      count={hitCount}
    />
  );
});

export default SearchResults;
