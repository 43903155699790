import React from 'react';
import ListFilters, { ListFiltersProps } from './ListFilters';
import Styled from '../../../contentful/templates/serviceListPages/ServiceListPage.styled';
import { Icons } from '../../../types/icon-types';
import { localize } from '../../../localization/i18n';

type SearchFiltersProps = Pick<ListFiltersProps, 'children' | 'filters' | 'onResetClick'> & {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
};

const SearchFilters = ({
  filters,
  searchTerm,
  children,
  setSearchTerm,
  onResetClick,
}: SearchFiltersProps) => (
  <Styled.Filters>
    <ListFilters
      label={localize('filters.filterBy')}
      resetLabel={localize('filters.listFilters.resetLabel')}
      filters={filters}
      onResetClick={onResetClick}
    >
      <Styled.Input
        type="text"
        placeholder={localize('search.form.inputPlaceholder')}
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        trailingIcon={{
          type: Icons.SearchLarge,
        }}
      />
      {children}
    </ListFilters>
  </Styled.Filters>
);

export default SearchFilters;
