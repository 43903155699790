const FontRules = {
  PlayfairDisplay: {
    RegularItalic: `
      font-family: Playfair Display, serif;
      font-weight: 400;
      font-style: italic;
    `,
    Bold: `
      font-family: Playfair Display, serif;
      font-weight: 700;
      font-style: normal;
    `,
  },
  TradeGothicLTStd: {
    Regular: `
      font-family: 'Trade Gothic LT W05 Roman', Arial Narrow, Helvetica, Arial, sans-serif;
      font-style: normal;
      `,
    Bold: `
      font-family: 'Trade Gothic LT W05 Bold', Arial Narrow, Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
  },
  TradeGothicLTStdExtended: {
    Regular: `
      font-family: 'Trade Gothic LT W05 Extended', Helvetica, Arial, sans-serif;
      font-style: normal;
      `,
    Bold: `
      font-family: 'Trade Gothic LT W05 Bold Ext', Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
  },
  TradeGothicNext: {
    Light: `
      font-family: 'Trade Gothic Next LT W01 Light', Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
    LightItalic: `
      font-family: 'Trade Gothic Next LT W01 Lt It', Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
    Regular: `
      font-family: 'Trade Gothic Next LT W01 Rg', Arial Narrow, Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
    Bold: `
      font-family: 'Trade Gothic Next LT W01 Bold', Arial Narrow, Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
  },
  TradeGothicNextCondensed: {
    Regular: `
      font-family: 'Trade Gothic Next LT W01 Cond', Arial Narrow, Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
    Bold: `
      font-family: 'Trade Gothic Next LT W01 Bd Cn', Arial Narrow, Helvetica, Arial, sans-serif;
      font-style: normal;
    `,
  },
};

export default FontRules;
