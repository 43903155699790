import React from 'react';
import { ChildMeta, ContentfulPageMetaFields } from '../contentful/meta';
import SearchPageTemplate, {
  PageProps,
  SearchPageProps,
} from '../contentful/templates/SearchPageTemplate';

const meta: ContentfulPageMetaFields = {
  title: 'Haku',
  description: '',
  image: undefined,
  lang: 'fi',
  hiddenFromSearchEngines: true,
};

const searchPageTemplateProps: SearchPageProps = {
  title: 'Haku',
  index: { indexName: (process.env.GATSBY_ALGOLIA_INDEX_ENV_PREFIX || "") + 'pages-fi' },
};

const SearchPage = ({ location }: PageProps) => (
  <>
    <ChildMeta {...meta} />
    <SearchPageTemplate {...searchPageTemplateProps} location={location} />
  </>
);

export default SearchPage;
