import styled from 'styled-components';
import { below, Breakpoint, breakpoints } from '../../../styles/breakpoints';
import { GridElement } from '../layout/Grid.styled';
import GridHeader from '../layout/GridHeader';
import { Typography } from '../typography/Typography.styled';
import { CardGridColumnProps } from './CardGrid';

const CardGrid = styled(GridElement)<CardGridColumnProps>`
  max-width: var(--max-content-width);

  display: grid;
  grid-gap: 2rem var(--gutter);

  --overflow-pad: calc((100vw - var(--max-content-width)) * 0.5);

  ${below(Breakpoint.lg)} {
    ${(props) =>
      props.mobileScrollable
        ? `
      
      grid-auto-columns: 304px;
      ${breakpoints.sm} {
        grid-auto-columns: 351.5px;
      }
      ${breakpoints.md} {
        grid-auto-columns: 298px;
      }

      grid-auto-flow: column;

      overflow-x: scroll;
      overflow-y: visible;
      padding-bottom: 1rem;

      scroll-snap-type: x mandatory;
      scroll-padding-left: var(--overflow-pad);
      scroll-padding-right: var(--overflow-pad);
      
      max-width: calc(100% + var(--overflow-pad) * 2);
      width: calc(100% + var(--overflow-pad) * 2);
      position: relative;
      left: calc(-1 * var(--overflow-pad));
      padding-right: var(--overflow-pad);
      padding-left: var(--overflow-pad);
      
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      `
        : `;
      grid-auto-columns: 100%;
      grid-auto-flow: row;

      ${breakpoints.sm} {
        grid-template-columns: repeat(2, 1fr);
      }
    `}
  }

  ${breakpoints.sm} {
    grid-column-start: 1;
    grid-column-end: -1;
  }

  ${breakpoints.sm} {
    ${(props) => props.columns === 1 && `grid-column: span 3;`}
  }

  ${breakpoints.md} {
    ${(props) => props.columns === 1 && `grid-column: span 6;`}
  }

  ${breakpoints.lg} {
    ${(props) =>
      props.columns === 3
        ? `
      grid-template-columns: repeat(3, 1fr);
      `
        : props.columns === 2
        ? `
      grid-template-columns: repeat(2, 1fr);
      `
        : `
      grid-column: span 4;
        `}
  }

  & > * {
    max-width: unset;
    scroll-snap-align: start;
  }
`;

const CardGridHeader = styled(GridHeader)`
  ${({ centered }) =>
    !centered &&
    `
  text-align: left;
  justify-content: unset;
  `}
  ${breakpoints.sm} {
    max-width: var(--max-content-width);
  }

  ${Typography.HeadingLarge} + * {
    margin-top: 1.5rem;

    ${breakpoints.lg} {
      margin-top: 2.5rem;
    }
  }

  ${Typography.HeadingLarge} {
    color: var(--card-grid-color-heading-primary);
  }

  ${Typography.SubHeadingLarge} {
    color: var(--card-grid-color-heading-secondary);

    & > span {
      color: var(--card-grid-color-heading-tertiary);
    }
  }

  margin-bottom: 1.75rem;
`;

const CardGridHeaderRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  > * + * {
    flex-shrink: 0;
    margin-left: 1.5rem;
    margin-bottom: 0.375rem;
  }

  ${breakpoints.lg} {
    align-items: center;
    * + * {
      margin-bottom: unset;
    }
  }
`;

const CardGridFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  ${breakpoints.sm} {
    margin-top: 2rem;
  }
  ${breakpoints.lg} {
    margin-top: 3rem;
  }

  grid-column-start: 1;
  grid-column-end: -1;
`;

export default {
  CardGrid,
  CardGridHeader,
  CardGridHeaderRow,
  CardGridFooter,
};
