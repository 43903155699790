import React from 'react';
import { graphql } from 'gatsby';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { Typography } from '../../components/common/typography/Typography.styled';
import ContentfulCallToAction from '../callToActions/contentfulCallToAction';
import ContentfulAccordion from '../fragments/contentfulAccordion';
import ContentfulInfoBox from '../fragments/contentfulInfoBox';
import ContentfulTable from '../fragments/contentfulTable';
import { BlockEntryIndex } from './types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { stripHtmlString } from '../../network/skiresort-api/parsers/utils';

const blockEntries: BlockEntryIndex = {
  ContentfulAccordion,
  ContentfulTable,
  ContentfulInfoBox,
  ContentfulCallToAction,
};

// const inlineEntries: InlineEntryIndex = {
//   ContentfulCallToAction: ContentfulCallToAction,
// };

const isValidChildren = (children: React.ReactNode) =>
  React.Children.toArray(children).filter((i) => !!i).length > 0;

const options: Options = {
  renderText: (text: string) => {
    try {
      if (stripHtmlString(text).replace(/\s*/, '') === '') return null;
    } catch (e) {}
    return text
      .split('\n')
      .reduce(
        (
          children: (string | false | JSX.Element)[],
          textSegment: string,
          index: number,
        ) => [...children, index > 0 && <br key={index} />, textSegment],
        [],
      );
  },
  renderNode: {
    [INLINES.HYPERLINK]: (_node, children) => {
      const { uri } = _node.data;
      if (!uri || !isValidChildren(children)) {
        return null;
      }
      return <a href={uri}>{children}</a>;
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      if (!isValidChildren(children)) {
        return null;
      }
      return <Typography.HeadingLarge>{children}</Typography.HeadingLarge>;
    },
    [BLOCKS.HEADING_3]: (_node, children) => {
      if (!isValidChildren(children)) {
        return null;
      }
      return <Typography.HeadingSmall>{children}</Typography.HeadingSmall>;
    },
    [BLOCKS.HEADING_4]: (_node, children) => {
      if (!isValidChildren(children)) {
        return null;
      }
      return (
        <Typography.SubHeadingLarge>{children}</Typography.SubHeadingLarge>
      );
    },
    [BLOCKS.HEADING_5]: (_node, children) => {
      if (!isValidChildren(children)) {
        return null;
      }
      return (
        <Typography.SubHeadingSmall>{children}</Typography.SubHeadingSmall>
      );
    },
    [BLOCKS.HEADING_6]: (_node, children) => {
      if (!isValidChildren(children)) {
        return null;
      }
      return (
        <Typography.BodyLarge>
          <b>{children}</b>
        </Typography.BodyLarge>
      );
    },
    [BLOCKS.UL_LIST]: (_node, children) => {
      if (!isValidChildren(children)) {
        return null;
      }
      return <ul>{children}</ul>;
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      try {
        if (
          _node.content &&
          _node.content.length === 1 &&
          _node.content[0].nodeType === 'text' &&
          _node.content[0].value.replace(/\s*/, '') === ''
        ) {
          return null;
        }
      } catch (e) {}
      return <Typography.BodyLarge>{children}</Typography.BodyLarge>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }) => {
      const { target } = data;
      // Find correct component for entry type
      const BlockEntryComponent = blockEntries[target?.__typename];
      if (!BlockEntryComponent) {
        console.warn('Cannot define BlockEntry type', target?.__typename);
        return null;
      }
      // More of these exceptions might be coming
      if (target.__typename === 'ContentfulCallToAction') {
        target.type = 'button';
      }

      return <BlockEntryComponent {...target} />;
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data: { target } }) => {
      if (!target?.gatsbyImageData) return null;

      return (
        <GatsbyImage
          alt={target.description || target.title || ''}
          image={target.gatsbyImageData}
        />
      );
    },
    [INLINES.EMBEDDED_ENTRY]: ({ data }): React.ReactNode => {
      // only callToActions for now
      const { title, destination, target } = data.target;
      return (
        <ContentfulCallToAction
          title={title}
          type="inline-link"
          destination={destination}
          target={target}
        />
      );
    },
  },
};

/*
type ContentfulAccordionRefTypes = ContentfulAccordionTypes & {
  contentful_id: string;
  __typename: string;
};
*/
type RichTextProps<T extends ContentfulRichTextGatsbyReference> = {
  data: RenderRichTextData<T>;
};

export const richTextRenderer = <T extends ContentfulRichTextGatsbyReference>(
  props: RichTextProps<T>,
) => renderRichText(props.data, options);

const RichText = <T extends ContentfulRichTextGatsbyReference>({
  data,
}: RichTextProps<T>): JSX.Element => {
  return <>{renderRichText(data, options)}</>;
};

export default RichText;

export const contentfulRichTextQuery = graphql`
  # fields for TextContentSection's body
  fragment contentfulTextContentBodyRichTextFields on ContentfulTextContentBody {
    raw
    references {
      ... on ContentfulAccordion {
        contentful_id
        __typename
        ...contentfulAccordionFields
      }
      ... on ContentfulTable {
        contentful_id
        __typename
        ...contentfulTableFields
      }
      ... on ContentfulInfoBox {
        contentful_id
        __typename
        ...contentfulInfoBoxFields
      }
      ... on ContentfulCallToAction {
        contentful_id
        __typename
        ...contentfulCallToActionFields
      }
      ... on ContentfulAsset {
        contentful_id
        __typename
        title
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
  # fields for EventPage's body
  fragment contentfulEventBodyRichTextFields on ContentfulEventBody {
    raw
    references {
      ... on ContentfulCallToAction {
        contentful_id
        __typename
        ...contentfulCallToActionFields
      }
      ... on ContentfulAsset {
        contentful_id
        __typename
        title
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
  # fields for BlogPostPage's body
  fragment contentfulBlogPostBodyRichTextFields on ContentfulBlogPostBody {
    raw
    references {
      ... on ContentfulAccordion {
        contentful_id
        __typename
        ...contentfulAccordionFields
      }
      ... on ContentfulTable {
        contentful_id
        __typename
        ...contentfulTableFields
      }
      ... on ContentfulInfoBox {
        contentful_id
        __typename
        ...contentfulInfoBoxFields
      }
      ... on ContentfulCallToAction {
        contentful_id
        __typename
        ...contentfulCallToActionFields
      }
      ... on ContentfulAsset {
        contentful_id
        __typename
        title
        description
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
