import styled from 'styled-components';
import CardGridStyled from '../../../components/common/cardgrid/CardGrid.styled';
import Input from '../../../components/common/input/Input';
import InputStyled from '../../../components/common/input/Input.styled';
import SearchBarStyled from '../../../components/search/SearchBar.styled';
import Section from '../../../components/section/Section';
import { below, Breakpoint, breakpoints } from '../../../styles/breakpoints';

const ContentContainer = styled(Section)`
  padding: 0 0 5rem;
`;

type MainSectionProps = {
  centered?: boolean;
};

const MainSection = styled.div<MainSectionProps>`
  display: flex;
  flex-flow: column nowrap;
  padding-top: 3rem;
  grid-auto-flow: column;
  grid-area: content;

  ${CardGridStyled.CardGridHeader} {
    margin-top: 5rem;
  }

  ${(props) =>
    props.centered &&
    `
    grid-column-start: 1;
    grid-column-end: -1;
    ${breakpoints.md} {
      grid-column-start: 2;
      grid-column-end: -2;
    }
    ${breakpoints.lg} {
      grid-column-start: 3;
      grid-column-end: -3;
    }
  `}
`;

const MapSection = styled.div`
  padding-top: 1.5rem;

  grid-area: aside;

  ${below(Breakpoint.lg)} {
    display: none;
  }
`;

const MapSectionSticky = styled.div`
  padding-top: 1.5rem;
  grid-area: aside;
  > div {
    position: sticky;
    top: 1.5rem;
  }

  ${below(Breakpoint.lg)} {
    display: none;
  }
`;

const StyledInput = styled(Input)`
  ${InputStyled.Input} {
    border-radius: 100px;
    padding: calc(0.5rem - 1px) 1rem;
  }
`;

const PrimaryFilterDateOnly = styled(SearchBarStyled.SearchBar)`
  padding: 0.3rem 1rem;
  position: relative;
  height: unset;
  margin-top: 1.5rem;
  max-width: 200px;
  ${below(Breakpoint.sm)} {
    flex-direction: row;
  }  
`;

const PrimaryFilters = styled(SearchBarStyled.SearchBar)`
  padding: 0.3rem 1rem;
  position: relative;
  height: unset;
  margin-top: 1.5rem;
  max-width: 400px;
  ${below(Breakpoint.sm)} {
    flex-direction: row;
  }
  > div:first-child,
  > div:last-child {
    width: 50%;
    ${below(Breakpoint.sm)} {
      padding: 1rem 0;
      text-align: center;
    }
  }
`;

const Filters = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  margin-top: 2rem;
`;

const VerticalDivider = styled.div`
  height: 2.5rem;
  width: 1px;
  border-left: 1px solid #ddd;
`;

const LoadMoreButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  ContentContainer,
  MainSection,
  MapSection,
  MapSectionSticky,
  PrimaryFilters,
  PrimaryFilterDateOnly,
  Filters,
  Input: StyledInput,
  VerticalDivider,
  LoadMoreButtonContainer,
};
