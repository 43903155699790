import React, { ReactNode } from 'react';
import GoogleMapReact from 'google-map-react';
import Styled from './Map.styled';

export type CoordType = {
  lat: number;
  lng: number;
};

type MapSettingTypes = {
  defaultCenter?: CoordType;
  defaultZoom: number;
  yesIWantToUseGoogleMapApiInternals?: boolean;
  onGoogleApiLoaded?: ({ map }) => void;
  onChange?: ({ zoom, bounds }) => void;
};

type MapProps = {
  width?: string;
  height?: string;
  className?: string;
  children?: ReactNode;
  settings: MapSettingTypes;
};

const Map: React.FC<MapProps> = ({ settings, children, ...rest }) => {
  return (
    <Styled.Map {...rest}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY || '' }}
        {...settings}
      >
        {children}
      </GoogleMapReact>
    </Styled.Map>
  );
};

export default Map;
