import ThemeDefinitions from './themeDefs';

export enum Theme {
  Default = 'Default',
  Primary = 'Primary',
  PrimaryInverse = 'PrimaryInverse',
  Sustainability = 'Sustainability',
  Alert = 'Alert',
  LeviBlack = 'LeviBlack',
}

const themes = {
  [Theme.Default]: {
    ...ThemeDefinitions.defaultTheme,
  },
  [Theme.Alert]: {
    ...ThemeDefinitions.alertTheme,
  },
  [Theme.Sustainability]: {
    ...ThemeDefinitions.sustainabilityTheme,
  },
  [Theme.LeviBlack]: {
    ...ThemeDefinitions.leviBlackTheme,
  },
  [Theme.Primary]: {
    ...ThemeDefinitions.primaryTheme,
  },
  [Theme.PrimaryInverse]: {
    ...ThemeDefinitions.primaryThemeInverse,
  },
};

export default themes;

const keyToDashCase = (key: string) =>
  key.replace(/[A-Z]/g, '-$&').toLowerCase();

export const parseThemeDefs = (theme: Theme): string => {
  const themeDefs = themes[theme];
  const res = Object.keys(themeDefs).reduce((acc, key) => {
    const fixedKey = keyToDashCase(key);
    const styles = Object.keys(themeDefs[key])
      .map(
        (sub) => `--${fixedKey}-${keyToDashCase(sub)}: ${themeDefs[key][sub]};`,
      )
      .join('');
    return `${acc}${styles}`;
  }, '');
  return res;
};
