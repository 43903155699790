import React from 'react';
import Cookies from 'js-cookie';

/**
 * FORKED from https://www.npmjs.com/package/react-cookie-consent
 */

interface CookieConsentProps {
  cookieName?: string;
  cookieValue?: string | boolean | number;
  declineCookieValue?: string | boolean | number;
  hideOnAccept?: boolean;
  hideOnDecline?: boolean;
  onAccept?: () => void;
  onDecline?: () => void;
  setDeclineCookie?: boolean;
  extraCookieOptions?: { [key: string]: unknown };
  expires?: number;
  sameSite?: 'strict' | 'lax' | 'none';
  cookieSecurity?: boolean;
}

export const enum SAME_SITE_OPTIONS {
  STRICT = 'strict',
  LAX = 'lax',
  NONE = 'none',
}

/**
 * Returns the value of the consent cookie
 * Retrieves the regular value first and if not found the legacy one according
 * to: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
 * @param {string} name optional name of the cookie
 */
export const getCookieConsentValue = (name = defaultCookieConsentName) => {
  let cookieValue = Cookies.get(name);

  // if the cookieValue is undefined check for the legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(getLegacyCookieName(name));
  }
  return cookieValue;
};

/**
 * Reset the consent cookie
 * Remove the cookie on browser in order to allow user to change their consent
 * @param {string} name optional name of the cookie
 */
export const resetCookieConsentValue = (name = defaultCookieConsentName) => {
  Cookies.remove(name);
};

/**
 * Get the legacy cookie name by the regular cookie name
 * @param {string} name of cookie to get
 */
const getLegacyCookieName = (name: string) => {
  return `${name}-legacy`;
};

/**
 * Default name of the cookie which is set by CookieConsent
 */
const defaultCookieConsentName = 'cookie-consent';

const useCookieConsent = ({
  cookieName = defaultCookieConsentName,
  cookieValue = true,
  declineCookieValue = false,
  hideOnAccept = true,
  hideOnDecline = true,
  onAccept,
  onDecline,
  setDeclineCookie = true,
  extraCookieOptions = {},
  expires = 365,
  sameSite = SAME_SITE_OPTIONS.LAX,
  cookieSecurity,
}: CookieConsentProps) => {
  const [visible, setVisible] = React.useState(false);

  /**
   * Function to set the consent cookie based on the provided variables
   * Sets two cookies to handle incompatible browsers, more details:
   * https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
   */
  const setCookie = React.useCallback(
    (cookieName, cookieValue) => {
      let secure = cookieSecurity;
      if (secure === undefined) {
        secure = location ? location.protocol === 'https:' : true;
      }

      const cookieOptions = {
        expires,
        ...extraCookieOptions,
        sameSite,
        secure,
      };

      // Fallback for older browsers where can not set SameSite=None, SEE: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
      if (sameSite === SAME_SITE_OPTIONS.NONE) {
        Cookies.set(
          getLegacyCookieName(cookieName),
          cookieValue,
          cookieOptions,
        );
      }

      // set the regular cookie
      Cookies.set(cookieName, cookieValue, cookieOptions);
    },
    [cookieSecurity, expires, extraCookieOptions, sameSite],
  );

  /**
   * Set a persistent accept cookie
   */
  const accept = React.useCallback(() => {
    setCookie(cookieName, cookieValue);

    onAccept && onAccept();

    if (hideOnAccept) {
      setVisible(false);
    }
  }, [cookieName, cookieValue, hideOnAccept, onAccept, setCookie]);

  /**
   * Set a persistent decline cookie
   */
  const decline = React.useCallback(() => {
    if (setDeclineCookie) {
      setCookie(cookieName, declineCookieValue);
    }

    onDecline && onDecline();

    if (hideOnDecline) {
      setVisible(false);
    }
  }, [
    cookieName,
    declineCookieValue,
    hideOnDecline,
    onDecline,
    setCookie,
    setDeclineCookie,
  ]);

  /**
   * Returns the value of the consent cookie
   * Retrieves the regular value first and if not found the legacy one according
   * to: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
   */
  const getCookieValue = React.useCallback(() => {
    return getCookieConsentValue(cookieName);
  }, [cookieName]);

  React.useEffect(() => {
    if (getCookieValue() === undefined) {
      setVisible(true);
    }
  }, [getCookieValue]);

  return {
    accept,
    decline,
    visible,
  };
};

export default useCookieConsent;
export { Cookies };
