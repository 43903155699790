import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import { Typography } from '../typography/Typography.styled';

const InfoBox = styled(Typography.BodyLarge).attrs({ as: 'div' })`
  background: ${AppColor.Neutral200};
  padding: 1.5rem;

  * + & {
    margin-top: 1.5rem;
  }

  strong,
  b {
    ${FontRules.TradeGothicNext.Bold}

    font-size: 0.8125rem;
    line-height: 1.23;
    letter-spacing: 0.0625rem;

    ${breakpoints.lg} {
      font-size: 1rem;
      letter-spacing: 0.03125rem;
    }
  }
`;

const InfoBoxRow = styled.div`
  & + & {
    margin-top: 1.5rem;
  }
`;

export default {
  InfoBox,
  InfoBoxRow,
};
