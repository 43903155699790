import styled from 'styled-components';
import { above, Breakpoint } from '../../styles/breakpoints';

const InfoGIS = styled.div`
  grid-column: 3 / -3;
  width: 100%;
  padding-top: 138%;
  ${above(Breakpoint.sm)} {
    padding-top: 50%;
  }
  height: 0;
  position: relative;

  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default {
  InfoGIS,
};
