import React from 'react';
import Styled from './VideoPlayer.styled';
import Player, { Options } from '@vimeo/player';
import YouTube, { YouTubeProps } from 'react-youtube';
import { Typography } from '../typography/Typography.styled';

const youtubeIdRegExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|shorts\/)([^#\&\?]*).*/;
const vimeoIdRegExp = /(?:https?:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/;

type RequiredVideoProp = { videoUrl?: string; videoId?: string | number };
type VimeoPlayerProps = Omit<Options, 'responsive'> & RequiredVideoProp;

const VimeoPlayer = (props: VimeoPlayerProps): JSX.Element => {
  const id = React.useMemo(() => {
    if (props.videoId) 
      return typeof props.videoId === "string" ? parseInt(props.videoId) : props.videoId;

    const match = props.videoUrl?.match(vimeoIdRegExp);
    return match ? parseInt(match[1]) : 0;
  }, [props.videoId, props.videoUrl]);

  const videoID = React.useMemo(() => `vimeo-${id}`, [id]);

  React.useEffect(() => {
    const videoPlayer = new Player(videoID, { ...props, id, responsive: true });

    return () => {
      videoPlayer.destroy();
    };
  }, [videoID, props, id]);

  return <Styled.VimeoPlayer id={videoID} />;
};

// type RequiredVideoIdProp = Required<Pick<YouTubeProps, 'videoId'>>;
type YoutubePlayerProps = YouTubeProps & RequiredVideoProp;

const YoutubePlayer = (props: YoutubePlayerProps) => {
  const playerID = React.useMemo(
    () => `yt-${props.id || props.videoId}`,
    [props.id, props.videoId],
  );

  const videoId = React.useMemo(() => {
    if (props.videoId) return props.videoId;

    const match = props.videoUrl?.match(youtubeIdRegExp);
    return match && match[2].length === 11 ? match[2] : '';
  }, [props.videoId, props.videoUrl])

  return (
    <Styled.YoutubePlayer id={playerID}>
      <YouTube
        {...props}
        videoId={videoId}
        opts={{ width: '878', height: '494' }}
        containerClassName="youtubeContainer"
      />
    </Styled.YoutubePlayer>
  );
};

export type VideoPlayerProps = {
  headline: string;
  vimeoUrl?: string;
  vimeoId?: string | number;
  youtubeUrl?: string;
  youtubeId?: string;
};

export const VideoPlayer = (props: VideoPlayerProps): JSX.Element => {
  return (
    <Styled.PlayerContainer>
      {props.headline && (
        <Typography.HeadingLarge>{props.headline}</Typography.HeadingLarge>
      )}
      {(props.vimeoUrl || props.vimeoId) && <VimeoPlayer videoId={props.vimeoId} videoUrl={props.vimeoUrl} />}
      {(props.youtubeUrl || props.youtubeId) && <YoutubePlayer videoId={props.youtubeId} videoUrl={props.youtubeUrl} />}
    </Styled.PlayerContainer>
  );
};

export default VideoPlayer;
