import React from 'react';
import { Typography } from '../typography/Typography.styled';
import { CardNoPriceInfoProps } from './types';
import Styled from './Card.styled';

const CardNoPriceInfo = ({ info }: CardNoPriceInfoProps): JSX.Element => (
  <Styled.CardPriceInfo>
    <Typography.SubLink>{info}</Typography.SubLink>
  </Styled.CardPriceInfo>
);

export default CardNoPriceInfo;
