import React from 'react';
import { Lift } from '../../network/skiresort-api/types/LiftDataTypes';
import { RowProps } from './SkiResortAmenitiesList';
import Styled from './SkiResortAmenities.styled';
import RowStatusIcon from './RowStatusIcon';

const LiftRow = ({ data, locale }: RowProps<Lift>) => {
  const { name, comment, open, statusIcon, identifier, symbol } = data;
  return (
    <Styled.Row>
      <Styled.LiftIcon src={symbol.url} alt="Lift icon" />
      {identifier !== '⋆' && (
        <Styled.LiftIdentifier>{identifier}</Styled.LiftIdentifier>
      )}
      <Styled.Info>{name[locale]}</Styled.Info>
      <Styled.StatusCell>
        <Styled.Info>{open.hours}</Styled.Info>
        <RowStatusIcon {...statusIcon} />
      </Styled.StatusCell>
      {comment?.[locale] && (
        <Styled.AdditionalInfo>{comment[locale]}</Styled.AdditionalInfo>
      )}
      {open?.reasonForClosed?.[locale] && (
        <Styled.AdditionalInfo>
          {open.reasonForClosed[locale]}
        </Styled.AdditionalInfo>
      )}
    </Styled.Row>
  );
};

export default LiftRow;
