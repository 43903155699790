import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulCardTypes } from './types';
import { Icons } from '../../types/icon-types';
import CategorizedCardGrid from '../../components/common/cardgrid/CategorizedCardGrid';
import useContentfulCategoryData from '../../hooks/useContentfulCategoryData';
import { ContentfulCallToActionProps } from '../callToActions/contentfulCallToAction';

export type ContentfulCategory = {
  title: string;
  content: ContentfulCardTypes[];
  icon?: Icons;
};

export type ContentfulCategoriesTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  allCategoryTitle: string;
  categories: ContentfulCategory[];
  callToAction?: ContentfulCallToActionProps;
};

const ContentfulCategories = (data: ContentfulCategoriesTypes): JSX.Element => {
  const { title, allCategoryTitle, callToAction } = data;
  const contentfulCategoryData = {
    title,
    categories: data.categories || [],
  };
  const { cards, categories } = useContentfulCategoryData(
    contentfulCategoryData,
  );

  return (
    <CategorizedCardGrid
      allCategoryTitle={allCategoryTitle}
      title={title}
      categories={categories}
      cards={cards}
      callToAction={callToAction}
      limitCount={6}
    />
  );
};

export default ContentfulCategories;

export const contentfulCategoriesQuery = graphql`
  fragment contentfulCategoriesFields on ContentfulCategories {
    id
    internal {
      type
    }
    title
    allCategoryTitle
    categories {
      ...contentfulCategoryFields
    }
    callToAction {
      ...contentfulCallToActionFields
    }
  }
  fragment contentfulCategoryFields on ContentfulCategory {
    title
    icon
    content {
      ...contentfulCardFields
      image {
        ...contentfulCardImage
      }
    }
  }
`;
