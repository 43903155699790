import React from 'react';
import { FilteredDataInput } from './useFilteredData';
import { SortedDataInput, SortOrder } from './useSortedData';
import useSortedFilteredData from './useSortedFilteredData';

export type PaginatedDataOutput<T> = {
  dataSlice: T[];
  maxItems: number;
};

type InputType<T extends Record<string, unknown>> = SortedDataInput<T> &
  Pick<FilteredDataInput<T>, 'filters'> & {
    initialOffset: number;
    itemLimit: number;
  };

type OutputType<T extends Record<string, unknown>> = PaginatedDataOutput<T> & {
  reSort: (setSortOrder: SortOrder) => void;
  fetchMore: () => void;
  hasMore: boolean;
};

const usePagedData = <T extends Record<string, unknown>>({
  data,
  sortBy,
  initialSortOrder,
  filters,
  initialOffset = 0,
  itemLimit = 20,
}: InputType<T>): OutputType<T> => {
  // the resulting slice of filtered and sorted data set
  const [dataSlice, setDataSlice] = React.useState<T[]>([]);
  // maximum number of items in the list i.e. size of filtered data set
  const [maxItems, setMaxItems] = React.useState<number>(0);
  // maximum limit of items in the next batch which is either itemLimit prop or remaining count of items in filtered data set
  const [currentItemLimit, setCurrentItemLimit] = React.useState<number>(0);
  // current size of dataSlice which grows up to maxItems on consequtive fetches, offset of the next slice in filtered data set
  const [currentOffset, setCurrentOffset] = React.useState<number>(0);
  // flag telling if the dataslice has been initialized 
  const [initialized, setInitialized] = React.useState<boolean>(false);
  // flag telling when new data should be fetched -> triggers dataSlice refresh effect
  const [fetching, setFetching] = React.useState<boolean>(false);
  // flag telling when sorting should be changed -> triggers dataSlice refresh effect
  const [sort, setSort] = React.useState<boolean>(false);

  // callback used outside to trigger fetching more items
  const fetchMore = () => {
    setFetching(true);
  };

  const { filteredData, setSortOrder } = useSortedFilteredData({
    data,
    sortBy,
    initialSortOrder,
    filters,
  });

  // callback used outside to trigger resorting of filtered data set
  const reSort = React.useCallback(
    (sortOrder: SortOrder) => {
      setSortOrder(sortOrder);
      setSort(true);
    },
    [setSortOrder],
  );

  React.useEffect(() => {
    if (!initialized) {
      // slice was never initialized, reset everything and start fetching
      setCurrentItemLimit(itemLimit);
      setCurrentOffset(initialOffset);
      setInitialized(true);
      setFetching(true);
    }

    if (fetching && dataSlice.length === filteredData.length) {
      // there is nothing more to fetch, stop fetching
      setFetching(false);
    }

    if (initialized && filteredData.length === 0 && maxItems > 0) {
      // change in filters removed all matches but list contains some, reset all
      setCurrentOffset(initialOffset);
      setCurrentItemLimit(itemLimit);
      setDataSlice([]);
      setMaxItems(0);
      setFetching(false);
    }

    if (
      initialized &&
      filteredData.length > 0 &&
      maxItems === 0 &&
      currentOffset === 0
    ) {
      // after initialization nothing has been loaded but filtered set contains items, start fetching
      setFetching(true);
    }

    if (initialized && filteredData.length !== maxItems && currentOffset > 0) {
      // this one I don't get at all. t. Mikko
      setCurrentOffset(initialOffset);
      setCurrentItemLimit(itemLimit);
      setDataSlice([]);
      setMaxItems(0);
      setFetching(true);
    }

    // this is a "jeesusteippi"-fix. dataSlice.length below is incorrect due to state not updating in sync.
    // using a reducer, zustand or some other solution would be better than setState all over the place
    let temp = 0;
    if (fetching && dataSlice.length < filteredData.length) {
      const batch = filteredData.slice(
        currentOffset,
        currentItemLimit + currentOffset,
      );

      const slice = [...dataSlice, ...batch];
      temp = slice.length;
      setDataSlice([...slice]);
      setMaxItems(filteredData.length);
      setCurrentItemLimit(Math.min(currentItemLimit, filteredData.length));
      setCurrentOffset(currentOffset + currentItemLimit);
      setFetching(false);
    }

    if (sort) {
      // filtered data set was resorted from outside 
      const batch = filteredData.slice(0, temp > 0 ? temp : dataSlice.length);
      setDataSlice(batch);
      setSort(false);
    }
  }, [
    currentItemLimit,
    currentOffset,
    dataSlice,
    fetching,
    filteredData,
    filters,
    initialized,
    itemLimit,
    maxItems,
    initialOffset,
    sort,
  ]);

  return {
    dataSlice,
    maxItems,
    reSort,
    fetchMore,
    hasMore: dataSlice.length < filteredData.length,
  };
};

export default usePagedData;
