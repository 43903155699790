import React from 'react';
import Styled from './InfoBox.styled';

type InfoItem = {
  title?: string;
  body: string;
};

export type InfoBoxProps = {
  items?: InfoItem[];
  html: string;
};
/*
const renderItem = ({ title, body }: InfoItem, index: number) => (
  <Styled.InfoBoxRow key={index}>
    {title && <Typography.LabelBig>{title}</Typography.LabelBig>}
    <Typography.BodyLarge as="div" dangerouslySetInnerHTML={{ __html: body }} />
  </Styled.InfoBoxRow>
);
*/

const InfoBox = ({ html }: InfoBoxProps): JSX.Element => (
  <Styled.InfoBox>{html}</Styled.InfoBox>
);

export default InfoBox;
