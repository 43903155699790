import React from 'react';
import { Typography } from '../../common/typography/Typography.styled';
import Styled from './TextContent.styled';

export type TextContentProps = {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  leftAligned?: boolean;
};

type ChildType = React.ReactChild | React.ReactFragment | React.ReactPortal;
type ChildAccumulator = {
  main: ChildType[];
  aside: ChildType[] | undefined;
};

const AsideContentTypes = ['InfoBox', 'ContentfulInfoBox'];

const isAsideContentChild = (child: ChildType) =>
  React.isValidElement(child) &&
  typeof child !== 'string' &&
  child.type &&
  typeof child.type !== 'string' &&
  (AsideContentTypes.includes(child.type.name) ||
    (child.props.__typename &&
      AsideContentTypes.includes(child.props.__typename)));

const TextContent: React.FC<TextContentProps> = ({
  title,
  subtitle,
  children,
  leftAligned,
}) => {
  const { main, aside } = React.useMemo(
    () =>
      React.Children.toArray(children).reduce(
        (acc: ChildAccumulator, child) => {
          if (isAsideContentChild(child)) {
            acc.aside = acc.aside || [];
            acc.aside = [...acc.aside, child];
          } else {
            acc.main = [...acc.main, child];
          }
          return acc;
        },
        { main: [], aside: undefined },
      ),
    [children],
  );

  return (
    <>
      <Styled.TextContent
        leftAligned={leftAligned || !!aside}
        narrow={!(subtitle || title)}
      >
        {(subtitle || title) && (
          <Styled.TextContentHeader>
            {subtitle && (
              <Typography.SubHeadingSmall>
                {subtitle}
              </Typography.SubHeadingSmall>
            )}
            {title && <Typography.Display>{title}</Typography.Display>}
          </Styled.TextContentHeader>
        )}
        {main}
      </Styled.TextContent>
      {aside && <Styled.Aside>{aside}</Styled.Aside>}
    </>
  );
};

export default TextContent;
