export enum ImageIcons {
  ImageInfoAdventurePark = 'icons/adventurepark.png',
  ImageInfoBaseJump = 'icons/basejump.png',
  ImageInfoBikeTrails = 'icons/bike-trail.png',
  ImageInfoCamera = 'icons/camera.png',
  ImageInfoEatAndDrink = 'icons/eat-and-drink.png',
  ImageInfoLiftsOpen = 'icons/lifts-open.png',
  ImageInfoSkiingTracks = 'icons/skiing-tracks.png',
  ImageInfoSledgeTracks = 'icons/sledge-track.png',
  ImageInfoSlopesOpen = 'icons/slopes-open.png',
  ImageInfoSnowflake = 'icons/snowflake.png',
  ImageInfoSnowmaking = 'icons/snowmaking-ongoing.png',
  ImageInfoSnowmobileTracks = 'icons/snowmobile-tracks.png',
  ImageInfoTrafficPark = 'icons/traffic_park.png',
  ImageInfoTrampoline = 'icons/trampoline.png',
  ImageInfoWalkingTrails = 'icons/walking-trails.png',
  ImageInfoZipline = 'icons/zipline.png',
}

type Keys = keyof typeof ImageIcons;

type ImageIconIndex = {
  [key in Keys as ImageIcons]: typeof ImageIcons[key];
};

export const ImageIconAssets: ImageIconIndex = {
  [ImageIcons.ImageInfoAdventurePark]: ImageIcons.ImageInfoAdventurePark,
  [ImageIcons.ImageInfoBaseJump]: ImageIcons.ImageInfoBaseJump,
  [ImageIcons.ImageInfoBikeTrails]: ImageIcons.ImageInfoBikeTrails,
  [ImageIcons.ImageInfoCamera]: ImageIcons.ImageInfoCamera,
  [ImageIcons.ImageInfoEatAndDrink]: ImageIcons.ImageInfoEatAndDrink,
  [ImageIcons.ImageInfoLiftsOpen]: ImageIcons.ImageInfoLiftsOpen,
  [ImageIcons.ImageInfoSkiingTracks]: ImageIcons.ImageInfoSkiingTracks,
  [ImageIcons.ImageInfoSledgeTracks]: ImageIcons.ImageInfoSledgeTracks,
  [ImageIcons.ImageInfoSlopesOpen]: ImageIcons.ImageInfoSlopesOpen,
  [ImageIcons.ImageInfoSnowflake]: ImageIcons.ImageInfoSnowflake,
  [ImageIcons.ImageInfoSnowmaking]: ImageIcons.ImageInfoSnowmaking,
  [ImageIcons.ImageInfoSnowmobileTracks]: ImageIcons.ImageInfoSnowmobileTracks,
  [ImageIcons.ImageInfoTrafficPark]: ImageIcons.ImageInfoTrafficPark,
  [ImageIcons.ImageInfoTrampoline]: ImageIcons.ImageInfoTrampoline,
  [ImageIcons.ImageInfoWalkingTrails]: ImageIcons.ImageInfoWalkingTrails,
  [ImageIcons.ImageInfoZipline]: ImageIcons.ImageInfoZipline,
};
