import { ApiPath } from '../network/skiresort-api';
import { ApiDataParser } from '../network/skiresort-api/parsers/ApiDataParser';
import useFilteredData, { FilterFunc } from './useFilteredData';
import { useSkiResortDataByApiPath } from './useSkiResortDataByApiPath';

export enum SkiResortAmenityType {
  BikeTrail = 'bike trails',
  Lifts = 'lifts',
  OtherActivities = 'other activities',
  Restaurants = 'restaurants',
  Services = 'services',
  Slopes = 'slopes',
}

export type SkiResortAmenitiesData = {
  [SkiResortAmenityType.Lifts]:
    | ReturnType<ApiDataParser<ApiPath.Lifts>>
    | undefined;
  [SkiResortAmenityType.OtherActivities]:
    | ReturnType<ApiDataParser<ApiPath.OtherActivities>>
    | undefined;
  [SkiResortAmenityType.Restaurants]:
    | ReturnType<ApiDataParser<ApiPath.Restaurants>>
    | undefined;
  [SkiResortAmenityType.Services]:
    | ReturnType<ApiDataParser<ApiPath.Services>>
    | undefined;
  [SkiResortAmenityType.Slopes]:
    | ReturnType<ApiDataParser<ApiPath.Slopes>>
    | undefined;
  [SkiResortAmenityType.BikeTrail]:
    | ReturnType<ApiDataParser<ApiPath.BikeTrails>>
    | undefined;
};

const useSkiResortAmenitiesData = (
  types: SkiResortAmenityType | SkiResortAmenityType[],
  filters: FilterFunc[] = [],
) => {
  const typesAsArray = Array.isArray(types) ? types : [types];

  const data: SkiResortAmenitiesData = {
    [SkiResortAmenityType.Lifts]: useSkiResortDataByApiPath(
      ApiPath.Lifts,
      {},
      typesAsArray.includes(SkiResortAmenityType.Lifts),
    ),
    [SkiResortAmenityType.Slopes]: useSkiResortDataByApiPath(
      ApiPath.Slopes,
      {},
      typesAsArray.includes(SkiResortAmenityType.Slopes),
    ),
    [SkiResortAmenityType.Services]: useSkiResortDataByApiPath(
      ApiPath.Services,
      {},
      typesAsArray.includes(SkiResortAmenityType.Services),
    ),
    [SkiResortAmenityType.Restaurants]: useSkiResortDataByApiPath(
      ApiPath.Restaurants,
      {},
      typesAsArray.includes(SkiResortAmenityType.Restaurants),
    ),
    [SkiResortAmenityType.BikeTrail]: useSkiResortDataByApiPath(
      ApiPath.BikeTrails,
      {},
      typesAsArray.includes(SkiResortAmenityType.BikeTrail),
    ),
    [SkiResortAmenityType.OtherActivities]: useSkiResortDataByApiPath(
      ApiPath.OtherActivities,
      {},
      typesAsArray.includes(SkiResortAmenityType.OtherActivities)
    )
  };

  const filteredData = {
    [SkiResortAmenityType.Lifts]: useFilteredData({
      data: data.lifts || [],
      filters,
    }).filteredData,
    [SkiResortAmenityType.Slopes]: useFilteredData({
      data: data.slopes || [],
      filters,
    }).filteredData,
    [SkiResortAmenityType.Services]: useFilteredData({
      data: data.services || [],
      filters,
    }).filteredData,
    [SkiResortAmenityType.Restaurants]: useFilteredData({
      data: data.restaurants || [],
      filters,
    }).filteredData,
    [SkiResortAmenityType.BikeTrail]: useFilteredData({
      data: data[SkiResortAmenityType.BikeTrail] || [],
      filters,
    }).filteredData,
    [SkiResortAmenityType.OtherActivities]: useFilteredData({
      data: data[SkiResortAmenityType.OtherActivities] || [],
      filters,
    }).filteredData,
  };

  return typesAsArray.reduce(
    (acc, curr) => ({ ...acc, [curr]: filteredData[curr] }),
    {} as SkiResortAmenitiesData,
  );
};

export default useSkiResortAmenitiesData;
