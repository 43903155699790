import React from 'react';
import { Typography } from '../common/typography/Typography.styled';
import Styled from './HighlightWithRelatedListing.styled';
import useCarouselIndex from '../../hooks/useCarouselIndex';
import SecondaryTabs from '../common/tabs/SecondaryTabs';
import CardGrid from '../common/cardgrid/CardGrid';
import StaticAccordionListItem from '../common/accordionlisting/StaticAccordionListItem';
import StaticAccordionList from '../common/accordionlisting/StaticAccordionList';
import { HighlightWithRelatedListingProps } from './HighlightWithRelatedListing';

const MobileHighlightWithRelatedListing = ({
  title,
  items = [],
}: HighlightWithRelatedListingProps): JSX.Element => {
  const { activeIndex, set } = useCarouselIndex(items.length);
  return (
    <>
      <Styled.GridHeader>
        <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
      </Styled.GridHeader>
      <StaticAccordionList>
        <SecondaryTabs
          items={items}
          selectedIndex={activeIndex}
          onSelect={set}
        />
        <StaticAccordionListItem {...items[activeIndex]} />
        <CardGrid cards={items[activeIndex].relatedCards} />
      </StaticAccordionList>
    </>
  );
};

export default MobileHighlightWithRelatedListing;
