import styled from 'styled-components';
import { AppColor } from '../../../../styles/colors';
import transitions from '../../../../styles/transitions';
import { Typography } from '../../../common/typography/Typography.styled';

type SheetProps = {
  open?: boolean;
};

const NavigationSheet = styled.div<SheetProps>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  min-height: 100vh;
  max-width: 480px;
  background: ${AppColor.White};
  transform: translate3d(100%, 0, 0);
  transform-style: flat;
  backface-visibitilty: hidden;
  color: ${AppColor.LeviBlue};
  z-index: 1;
  margin: 0;

  display: grid;
  grid-template-rows: 5rem calc(100vh - 5rem);

  &.open {
    transform: translate3d(0%, 0, 0);
    pointer-events: all;
  }

  ${transitions.NavigationSheet}
`;

const NavigationSheetContent = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 2.5rem 0 10rem;
  position: relative;

  .flex & {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

type NavigationProps = { sub?: boolean };

const NavigationSheetHeader = styled.header<NavigationProps>`
  color: ${AppColor.LeviBlue};
  display: flex;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem 1rem;
  gap: 1.5rem;
  text-align: center;

  ${Typography.NavigationLink} {
    padding: 0 0.5rem;
  }

  ${Typography.SubHeadingLarge} {
    color: ${AppColor.Neutral600};
    align-self: flex-start;
    padding-top: 1.35rem;
  }

  ${(props) =>
    props.sub &&
    `
    border-bottom: 1px solid ${AppColor.Neutral300};
    margin-bottom: 2.8125rem;
  `}
`;

const NavigationUl = styled.ul`
  padding-left: 1.5rem;
  border-bottom: 1px solid ${AppColor.Neutral300};
  text-align: left;

  & + & {
    margin-top: 2rem;
  }

  &:last-of-type {
    border-bottom: unset;
  }
`;

const NavigationLi = styled.li`
  border-top: 1px solid ${AppColor.Neutral300};
  display: flex;
`;

const NavigationSearchLi = styled.li`
  display: flex;
  gap: 0.625rem;
`;

const SubNavigationLi = styled.li`
  margin-bottom: 3rem;

  & & {
    margin-bottom: 1.375rem;
  }
`;

const SubNavigationUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0 1.5rem;

  > ${SubNavigationLi} {
    width: 50%;

    &:nth-of-type(odd) {
      padding-right: 0.5rem;
    }
    &:nth-of-type(even) {
      padding-left: 0.5rem;
    }
  }
`;

const SubNavigationSecondLevelUl = styled.ul`
  margin-top: 1rem;
`;

const NavigationLink = styled(Typography.MainNavigationLinkMobile)`
  flex-grow: 1;
  padding: 14.5px 0 10.5px 0;
`;

const SubMenuButton = styled.button`
  width: 3.75rem;
  background-color: ${AppColor.LeviBlue};
  color: ${AppColor.White};
  border: 0;
  padding: 0;
  margin-bottom: -1px;
  border-bottom: 1px solid #0076aa;
`;

export default {
  NavigationSheet,
  NavigationSheetHeader,
  NavigationSheetContent,
  NavigationUl,
  NavigationLi,
  NavigationSearchLi,
  NavigationLink,
  SubMenuButton,
  SubNavigationUl,
  SubNavigationSecondLevelUl,
  SubNavigationLi,
};
