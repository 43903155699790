import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { GridElement } from '../layout/Grid.styled';
import GridHeader from '../layout/GridHeader';

const CardListHeader = styled(GridHeader)`
  ${breakpoints.sm} {
    grid-column-end: unset;
    grid-column: span 6;
  }
`;

const CardListFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3rem 0 0;
`;

const CardList = styled(GridElement)`
  display: flex;
  flex-direction: column;
  ${breakpoints.sm} {
    grid-column: span 6;
  }
`;

export default {
  CardList,
  CardListHeader,
  CardListFooter,
};
