import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { ContentfulGatsbyImage } from '../../types/images';
import Styled from './Gallery.styled';
import useSlide from './useSlide';
import Icon, { IconSize } from '../common/icon/Icon';
import { Icons } from '../../types/icon-types';
import { Theme } from '../../styles/themes';
import useBreakpoints from '../../hooks/useBreakpoints';
import useNavigationSheet from '../../hooks/useNavigationSheet';
import { localize } from '../../localization/i18n';

type GalleryProps = {
  items: ContentfulGatsbyImage[] | string[]; // only images for now
};

const Gallery: React.FC<GalleryProps> = ({ items }) => {
  const { isMobile } = useBreakpoints();

  if (isMobile) {
    return <MobileGallery items={items} />;
  }

  return <FullScreenGallery items={items} />;
};

const GalleryGatsbyImage = ({ item }: { item: ContentfulGatsbyImage }) => {
  const image = getImage(item.image);
  if (!image) return null;
  return (
    <GatsbyImage
      image={image}
      alt={item.description || item.title || ''}
      objectFit="contain"
    />
  );
};

const GalleryImage = ({ item }: { item: string }) => {
  return <Styled.AccommodationImage src={item} alt={''} />;
};

const isValidGatsbyImage = (
  item: ContentfulGatsbyImage | string,
): item is ContentfulGatsbyImage => {
  return (
    typeof item !== 'string' &&
    !!getImage((item as ContentfulGatsbyImage).image)
  );
};

const GallerySlides = ({
  items,
  onClose,
}: GalleryProps & { onClose?: () => void }) => {
  const { onNext, onPrevious, selectedIndex } = useSlide({
    slideAmount: items.length,
  });

  return (
    <>
      <Styled.SlidesContainer>
        {items.map((item) => {
          if (isValidGatsbyImage(item)) {
            return (
              <Styled.Slide key={item.id} distance={`${selectedIndex * -100}%`}>
                <Styled.ImageContainer>
                  <GalleryGatsbyImage item={item} />
                </Styled.ImageContainer>
              </Styled.Slide>
            );
          } else {
            if (!!item) {
              return (
                <Styled.Slide key={item} distance={`${selectedIndex * -100}%`}>
                  <Styled.ImageContainer>
                    <GalleryImage item={item} />
                  </Styled.ImageContainer>
                </Styled.Slide>
              );
            }
            return null;
          }
        })}
      </Styled.SlidesContainer>
      {onClose && (
        <Styled.NavElement positionX="right" positionY="top" onClick={onClose}>
          <Icon type={Icons.Close} />
        </Styled.NavElement>
      )}
      <Styled.NavElement positionX="left" onClick={onPrevious}>
        <Icon type={Icons.ChevronLeftLarge} />
      </Styled.NavElement>
      <Styled.NavElement positionX="right" onClick={onNext}>
        <Icon type={Icons.ChevronRightLarge} />
      </Styled.NavElement>
    </>
  );
};

const MobileGallery = ({ items }: GalleryProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const {
    actions: { open, close },
  } = useNavigationSheet(ref);

  return (
    <>
      <Styled.OpenButton onClick={open} theme={Theme.PrimaryInverse}>
        <Icon type={Icons.Camera} size={IconSize.Size24} />
        {`${localize('gallery.show')} (${items.length})`}
      </Styled.OpenButton>
      <Styled.MobileGalleryNavigationSheet
        ref={ref}
        headerContent={
          <>
            <Icon type={Icons.Close} size={IconSize.Size32} onClick={close} />
          </>
        }
      >
        <GallerySlides items={items} />
      </Styled.MobileGalleryNavigationSheet>
    </>
  );
};

const FullScreenGallery = ({ items }: GalleryProps) => {
  const fullScreenHandler = useFullScreenHandle();

  return (
    <>
      <Styled.OpenButton
        onClick={fullScreenHandler.enter}
        theme={Theme.PrimaryInverse}
      >
        <Icon type={Icons.Camera} size={IconSize.Size24} />
        {`${localize('gallery.show')} (${items.length})`}
      </Styled.OpenButton>
      <FullScreen handle={fullScreenHandler}>
        {fullScreenHandler.active && (
          <GallerySlides items={items} onClose={fullScreenHandler.exit} />
        )}
      </FullScreen>
    </>
  );
};

export default Gallery;
