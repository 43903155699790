import React from 'react';
import { graphql } from 'gatsby';
import CardGrid from '../../components/common/cardgrid/CardGrid';
import CardGridHeader from '../../components/common/cardgrid/CardGridHeader';
import { ContentfulCardTypes } from './types';
import useContentfulCardGridData from '../../hooks/useContentfulCardGridData';
import useContentfulTransformedCardGridData from '../../hooks/useContentfulTransformedCardGridData';

export type ContentfulCardGridTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  subtitle: string;
  items: ContentfulCardTypes[];
};

const ContentfulCardGrid = ({
  title,
  subtitle,
  items = [],
}: ContentfulCardGridTypes): JSX.Element => {
  const { cards, type } = useContentfulCardGridData({ items });

  const transformedCards = useContentfulTransformedCardGridData({
    cards,
    type,
  });
  return (
    <>
      {title && <CardGridHeader title={title} subtitle={subtitle} centered />}
      <CardGrid cards={transformedCards} />
    </>
  );
};

export default ContentfulCardGrid;

export const contentfulCardGridQuery = graphql`
  fragment contentfulCardGridFields on ContentfulCardGrid {
    id
    internal {
      type
    }
    title
    subtitle
    items: cards {
      ...contentfulVerticalCardFields
      ...eventCard
      ...contentfulRestaurantCardFields
      ...contentfulCourseCardFields
      ...contentfulRentalCardFields
      ...contentfulServiceCardFields
      ...blogPostCardFields
    }
  }
  fragment contentfulVerticalCardFields on ContentfulCard {
    ...contentfulCardFields
    image {
      ...contentfulCardImage
    }
  }
  fragment contentfulCardFields on ContentfulCard {
    id
    title
    description: body {
      text: body
    }
    callToAction {
      ...contentfulCallToActionFields
    }
    leviBlackCard
    internal {
      type
    }
    hideFromLocalization
  }
`;
