import { addDays, isAfter, isBefore, isSameDay, max, min } from 'date-fns';
import {
  isFirstInHoverRange,
  isFirstInRange,
  isInHoverRange,
  isInRange as isInSelectedRange,
  isLastInHoverRange,
  isLastInRange,
  isOneOfDates,
  isSelected as isSelectedDay,
  isSelectedInRange,
  isValidDateRange,
} from '../../../utils/date-utils';
import { CalendarState, Days } from './calendarStore';

const resolveMaxSelectableDate = (state: CalendarState): Date | undefined => {
  const {
    disabledDates = [],
    maxDate,
    maxRange,
    selectedDateRange: { start, end },
  } = state;

  const compareMaxRange =
    start && !end && maxRange ? [addDays(start, maxRange - 1)] : undefined;

  const compareAfter =
    start && !end && disabledDates.length > 0
      ? disabledDates.filter((date) => isAfter(date, start))
      : undefined;

  const compareMax = [
    ...(maxDate ? [maxDate] : []),
    ...(compareMaxRange ? compareMaxRange : []),
    ...(compareAfter ? compareAfter : []),
  ];
  return compareMax.length > 0 ? min(compareMax) : undefined;
};

const resolveMinSelectableDate = (state: CalendarState): Date | undefined => {
  const {
    disabledDates = [],
    maxRange,
    minDate,
    selectedDateRange: { start, end },
    today,
  } = state;

  const compareBase: Date[] = [today, ...(minDate ? [minDate] : [])];

  const compareMaxRange =
    start && !end && maxRange ? [addDays(start, -(maxRange - 1))] : undefined;

  const compareBefore =
    start && !end && disabledDates.length > 0
      ? disabledDates.filter((date) => isBefore(date, start))
      : undefined;

  const compareMin = [
    ...compareBase,
    ...(compareMaxRange ? compareMaxRange : []),
    ...(compareBefore ? compareBefore : []),
  ];
  return compareMin.length > 0 ? max(compareMin) : undefined;
};

const resolveBlockedDates = (state: CalendarState): Date[] => {
  const { disabledDates = [] } = state;
  const maxSelectableDate = resolveMaxSelectableDate(state);
  const minSelectableDate = resolveMinSelectableDate(state);
  return state.days
    .map(({ date }) => date)
    .filter(
      (date) =>
        isOneOfDates(date, disabledDates) ||
        (minSelectableDate && isBefore(date, minSelectableDate)) ||
        (maxSelectableDate && isAfter(date, maxSelectableDate)),
    );
};

const resolveDayRangeStates = (state: CalendarState): Days => {
  const {
    activeDates = [],
    allowRangeSelection,
    days,
    selectedDateRange,
  } = state;

  if (!allowRangeSelection) {
    throw new Error('Invalid CalendarState for Day Range');
  }

  const blockedDates = resolveBlockedDates(state);
  const isValidRange =
    isValidDateRange(selectedDateRange) &&
    !isSameDay(selectedDateRange.start, selectedDateRange.end);

  return days.map((day) => {
    const isActive = isOneOfDates(day.date, activeDates);
    const isDisabled = isOneOfDates(day.date, blockedDates);
    const isSelected =
      !isDisabled && isSelectedInRange(day.date, selectedDateRange);
    const isFirst =
      !isDisabled &&
      isValidRange &&
      isFirstInRange(day.date, selectedDateRange);
    const isLast =
      !isDisabled && isValidRange && isLastInRange(day.date, selectedDateRange);
    const isInRange =
      !isDisabled &&
      !isFirst &&
      !isLast &&
      isValidRange &&
      isInSelectedRange(day.date, selectedDateRange);

    return {
      ...day,
      isActive,
      isDisabled,
      isSelected,
      isFirst,
      isLast,
      isInRange,
    };
  });
};

const resolveSingleDayStates = (state: CalendarState): Days => {
  const { activeDates = [], allowRangeSelection, days, selectedDate } = state;

  if (allowRangeSelection) {
    throw new Error('Invalid CalendarState for Single Day');
  }

  const blockedDates = resolveBlockedDates(state);

  return days.map((day) => {
    const isActive = isOneOfDates(day.date, activeDates);
    const isDisabled = isOneOfDates(day.date, blockedDates);
    const isSelected = selectedDate
      ? isSelectedDay(day.date, selectedDate)
      : false;

    return {
      ...day,
      isActive,
      isDisabled,
      isSelected,
      isFirst: false,
      isLast: false,
      isInRange: false,
    };
  });
};

export const resolveDayStates = (state: CalendarState): Days => {
  const { allowRangeSelection } = state;

  return allowRangeSelection
    ? resolveDayRangeStates(state)
    : resolveSingleDayStates(state);
};

export const resolveDayHoverStates = (state: CalendarState): Days => {
  const { days, hoverDate, selectedDateRange } = state;

  const isValidRange = isValidDateRange(selectedDateRange);
  if (!hoverDate || isValidRange || !selectedDateRange.start) {
    return days;
  }

  return days.map((day) => {
    const isFirst = isFirstInHoverRange(day.date, hoverDate, selectedDateRange);
    const isLast = isLastInHoverRange(day.date, hoverDate, selectedDateRange);
    const isInRange =
      !isFirst &&
      !isLast &&
      isInHoverRange(day.date, hoverDate, selectedDateRange);

    return {
      ...day,
      isFirst,
      isLast,
      isInRange,
    };
  });
};
