import React from 'react';
import { Typography } from '../common/typography/Typography.styled';
import Icon from '../common/icon/Icon';
import { Icons } from '../../types/icon-types';
import Styled from './HighlightWithRelatedListing.styled';
import useCarouselIndex from '../../hooks/useCarouselIndex';
import HighlightItem from './HighlightItem';
import { HighlightWithRelatedListingProps } from './HighlightWithRelatedListing';

const DesktopHighlightWithRelatedListing = ({
  title,
  items = [],
}: HighlightWithRelatedListingProps): JSX.Element => {
  const { activeIndex, prev, next } = useCarouselIndex(items.length);

  return (
    <>
      <Styled.GridHeader>
        <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
        <Styled.ActionsContainer>
          <button onClick={prev} disabled={activeIndex === 0}>
            <Icon type={Icons.ArrowLeftLarge} />
          </button>
          <button onClick={next} disabled={activeIndex === items.length - 1}>
            <Icon type={Icons.ArrowRightLarge} />
          </button>
        </Styled.ActionsContainer>
      </Styled.GridHeader>
      <Styled.ContentContainer>
        <Styled.ItemTrack
          style={{
            transform: `translate3d(${
              activeIndex > 0
                ? `calc(-${activeIndex} * var(--max-section-width))`
                : 0
            }, 0, 0)`,
          }}
        >
          {items.map(HighlightItem)}
        </Styled.ItemTrack>
      </Styled.ContentContainer>
    </>
  );
};

export default DesktopHighlightWithRelatedListing;
