import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import useContentfulGatsbyImage from '../../../hooks/useContentfulGatsbyImage';
import { AppColor } from '../../../styles/colors';
import { Icons } from '../../../types/icon-types';
import { ContentfulGatsbyImage } from '../../../types/images';
import Icon, { IconSize } from '../icon/Icon';
import SocialMediaButtons, {
  SocialMediaLink,
} from '../social-media-button/SocialMediaButtons';
import { Typography } from '../typography/Typography.styled';
import Styled from './ContactDetails.styled';
import { ContentfulOpeningHoursTypes } from '../../../contentful/templates/types';
import { openingHoursToShortString } from '../../../utils/time-utils';
import { graphql } from 'gatsby';
import { ParsedOpeningHoursType } from '../../../network/skiresort-api/types/RestaurantDataTypes';

export type ContactDetailsProps = {
  address: string;
  phone: string;
  email: string;
  website?: string;
  socialMediaLinks: SocialMediaLink[];
  logo?: ContentfulGatsbyImage;
  openingHours: ContentfulOpeningHoursTypes | ParsedOpeningHoursType;
};

const ContactDetails = ({
  phone,
  email,
  address,
  website,
  socialMediaLinks,
  logo,
  openingHours,
}: ContactDetailsProps): JSX.Element => {
  const imageProps = useContentfulGatsbyImage(logo);

  return (
    <Styled.ContactDetails>
      {imageProps && (
        <Styled.LogoContainer>
          <GatsbyImage {...imageProps} />
        </Styled.LogoContainer>
      )}
      <address>
        {openingHours?.openingHours && !openingHours.hidden && (
          <Styled.Row color={AppColor.Neutral600}>
            <Icon type={Icons.Time} size={IconSize.Size24} />
            <div>
              <Typography.BodyLarge>
                {openingHoursToShortString(openingHours.openingHours)}
              </Typography.BodyLarge>
              {openingHours.exceptions && (
                <Typography.BodySmall>
                  {openingHours.exceptions}
                </Typography.BodySmall>
              )}
            </div>
          </Styled.Row>
        )}
        {address && (
          <Styled.Row color={AppColor.Neutral600}>
            <Icon type={Icons.Location} size={IconSize.Size24} />
            <Typography.BodyLarge>{address}</Typography.BodyLarge>
          </Styled.Row>
        )}
        {phone && (
          <Styled.Row>
            <Icon type={Icons.Phone} size={IconSize.Size24} />
            <Typography.BodyLarge as="a" href={`tel: ${phone}`}>
              {phone}
            </Typography.BodyLarge>
          </Styled.Row>
        )}
        {website && (
          <Styled.Row>
            <Icon type={Icons.Website} size={IconSize.Size24} />
            <Typography.BodyLarge as="a" href={website}>
              {website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')}
            </Typography.BodyLarge>
          </Styled.Row>
        )}
        {email && (
          <Styled.Row>
            <Icon type={Icons.Mail} size={IconSize.Size24} />
            <Typography.BodyLarge as="a" href={`mailto:${email}`}>
              {email}
            </Typography.BodyLarge>
          </Styled.Row>
        )}
      </address>
      {socialMediaLinks && (
        <SocialMediaButtons socialMediaLinks={socialMediaLinks} />
      )}
    </Styled.ContactDetails>
  );
};

export default ContactDetails;

export const contentfulCompany = graphql`
  fragment contentfulCompanyFields on ContentfulCompany {
    id
    title: name
    phone
    email
    address
    website
    socialMediaLinks {
      type: key
      link: value
    }
  }
`;
