import React from 'react';
import Grid from '../../components/common/layout/Grid';
import Section from '../../components/section/Section';
import ContentfulCardGrid, {
  ContentfulCardGridTypes,
} from '../sections/contentfulCardGrid';
import ContentfulCardList, {
  ContentfulCardListTypes,
} from '../sections/contentfulCardList';
import ContentfulCarousel, {
  ContentfulCarouselTypes,
} from '../sections/contentfulCarousel';
import ContentfulCategories, {
  ContentfulCategoriesTypes,
} from '../sections/contentfulCategories';
import ContentfulEmbed, {
  ContentfulEmbedTypes,
} from '../sections/contentfulEmbed';
import ContentfulHighlightItemList, {
  ContentfulHighlightItemListTypes,
} from '../sections/contentfulHighlightItemList';
import ContentfulLeviToday, {
  ContentfulLeviTodayTypes,
} from '../sections/contentfulLeviToday';
import ContentfulLiftUp, {
  ContentfulLiftUpTypes,
} from '../sections/contentfulLiftUp';
import ContentfulLocationSection, {
  ContentfulLocationSectionTypes,
} from '../sections/contentfulLocationSection';
import ContentfulSkiBusSchedule, {
  ContentfulSkiBusScheduleTypes,
} from '../sections/contentfulSkiBusSchedule';
import ContentfulInfogis, {
  ContentfulInfogisTypes,
} from '../sections/contentfulInfogisSection';
import ContentfulSplitSection, {
  ContentfulSplitSectionTypes,
} from '../sections/contentfulSplitSection';
import ContentfulTableGroups, {
  ContentfulTableGroupsTypes,
} from '../sections/contentfulTableGroups';
import ContentfulTableSection, {
  ContentfulTableSectionTypes,
} from '../sections/contentfulTableSection';
import ContentfulTextContent, {
  ContentfulTextContentTypes,
} from '../sections/contentfulTextContent';
import ContentfulVideoSection, {
  ContentfulVideoSectionTypes,
} from '../sections/contentfulVideoSection';
import ContentfulSkiResortAmenitiesSection, {
  ContentfulSkiResortAmenitiesSectionTypes,
} from '../sections/contentfulSkiResortAmenitiesSection';
import ContentfulWeeklyProgrammeSection, {
  ContentfulWeeklyProgrammeTypes,
} from '../sections/contentfulWeeklyProgrammeSection';
import ContentfulNewsletterSection, {
  ContentfulNewsletterSectionTypes,
} from '../sections/contentfulNewsletterSection';
import ContentfulEventListSection, {
  ContentfulEventListSectionTypes,
} from '../sections/contentfulEventListSection';
import ContentfulImage, {
  ContentfulImageTypes,
} from '../fragments/contentfulImage';
import ContentfulBlogsSection, {
  ContentfulBlogsSectionTypes,
} from '../sections/contentfulBlogsSection';
import ContentfulCourseSection, {
  ContentfulCourseSectionTypes,
} from '../sections/contentfulCourseSection';

export type ContentfulSectionContainerTypes = ContentfulLiftUpTypes &
  ContentfulCardGridTypes &
  ContentfulTextContentTypes &
  ContentfulCardListTypes &
  ContentfulSplitSectionTypes &
  ContentfulCategoriesTypes &
  ContentfulLeviTodayTypes &
  ContentfulTableGroupsTypes &
  ContentfulCarouselTypes &
  ContentfulVideoSectionTypes &
  ContentfulHighlightItemListTypes &
  ContentfulLocationSectionTypes &
  ContentfulEmbedTypes &
  ContentfulNewsletterSectionTypes &
  ContentfulTableSectionTypes &
  ContentfulSkiBusScheduleTypes &
  ContentfulInfogisTypes &
  ContentfulSkiResortAmenitiesSectionTypes &
  ContentfulWeeklyProgrammeTypes &
  ContentfulEventListSectionTypes &
  ContentfulImageTypes &
  ContentfulCourseSectionTypes &
  ContentfulBlogsSectionTypes & {
    id: string;
    type: string;
    internal: {
      type: string;
    };
  };

type ComponentIndex = {
  [key: string]: React.FC<ContentfulSectionContainerTypes>;
};

// Finds correct wrapper for a section
const components: ComponentIndex = {
  ContentfulLiftUp,
  ContentfulTextContent,
  ContentfulCardGrid,
  ContentfulCardList,
  ContentfulSplitSection,
  ContentfulCategories,
  ContentfulLeviToday,
  ContentfulTableGroups,
  ContentfulCarousel,
  ContentfulVideoSection,
  ContentfulHighlightItemList,
  ContentfulLocationSection,
  ContentfulEmbed,
  ContentfulTableSection,
  ContentfulSkiBusSchedule,
  ContentfulInfogis,
  ContentfulSkiResortAmenitiesSection,
  ContentfulWeeklyProgrammeSection,
  ContentfulNewsletterSection,
  ContentfulEventListSection,
  ContentfulImage,
  ContentfulCourseSection,
  ContentfulBlogsSection,
};

export const getComponentByType: React.FC<ContentfulSectionContainerTypes> = (
  props,
) => {
  let type: string | undefined;
  if (props.type) {
    type = props.type;
  } else if (props.internal !== undefined) {
    type = props.internal.type;
  }

  if (!type) {
    console.warn('Cannot define Section type', props);
    return null;
  }
  if (!components[type]) {
    console.warn('No matching component for section-type: ', type);
    return null;
  }
  const SectionComponent = components[type];
  return <SectionComponent {...props} />;
};

const ContentfulSectionContainer: React.FC<ContentfulSectionContainerTypes> = (
  props,
) => {
  const element = getComponentByType(props);
  return (
    <Section theme={props.theme}>
      <Grid>{element}</Grid>
    </Section>
  );
};

export default ContentfulSectionContainer;
