import React from 'react';
import { graphql } from 'gatsby';
import { PageProps } from '../types';
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../../containers/contentfulSectionContainer';
import SlopeCameras from '../../../components/slope-cameras/SlopeCameras';

const WeatherCamerasPage: React.FC<PageProps> = ({ data }) => {
  const { sections } = data.contentfulPage;

  return (
    <main>
      <SlopeCameras />

      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}
    </main>
  );
};

export default WeatherCamerasPage;

export const contentfulPageQuery = graphql`
  query WeatherCamerasPageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      sections {
        ...allPageSections
      }
      meta {
        ...contentfulPageMetaFields
      }
    }
  }
`;
