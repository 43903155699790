import { CardType } from '../../../components/common/card/Card';
import { Theme } from '../../../styles/themes';
import { DataTransformer } from '../cardDataTransformers';
import { trimLeadingWhiteSpace } from './utils';

const courseFieldsTransformer: DataTransformer<CardType.Course> = (props) => {
  // Course properties might be wrapped with node property (fetched directly / linked entries)
  // I have no idea how to type this for typescript
  const course = props.hasOwnProperty('node') ? props.node : props;
  const {
    id,
    name: title,
    ingress: description = '',
    categories,
    image,
    price,
    skillLevel,
    callToAction,
    leviBlackCard,
    hideFromLocalization
  } = course;

  return {
    type: CardType.Course,
    id,
    title: trimLeadingWhiteSpace(title),
    description,
    categories,
    image,
    callToAction: callToAction,
    priceInfo: {
      price: price, //unit: '€', //totalPrice: price || 1
    },
    skillLevel,
    theme: leviBlackCard ? Theme.LeviBlack : Theme.Primary,
    hideFromLocalization
  };
};

export default courseFieldsTransformer;
