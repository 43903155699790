import React from 'react';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';
import { Icons } from '../../../types/icon-types';
import CardStyled from '../card/Card.styled';
import CardFooterLink from '../card/CardFooterLink';
import CardTag from '../card/CardTag';
import { IconSize } from '../icon/Icon';
import ArrowLink from '../link/ArrowLink';
import { Typography } from '../typography/Typography.styled';
import Styled from './HorizontalCard.styled';

import { ServiceHorizontalCardContentProps } from './types';

const ServiceHorizontalCardContent = ({
  title,
  address,
  description,
  callToAction,
  cardLinkProps = {},
  links = [],
}: ServiceHorizontalCardContentProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);
  return (
    <Styled.CardContent>
      <CardStyled.CardLink {...cardLinkProps}>
        <Typography.HeadingSmall>{title}</Typography.HeadingSmall>
        {address && (
          <CardStyled.CardInfoRow>
            <CardTag icon={Icons.Location} iconSize={IconSize.Size16}>
              {address}
            </CardTag>
          </CardStyled.CardInfoRow>
        )}
        {description && (
          <Typography.BodySmall
            as="div"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </CardStyled.CardLink>
      {linkProps && <ArrowLink {...linkProps} />}
      {links.map(CardFooterLink)}
    </Styled.CardContent>
  );
};
export default ServiceHorizontalCardContent;
