import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Typography } from '../common/typography/Typography.styled';
import Styled from './Footer.styled';
import { ContentfulGatsbyImage } from '../../types/images';
import { Link } from "@Levi/components/common/link";

export type PartnerType = {
  id: string;
  image: ContentfulGatsbyImage;
  to: string;
};

type FooterPartnersListProps = {
  title: string;
  partners: PartnerType[];
};

const FooterPartnersList: React.FC<FooterPartnersListProps> = ({
  title,
  partners,
}) => (
  <Styled.PartnersContent>
    {title && <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>}
    <ul>
      {partners?.map(({ id, image, to }) => (
        <li key={id}>
          <Link to={to}>
            <GatsbyImage
              image={image.image}
              alt={image.description || image.title || ''}
            />
          </Link>
        </li>
      ))}
    </ul>
  </Styled.PartnersContent>
);

export default FooterPartnersList;
