import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import CardGrid from '../../../components/common/cardgrid/CardGrid';
import { PageProps } from '../types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Styled from './ServiceListPage.styled';
import { MultiSelectDropdownProps } from '../../../components/common/dropdown/MultiSelectDropdown';
import { rentalCategoriesToLocalizedList } from '../../data/rentalCategories';
import { SearchPageGrid } from '../../../components/common/layout/Grid.styled';
import LabeledDropdown from '../../../components/common/dropdown/LabeledDropdown';
import { SortOrder } from '../../../hooks/useSortedData';
import {
  containsString,
  FilterFunc,
  matchesAny,
} from '../../../hooks/useFilteredData';
import { CardType } from '../../../components/common/card/Card';
import useContentfulTransformedCardGridData from '../../../hooks/useContentfulTransformedCardGridData';
import CardGridHeader from '../../../components/common/cardgrid/CardGridHeader';
import useUrlParamAwareState from '../../../hooks/useUrlParamAwareState';
import { ServiceFields } from './contentfulSearchServicesPage';
import SearchFilters from '../../../components/common/list-filters/SearchFilters';
import { localize } from '../../../localization/i18n';
import usePagedData from '../../../hooks/usePagedData';
import { TertiaryButton } from '../../../components/common/button/Button';
import Icon from '../../../components/common/icon/Icon';
import { Icons } from '../../../types/icon-types';
import ClusterMap from '../../../components/map/ClusterMap';
import { isDomAvailable } from '../../../utils/env-utils';
import { filterOutDummyCards } from '../utils';

const initialOffset = 0;
const itemLimit = 20;

type SearchRentalPageProps = PageProps & {
  data: {
    rentals: {
      edges: Array<{ node: ServiceFields }>;
    };
  };
};

const ContentfulSearchRentalPage: React.FC<SearchRentalPageProps> = ({
  data: {
    rentals: { edges: cards },
  },
  location,
}) => {
  cards = filterOutDummyCards(cards);
  
  const data = useContentfulTransformedCardGridData({
    cards,
    type: CardType.Rental,
  });

  const [searchTerm, setSearchTerm] = React.useState('');

  const [selectedCategories, setSelectedCategories] = useUrlParamAwareState({
    location,
    paramName: 'category',
    initialValue: [],
  });

  const [sort, setSort] = useUrlParamAwareState({
    location,
    paramName: 'sort',
    initialValue: ['asc'],
  });

  const sortParamRef = React.useRef<SortOrder>('asc');

  const sortParam = React.useMemo(() => {
    const order = sort[0];
    if (sortParamRef.current !== order) {
      sortParamRef.current = order as SortOrder;
    }

    return sortParamRef.current;
  }, [sort]);

  const filters = React.useMemo<FilterFunc[]>(() => {
    return [
      containsString('title', searchTerm),
      matchesAny('categories', selectedCategories),
    ];
  }, [searchTerm, selectedCategories]);

  const { dataSlice, reSort, maxItems, fetchMore, hasMore } = usePagedData({
    data: isDomAvailable() ? data : [],
    sortBy: 'title',
    initialSortOrder: sortParamRef.current,
    filters,
    initialOffset,
    itemLimit,
  });

  const changeSortOrder = React.useCallback(
    (order: SortOrder) => {
      reSort(order);
    },
    [reSort],
  );

  React.useEffect(() => {
    changeSortOrder(sortParam);
  }, [changeSortOrder, sortParam]);

  const listFilters = React.useMemo<MultiSelectDropdownProps[]>(
    () => [
      {
        name: 'needsFilter',
        label: localize('filters.listFilters.needs'),
        items: rentalCategoriesToLocalizedList(),
        onChange: (arr) => setSelectedCategories(arr),
        variant: 'primary',
        value: selectedCategories,
      },
    ],
    [selectedCategories, setSelectedCategories],
  );

  return (
    <main>
      <Styled.ContentContainer>
        <SearchPageGrid>
          <Styled.MainSection>
            <Typography.Display>
              {localize('rentals.headline')}
            </Typography.Display>

            <SearchFilters
              filters={listFilters}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />

            <CardGridHeader
              title={localize('searchResults.seachResultsTitle')}
              count={maxItems}
            >
              <LabeledDropdown
                variant="secondary"
                defaultValue="asc"
                value={sort[0] || 'asc'}
                name="sortBy"
                dropdownLabel={localize('searchResults.sort.label')}
                label=""
                onChange={(value) => setSort([value as SortOrder])}
                items={[
                  {
                    value: 'asc',
                    label: localize('searchResults.sort.textAsc'),
                  },
                  {
                    value: 'desc',
                    label: localize('searchResults.sort.textDesc'),
                  },
                ]}
              />
            </CardGridHeader>
            <CardGrid
              cards={dataSlice.map((rental, index) => ({
                ...rental,
                locationMarkerIndex: index + 1,
              }))}
              columns={2}
              mobileScrollable={false}
            />
            {hasMore && (
              <Styled.LoadMoreButtonContainer>
                <TertiaryButton onClick={fetchMore}>
                  {localize('searchResults.loadMore')}{' '}
                  <Icon type={Icons.ChevronDownLarge} />
                </TertiaryButton>
              </Styled.LoadMoreButtonContainer>
            )}
          </Styled.MainSection>
          <Styled.MapSectionSticky>
            <ClusterMap data={dataSlice} />
          </Styled.MapSectionSticky>
        </SearchPageGrid>
      </Styled.ContentContainer>
    </main>
  );
};

export default ContentfulSearchRentalPage;

export const contentfulPageQuery = graphql`
  query SearchRentalsPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      meta {
        ...contentfulPageMetaFields
      }
    }
    # Fetch all rentals
    rentals: allContentfulRental(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          ...contentfulRentalCardFields
        }
      }
    }
  }
  fragment contentfulRentalCardFields on ContentfulRental {
    ...contentfulBaseRentalFields
    images {
      ...contentfulCardImage
    }
    internal {
      type
    }
  }
`;
