import React from 'react';
import Styled, { DropdownVariants } from './Dropdown.styled';
import DropdownButton from './DropdownButton';
import useDropdownToggle from './useDropdownToggle';

export type DropdownProps = {
  name: string;
  label: string;
  ariaLabel?: string;
  items: { value: string; label: string; component?: React.ReactNode }[];
  variant?: DropdownVariants;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const Dropdown = ({
  name,
  label,
  ariaLabel,
  defaultValue,
  value,
  onChange,
  items = [],
  variant = 'primary',
}: DropdownProps): JSX.Element | null => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const { isOpen, onDropdownClick } = useDropdownToggle({ ref });
  const [selectedValue, setSelectedValue] = React.useState(
    value || defaultValue,
  );

  const onValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedValue(event.currentTarget.value);
      onChange && onChange(event.currentTarget.value);
    },
    [onChange],
  );

  const onOptionClick = React.useCallback(
    (event) => {
      const { value } = event.currentTarget.dataset;
      if (selectedValue === value) {
      } else {
        setSelectedValue(value);
        onChange && onChange(value);
        onDropdownClick();
      }
    },
    [onChange, selectedValue, onDropdownClick],
  );

  const selectedValueLabel = !selectedValue
    ? label
    : items.find((item) => item.value === selectedValue)?.label || label;

  if (items.length === 0) {
    return null;
  }

  return (
    <Styled.Dropdown
      ref={ref}
      className={!!selectedValue ? 'hasValue' : ''}
      isOpen={isOpen}
      variant={variant}
    >
      <select
        name={name}
        value={selectedValue}
        onChange={onValueChange}
        aria-label={ariaLabel}
      >
        <option>{label}</option>
        {items.map(({ value, label }) => (
          <option key={label} value={value}>
            {label}
          </option>
        ))}
      </select>
      <DropdownButton label={selectedValueLabel} onClick={onDropdownClick} />
      <Styled.DropdownOptions>
        {items.map(({ value, label }) => (
          <Styled.DropdownOption
            key={label}
            onClick={onOptionClick}
            selected={selectedValue === value}
            data-value={value}
          >
            {label}
          </Styled.DropdownOption>
        ))}
      </Styled.DropdownOptions>
    </Styled.Dropdown>
  );
};

export default Dropdown;
