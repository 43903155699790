import React from 'react';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import { ContentfulCardTypes } from '../../../contentful/sections/types';
import useCategorizedCards from '../../../hooks/useCategorizedCards';
import { MappedContentfulCategory } from '../../../hooks/useContentfulCategoryData';
import GridHeader from '../layout/GridHeader';
import CategoryTabs from '../tabs/CategoryTabs';
import { Typography } from '../typography/Typography.styled';
import CardGrid from './CardGrid';

export type CategorizedCardGridProps = {
  allCategoryTitle?: string;
  title: string;
  categories: MappedContentfulCategory[];
  cards: ContentfulCardTypes[];
  callToAction?: ContentfulCallToActionTypes;
  limitCount?: number;
};

const CategorizedCardGrid = ({
  allCategoryTitle,
  title,
  categories,
  cards,
  callToAction,
  limitCount,
}: CategorizedCardGridProps): JSX.Element => {
  const { currentCategory, cardGridData, onSelect } = useCategorizedCards(
    categories,
    cards,
    !!allCategoryTitle,
    limitCount,
  );

  return (
    <>
      {title && (
        <GridHeader>
          <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
          <CategoryTabs
            allCategoryTitle={allCategoryTitle}
            categories={categories}
            onSelect={onSelect}
            selected={currentCategory}
          />
        </GridHeader>
      )}
      <CardGrid cards={cardGridData} callToAction={callToAction} />
    </>
  );
};

export default CategorizedCardGrid;
