import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';

type IconFiles = {
  allFile: {
    edges: {
      node: {
        relativePath: string;
        childImageSharp: IGatsbyImageData;
      };
    }[];
  };
};

const useStaticIcon = (path: string) => {
  const {
    allFile: { edges },
  } = useStaticQuery<IconFiles>(
    graphql`
      query {
        allFile(filter: { relativeDirectory: { eq: "icons" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1
                  width: 64
                  breakpoints: [64]
                  placeholder: NONE
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    `,
  );
  return edges.filter(({ node }) => node.relativePath === path)[0].node
    .childImageSharp;
};

export default useStaticIcon;
