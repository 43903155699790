import React from 'react';
import { graphql } from 'gatsby';
import MapContainer from '../../components/common/map/MapContainer';

export type ContentfulLocationSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  title?: string;
  address?: string;
  location: {
    lat: number;
    lon: number;
  };
};

const ContentfulLocationSection = ({
  title,
  address,
  location,
}: ContentfulLocationSectionTypes): JSX.Element | null => {
  if (!location) return null;
  return (
    <>
      <MapContainer title={title} address={address} location={location} />
    </>
  );
};

export default ContentfulLocationSection;

export const contentfulLocationSectionQuery = graphql`
  fragment contentfulLocationSectionFields on ContentfulLocationSection {
    id
    internal {
      type
    }
    title
    address
    location {
      lat
      lon
    }
  }
`;
