import React from 'react';
import Styled from './Calendar.styled';
import { DayType } from './calendarStore';
import useCalendarDayState from './useCalendarDayState';

const CalendarDay = ({
  day,
  month,
}: {
  day: DayType;
  month: Date;
}): JSX.Element => {
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const { onClick, onHover } = useCalendarDayState({ day, ref, month });

  return (
    <Styled.CalendarDay ref={ref} onMouseOver={onHover} onClick={onClick}>
      <Styled.CalendarDayLabel>{day.displayDate}</Styled.CalendarDayLabel>
    </Styled.CalendarDay>
  );
};

export default CalendarDay;
