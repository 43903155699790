import styled from 'styled-components';
import { breakpoints } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover, touchActive } from '../../../styles/utils';
import CardStyled, { CardLinkProps } from '../card/Card.styled';
import Icon from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';
import { HorizontalCardType } from './HorizontalCard';

type CardTypeIndex = {
  [key in HorizontalCardType]: string;
};

type CardTypeProps = {
  type: HorizontalCardType;
  hasImage?: boolean;
  $hasLink?: boolean;
} & CardLinkProps;

const CardImageColumn = styled.div`
  grid-area: image;
`;

const CardDateColumn = styled.div`
  grid-area: image;

  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  width: 4rem;
  height: 2.875rem;

  ${Typography.NumberBig} {
    color: ${AppColor.LeviBlue};
    margin-top: -0.1em;
  }

  ${Typography.DecorativeLabel} {
    color: ${AppColor.Neutral700};
  }
`;

const CardImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    transform: translate3d(0, 0, 0) scale(1);
    ${transitions.CardImageHover}
  }
`;

const CardContent = styled.div`
  grid-area: content;
  padding: 0.625rem 1rem 0.75rem 0;
  ${breakpoints.sm} {
    padding: 1rem 1.5rem 1rem 0;
  }

  display: flex;
  flex-direction: column;

  color: var(--card-color);

  ${Typography.HeadingSmall} {
    word-wrap: break-word;
    color: var(--card-color-heading, var(--base-primary-heading-color));
  }

  ${Typography.HeadingSmall} + * {
    margin-top: 0.75rem;
    ${breakpoints.sm} {
      margin-top: 1rem;
    }
  }

  ${Typography.BodySmall} {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > * + a {
    margin-top: 1.125rem;
    ${breakpoints.sm} {
      margin-top: 1.3125rem;
    }
  }
`;

const Card = styled.div<CardTypeProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'image content content';

  position: relative;
  background-color: ${AppColor.White};

  gap: var(--gutter);
  ${breakpoints.sm} {
    grid-column: span 3;
  }
  ${breakpoints.md} {
    grid-column: span 6;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    box-shadow: var(--card-box-shadow, unset);

    opacity: 1;
    ${transitions.CardShadowHover}
  }

  ${(props) =>
    props.$hasLink &&
    `
    ${hover} {

      &:hover {
        &::before {
          opacity: 0.35;
        }
        ${CardImageWrapper} {
          .gatsby-image-wrapper {
            transform: translate3d(0, 0, 0) scale(1.05);
          }
        }
      }
    }
    ${touchActive} {
      &:active {
        &::before {
          opacity: 0.35;
        }
        ${CardImageWrapper} {
          .gatsby-image-wrapper {
            transform: translate3d(0, 0, 0) scale(1.05);
          }
        }
      }
    }
  `}

  ${(props) => variantStyles(props)[props.type]}
`;

const variantStyles = (props: CardTypeProps): CardTypeIndex => ({
  default: `
    --card-box-shadow: rgb(31 55 69 / 12%) 0px 5px 14px 0;
    & + & {
      margin-top: 1.5rem;
    }
  `,
  service: `
    --card-box-shadow: rgb(31 55 69 / 12%) 0px 5px 14px 0;
    ${
      !props.hasImage &&
      `
      grid-template-areas: 'content content content';
      `
    }

    & + & {
      margin-top: 1.5rem;
    }

    ${CardContent} {
      padding: 1rem 2rem 1.5rem;

      * + ${CardStyled.CardInfoRow} {
        margin-top: 0.25rem;

        > ${CardStyled.CardTag} {
          > ${Icon} {
            color: ${AppColor.Neutral500};
            margin-right: 0.375rem;
          }
        }

        & + * {
          margin-top: 1rem;
        }
      }

      > * + a {
        margin-top: 0.5rem;
      }
    }
  `,
  searchresult: `
    --card-box-shadow: rgb(31 55 69 / 12%) 0px 5px 14px 0;
    gap: unset;
    ${
      !props.hasImage
        ? `
        grid-template-columns: 1fr;
        grid-template-areas: 'content';
        `
        : `
        grid-template-columns: max-content 1fr;
        grid-template-areas: 'image content';
      `
    }

    & + & {
      margin-top: 1.5rem;
    }

    ${CardContent} {
      padding: 1rem 2rem 1.5rem;

      * + ${Typography.SubLink} {
        margin-top: 0.25rem;
        color: ${AppColor.Neutral600};

        & + * {
          margin-top: 1rem;
        }
      }

      > * + a {
        margin-top: 0.5rem;
      }
    }
  `,
  compact: `
    border-top: 1px solid ${AppColor.Neutral300};
    border-bottom: 1px solid ${AppColor.Neutral300};

    & + & {
      border-top-width: 0px;
    }

    ${CardImageColumn} {
      background: ${AppColor.White};
      ${breakpoints.sm} {
        padding: 1.5rem 0 2.6125rem 2rem;
      }
    }
    
    ${breakpoints.sm} {
      ${CardImageWrapper} {
        max-width: 168px;
        max-height: 112px;
      }
    }

    ${CardContent} {
      ${breakpoints.sm} {
        padding: 1.5rem 3rem 2.6125rem 0.5rem;
      }

      ${Typography.HeadingSmall} + * {
        ${breakpoints.sm} {
          margin-top: 0.5rem;
        }
      }
      > * + a {
        margin-top: 0.5rem;
      }
    }
  `,
  date: `
    grid-template-columns: unset;
    grid-template-areas: unset;

    display: flex;
    flex-direction: row;

    padding: 0.75rem 1rem 1rem 6px;

    border-top: 1px solid ${AppColor.Neutral300};
    border-bottom: 1px solid ${AppColor.Neutral300};

    & + & {
      border-top-width: 0px;
      margin-top: 0;
    }

    ${CardContent} {
      ${breakpoints.sm} {
        padding: 0;
      }

      ${Typography.HeadingSmall} + * {
        ${breakpoints.sm} {
          margin-top: 0.5rem;
        }
      }
      > * + a {
        margin-top: 0.5rem;
        ${breakpoints.sm} {
          margin-top: 0.5rem;
        }
      }
    }
  `,
});

export default {
  Card,
  CardContent,
  CardDateColumn,
  CardImageColumn,
  CardImageWrapper,
};
