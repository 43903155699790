import React from 'react';
import {
  equals,
  FilterFunc,
  isOperatingOrTemporarilyClosed,
} from '../../hooks/useFilteredData';
import useSkiResortAmenitiesData, {
  SkiResortAmenityType,
} from '../../hooks/useSkiResortAmenitiesData';
import { SlopeLocation } from '../../network/skiresort-api/parsers/slopeDataParser';
import SkiResortAmenitiesList from './SkiResortAmenitiesList';
import SkiResortAmenitiesListLegend, { BikeTrailAmenitiesListLegend } from './SkiResortAmenitiesListLegend';

export type SkiResortAmenityFilterProps = {
  slopeLocation?: SlopeLocation;
  groupBy: 'type' | 'location';
  openOnly?: boolean;
  type?: SkiResortAmenityType | SkiResortAmenityType[];
};

const SkiResortAmenities = ({
  type = [
    SkiResortAmenityType.BikeTrail,
    SkiResortAmenityType.Lifts,
    SkiResortAmenityType.OtherActivities,
    SkiResortAmenityType.Slopes,
    SkiResortAmenityType.Services,
    SkiResortAmenityType.Restaurants,
  ],
  groupBy = 'location',
  slopeLocation = SlopeLocation.All,
  openOnly = false,
}: SkiResortAmenityFilterProps) => {
  const filters: FilterFunc[] = React.useMemo(
    () =>
      [
        slopeLocation !== SlopeLocation.All &&
          equals('slopeLocation', slopeLocation),
        openOnly && isOperatingOrTemporarilyClosed(),
      ].filter((f) => !!f) as FilterFunc[],
    [slopeLocation, openOnly],
  );

  const data = useSkiResortAmenitiesData(type, filters);
  
  // Choose legend based on type
  const Legend = ()=>{
    // If there is only bike trail amenity, choose specific legend
    if(type === SkiResortAmenityType.BikeTrail || (Array.isArray(type) && !type.find(t => t !== SkiResortAmenityType.BikeTrail)))
      return <BikeTrailAmenitiesListLegend/>;

    // Use generic legend
    return <SkiResortAmenitiesListLegend />
  }

  return (
    <>
      <SkiResortAmenitiesList data={data} groupBy={groupBy} />
      <Legend/>
    </>
  );
};

export default SkiResortAmenities;
