import React from 'react';
import Styled from './Card.styled';
import { Typography } from '../typography/Typography.styled';
import { CardLocationMarkProps } from './types';

const isStringChild = (children: React.ReactNode): children is string =>
  typeof children === 'string';

const CardLocationMark = ({
  markerIndex,
  children,
  label,
}: CardLocationMarkProps): JSX.Element => (
  <Styled.CardLocationMark>
    <Styled.LocationMark>{markerIndex}</Styled.LocationMark>
    {label || isStringChild(children) ? (
      <Typography.SubLink>{label || children}</Typography.SubLink>
    ) : (
      children
    )}
  </Styled.CardLocationMark>
);

export default CardLocationMark;
