import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from "@Levi/components/common/link";
import useLocale from '../../localization/useLocale';
import TextContent from '../../components/common/textContent/TextContent';
import { Typography } from '../../components/common/typography/Typography.styled';
import { eventFieldsTransformer } from '../data/transformers';
import useFormattedDate from '../../hooks/useFormattedDate';
import useContentfulCallToAction from '../../hooks/useContentfulCallToAction';
import { EventCardProps } from '../../components/common/card/types';
import { ContentfulEvent } from '../../../graphql-types';

// Display events that starts within
const WEEKS_RANGE = 4;

export type ContentfulEventListSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  eventType: string;
};

const query = graphql`
  query eventsQuery {
    en: allContentfulEvent(
      filter: { node_locale: { eq: "en" }, isPast: { eq: false }, hideFromLocalization: { ne: true } }
    ) {
      edges {
        node {
          ...eventCard
          categories
        }
      }
    }
    fi: allContentfulEvent(
      filter: { node_locale: { eq: "fi" }, isPast: { eq: false } }
    ) {
      edges {
        node {
          ...eventCard
          categories
        }
      }
    }
  }
`;

const EventRow = ({ date, title, links, id }: EventCardProps) => {
  const formattedDate = useFormattedDate({ date });
  const link = useContentfulCallToAction(links[0]);

  if (!link) return null;

  return (
    <Typography.BodyLarge key={id}>
      {formattedDate} <Link to={link.to}>{title}</Link>
    </Typography.BodyLarge>
  );
};

const ContentfulEventListSection = ({
  title,
  eventType,
}: ContentfulEventListSectionTypes): JSX.Element => {
  const locale = useLocale();
  const data = useStaticQuery(query);
  const now = new Date();
  const dateRangeEnd = now.setDate(now.getDate() + WEEKS_RANGE * 7);

  const events = data[locale].edges
    .filter(({ node }: { node: ContentfulEvent }) => {
      return (
        node.categories?.includes(eventType) &&
        new Date(node.startDate) < new Date(dateRangeEnd)
      );
    })
    .map(eventFieldsTransformer)
    .sort(
      (a: EventCardProps, b: EventCardProps) =>
        (a.date.start || a.date) - (b.date.start || b.date),
    );

  return <TextContent title={title}>{events.map(EventRow)}</TextContent>;
};

export default ContentfulEventListSection;

export const contentfulEventListSectionQuery = graphql`
  fragment contentfulEventListSectionFields on ContentfulEventListSection {
    id
    internal {
      type
    }
    title
    eventType
  }
`;
