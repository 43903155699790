import styled from 'styled-components';
import { GridElement } from '../layout/Grid.styled';
import { Typography } from '../typography/Typography.styled';
import { AppColor } from '../../../styles/colors';
import Icon from '../icon/Icon';
import { breakpoints } from '../../../styles/breakpoints';
import { hover } from '../../../styles/utils';
import transitions from '../../../styles/transitions';

const ToggleIcon = styled(Icon)`
  color: ${AppColor.Neutral500};
  margin-left: auto;
  transform: rotate(90deg);
  align-self: flex-start;

  [open] & {
    transform: rotate(-90deg);
  }
`;

const TypeIcon = styled(Icon)`
  margin: 0.25em 0.75rem 0 0;
  color: ${AppColor.LeviBlue};

  [open] & {
    font-size: 2rem;
  }
`;

const Summary = styled.summary`
  display: flex;
  align-items: flex-start;
  outline: 0;

  ${Typography.HeadingDecoSmall} {
    display: block;
  }

  ${hover} {
    cursor: pointer;
    ${ToggleIcon}, ${TypeIcon}, ${Typography.HeadingDecoSmall} {
      ${transitions.AccordionColorHover}
    }
    &:hover {
      ${ToggleIcon}, ${TypeIcon}, ${Typography.HeadingDecoSmall} {
        color: ${AppColor.LeviBlueHover};
      }
    }
  }

  [open] & {
    ${Typography.HeadingDecoSmall} {
      font-size: 2rem;
      color: ${AppColor.Neutral700};
    }
  }

  [disabled] & {
    pointer-events: none;
  }
`;

const Content = styled.div`
  ${Typography.BodySmall} {
    margin-top: 1.5rem;
    &:before {
      content: '';
      width: 56px;
      height: 2px;
      background-color: ${AppColor.Neutral300};
      display: block;
      margin-bottom: 1.5rem;
      ${breakpoints.md} {
        margin-bottom: 2rem;
      }
    }
  }
`;

const ContentFooter = styled.footer`
  margin-top: 1.5rem;
`;

type DetailsProps = {
  disabled?: boolean;
};

const Details = styled.details<DetailsProps>`
  border-top: 1px solid ${AppColor.Neutral300};
  border-bottom: 1px solid ${AppColor.Neutral300};
  padding: 2.25rem 1.5rem;

  & + & {
    border-top-width: 0px;
  }

  &[open] {
    flex-grow: 1;
  }
`;

const Accordion = styled(GridElement)`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${AppColor.Neutral300};
`;

const StaticAccordionListItem = styled.div`
  padding: 1.5rem 0 2.5rem;
  grid-row: header;
`;

const StaticAccordionList = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
`;

export default {
  Accordion,
  Content,
  ContentFooter,
  Details,
  Summary,
  ToggleIcon,
  TypeIcon,
  StaticAccordionList,
  StaticAccordionListItem,
};
