import styled from 'styled-components';
import { Breakpoint, breakpoints, only } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import Mount from '../../transitions/Mount';
import Spinner from '../loader/Spinner';
import { GridElement } from '../layout/Grid.styled';
import GridHeader from '../layout/GridHeader';

const InfoGrid = styled(GridElement)`
  grid-column-start: 1;
  grid-column-end: -1;
  display: grid;
  position: relative;

  grid-auto-rows: minmax(10rem, auto);
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 2rem 1rem 3.5rem;

  ${breakpoints.sm} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${breakpoints.md} {
    gap: 3rem;
    grid-template-columns: repeat(4, 1fr);
  }

  ${breakpoints.lg} {
    grid-template-columns: repeat(5, 1fr);
    gap: 4.5rem;
    padding: 2rem 2.5rem 5rem;
  }

  background-color: ${AppColor.Neutral200};
`;

const InfoGridMount = styled(Mount)`
  display: grid;
  grid: inherit;
  grid-column: 1 / -1;
  gap: inherit;
  grid-auto-rows: inherit;
  grid-row: 1;
`;

const InfoGridSpinnerContainer = styled(Mount)`
  grid-column: 1 / -1;
  justify-content: center;
  display: flex;
  grid-row: 1;
  max-height: 10rem;
`;

const InfoGridSpinner = styled(Spinner)`
  align-self: flex-start;
`;

const InfoGridGrouped = styled(InfoGrid)`
  gap: 3rem 1.5rem;
  padding-left: 0;
  padding-right: 0;

  grid-template-columns: repeat(1, 1fr);

  ${breakpoints.sm} {
    gap: 4.5rem 1.5rem;
    grid-template-columns: repeat(2, 1fr);
  }
  ${breakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${breakpoints.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const InfoGridGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  text-align: center;
  position: relative;
  > * {
    max-width: 12.5rem;
    margin: 0 auto;
  }

  ${breakpoints.sm} {
    &:before {
      border-left: 1px solid #c4c4c4;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      left: -0.75rem;
    }
  }

  ${only(Breakpoint.sm)} {
    &:nth-of-type(2n + 1) {
      &:before {
        border-width: 0;
      }
    }
  }
  ${breakpoints.md} {
    &:nth-of-type(3n + 1) {
      &:before {
        border-width: 0;
      }
    }
  }
`;

const InfoGridHeader = styled(GridHeader)`
  background-color: ${AppColor.Neutral200};
  margin-bottom: 0;
  padding: 3rem 2.5rem 0.5rem;
`;

export default {
  InfoGrid,
  InfoGridMount,
  InfoGridSpinner,
  InfoGridSpinnerContainer,
  InfoGridGrouped,
  InfoGridGroup,
  InfoGridHeader,
};
