import { LeviTodayFieldKeys } from '../../context/types';
import { Icons } from '../../types/icon-types';
import { ImageIcons } from '../../types/image-icon-types';

// Map contentful's levi today content-field values to actual content

type LeviTodayFieldIndex = {
  [key in LeviTodayFieldKeys]: {
    icon: Icons | ImageIcons;
  };
};

export const LeviTodayFields: LeviTodayFieldIndex = {
  Lifts: {
    icon: ImageIcons.ImageInfoLiftsOpen,
  },
  Snowmaking: {
    icon: ImageIcons.ImageInfoSnowmaking,
  },
  'Snow depth': {
    icon: ImageIcons.ImageInfoSnowflake,
  },
  'Ski tracks': {
    icon: ImageIcons.ImageInfoSkiingTracks,
  },
  'Snowmobile tracks': {
    icon: ImageIcons.ImageInfoSnowmobileTracks,
  },
  'Trekking routes': {
    icon: ImageIcons.ImageInfoWalkingTrails,
  },
  'Slope cameras': {
    icon: ImageIcons.ImageInfoCamera,
  },
  Slopes: {
    icon: ImageIcons.ImageInfoSlopesOpen,
  },
  'Slope restaurants': {
    icon: ImageIcons.ImageInfoEatAndDrink,
  },
  Weather: {
    icon: ImageIcons.ImageInfoSnowflake,
  },
  'Bike trails': {
    icon: ImageIcons.ImageInfoBikeTrails,
  },
  'Basejump': {
    icon: ImageIcons.ImageInfoBaseJump,
  },
  'Sled track': {
    icon: ImageIcons.ImageInfoSledgeTracks,
  },
  'Traffic park': {
    icon: ImageIcons.ImageInfoTrafficPark,
  },
  'Adventure park': {
    icon: ImageIcons.ImageInfoAdventurePark,
  },
  'Trampoline': {
    icon: ImageIcons.ImageInfoTrampoline,
  },
  'Zipline': {
    icon: ImageIcons.ImageInfoZipline,
  },
};
