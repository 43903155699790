import React from 'react';
import { Icons } from '../../../types/icon-types';
import Icon, { IconSize } from '../icon/Icon';
import Styled from './InfoGridElement.styled';
import { Typography } from '../typography/Typography.styled';
import { ImageIcons } from '../../../types/image-icon-types';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';

export type InfoGridElementProps = {
  icon?: Icons | ImageIcons;
  label: string;
  statistic: string;
  callToAction?: ContentfulCallToActionTypes;
};

const InfoGridElement = ({
  icon,
  label,
  statistic,
  callToAction,
}: InfoGridElementProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction) || {};

  return (
    <Styled.InfoGridElement {...linkProps}>
      {icon && <Icon type={icon} size={IconSize.Size64} />}
      <Typography.LinkSmall>{label}</Typography.LinkSmall>
      <Typography.NumberBig>{statistic}</Typography.NumberBig>
    </Styled.InfoGridElement>
  );
};

export default InfoGridElement;
