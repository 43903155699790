import { localize } from '../../localization/i18n';
import { Icons } from '../../types/icon-types';
import { RentalCategoryTypes } from './types';

export const rentalCategories: RentalCategoryTypes = {
  Ski: {
    icon: Icons.ActivitySki,
  },
  Bike: {
    icon: Icons.ActivityBike,
  },
  Snowboard: {
    icon: Icons.ActivitySnowboarding,
  },
  Snowshoes: {
    icon: Icons.ActivitySnowShoe,
  },
  Snowmobile: {
    icon: Icons.ActivitySnowMobile,
  },
  'Clothing rental': {
    icon: Icons.ActivityHiking,
  },
  'Cross country skiing': {
    icon: Icons.ActivityCrossCountrySki,
  },
  'Skin based skis': {
    icon: Icons.ActivitySnowShoe,
  },
  Car: {
    icon: Icons.RentalCar,
  },
};

export const rentalCategoriesToLocalizedList = () =>
  Object.keys(rentalCategories).map((key) => ({
    value: key,
    label: localize(`rentals.categories.${key}`),
    icon: rentalCategories[key],
  }));
