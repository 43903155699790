import styled from 'styled-components';
import { AppColor } from '../../styles/colors';

type MapInfoBoxStyledProps = {
  offsetLeft: number;
  offsetBottom: number | string;
  offsetTop: number | string;
};

const MapInfoBox = styled.div<MapInfoBoxStyledProps>`
  background: ${AppColor.White};
  width: 250px;
  color: ${AppColor.Neutral700};
  padding: 0;
  position: absolute;
  bottom: ${({ offsetBottom }) => `${offsetBottom}px`};
  top: ${({ offsetTop }) => `${offsetTop}px`};
  left ${({ offsetLeft }) => `${offsetLeft}px`};
  z-index: 20;
  border-radius: 4px;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
`;

export default {
  MapInfoBox,
};
