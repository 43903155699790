import React from 'react';
import { Link } from "@Levi/components/common/link";
import { NavLinkProps } from '../main/MainNavigationPropTypes';
import Styled from './BreadCrumbs.styled';
import useBreakpoints from '../../../hooks/useBreakpoints';
import { Typography } from '../../common/typography/Typography.styled';

export type BreadCrumbsProps = {
  links: NavLinkProps[];
  noBackgroundColor?: boolean;
};

const renderLink = (
  { to, label }: NavLinkProps,
  index: number,
  array: NavLinkProps[],
) => {
  const shouldBeLink = index < array.length - 1;
  return (
    <Styled.BreadCrumbListItem key={label}>
      <Typography.SubLink as={shouldBeLink ? Link : undefined} to={to}>
        {label}
      </Typography.SubLink>
    </Styled.BreadCrumbListItem>
  );
};

const BreadCrumbs = ({
  links,
  noBackgroundColor = false,
}: BreadCrumbsProps): JSX.Element | null => {
  const { isMobile } = useBreakpoints();
  if (isMobile) return null;
  return (
    <Styled.BreadCrumbContainer noBackgroundColor={noBackgroundColor}>
      <Styled.BreadCrumbList>{links.map(renderLink)}</Styled.BreadCrumbList>
    </Styled.BreadCrumbContainer>
  );
};

export default BreadCrumbs;
