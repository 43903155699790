import { startOfHour } from 'date-fns';
import React from 'react';
import { SkiResortWeatherType } from '../../../context/types';
import { getWeatherIconByID } from '../../../network/skiresort-api/parsers/weatherForecastDataParser';
import { WeatherForecastType } from '../../../network/skiresort-api/types/types';
import { Icons } from '../../../types/icon-types';
import Icon, { IconSize } from '../../common/icon/Icon';
import ArrowLink from '../../common/link/ArrowLink';
import { Typography } from '../../common/typography/Typography.styled';
import { NavLinkProps } from '../main/MainNavigationPropTypes';
import MainNavigationDropdownStyled from '../main/desktop/MainNavigationDropdown.styled';
import Styled from './WeatherDropdown.styled';
import { localize } from '../../../localization/i18n';
import { Link } from "@Levi/components/common/link";
import { useDropdownContainerCalculations } from '../../../hooks/useDropdownContainerCalculations';

const converTemperature = (
  value: number | undefined,
  targetUnits: TemperatureUnits,
  originUnits: TemperatureUnits = TemperatureUnits.Celsius,
): number | string => {
  if (value === undefined) {
    return '-';
  }
  const convertedValue =
    targetUnits === originUnits
      ? value
      : targetUnits === TemperatureUnits.Fahrenheit
      ? (value * 9) / 5 + 32
      : (5 / 9) * (value - 32);

  return +convertedValue.toFixed(2);
};

const WeatherStation = ({
  title,
  data,
}: {
  title: string;
  data: {
    airTemperature?: number;
    windSpeed?: number;
    temperatureUnits: TemperatureUnits;
    icon: Icons;
  };
}) => {
  return (
    <Styled.WeatherStation>
      <Styled.Title>{title}</Styled.Title>
      <Styled.WeatherInfo>
        <Icon type={data.icon} size={IconSize.Size64} />
        <Styled.Temperature>
          <Typography.NumberBig>
            {data.airTemperature
              ? converTemperature(data.airTemperature, data.temperatureUnits)
              : '-'}
          </Typography.NumberBig>
          <Typography.NumberSmall>
            °{data.temperatureUnits}
          </Typography.NumberSmall>
        </Styled.Temperature>
        <Styled.WindSpeed>{data.windSpeed ?? '-'} m/s</Styled.WindSpeed>
      </Styled.WeatherInfo>
    </Styled.WeatherStation>
  );
};

const enum TemperatureUnits {
  Celsius = 'C',
  Fahrenheit = 'F',
}

const WeatherDropdown = (props: {
  weather: SkiResortWeatherType;
  weatherForecast: WeatherForecastType;
  weatherCamerasLink?: NavLinkProps;
}): JSX.Element => {
  const refWrapper = React.useRef<HTMLDivElement>(null);
  const refContainer = React.useRef<HTMLDivElement>(null);

  const { recalculate } = useDropdownContainerCalculations({
    refWrapper,
    refContainer,
  });

  React.useEffect(() => recalculate());

  return (
    <MainNavigationDropdownStyled.DropdownWrapper ref={refWrapper}>
      <Styled.DropdownContainer ref={refContainer}>
        <DetailedWeatherContent {...props} onToggleUnits={recalculate} />
      </Styled.DropdownContainer>
    </MainNavigationDropdownStyled.DropdownWrapper>
  );
};

export const DetailedWeatherContent = ({
  weather,
  weatherForecast,
  weatherCamerasLink,
  onToggleUnits,
}: {
  weather: SkiResortWeatherType;
  weatherForecast: WeatherForecastType;
  weatherCamerasLink?: NavLinkProps;
  onToggleUnits?: () => void;
}) => {
  const leviPeakWeather = weather?.leviPeak?.weather;
  const leviCentreWeather = weather?.leviCentre?.weather;

  const icon = React.useMemo(() => {
    const currentTimeKeyString = startOfHour(new Date())
      .toISOString()
      .replace('.000', '');
    const { icon: iconId = 0 } = weatherForecast?.[currentTimeKeyString] || {};
    return getWeatherIconByID(iconId || 0);
  }, [weatherForecast]);

  const [temperatureUnits, setUnits] = React.useState<TemperatureUnits>(
    TemperatureUnits.Celsius,
  );

  React.useEffect(
    () => onToggleUnits && onToggleUnits(),
    [onToggleUnits, temperatureUnits],
  );

  const toggleUnits = React.useCallback(
    () =>
      setUnits((prevUnits) =>
        prevUnits === TemperatureUnits.Celsius
          ? TemperatureUnits.Fahrenheit
          : TemperatureUnits.Celsius,
      ),
    [],
  );

  if (!weather || !weatherForecast) {
    return null;
  }

  return (
    <>
      <Styled.WeatherStationsContainer>
        <WeatherStation
          title={localize('weather.stationPeakTitle')}
          data={{ ...leviPeakWeather, temperatureUnits, icon }}
        />
        <WeatherStation
          title={localize('weather.stationCentreTitle')}
          data={{ ...leviCentreWeather, temperatureUnits, icon }}
        />
      </Styled.WeatherStationsContainer>
      <Styled.DropdownFooter>
        <Styled.UnitSelector>
          <Styled.Unit
            selected={temperatureUnits === TemperatureUnits.Celsius}
            onClick={toggleUnits}
          >
            °{TemperatureUnits.Celsius}
          </Styled.Unit>
          <Styled.Unit
            selected={temperatureUnits === TemperatureUnits.Fahrenheit}
            onClick={toggleUnits}
          >
            °{TemperatureUnits.Fahrenheit}
          </Styled.Unit>
        </Styled.UnitSelector>
        {weatherCamerasLink && <ArrowLink {...weatherCamerasLink} as={Link} />}
      </Styled.DropdownFooter>
    </>
  );
};

export default WeatherDropdown;
