import React from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import { localize } from '../../localization/i18n';
import { SlopeLocation } from '../../network/skiresort-api/parsers/slopeDataParser';
import CategoryTab from '../common/tabs/CategoryTab';
import Styled from '../common/tabs/CategoryTabs.styled';
import SecondaryTab from '../common/tabs/SecondaryTab';
import SecondaryTabsStyled from '../common/tabs/SecondaryTabs.styled';

type SlopeLocationTabsProps = {
  onSelect?: (location: SlopeLocation) => void;
};

type TabsVariantProps = SlopeLocationTabsProps & { selected: SlopeLocation };

const MobileTabs = ({ selected, onSelect }: TabsVariantProps) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <SecondaryTabsStyled.SecondaryTabs ref={containerRef}>
      {Object.values(SlopeLocation).map((slopeLocation) => (
        <SecondaryTab
          key={slopeLocation}
          selected={selected === slopeLocation}
          onClick={(event: React.PointerEvent<HTMLElement>) => {
            if (event.currentTarget) {
              if (containerRef.current) {
                containerRef.current.scrollLeft =
                  event.currentTarget.offsetLeft;
              }
            }
            onSelect && onSelect(slopeLocation);
          }}
        >
          {localize(`slopes.tab_locations.${slopeLocation}`)}
        </SecondaryTab>
      ))}
    </SecondaryTabsStyled.SecondaryTabs>
  );
};

const DesktopTabs = ({ selected, onSelect }: TabsVariantProps) => (
  <Styled.Tabs>
    {Object.values(SlopeLocation).map((slopeLocation) => (
      <CategoryTab
        key={slopeLocation}
        selected={selected === slopeLocation}
        onClick={() => {
          onSelect && onSelect(slopeLocation);
        }}
      >
        {localize(`slopes.tab_locations.${slopeLocation}`)}
      </CategoryTab>
    ))}
  </Styled.Tabs>
);

const SlopeLocationTabs = ({ onSelect }: SlopeLocationTabsProps) => {
  const [selected, setSelected] = React.useState<SlopeLocation>(
    SlopeLocation.All,
  );

  const handleSelect = React.useCallback(
    (slopeLocation: SlopeLocation) => {
      onSelect && onSelect(slopeLocation);
      setSelected(slopeLocation);
    },
    [onSelect],
  );

  const { isPhone } = useBreakpoints();

  if (isPhone) {
    return <MobileTabs selected={selected} onSelect={handleSelect} />;
  }

  return <DesktopTabs selected={selected} onSelect={handleSelect} />;
};

export default SlopeLocationTabs;
