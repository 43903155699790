import { CardType } from '../../../components/common/card/Card';
import { Theme } from '../../../styles/themes';
import { DataTransformer } from '../cardDataTransformers';
import { resolveLinks, trimLeadingWhiteSpace } from './utils';

const eventFieldsTransformer: DataTransformer<CardType.Event> = (props) => {
  // Event properties might be wrapped with node property (fetched directly / linked entries)
  // I have no idea how to type this for typescript
  const event = props.hasOwnProperty('node') ? props.node : props;
  const {
    id,
    name: title,
    ingress: description = '',
    image,
    startDate,
    endDate,
    leviBlackCard,
    pageLink,
    categories,
    hideFromLocalization
  } = event;

  return {
    type: CardType.Event,
    id,
    title: trimLeadingWhiteSpace(title),
    description,
    image,
    date:
      (endDate && {
        start: new Date(startDate),
        end: new Date(endDate),
      }) ||
      new Date(startDate),
    links: resolveLinks(pageLink?.id || id, 'events'),
    theme: leviBlackCard ? Theme.LeviBlack : Theme.Primary,
    categories: categories || [],
    hideFromLocalization
  };
};

export default eventFieldsTransformer;
