import React from 'react';
import { graphql } from 'gatsby';
import InfoTable from '../../components/common/infotable/InfoTable';

type ContentfulTableElementTypes = {
  key: string;
  value: string;
};

export type ContentfulTableTypes = {
  id: string;
  title?: string;
  content: ContentfulTableElementTypes[];
};

const ContentfulTable = ({
  content,
}: ContentfulTableTypes): JSX.Element | null => <InfoTable items={content} />;

export default ContentfulTable;

export const contentfulTableQuery = graphql`
  fragment contentfulTableFields on ContentfulTable {
    id
    content {
      key
      value
    }
  }
`;
