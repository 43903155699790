import React from 'react';
import { graphql } from 'gatsby';
import TextContent from '../../components/common/textContent/TextContent';
import { richTextRenderer } from '../richText/richText';
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Theme } from '../../styles/themes';
import useLocale from '../../localization/useLocale';

export type ContentfulTextContentTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  subtitle: string;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  theme: Theme;
  hideFromLocalization?: boolean;
};

const ContentfulTextContent = ({
  title,
  subtitle,
  body,
  hideFromLocalization
}: ContentfulTextContentTypes): JSX.Element | null => {

  const locale = useLocale();
  if (locale !== 'fi' && hideFromLocalization) return <></>;

  const richText = React.useMemo(
    () => (body ? richTextRenderer({ data: body }) : undefined),
    [body],
  );
  return (
    <TextContent title={title} subtitle={subtitle}>
      {richText}
    </TextContent>
  );
};
export default ContentfulTextContent;

export const contentfulTextContentQuery = graphql`
  fragment contentfulTextContentFields on ContentfulTextContent {
    id
    internal {
      type
    }
    title
    subtitle
    body {
      ...contentfulTextContentBodyRichTextFields
    }
    theme
    hideFromLocalization
  }
`;
