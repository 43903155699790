import React from 'react';
import styled from 'styled-components';
import { AppColor } from '../../../styles/colors';
import { Icons } from '../../../types/icon-types';
import Icon from '../icon/Icon';

type Rating = 1 | 2 | 3 | 4 | 5;
export type MaybeRating = Rating | number | string;

const isValidRating = (rating: MaybeRating): rating is Rating =>
  [1, 2, 3, 4, 5].includes(+rating);

export type RatingProps = {
  rating: MaybeRating;
};

type StarProps = { selected: boolean };

const StyledContainer = styled.div`
  line-height: inherit;
  white-space: nowrap;
  display: flex;
  align-self: center;
  ${Icon} {
    line-height: inherit;
  }
`;

const StyledStar = styled(Icon)<StarProps>`
  color: ${AppColor.LeviBlue};
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
`;

const StarRating = ({ rating }: RatingProps) => {
  if (!isValidRating(rating)) {
    return null;
  }
  return (
    <>
      <StyledContainer aria-hidden>
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <StyledStar
              key={index}
              type={Icons.Star}
              selected={rating > index}
            />
          ))}
      </StyledContainer>
      <StyledContainer style={{ display: 'none' }}>
        {'*'
          .repeat(+rating)
          .split('')
          .map((i, index) => (
            <figure key={index} role="presentation">
              {i}
            </figure>
          ))}
      </StyledContainer>
    </>
  );
};

export default StarRating;
