import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import useCarouselIndex from '../../../hooks/useCarouselIndex';
import { Icons } from '../../../types/icon-types';
import { ContentfulGatsbyImage } from '../../../types/images';
import Icon, { IconSize } from '../icon/Icon';
import Styled from './Carousel.styled';
import { localize } from '../../../localization/i18n';

export type CarouselProps = {
  images: ContentfulGatsbyImage[];
};

const Carousel = ({ images }: CarouselProps): JSX.Element => {
  const { activeIndex, prev, next, set } = useCarouselIndex(images.length);

  return (
    <Styled.Carousel>
      <Styled.CarouselTrack
        style={{
          transform: `translate3d(${
            activeIndex > 0
              ? `calc(-${activeIndex} * (100% + var(--gutter)))`
              : 0
          }, 0, 0)`,
        }}
      >
        {images.map((image, index) => (
          <Styled.CarouselImage
            key={index}
            className={activeIndex === index ? 'active' : ''}
            onClick={() => set(index)}
          >
            <GatsbyImage
              image={image.image}
              alt={image.description || image.title || ''}
            />
          </Styled.CarouselImage>
        ))}
      </Styled.CarouselTrack>
      <Styled.CarouselActions>
        <button onClick={prev} aria-label={localize('gallery.previous')}>
          <Icon type={Icons.ArrowLeftMedium} size={IconSize.Size24} />
        </button>
        <button onClick={next} aria-label={localize('gallery.next')}>
          <Icon type={Icons.ArrowRightMedium} size={IconSize.Size24} />
        </button>
      </Styled.CarouselActions>
    </Styled.Carousel>
  );
};

export default Carousel;
