import {
  differenceInMinutes,
  differenceInSeconds,
  isAfter,
  isBefore,
  parse,
  startOfMinute,
  startOfSecond,
} from 'date-fns';
import { BusStop, BusRoute } from '../types/BusScheduleDataTypes';

export type BusScheduleSlot = { time?: string };
export type BusScheduleRowsByRoute = {
  [key in BusRoute]: BusScheduleTableRows;
};

type RowType = {
  id: number;
  name: Record<string, string>;
  route: BusRoute;
  slots: BusScheduleSlot[];
  showDepartures: boolean;
};

type BusScheduleTableRows = RowType[];

type ScheduledStop = { id: number; time: string; timeParsed: Date };

const referenceDate = new Date();
const parseTimeString = (time: string) => parse(time, 'HH:mm', referenceDate);

const filterByRoute = (route: BusRoute) => (stop: BusStop) =>
  stop.route === route;

const sortByTime = (a: ScheduledStop, b: ScheduledStop) =>
  isBefore(a.timeParsed, b.timeParsed)
    ? -1
    : isAfter(a.timeParsed, b.timeParsed)
    ? 1
    : 0;

const sortByTimeAndId = (a: ScheduledStop, b: ScheduledStop) =>
  isBefore(a.timeParsed, b.timeParsed)
    ? -1
    : isAfter(a.timeParsed, b.timeParsed)
    ? 1
    : b.id - a.id;

const stopScheduleToScheduledStops = ({ schedule, id, pause }: BusStop) => [
  ...schedule.map((time) => ({
    id,
    time,
    timeParsed: parseTimeString(time),
  })),
  { id, time: '', timeParsed: parseTimeString(pause) },
];

const scheduledStopsToTable = (
  busStops: BusStop[],
  scheduledStops: ScheduledStop[],
): RowType[] => {
  const rows = busStops.length;
  const cols = Math.ceil(scheduledStops.length / busStops.length) + 1;

  const count = rows * cols;
  const table: RowType[] = [];

  for (let i = 0, scheduleIndex = 0; i < count; i++) {
    const row = i % rows;
    const col = i - row * cols;
    const nextInSortedSchedule = scheduledStops[scheduleIndex];
    const { id: slotId, name, route, showDepartures } = busStops[row];
    const { id: nextId, time } = nextInSortedSchedule;
    if (!table[row]) {
      table[row] = {
        id: slotId,
        name,
        route,
        slots: [],
        showDepartures,
      };
    }
    if (nextId === slotId) {
      // The pdf version has first route1 column "floating" one column to the right
      //  Hack this same behaviour into the web table by adding a empty cells to first column
      if(route == "route1" && col == 0){
        table[row].slots.push({});
        continue;
      }

      table[row].slots.push({ time });
      if (scheduleIndex < scheduledStops.length - 1) {
        scheduleIndex++;
      } else {
        break;
      }
    } else {
      table[row].slots.push({});
    }
  }

  return table;
};

export const stopsToRows = (stops: BusStop[]) =>
  Object.values(BusRoute).reduce((acc, curr) => {
    const busStops = stops.filter(filterByRoute(curr));
    const scheduledStops = busStops
      .flatMap(stopScheduleToScheduledStops)
      .sort(sortByTimeAndId);

    acc[curr] = scheduledStopsToTable(busStops, scheduledStops);
    return acc;
  }, {} as BusScheduleRowsByRoute);

const getBusRunningSchedule = (route: BusRoute, stops: BusStop[]) => {
  const routeStops = stops.filter(filterByRoute(route));
  if (routeStops.length === 0) {
    return undefined;
  }

  // Determine bus schedule hours
  //
  const schedule = routeStops
    .flatMap(({ schedule, id }) =>
      schedule.map((time) => ({ timeParsed: parseTimeString(time), id, time })),
    )
    .sort(sortByTimeAndId);
  if (schedule.length < 2) {
    return undefined;
  }

  const start = schedule[0];
  const end = schedule[schedule.length - 1];

  return {
    start,
    end,
    schedule,
  };
};

const getStopsAfterNow = (route: BusRoute, stops: BusStop[], limit = 0) => {
  if (!route) {
    return undefined;
  }

  // If the bus is not running according to schedule hours, return undefined
  if (!isBusRunning(route, stops)) {
    return undefined;
  }

  // Filter out end stops for routes because their stop time matches the start
  const filteredStops = stops.filter((stop) => {
    if (stop.route === BusRoute.Route1) {
      return stop.id !== 324;
    }
    if (stop.route === BusRoute.Route2) {
      return stop.id !== 339;
    }
  });
  const { schedule } = getBusRunningSchedule(route, filteredStops) || {};
  if (!schedule) {
    return undefined;
  }

  const now = startOfMinute(new Date());

  return schedule
    .filter(({ timeParsed }) => differenceInMinutes(now, timeParsed) <= 0)
    .sort(sortByTime)
    .slice(0, limit > 0 ? limit : undefined);
};

export const getCurrentStopWithNextUpdateTime = (
  route: BusRoute,
  stops: BusStop[],
) => {
  const determinedStops = getStopsAfterNow(route, stops, 3);

  if (determinedStops && determinedStops.length > 0) {
    const { id: currentId } = determinedStops[0];
    const currentStop = stops.find((stop) => stop.id === currentId);

    const nextStop =
      determinedStops.length > 1 ? determinedStops[1] : undefined;

    const now = startOfSecond(new Date());
    let nextUpdate = undefined;
    if (nextStop) {
      nextUpdate = differenceInSeconds(nextStop.timeParsed, now);
    }

    return {
      currentStop,
      nextUpdate,
    };
  }

  return undefined;
};

export const getCurrentBusStopBySchedule = (
  route: BusRoute,
  stops: BusStop[],
) => {
  const determinedStops = getStopsAfterNow(route, stops, 1);

  if (determinedStops && determinedStops.length > 0) {
    const { id } = determinedStops[0];
    return stops.find((stop) => stop.id === id);
  }

  return undefined;
};

const isBusRunning = (route: BusRoute, stops: BusStop[]) => {
  const { start, end } = getBusRunningSchedule(route, stops) || {};
  if (!start || !end) {
    return false;
  }

  const now = startOfMinute(new Date());

  return (
    differenceInMinutes(end.timeParsed, now) >= 0 &&
    differenceInMinutes(now, start.timeParsed) >= 0
  );
};
