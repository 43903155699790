import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  PortalClassName,
  BodyModalClass,
} from '../../../styles/global/portal';
import { setScrollBarWidth } from '../../../styles/utils';

/**
 * The portal container styles are defined in global styles.
 */
interface IProps {
  className?: PortalClassName;
  el?: string;
  children: React.ReactNode;
}

/**
 * Adapted from: https://stackoverflow.com/a/66212708/10207153
 * @param children Child elements
 * @param className A CSS classname from enum PortalClassName
 * @param el The HTML element that is added to the end of the body tag. Default: div
 */
const Portal: React.FC<IProps> = ({
  children,
  className = PortalClassName.ModalContainer,
  el = 'div'
}: IProps) => {
  const [container] = React.useState(document.createElement(el));
  container.classList.add(className);

  React.useEffect(() => {
    if (className === PortalClassName.ModalContainer) {
      updateModalStyles();
    }
    document.body.appendChild(container);

    return () => {
      if (className === PortalClassName.ModalContainer) {
        removeModalStyles();
      }
      document.body.removeChild(container);
    }
  }, []);

  return ReactDOM.createPortal(children, container);
}

const updateModalStyles = () => {
  setScrollBarWidth();
  document.body.classList.add(BodyModalClass);
}

const removeModalStyles = () => {
  document.body.classList.remove(BodyModalClass);
}

export default Portal;
