import { ApiPath } from '..';
import { Icons } from '../../../types/icon-types';
import {
  WeatherForecastDataParameter,
  WeatherForecastType,
} from '../types/types';
import { ApiDataParser } from './ApiDataParser';

export const weatherForecastDataParser: ApiDataParser<
  ApiPath.WeatherForecast
> = (data): WeatherForecastType => {
  return data.reduce((acc, curr) => {
    const { time, parameter, value } = curr;
    return {
      ...acc,
      [time]: {
        ...(acc[time] || {}),
        [parameter === WeatherForecastDataParameter.Temperature
          ? 'temperature'
          : 'icon']: value,
      },
    };
  }, {} as WeatherForecastType);
};

export const getWeatherIconByID = (id: number, night = false) => {
  switch (id) {
    case 1:
      return !night ? Icons.WeatherCleardayBig : Icons.WeatherClearnightBig;
    case 2:
      return !night
        ? Icons.WeatherPartlycloudyDayBig
        : Icons.WeatherPartlycloudyNightBig;
    case 3:
      return Icons.WeatherCloudyBig;
    case 21:
      return Icons.WeatherLightrain;
    case 22:
    case 23:
      return Icons.WeatherRainBig;
    case 31:
      return Icons.WeatherLightrainBig;
    case 32:
    case 33:
      return Icons.WeatherRainBig;
    case 41:
    case 42:
      return Icons.WeatherLightsnowBig;
    case 43:
    case 51:
    case 52:
    case 53:
      return Icons.WeatherSnowBig;
    case 61:
    case 62:
    case 63:
    case 64:
      return Icons.WeatherThunderBig;
    case 71:
    case 72:
    case 73:
    case 81:
    case 82:
    case 83:
      return Icons.WeatherSleetBig;
    case 91:
    case 92:
      return Icons.WeatherFogBig;
  }
  return Icons.WeatherCleardayBig;
};
