import React from 'react';

type HookProps = {
  slideAmount: number;
};

type HookReturnType = {
  selectedIndex: number;
  onNext: () => void;
  onPrevious: () => void;
};

const useSlide = ({ slideAmount }: HookProps): HookReturnType => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const onNext = () => {
    selectedIndex >= slideAmount - 1
      ? setSelectedIndex(0)
      : setSelectedIndex((prevIndex) => ++prevIndex);
  };
  const onPrevious = () => {
    selectedIndex < 1
      ? setSelectedIndex(slideAmount - 1)
      : setSelectedIndex((prevIndex) => --prevIndex);
  };

  return {
    selectedIndex,
    onNext,
    onPrevious,
  };
};

export default useSlide;
