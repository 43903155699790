import React from 'react';
import GlobalStyle from './src/styles/GlobalStyle';
import CookieConsent from './src/components/cookie-consent/CookieConsentWrapper';
import { resolveLocaleFromLocation } from './src/localization/i18n';
import { LocalizationContextProvider } from './src/localization/LocalizationContext';
import Layout from './src/contentful/layout';
import { resolveStaticPageContext } from './utils/staticPageProps';

export const shouldUpdateScroll = ({ routerProps }) => {
  const { disableScrollUpdate = false } = routerProps.location.state || {};
  return !disableScrollUpdate;
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  /**
   * onPreRouteUpdate hook is buggy at least in v3.
   * prevLocation & location are inversed if both are set.
   * that's why there's a need for the hack below.
   */
  const inverseLocations = prevLocation !== null;
  const prev = inverseLocations ? location : prevLocation;

  if (location && location.state) {
    location.state.referrer = prev ? prev.pathname : null;
  }
};

export const wrapPageElement = ({ element, props }) => {
  const locale =
    props.pageContext?.locale || resolveLocaleFromLocation(props.location);

  const { data: { contentfulPage: { meta, heroCarousel } = {} } = {} } = props;

  addAnalyticsVariablesForGtm(props);

  const pageContext = getPageContext(props);

  return (
    <>
      <GlobalStyle />
      <LocalizationContextProvider locale={locale}>
        <Layout
          location={props.location}
          pageProps={pageContext}
          meta={meta}
          heroCarousel={heroCarousel}
          invertedNavigationTheme={heroCarousel && heroCarousel.length > 0}
        >
          {element}
        </Layout>
        <CookieConsent />
      </LocalizationContextProvider>
    </>
  );

  /**
   * The Google Tag Manager script will read the variables that we set here from the window object
   * to decide which tags will be attached to which page.
   */
  function addAnalyticsVariablesForGtm(props) {
    if (typeof window !== 'undefined' && props?.data) {
      const { data } = props;
      const currentPageType = Object.keys(data)[0];
      const analyticsTags = data[currentPageType]?.analyticsTags;

      /**
       * Tags are set for either one or both companies. If tags have not been set in Contentful,
       * we fallback to using Visit Levi's tags. Pages sourced from AWS use Visit Levi's tags.
       */
      window._lsr = analyticsTags?.includes('Levi Ski Resort') ? 1 : 0;
      window._vld = analyticsTags?.includes('Visit Levi') || window._lsr === 0 ? 1 : 0;
    }
  }

  function getPageContext(props) {
    return (Object.keys(props.pageContext).length === 0
      ? resolveStaticPageContext(props.uri)
      : props.pageContext.matchPath
        ? resolveStaticPageContext(props.pageContext.matchPath)
        : props.pageContext) || {};
  }
};
