import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '../../components/common/typography/Typography.styled';
import Table from '../../components/common/table/Table';
import GridHeader from '../../components/common/layout/GridHeader';
import useHtmlAst from '../../hooks/useHtmlAst';
import { ContentfulMarkdownTypes } from '../templates/types';

export type ContentfulTableSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  table: {
    tableData: [[string]];
  };
  ingress?: ContentfulMarkdownTypes;
};

const ContentfulTableSection = ({
  title,
  table: { tableData },
  ingress,
}: ContentfulTableSectionTypes): JSX.Element | null => {
  if (!tableData) return null;
  const ingressText = useHtmlAst(ingress?.childMarkdownRemark?.htmlAst);
  return (
    <>
      {title && (
        <GridHeader>
          <Typography.Display>{title}</Typography.Display>
        </GridHeader>
      )}

      <Table data={tableData} ingress={ingressText} />
    </>
  );
};

export default ContentfulTableSection;

export const contentfulTableSectionQuery = graphql`
  fragment contentfulTableSectionFields on ContentfulTableSection {
    id
    internal {
      type
    }
    title
    ingress {
      childMarkdownRemark {
        htmlAst
      }
    }
    table {
      tableData
    }
  }
`;
