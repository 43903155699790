import React from "react";
import { graphql } from 'gatsby';
import styled from "styled-components";
import { CardType } from "../../components/common/card/Card";
import CardGrid from "../../components/common/cardgrid/CardGrid";
import { Typography } from "../../components/common/typography/Typography.styled";
import useContentfulTransformedCardGridData from "../../hooks/useContentfulTransformedCardGridData";
import { breakpoints } from "../../styles/breakpoints";
import { CourseFields } from '../templates/serviceListPages/contentfulSearchCoursesPage';


export const StyledContentfulCourseSection = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;

  h2 {
    margin-bottom: 1em;
  }
`;

export type ContentfulCourseSectionTypes = {
    id      : string;
    internal: {
      type  : string;
    };
    name    : string;
    title   : string;
    courses : CourseFields[];
};

export const ContentfulCourseSection = ({ name, title, courses}: ContentfulCourseSectionTypes): JSX.Element => {
  const courseCards = useContentfulTransformedCardGridData({
    cards: courses.map(c => ({node: c})),
    type: CardType.Course,
  });

  return (
    <StyledContentfulCourseSection>
      <Typography.HeadingLarge>
        {title}
      </Typography.HeadingLarge>

      <CardGrid
        cards={courseCards}
        columns={3}
        mobileScrollable={false}
      />
    </StyledContentfulCourseSection>
  )
};

export default ContentfulCourseSection;

export const contentfulCourseSectionQuery = graphql`
  fragment contentfulCourseSectionFields on ContentfulCourseSection {
    id
    internal {
      type
    }
    name
    title
    courses {
      ...contentfulCourseCardFields
    }
  }
`;
