import React from 'react';
import useBreakpoints from '../../hooks/useBreakpoints';
import { HighlightListItem } from './HighlightItem';
import MobileHighlightWithRelatedListing from './MobileHighlightWithRelatedListing';
import DesktopHighlightWithRelatedListing from './DesktopHighlightWithRelatedListing';

export type HighlightWithRelatedListingProps = {
  title: string;
  items: HighlightListItem[];
};

const HighlightWithRelatedListing = ({
  title,
  items = [],
}: HighlightWithRelatedListingProps): JSX.Element => {
  const { isMobile } = useBreakpoints();

  if (isMobile) {
    return <MobileHighlightWithRelatedListing title={title} items={items} />;
  }

  return <DesktopHighlightWithRelatedListing title={title} items={items} />;
};

export default HighlightWithRelatedListing;
