import React from 'react';
import { Typography } from '../typography/Typography.styled';
import Styled from './InfoGrid.styled';
import InfoGridElement, { InfoGridElementProps } from './InfoGridElement';

export type InfoGridProps = {
  title?: string;
  data: InfoGridElementProps[];
};

const InfoGrid = ({ title, data = [] }: InfoGridProps): JSX.Element => {
  return (
    <>
      {title && (
        <Styled.InfoGridHeader>
          <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
        </Styled.InfoGridHeader>
      )}

      <Styled.InfoGrid>
        <Styled.InfoGridSpinnerContainer show={data.length === 0}>
          <Styled.InfoGridSpinner />
        </Styled.InfoGridSpinnerContainer>
        <Styled.InfoGridMount show={data.length > 0}>
          {data.map((itemProps, index) => (
            <InfoGridElement key={index} {...itemProps} />
          ))}
        </Styled.InfoGridMount>
      </Styled.InfoGrid>
    </>
  );
};

export default InfoGrid;
