import React from 'react';
import { orderBy } from 'lodash';

export type SortOrder = 'asc' | 'desc';

export type ListIterator<T, TResult> = (
  value: T,
  index?: number,
  collection?: ArrayLike<T>,
) => TResult;

export type SortedDataInput<T extends Record<string, unknown>> = {
  data: T[];
  sortBy:
    | string
    | ListIterator<T, unknown>
    | (string | ListIterator<T, unknown>)[];
  initialSortOrder?: SortOrder;
};

export type SortedDataOutput<T extends Record<string, unknown>> = {
  sortedData: T[];
  setSortOrder: (sortOrder: SortOrder) => void;
};

const useSortedData = <T extends Record<string, unknown>>({
  data,
  sortBy,
  initialSortOrder = 'asc',
}: SortedDataInput<T>): SortedDataOutput<T> => {
  const [sortOrder, setSortOrder] = React.useState<SortOrder>(initialSortOrder);
  const sortedData = React.useMemo(
    () => {
      if (typeof sortBy === "function") {
        const undefs = data.filter(di => sortBy(di) === undefined || sortBy(di) === 0)
        const nonUndefs = data.filter(di => sortBy(di) !== undefined && sortBy(di) !== 0)
        return [...orderBy(nonUndefs, sortBy, [sortOrder]), ...orderBy(undefs, "title", ["asc"])];
      }
      return orderBy(data, sortBy, [sortOrder])
    },
    [data, sortBy, sortOrder],
  );

  return {
    sortedData,
    setSortOrder,
  };
};

export default useSortedData;
