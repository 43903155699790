import React from 'react';
import Styled from './Spinner.styled';
import { animated, useSpring } from '@react-spring/web';

const Spinner = ({ className }: { className?: string }) => {
  const styles = useSpring({
    loop: true,
    from: { rotate: 0 },
    to: { rotate: 360 },
    config: { duration: 1000 },
  });
  const styles2 = useSpring({
    loop: { reverse: true },
    from: { strokeDashoffset: 2.8284271558125815 },
    to: { strokeDashoffset: 0.707106789 },
    config: { duration: 1500 },
  });

  return (
    <Styled.Spinner className={className}>
      <animated.svg
        viewBox="0 0 1 1"
        style={{
          ...styles,
          ...styles2,
        }}
      >
        <circle cx="0.5" cy="0.5" r="0.45" />
      </animated.svg>
    </Styled.Spinner>
  );
};

export default Spinner;
