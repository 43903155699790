import React from 'react';
import rehypeReact from 'rehype-react';
import MarkdownLink from '../contentful/callToActions/markdownLink';

const useHtmlAst = (htmlAst: any) => {
  if (!htmlAst) return null;
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    Fragment: React.Fragment,
    components: { cta: MarkdownLink },
  }).Compiler;

  return renderAst(htmlAst);
};

export default useHtmlAst;
