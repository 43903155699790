import React from 'react';
import { graphql } from 'gatsby';
import InfoBox from '../../components/common/infobox/InfoBox';
import useHtmlAst from '../../hooks/useHtmlAst';
import { ContentfulMarkdownTypes } from '../templates/types';

export type ContentfulInfoBoxTypes = {
  body: ContentfulMarkdownTypes;
};

const ContentfulInfoBox = ({
  body,
}: ContentfulInfoBoxTypes): JSX.Element | null => {
  const bodyText = useHtmlAst(body?.childMarkdownRemark?.htmlAst);
  if (!bodyText) return null;
  return <InfoBox items={[]} html={bodyText} />;
};

export default ContentfulInfoBox;

export const contentfulInfoBoxQuery = graphql`
  fragment contentfulInfoBoxFields on ContentfulInfoBox {
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
  }
`;
