import React from 'react';
import Styled from './Card.styled';
import { CardTagProps } from './types';
import CardTag from './CardTag';

const CardTags = ({ tags }: { tags: CardTagProps[] }): JSX.Element => (
  <Styled.CardTags>
    {tags.map((tagProps, index) => (
      <CardTag key={index} {...tagProps} />
    ))}
  </Styled.CardTags>
);

export default CardTags;
