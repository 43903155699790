import React from 'react';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';
import { Icons } from '../../../types/icon-types';
import { ButtonLinkWrapper, TertiaryButton } from '../button/Button';
import Card, { CardProps, CardType } from '../card/Card';
import Icon from '../icon/Icon';
import Styled from './CardGrid.styled';

export type CardGridColumnProps = {
  columns?: 1 | 2 | 3;
  mobileScrollable?: boolean;
};

export type CardGridProps<T extends CardType = CardType.Default> = {
  cards: CardProps<T>[];
  callToAction?: ContentfulCallToActionTypes;
} & CardGridColumnProps;

const renderCard = <T extends CardType>(props: CardProps<T>) => {
  const { id } = props;
  return <Card key={id} {...props} role="list-item" />;
};

const CardGrid = <T extends CardType>({
  columns = 3,
  cards = [],
  callToAction,
  mobileScrollable = true,
}: CardGridProps<T>): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);

  return (
    <>
      <Styled.CardGrid
        role="list"
        columns={columns}
        mobileScrollable={mobileScrollable}
      >
        {cards.map(renderCard)}
      </Styled.CardGrid>
      {linkProps && (
        <Styled.CardGridFooter>
          <ButtonLinkWrapper {...linkProps}>
            <TertiaryButton>
              {linkProps.label} <Icon type={Icons.ChevronDownLarge} />
            </TertiaryButton>
          </ButtonLinkWrapper>
        </Styled.CardGridFooter>
      )}
    </>
  );
};

export default CardGrid;
