import React from 'react';

type HookProps = {
  ref: React.MutableRefObject<HTMLDivElement | null>;
};

type HookReturnType = {
  isOpen: boolean;
  onDropdownClick: () => void;
};

const useDropdownToggle = ({ ref }: HookProps): HookReturnType => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onDropdownClick = React.useCallback(
    () => setIsOpen((wasOpen) => !wasOpen),
    [],
  );

  React.useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent | TouchEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);
    document.addEventListener('touchstart', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
      document.removeEventListener('touchstart', checkIfClickedOutside);
    };
  }, [isOpen, ref]);

  return {
    isOpen,
    onDropdownClick,
  };
};

export default useDropdownToggle;
