import styled from 'styled-components';
import { below, Breakpoint } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import { hover } from '../../../styles/utils';
import {
  LinkUnderlineCSS,
  LinkUnderLineHoverCSS,
  Typography,
} from '../typography/Typography.styled';

const ListFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  //grid-column-start: 1;
  //grid-column-end: -1;
  align-items: flex-start;
  gap: 1rem;
`;

const ListFiltersLabel = styled(Typography.LabelSmall)`
  padding-top: 0.5rem;
  flex-shrink: 0;
  color: ${AppColor.LeviBlue};

  ${below(Breakpoint.md)} {
    display: none;
  }
`;

const ListFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const ResetButton = styled(Typography.LabelSmall).attrs({ as: 'button' })`
  line-height: 1;
  padding: 0.5rem 0;
  margin: 0 1rem;
  display: block;
  align-self: baseline;
  color: ${AppColor.LeviBlue};
  ${hover} {
    cursor: pointer;
    ${LinkUnderlineCSS}

    &:hover {
      ${LinkUnderLineHoverCSS}
    }
  }
`;

export default {
  ListFiltersContainer,
  ListFiltersLabel,
  ListFilters,
  ResetButton,
};
