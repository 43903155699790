import { css } from 'styled-components';

// You can rename and move this
const innerHtmlElements = css`
  .contentfulMarkdown {
    img {
      max-width: 100%;
    }
  }
  .restaurant-menu {
    ul + p {
      margin-top: 2.5rem;
    }
  }

  .gatsby-image-wrapper {
    overflow: visible;
  }

  .gatsby-image-wrapper img[alt*="Bike-Park-kartta"] {
    width: var(--max-content-width);
    position: relative;
    left: calc(var(--max-content-width) / -2 + 50%);
    height: auto;
    margin-top: -70%;
  }
`;

export default innerHtmlElements;
