import React from 'react';
import { MappedContentfulCategory } from '../../../hooks/useContentfulCategoryData';

import { Icons } from '../../../types/icon-types';
import { getEnumKeyByString } from '../../../utils/enum-utils';
import CategoryTab from './CategoryTab';
import Styled from './CategoryTabs.styled';

type CategoryTabsProps = {
  allCategoryTitle?: string;
  categories: MappedContentfulCategory[];
  onSelect?: (category?: MappedContentfulCategory) => void;
  selected: MappedContentfulCategory | undefined;
};

const CategoryTabs = ({
  allCategoryTitle,
  categories,
  onSelect,
  selected,
}: CategoryTabsProps): JSX.Element => {
  return (
    <Styled.Tabs>
      {allCategoryTitle && (
        <CategoryTab
          key={allCategoryTitle}
          icon={undefined}
          selected={selected === undefined}
          onClick={() => {
            onSelect && onSelect(undefined);
          }}
        >
          {allCategoryTitle}
        </CategoryTab>
      )}
      {categories.map((category) => {
        const { icon: iconString, title } = category;
        const icon = iconString
          ? getEnumKeyByString(Icons, iconString)
          : undefined;

        return (
          <CategoryTab
            key={title}
            icon={icon ? Icons[icon] : undefined}
            selected={selected === category}
            onClick={() => {
              onSelect && onSelect(category);
            }}
          >
            {title}
          </CategoryTab>
        );
      })}
    </Styled.Tabs>
  );
};

export default CategoryTabs;
