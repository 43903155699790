import { ApiPath } from '..';
import { LiveCamera, StillCamera } from '../types/SlopeCameraDataTypes';
import { ApiDataParser } from './ApiDataParser';

export const slopeCamerasParser: ApiDataParser<ApiPath.SlopeCameras> = (
  data,
) => {
  const stillCameras: StillCamera[] = [];
  const liveCameras: LiveCamera[] = [];
  data.forEach(({ videoId, stillUrl, name }) => {
    if (videoId) {
      liveCameras.push({ name, videoId });
    } else if (stillUrl) {
      stillCameras.push({ name, stillUrl });
    }
  });

  return { stillCameras, liveCameras };
};
