import styled from 'styled-components';
import { Icons, ICONS_CODEPOINTS } from '../../../types/icon-types';
import { ImageIconAssets, ImageIcons } from '../../../types/image-icon-types';
import ImageIcon from './ImageIcon';

/** To add new icons:
 * 1) drop the svg to /svg-assets/original -folder
 * 2) run `yarn batch-svg`
 * 3) validate generated icon in storybook (under Icons)
 * 4) if the icon does not show, the svg might need to be converted to path(s) manually. ask @saario for assistance.
 *
 * - use consistent size for svg's
 * - follow naming conventions
 * - don't use custom folders for organizing the assets, the scripts run only in the predefined folder for now.
 */

export enum IconSize {
  Size08 = 0.5,
  Size16 = 1,
  Size24 = 1.5,
  Size32 = 2,
  Size48 = 3,
  Size64 = 4,
}

export type IconProps = {
  type: Icons | ImageIcons;
  size?: IconSize;
};

const isImageIcon = (type: Icons | ImageIcons): type is ImageIcons =>
  !!ImageIconAssets[type as ImageIcons];

const Icon = styled.figure.attrs<IconProps>(({ type }) => {
  if (!isImageIcon(type)) {
    const codePoint = +ICONS_CODEPOINTS[type];
    if (isNaN(codePoint)) {
      return;
    }
    return {
      'data-icon': String.fromCodePoint(+ICONS_CODEPOINTS[type]),
    };
  } else {
    return {
      as: ImageIcon,
    };
  }
})<IconProps>`
  display: inline-block;
  vertical-align: middle;
  ${(props) =>
    !isImageIcon(props.type)
      ? `
    font-family: icons !important;
    font-size: ${props.size || IconSize.Size16}rem;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    line-height: 1;

    &:before {
      content: attr(data-icon);
    }
  `
      : `
    width: ${props.size || IconSize.Size16}rem;
    height: ${props.size || IconSize.Size16}rem;
    //background-image: url(${ImageIconAssets[props.type]});
    //background-repeat: no-repeat;
    //background-size: 100% 100%;
  `}
`;

export default Icon;
