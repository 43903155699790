import React from 'react';
import { animated, useTransition } from '@react-spring/web';

const Mount = ({
  className,
  children,
  show,
  delay = 0,
}: {
  className?: string;
  children: React.ReactNode;
  show: boolean;
  delay?: number;
}) => {
  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay,
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div className={className} style={styles}>
          {children}
        </animated.div>
      ),
  );
};

export default Mount;
