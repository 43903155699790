import { AppColor } from './colors';
import { merge } from 'lodash';

type ThemeDef = {
  readonly [key: string]: {
    readonly [key: string]: string;
  };
};

const extendTheme = (theme: ThemeDef, withThemeDef: ThemeDef): ThemeDef =>
  merge(JSON.parse(JSON.stringify(theme)), { ...withThemeDef });

const defaultTheme: ThemeDef = {
  base: {
    primaryHeadingColor: AppColor.LeviBlue,
    secondaryHeadingColor: AppColor.Neutral700,
    bodyColor: AppColor.Neutral700,
    linkColor: AppColor.LeviBlue,
  },
  btn: {
    bg: 'transparent',
    bgHover: AppColor.LeviBlueHover,
    borderColor: AppColor.LeviBlue,
    color: AppColor.LeviBlue,
    disabledBorderColor: AppColor.Neutral500,
    disabledColor: AppColor.Neutral500,
  },
  card: {
    colorHeading: AppColor.LeviBlue,
    color: AppColor.Neutral700,
  },
  cardGrid: {
    colorHeadingPrimary: AppColor.LeviBlue,
    colorHeadingSecondary: AppColor.Neutral700,
    colorHeadingTertiary: AppColor.Neutral500,
  },
  nav: {
    logoSize: '3.375rem',
  },
  searchBar: {
    bg: AppColor.Neutral200,
  },
};

const alertTheme: ThemeDef = extendTheme(defaultTheme, {
  base: {
    bodyColor: AppColor.White,
    linkColor: AppColor.White,
  },
  btn: {
    bgHover: AppColor.White,
    borderColor: AppColor.White,
    color: AppColor.White,
    colorHover: AppColor.ErrorRed,
  },
});

const sustainabilityTheme: ThemeDef = extendTheme(defaultTheme, {
  base: {
    primaryHeadingColor: AppColor.SustainableGreen,
    linkColor: AppColor.SustainableGreen,
  },
  btn: {
    bgHover: AppColor.SustainableGreen,
    borderColor: AppColor.SustainableGreen,
    color: AppColor.SustainableGreen,
  },
});

const leviBlackTheme: ThemeDef = extendTheme(defaultTheme, {});

const primaryTheme: ThemeDef = extendTheme(defaultTheme, {
  btn: {
    bg: AppColor.LeviBlue,
    borderColor: 'transparent',
    color: AppColor.White,
    disabledBg: AppColor.Neutral300,
    disabledBorderColor: 'transparent',
    disabledColor: AppColor.White,
  },
  nav: {
    bgColor: 'transparent',
    bgShadow: 'none',
    logoSize: '4rem',
    verticalAlign: 'flex-start',
    verticalPadding: '1.25rem',
  },
  searchBar: {
    bg: 'rgb(255 255 255 / 0.9)',
  },
});

const primaryThemeInverse: ThemeDef = extendTheme(primaryTheme, {
  base: {
    primaryHeadingColor: AppColor.White,
    secondaryHeadingColor: AppColor.White,
    bodyColor: AppColor.White,
  },
  btn: {
    bg: AppColor.White,
    color: AppColor.LeviBlue,
    disabledBg: AppColor.Neutral300,
    disabledBorderColor: 'transparent',
    disabledColor: AppColor.White,
  },
  nav: {
    color: AppColor.White,
  },
});

export default {
  defaultTheme,
  alertTheme,
  sustainabilityTheme,
  leviBlackTheme,
  primaryTheme,
  primaryThemeInverse,
};
