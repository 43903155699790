import React from 'react';
import { LocaleType } from '../../contentful/templates/types';
import {
  SkiResortAmenityType,
  SkiResortAmenitiesData,
} from '../../hooks/useSkiResortAmenitiesData';
import { localize } from '../../localization/i18n';
import useLocale from '../../localization/useLocale';
import { SlopeLocation } from '../../network/skiresort-api/parsers/slopeDataParser';
import { Lift } from '../../network/skiresort-api/types/LiftDataTypes';
import { Restaurant } from '../../network/skiresort-api/types/RestaurantDataTypes';
import { Service } from '../../network/skiresort-api/types/ServiceDataTypes';
import { Slope } from '../../network/skiresort-api/types/SlopeDataTypes';
import { Typography } from '../common/typography/Typography.styled';
import { groupBy as _groupBy } from 'lodash';
import LiftRow from './LiftRow';
import RestaurantRow from './RestaurantRow';
import ServiceRow from './ServiceRow';
import Styled from './SkiResortAmenities.styled';
import SlopeRow from './SlopeRow';
import useSimpleMasonry from '../../hooks/useSimpleMasonry';
import BikeTrailRow from './BikeTrailRow';
import OtherActivityRow from './OtherActivityRow';
import { OtherActivity } from '@Levi/network/skiresort-api/types/OtherActivitiesTypes';

export type RowProps<T extends { id: number } = { id: 0 }> = {
  data: T;
  locale: LocaleType;
};

const rowComponents = {
  [SkiResortAmenityType.BikeTrail]: BikeTrailRow,
  [SkiResortAmenityType.Lifts]: LiftRow,
  [SkiResortAmenityType.OtherActivities]: OtherActivityRow,
  [SkiResortAmenityType.Restaurants]: RestaurantRow,
  [SkiResortAmenityType.Services]: ServiceRow,
  [SkiResortAmenityType.Slopes]: SlopeRow,
};

type DataType = {
  [SkiResortAmenityType.BikeTrail]: Slope;
  [SkiResortAmenityType.Lifts]: Lift;
  [SkiResortAmenityType.Restaurants]: Restaurant;
  [SkiResortAmenityType.Services]: Service;
  [SkiResortAmenityType.Slopes]: Slope;
  [SkiResortAmenityType.OtherActivities]: OtherActivity;
};

const rowComponent = <T extends SkiResortAmenityType>(
  type: T,
): React.FC<RowProps<DataType[T]>> =>
  rowComponents[type] as React.FC<RowProps<DataType[T]>>;

const locations = Object.values(SlopeLocation).filter((key) => key !== 'all');
type GroupedDataType = {
  [key in keyof SkiResortAmenitiesData]: {
    [xkey in SlopeLocation]: SkiResortAmenitiesData[key];
  };
};

const SkiResortAmenitiesList = ({
  data,
  groupBy,
}: {
  data: SkiResortAmenitiesData;
  groupBy: 'type' | 'location';
}) => {
  const locale = useLocale();
  const keys = Object.keys(data) as SkiResortAmenityType[];

  const gridRef = React.useRef<HTMLDivElement>(null);

  const { resizeGrid } = useSimpleMasonry(gridRef);

  React.useEffect(() => resizeGrid(), [resizeGrid, data]);

  if (groupBy === 'type') {
    return (
      <Styled.ListWrapper ref={gridRef} sections={keys.length}>
        {keys.map((key) => {
          const d = data[key];
          if (d === undefined || d.length === 0) {
            return null;
          }
          const Component = rowComponent(key);
          return (
            <Styled.ListContainer key={key}>
              <Styled.ListContent>
                <Styled.ListTitle>
                  {localize(`amenities.${key}`)}
                </Styled.ListTitle>
                <Styled.List>
                  {d.map((item) => (
                    <Component key={item.id} data={item} locale={locale} />
                  ))}
                </Styled.List>
              </Styled.ListContent>
            </Styled.ListContainer>
          );
        })}
      </Styled.ListWrapper>
    );
  }

  const groupedData = keys.reduce((acc, curr) => {
    const grouped = _groupBy(data[curr], 'slopeLocation');
    return {
      ...acc,
      [curr]: grouped,
    };
  }, {} as GroupedDataType);

  return (
    <Styled.ListWrapper ref={gridRef}>
      {locations.map((locationKey) => (
        <Styled.ListContainer key={locationKey}>
          <Styled.ListContent>
            <Styled.ListTitle>
              {localize(`slopes.locations.${locationKey}`)}
            </Styled.ListTitle>
            {keys.map((key) => {
              const Component = rowComponent(key);
              const gData = groupedData[key][locationKey];
              if (gData) {
                return (
                  <React.Fragment key={key}>
                    <Styled.SlopeSectionTitle>
                      <Typography.SubHeadingSmall>
                        {localize(`amenities.${key}`)}
                      </Typography.SubHeadingSmall>
                    </Styled.SlopeSectionTitle>
                    <Styled.List>
                      {gData.map((item) => {
                        return (
                          <Component
                            key={item.id}
                            data={item}
                            locale={locale}
                          />
                        );
                      })}
                    </Styled.List>
                  </React.Fragment>
                );
              }
            })}
          </Styled.ListContent>
        </Styled.ListContainer>
      ))}
    </Styled.ListWrapper>
  );
};

export default SkiResortAmenitiesList;
