import { ApiPath } from '..';
import {
  LocaleType,
  LocalizedString,
} from '../../../contentful/templates/types';
import { WeeklyProgrammeDays } from '../types/ProgrammeDataTypes';
import { ApiDataParser } from './ApiDataParser';
import { stripHtmlString } from './utils';

const parseSingleWeekProgramme = (programme: WeeklyProgrammeDays) => {
  return Object.keys(programme).reduce((acc, day) => {
    const dayKey = day as keyof WeeklyProgrammeDays;
    const currentDay = programme[dayKey];
    return {
      ...acc,
      [day]: Object.keys(currentDay).reduce((acc, locale) => {
        const localeKey = locale as LocaleType;
        return {
          ...acc,
          [localeKey]: stripHtmlString(currentDay[localeKey]),
        };
      }, {} as LocalizedString),
    };
  }, {} as WeeklyProgrammeDays);
};

// TODO Parse single Programme
export const programmeDataParser: ApiDataParser<ApiPath.Programme> = (
  data,
) => ({
  ...data,
  currentWeek: {
    ...data.currentWeek,
    programme: parseSingleWeekProgramme(data.currentWeek.programme),
  },
  allProgrammes: data.allProgrammes.map((programmeItem) => {
    return {
      ...programmeItem,
      programme: parseSingleWeekProgramme(programmeItem.programme),
    };
  }),
});
