import React from 'react';
import Styled from './CardList.styled';
import { Typography } from '../typography/Typography.styled';
import { ButtonLinkWrapper, TertiaryButton } from '../button/Button';
import Icon from '../icon/Icon';
import { Icons } from '../../../types/icon-types';
import { NavLinkProps } from '../../navigation/main/MainNavigationPropTypes';
import { Link } from "@Levi/components/common/link";
import Card, { CardProps, CardType } from '../card/Card';
import { HorizontalCardType } from '../horizontal-card/HorizontalCard';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';

type NavLinkOrButtonProps =
  | NavLinkProps
  | (Pick<NavLinkProps, 'label'> & { onClick: () => void });

export type CardListProps<T extends CardType = CardType.Default> = {
  title?: string;
  type?: HorizontalCardType;
  cards: CardProps<T>[];
  moreLink?: NavLinkOrButtonProps;
  callToAction?: ContentfulCallToActionTypes;
};

const isNavLinkProps = (
  moreLink: NavLinkOrButtonProps,
): moreLink is NavLinkProps => !!(moreLink as NavLinkProps).to;

const renderCard =
  (type: HorizontalCardType) =>
  <T extends CardType>(props: CardProps<T>) => {
    return <Card key={props.id} {...props} horizontal={type} />;
  };

const CardList = <T extends CardType>({
  type = HorizontalCardType.Default,
  title,
  cards = [],
  moreLink = undefined,
  callToAction,
}: CardListProps<T>): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);

  return (
    <>
      {title && (
        <Styled.CardListHeader>
          <Typography.SubHeadingLarge>{title}</Typography.SubHeadingLarge>
        </Styled.CardListHeader>
      )}
      <Styled.CardList role="list">
        {cards.map(renderCard(type))}
        {moreLink && (
          <Styled.CardListFooter>
            {isNavLinkProps(moreLink) ? (
              <Link to={moreLink.to}>
                <TertiaryButton>
                  {moreLink.label} <Icon type={Icons.ChevronDownLarge} />
                </TertiaryButton>
              </Link>
            ) : (
              <TertiaryButton onClick={moreLink.onClick}>
                {moreLink.label} <Icon type={Icons.ChevronDownLarge} />
              </TertiaryButton>
            )}
          </Styled.CardListFooter>
        )}
        {linkProps && (
          <Styled.CardListFooter>
            <ButtonLinkWrapper {...linkProps}>
              <TertiaryButton>
                {linkProps.label} <Icon type={Icons.ChevronDownLarge} />
              </TertiaryButton>
            </ButtonLinkWrapper>
          </Styled.CardListFooter>
        )}
      </Styled.CardList>
    </>
  );
};

export default CardList;
