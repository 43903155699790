import React from 'react';

import { GatsbyLinkProps } from 'gatsby';
import { Link } from "@Levi/components/common/link";
import Styled from './MainNavigationLink.styled';

interface MainNavigationLinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  label: string;
}

const MainNavigationLink = React.forwardRef<
  HTMLLIElement,
  MainNavigationLinkProps
>(({ label, children, ...props }, ref) => (
  <Styled.NavLinkLi ref={ref} hasSubPages={!!children}>
    <Styled.NavLink as={Link} {...props}>
      {label}
    </Styled.NavLink>
    {children}
  </Styled.NavLinkLi>
));

export default MainNavigationLink;
