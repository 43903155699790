import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useLocale from '../../localization/useLocale';
import CardGridHeader from '../../components/common/cardgrid/CardGridHeader';
import useContentfulTransformedCardGridData from '../../hooks/useContentfulTransformedCardGridData';
import { CardType } from '../../components/common/card/Card';
import CardList from '../../components/common/cardlist/CardList';
import { HorizontalCardType } from '../../components/common/horizontal-card/HorizontalCard';
import Styled from './ContentfulBlogsSection.styled';

export type ContentfulBlogsSectionTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  limitCount?: number;
  layout?: string;
};

const query = graphql`
  query blogsQuery {
    en: allContentfulBlogPost(
      filter: { node_locale: { eq: "en" }, hideFromLocalization: { ne: true } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...blogPostCardFields
          horizontalCardImage: image {
            ...contentfulHorizontalCardImage
          }
        }
      }
    }
    fi: allContentfulBlogPost(
      filter: { node_locale: { eq: "fi" } }
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...blogPostCardFields
          horizontalCardImage: image {
            ...contentfulHorizontalCardImage
          }
        }
      }
    }
  }
`;

const ContentfulBlogsSection = ({
  title,
  limitCount,
  layout,
}: ContentfulBlogsSectionTypes): JSX.Element => {
  const locale = useLocale();
  const data = useStaticQuery(query);
  const items = limitCount
    ? data[locale].edges.slice(0, limitCount)
    : data[locale].edges;
  const cards = useContentfulTransformedCardGridData({
    cards: items,
    type: CardType.BlogPost,
  });

  if (layout === 'tight') {
    return (
      <CardList title={title} type={HorizontalCardType.Default} cards={cards} />
    );
  }

  return (
    <Styled.MainSection centered>
      <CardGridHeader title={title} centered />
      <CardList type={HorizontalCardType.Default} cards={cards} />
    </Styled.MainSection>
  );
};

export default ContentfulBlogsSection;

export const contentfulBlogsSectionQuery = graphql`
  fragment contentfulBlogsSectionFields on ContentfulBlogsSection {
    id
    internal {
      type
    }
    title
    limitCount
  }
`;
