import {
  addMonths,
  differenceInCalendarDays,
  eachDayOfInterval,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  isWithinInterval,
  startOfToday,
} from 'date-fns';

import { some, range } from 'lodash';
import { Days } from '../components/common/calendar/calendarStore';

export type DateRange = { start?: Date; end?: Date };
export type DateRangeValid = Required<DateRange>;

export type DateSelectable = Date;
export type DateRangeSelectable = DateRangeValid;

export const extractTimeFrom = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate());

export const isDateSelectable = (date: Date): date is DateSelectable => {
  const testDate = extractTimeFrom(date);
  return isToday(testDate) || isAfter(testDate, startOfToday());
};

export const isDateRangeSelectable = (
  dateRange: DateRangeValid,
): dateRange is DateRangeSelectable => isDateSelectable(dateRange.start);

export const isDateRange = (date: Date | DateRange): date is DateRange =>
  'start' in date;

export const isValidDateRange = (
  date: Date | DateRange,
): date is DateRangeValid =>
  !!(date as DateRange).start && !!(date as DateRange).end;

export const isSameYearDateRange = (date: DateRange): boolean =>
  isValidDateRange(date) && isSameYear(date.start, date.end);

export const isSameMonthDateRange = (date: DateRange): boolean =>
  isValidDateRange(date) && isSameMonth(date.start, date.end);

export const isSameDayDateRange = (date: DateRange): boolean =>
  isValidDateRange(date) && isSameDay(date.start, date.end);

export const isOneOfDates = (date: Date, dates: Date[]): boolean => {
  if (!dates || dates.length === 0) return false;
  return some(dates, (compareDate) => isSameDay(date, compareDate));
};

export const calendarMonthToDays = (month: Date): Days =>
  calendarMonthDates(month).map((date) => ({
    key: date.getTime(),
    date,
    displayDate: date.getDate(),
    isActive: false,
    isDisabled: false,
    isSelected: false,
    isFirst: false,
    isLast: false,
    isInRange: false,
    isVisible: false,
  }));

export const calendarMonthsToDays = (month: Date, numberOfMonths = 1): Days =>
  calendarMonthsDates(month, numberOfMonths).map((date) => ({
    key: date.getTime(),
    date,
    displayDate: date.getDate(),
    isActive: false,
    isDisabled: false,
    isSelected: false,
    isFirst: false,
    isLast: false,
    isInRange: false,
    isVisible: false,
  }));

export const datesInMonth = (month: Date): Date[] => {
  const start = new Date(month.getFullYear(), month.getMonth(), 1);
  const end = new Date(
    month.getFullYear(),
    month.getMonth(),
    getDaysInMonth(month),
  );

  return eachDayOfInterval({ start, end });
};

export const calendarMonthsDates = (
  monthDate: Date,
  numberOfMonths = 1,
): Date[] => {
  const currentMonth = new Date(
    monthDate.getFullYear(),
    monthDate.getMonth(),
    1,
  );

  if (numberOfMonths < 1) {
    throw new Error('Invalid numberOfMonths');
  }

  const otherMonths = [
    -1,
    ...Array(numberOfMonths)
      .fill(0)
      .map((i, index) => index + 1),
  ].map((num) => addMonths(currentMonth, num));

  const [prevMonth, ...rest] = otherMonths;
  const nextToLastMonth = rest.pop() as Date;

  const currentMonthStartDay = currentMonth.getDay();
  const prevPadding = range(
    currentMonthStartDay === 0 ? 6 : currentMonthStartDay - 1,
  )
    .reduce((days: number[], i) => [getDaysInMonth(prevMonth) - i, ...days], [])
    .map(
      (date) => new Date(prevMonth.getFullYear(), prevMonth.getMonth(), date),
    );

  const monthDates = [currentMonth, ...rest].flatMap((month) =>
    datesInMonth(month),
  );

  const remainder = (prevPadding.length + monthDates.length) % 7;
  const nextPadding = range(remainder ? Math.abs(remainder - 7) : 0).map(
    (i) =>
      new Date(
        nextToLastMonth.getFullYear(),
        nextToLastMonth.getMonth(),
        i + 1,
      ),
  );

  return [...prevPadding, ...monthDates, ...nextPadding];
};

export const calendarMonthDates = (monthDate: Date): Date[] => {
  const currentMonth = new Date(
    monthDate.getFullYear(),
    monthDate.getMonth(),
    1,
  );
  const [prevMonth, nextMonth] = [-1, 1].map((num) =>
    addMonths(currentMonth, num),
  );

  const currentMonthStartDay = currentMonth.getDay();
  const prevPadding = range(
    currentMonthStartDay === 0 ? 6 : currentMonthStartDay - 1,
  )
    .reduce((days: number[], i) => [getDaysInMonth(prevMonth) - i, ...days], [])
    .map(
      (date) => new Date(prevMonth.getFullYear(), prevMonth.getMonth(), date),
    );

  const monthDates = datesInMonth(currentMonth);

  const remainder = (prevPadding.length + monthDates.length) % 7;
  const nextPadding = range(remainder ? Math.abs(remainder - 7) : 0).map(
    (i) => new Date(nextMonth.getFullYear(), nextMonth.getMonth(), i + 1),
  );

  return [...prevPadding, ...monthDates, ...nextPadding];
};

export const isInHoverRange = (
  date: Date,
  hoverDate: Date,
  { start }: DateRange,
): boolean =>
  start
    ? !isEqual(hoverDate, start) &&
      isWithinInterval(date, {
        start: isBefore(hoverDate, start) ? hoverDate : start,
        end: isBefore(hoverDate, start) ? start : hoverDate,
      })
    : false;

export const isFirstInHoverRange = (
  date: Date,
  hoverDate: Date,
  { start }: DateRange,
): boolean =>
  start
    ? (isEqual(start, date) && isAfter(hoverDate, date)) ||
      (isEqual(hoverDate, date) && isBefore(hoverDate, start))
    : false;

export const isLastInHoverRange = (
  date: Date,
  hoverDate: Date,
  { start }: DateRange,
): boolean =>
  start
    ? (isEqual(start, date) && isBefore(hoverDate, date)) ||
      (isEqual(hoverDate, date) && isAfter(hoverDate, start))
    : false;

export const isSelected = (date: Date, selectedDate: Date): boolean =>
  isEqual(selectedDate, date);

export const isSelectedInRange = (
  date: Date,
  selectedDateRange: DateRange,
): boolean =>
  isValidDateRange(selectedDateRange)
    ? isWithinInterval(date, selectedDateRange)
    : selectedDateRange.start
    ? isEqual(date, selectedDateRange.start)
    : false;

export const isInRange = (
  date: Date,
  selectedDateRange: DateRangeValid,
): boolean =>
  isAfter(date, selectedDateRange.start) &&
  isBefore(date, selectedDateRange.end);

export const isFirstInRange = (
  date: Date,
  selectedDateRange: DateRangeValid,
): boolean => isEqual(date, selectedDateRange.start);

export const isLastInRange = (
  date: Date,
  selectedDateRange: DateRangeValid,
): boolean => isEqual(date, selectedDateRange.end);

export const differenceInDays = (start: Date, end: Date): number => {
  return (end.getTime() - start.getTime()) / (1000 * 3600 * 24);
};

export const differenceInFullDays = (start: Date, end: Date): number => {
  return differenceInCalendarDays(end, start);
};

export const getDateRangeByWeekNumber = (week: number, year: number) => {
  const simple = new Date(year, 0, 1 + (week - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) {
    ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  } else {
    ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  }
  const ISOweekEnd = new Date(ISOweekStart);
  ISOweekEnd.setDate(ISOweekEnd.getDate() + 6);
  return {
    start: ISOweekStart,
    end: ISOweekEnd,
  };
};

export const getLocalSQLDate = (date: Date) => {
  const year = date.getFullYear();
  const month = ('00' + (date.getMonth() + 1)).slice(-2);
  const day = ('00' + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};
