import React from "react";
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import ContentfulHero, { ContentfulHeroProps } from "../../contentful/hero/contentfulHero";
import styled, { keyframes } from "styled-components";
import { Breakpoint } from "../../styles/breakpoints";
import PageHeader from './PageHeader.styled';

const appear = keyframes`
  0%   {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
`;

const fadeSpeed   = 0.5;  // How fast in/out fade slide contents
const textDelay   = 1.0;  // Delay for text section
const actionDelay = 1.1;  // Delay for action section

export const StyledSwiper = styled(Swiper)`
  //
  // Style default swiper components to fit Levi

  & > .swiper-button-prev,
  & > .swiper-button-next {
    color: white;
  }

  & .swiper-pagination-bullet {
    border: 2px solid white;
    background-color: transparent;
    opacity: 1;
  }

  & .swiper-pagination-bullet-active {
    background-color: white;
  }

  //
  // Custom slide animations

  &:not(.noAnim) .swiper-slide {
    // Setup text and action sections
    ${PageHeader.TextContainer},
    ${PageHeader.ActionsContainer} {
      transition: opacity ${fadeSpeed}s ease;

      // Animate text and action out
      &:not(.swiper-slide-active){
        opacity: 0;
      }
    }

    // Animate text and actions in
    &.swiper-slide-active ${PageHeader.TextContainer} {
      animation: ${appear} ${fadeSpeed}s ease ${textDelay}s;
      transition: opacity ${fadeSpeed}s ease ${textDelay}s;
      opacity: 1;
    }
    &.swiper-slide-active ${PageHeader.ActionsContainer} {
      animation: ${appear} ${fadeSpeed}s ease ${actionDelay}s;
      transition: opacity ${fadeSpeed}s ease ${actionDelay}s;
      opacity: 1;
    }
  }
`;

export interface HeroCarouselProps {
  heroCarousel: ContentfulHeroProps[];
}

export type HeroCarouselType = (props: HeroCarouselProps)=>JSX.Element;

/** Switch between provided hero elements */
export const HeroCarousel: HeroCarouselType = ({heroCarousel})=>{
  const loop = heroCarousel.length > 1;
  const className = loop ? undefined : "noAnim";

  // Don't use Swiper if only one image (fixes nav arrow bug)
  if(heroCarousel.length == 1)
    return <ContentfulHero {...heroCarousel[0]}/>

  return (
    <StyledSwiper
      className={className}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      loop={loop}
      pagination={{
        type: "bullets",
      }}
      breakpoints={{
        [Breakpoint.lg]: {
          navigation: {enabled: true},
        }
      }}
      navigation={{enabled: false}}
      autoplay={{
        delay: 10 * 1000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      effect="fade"
      speed={1000}
    >
      {heroCarousel.map((hero, i) => (
        <SwiperSlide key={i}>
          <ContentfulHero {...hero}/>
        </SwiperSlide>
      ))}
    </StyledSwiper>
  );
}
