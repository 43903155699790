import React from 'react';
import { Typography } from '../typography/Typography.styled';
import Dropdown, { DropdownProps } from './Dropdown';
import Styled from './Dropdown.styled';

export type LabeledDropdownProps = {
  dropdownLabel: string;
} & DropdownProps;

const LabeledDropdown = ({
  dropdownLabel,
  ...rest
}: LabeledDropdownProps): JSX.Element => (
  <Styled.LabeledContainer>
    <Typography.SubLink>{dropdownLabel}</Typography.SubLink>
    <Dropdown ariaLabel={dropdownLabel} {...rest} />
  </Styled.LabeledContainer>
);

export default LabeledDropdown;
