import React from 'react';

type AnchorPoint = 'center' | 'left';

export const useDropdownContainerCalculations = ({
  refWrapper,
  refContainer,
  anchorPoint = 'center',
}: {
  refWrapper: React.RefObject<HTMLElement>;
  refContainer?: React.RefObject<HTMLElement>;
  anchorPoint?: AnchorPoint;
}) => {
  const recalculate = React.useCallback(() => {
    if (!refWrapper.current) {
      return;
    }

    const refC =
      refContainer && refContainer.current
        ? refContainer.current
        : refWrapper.current;

    const clientRect = refC.getBoundingClientRect();
    const computedStyle = getComputedStyle(refWrapper.current);
    const mid = parseFloat(
      computedStyle.getPropertyValue('--offset-mid') || '0',
    );
    const min = parseFloat(
      computedStyle.getPropertyValue('--offset-min') || '0',
    );
    const max = parseFloat(
      computedStyle.getPropertyValue('--offset-max') || '0',
    );

    const { width, left } = clientRect;

    if (anchorPoint === 'center') {
      const targetMin = mid - (min + width * 0.5);
      const targetMax = mid + width * 0.5;

      const dx =
        targetMin < 0
          ? `${min - mid}px`
          : targetMax > max
          ? `${max - mid - width}px`
          : `-${width * 0.5}px`;

      refWrapper.current.style.setProperty('--transform-x', dx);
    } else {
      const targetMax = left + width;
      const dx = targetMax > max ? `${max - targetMax}px` : `0px`;

      refC.style.setProperty('--transform-x', dx);
    }
  }, [anchorPoint, refContainer, refWrapper]);

  React.useEffect(() => {
    recalculate();
  }, [recalculate]);

  return {
    recalculate,
  };
};

/*
let mid = 287;
let min = 16;
let max = 558;
let width = 201.15625;
let targetMin = mid - (min + width * 0.5);
let targetMax = mid + width * 0.5;
let dx =
  targetMin < 0
    ? `${min - mid}px`
    : targetMax > max
    ? `${max - mid - width}px`
    : `-${width * 0.5}px`;
*/
