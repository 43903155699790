import styled from 'styled-components';
import { below, Breakpoint } from '../../../styles/breakpoints';
import { AppColor } from '../../../styles/colors';
import transitions from '../../../styles/transitions';
import { hover } from '../../../styles/utils';
import Icon from '../../common/icon/Icon';
import { Typography } from '../../common/typography/Typography.styled';

const BackNavigationContainer = styled.div`
  width: 100%;
  background: ${AppColor.Neutral200};
  ${below(Breakpoint.lg)} {
    display: none;
  }
`;

const BackNavigationContainerInner = styled.div`
  margin: 0 auto 0;
  width: 100%;
  max-width: var(--max-content-width);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: unset;
`;

const BackNavigation = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;

  padding: 1.25rem 0 1.5rem;

  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  display: inline-flex;
  height: 1.25rem;
  align-items: center;
  background-color: transparent;

  color: ${AppColor.LeviBlue};

  ${hover} {
    cursor: pointer;
    ${Icon} {
      transform: translate3d(0, 0, 0);
      ${transitions.ArrowLinkHover}
    }
    &:hover {
      ${Icon} {
        transform: translate3d(-1rem, 0, 0);
      }
    }
  }

  ${Icon} + ${Typography.LabelBig} {
    margin-left: 1.25rem;
  }
`;

export default {
  BackNavigationContainer,
  BackNavigationContainerInner,
  BackNavigation,
  BackButton,
};
