import { CardType } from '../../../components/common/card/Card';
import { Theme } from '../../../styles/themes';
import { DataTransformer } from '../cardDataTransformers';

const defaultFieldsTransformer: DataTransformer<CardType.Default> = ({
  id,
  title,
  image,
  description,
  callToAction,
  leviBlackCard,
  hideFromLocalization
}) => ({
  id,
  title,
  image,
  description: (description && description.text) || '',
  callToAction,
  theme: leviBlackCard ? Theme.LeviBlack : Theme.Primary,
  hideFromLocalization
});

export default defaultFieldsTransformer;
