import AccommodationMapInfoCard from '../common/card/variants/AccommodationMapInfoCard';
import DefaultMapInfoCard from '../common/card/variants/DefaultMapInfoCard';
import RentalMapInfoCard from '../common/card/variants/RentalMapInfoCard';
import RestaurantMapInfoCard from '../common/card/variants/RestaurantMapInfoCard';

type InfoComponents = {
  [key: string]: React.ReactNode;
};

const infoComponents: InfoComponents = {
  default: DefaultMapInfoCard,
  rental: RentalMapInfoCard,
  restaurant: RestaurantMapInfoCard,
  accommodation: AccommodationMapInfoCard,
};

export const getMapInfoComponent = (type: string): React.ReactNode => {
  return infoComponents[type];
};
