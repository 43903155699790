import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../../components/footer/Footer';
import useSitePageData from '../../hooks/useSitePageData';
import { ContentfulFooterTypes } from './types';
import useLocale from '../../localization/useLocale';

type ContentfulFooterQueryTypes = {
  fi: ContentfulFooterTypes;
  en: ContentfulFooterTypes;
};

const query = graphql`
  fragment footerFields on ContentfulFooter {
    title
    backgroundImage {
      desktop: gatsbyImageData(layout: FULL_WIDTH)
      mobile: gatsbyImageData(aspectRatio: 0.95) #crop image for narrow screens
      title
      description
    }
    navigation {
      pageId: id
      label: navigation_label
    }
    companies {
      ...contentfulCompanyFields
    }
    partnersTitle
    partners {
      id
      image {
        title
        description
        image: gatsbyImageData
      }
      destination {
        ... on ContentfulPage {
          pageId: id
        }
      }
    }
  }

  query FooterQuery {
    en: contentfulFooter(id: { eq: "33a56da7-9cf7-54dc-9dd0-7a70466620dc" }) {
      ...footerFields
    }
    fi: contentfulFooter(id: { eq: "c7323ae2-01ac-588c-913f-8bf11efb0ee6" }) {
      ...footerFields
    }
  }
`;

const ContentfulFooter = () => {
  const locale = useLocale();
  const data: ContentfulFooterQueryTypes = useStaticQuery(query);
  const sitePageData = useSitePageData();

  // create links and skip undefined
  const navigation = data[locale].navigation?.flatMap(({ pageId, label }) => {
    const to = sitePageData.get(pageId);
    if (!to || !label) return [];
    return {
      to,
      label,
    };
  });

  const partners = data[locale].partners?.flatMap(
    ({ destination, image, id }) => {
      const to = sitePageData.get(destination.pageId);
      if (!to || !image) return [];
      return {
        id,
        to,
        image,
      };
    },
  );

  const footerProps = { ...data[locale], navigation, partners };

  return <Footer {...footerProps} />;
};

export default ContentfulFooter;
