export enum AppColor {
  ErrorRed = '#FE5065',
  HighlightOrange = '#F87328',
  FunctionalYellow = '#EDB22F',
  LeviBlue = '#0092C8',
  LeviBlueHover = '#0085BF',
  LeviDarkBlue = '#004B88',
  Neutral100 = '#FDFDFE',
  Neutral200 = '#F1F4F7',
  Neutral300 = '#E1E4E9',
  Neutral500 = '#9E9FA1',
  Neutral600 = '#6F7176',
  Neutral700 = '#4B4D50',
  SecondaryBlue100 = '#DFF6FF',
  SecondaryBlue200 = '#C9EAFC',
  SustainableGreen = '#6ABD82',
  White = '#FFFFFF',
  EasyGreen = '#6ABD82',

  SkiBusRoute1 = '#ef404a',
  SkiBusRoute2 = '#ffd55e',
}

export enum SocialMediaColor {
  Facebook = '#3D4F8C',
  Instagram = '#BF3853',
  Mail = '#BA4233',
  Pinterest = '#e60023',
  Tripadvisor = '#00af87',
  Twitter = '#6BA2E9',
  Youtube = '#A81D1C',
}

export type CoreColor =
  | AppColor.LeviBlue
  | AppColor.LeviDarkBlue
  | AppColor.White;

export type AccentColor =
  | AppColor.SecondaryBlue100
  | AppColor.SecondaryBlue200
  | AppColor.HighlightOrange
  | AppColor.SustainableGreen
  | AppColor.FunctionalYellow;

export type NeutralColor =
  | AppColor.Neutral100
  | AppColor.Neutral200
  | AppColor.Neutral300
  | AppColor.Neutral500
  | AppColor.Neutral600
  | AppColor.Neutral700;

export type StatusColor =
  | AppColor.Neutral700
  | AppColor.Neutral600
  | AppColor.Neutral500;
