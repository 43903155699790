import * as React from 'react';
import CompactHorizontalCard from './variants/CompactHorizontalCard';
import DateHorizontalCard from './variants/DateHorizontalCard';
import DefaultHorizontalCard from './variants/DefaultHorizontalCard';
import SearchResultHorizontalCard from './variants/SearchResultHorizontalCard';
import ServiceHorizontalCard from './variants/ServiceHorizontalCard';

export const enum HorizontalCardType {
  Default = 'default',
  Compact = 'compact',
  Date = 'date',
  SearchResult = 'searchresult',
  Service = 'service',
}

const horizontalCardComponents = {
  [HorizontalCardType.Default]: DefaultHorizontalCard,
  [HorizontalCardType.Compact]: CompactHorizontalCard,
  [HorizontalCardType.Date]: DateHorizontalCard,
  [HorizontalCardType.SearchResult]: SearchResultHorizontalCard,
  [HorizontalCardType.Service]: ServiceHorizontalCard,
};

type HorizontalCardComponentTypes = typeof horizontalCardComponents;

type HorizontalCardTypeProps<T extends HorizontalCardType> = {
  type?: T;
};

type HorizontalCardComponentProps<T extends HorizontalCardType> =
  React.ComponentPropsWithoutRef<HorizontalCardComponentTypes[T]>;

type HorizontalCardProps<T extends HorizontalCardType> =
  HorizontalCardTypeProps<T> &
    Omit<HorizontalCardComponentProps<T>, keyof HorizontalCardTypeProps<T>>;

const HorizontalCard = <T extends HorizontalCardType>({
  type,
  ...props
}: HorizontalCardProps<T>): JSX.Element => {
  const Component = horizontalCardComponents[
    type || HorizontalCardType.Default
  ] as React.FC;

  return <Component {...props} />;
};

export default HorizontalCard;
