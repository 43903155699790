import React from 'react';
import { IconProps } from '../icon/Icon';
import Styled from './Input.styled';

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | 'type'
  | 'onChange'
  | 'onFocus'
  | 'value'
  | 'placeholder'
  | 'disabled'
  | 'title'
> & {
  label?: string;
  message?: string;
  leadingIcon?: IconProps & { onClick?: () => void; visible?: boolean };
  trailingIcon?: IconProps & { onClick?: () => void; visible?: boolean };
  className?: string;
};

const Input = ({
  label,
  leadingIcon,
  trailingIcon,
  message,
  className,
  ...rest
}: InputProps) => {
  const { visible: leadingIconVisible = true } = leadingIcon || {};
  const { visible: trailingIconVisible = true } = trailingIcon || {};
  return (
    <Styled.Wrapper className={className}>
      {label && <Styled.Label>{label}</Styled.Label>}
      <Styled.InputContainer>
        {leadingIcon && leadingIconVisible && (
          <Styled.Icon position="leading" {...leadingIcon} />
        )}
        {trailingIcon && trailingIconVisible && (
          <Styled.Icon position="trailing" {...trailingIcon} />
        )}
        <Styled.Input {...rest} aria-label={label ?? rest.placeholder} />
      </Styled.InputContainer>
      {message && <Styled.Hint>{message}</Styled.Hint>}
    </Styled.Wrapper>
  );
};

export default Input;
