import React from 'react';

const useSimpleMasonry = (gridRef: React.RefObject<HTMLDivElement>) => {
  const resizeGridItem = React.useCallback(
    (item) => {
      if (!window || typeof window === 'undefined') return;

      if (gridRef.current) {
        const computedStyle = getComputedStyle(gridRef.current);
        const rowHeight = parseInt(
          computedStyle.getPropertyValue('grid-auto-rows'),
        );
        if (rowHeight > 0) {
          const rowGap = parseInt(
            computedStyle.getPropertyValue('grid-row-gap'),
          );
          const rowSpan = Math.ceil(
            (item.querySelector(':first-child').getBoundingClientRect().height +
              rowGap) /
              (rowHeight + rowGap),
          );
          item.style.gridRowEnd = `span ${rowSpan}`;
        } else {
          item.style.removeProperty('grid-row-end');
        }
      }
    },
    [gridRef],
  );

  const resizeGrid = React.useCallback(() => {
    if (gridRef.current) {
      gridRef.current.childNodes.forEach(resizeGridItem);
    }
  }, [gridRef, resizeGridItem]);

  React.useLayoutEffect(() => {
    resizeGrid();
  }, [gridRef, resizeGrid]);

  React.useEffect(() => {
    if (!window || typeof window === 'undefined') return;

    window.addEventListener('resize', resizeGrid);

    return () => {
      window.removeEventListener('resize', resizeGrid);
    };
  }, [resizeGrid]);

  return {
    resizeGrid,
  };
};

export default useSimpleMasonry;
