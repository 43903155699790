import React from 'react';
import DefaultCard from './DefaultCard';
import Styled from '../Card.styled';
import { Typography } from '../../typography/Typography.styled';
import Button, { ButtonLinkWrapper } from '../../button/Button';
import { CourseCardProps } from '../types';
import CardPriceInfo from '../CardPriceInfo';
import useContentfulCallToAction from '../../../../hooks/useContentfulCallToAction';
import ThemeContainer from '../../ThemeContainer';
import { Theme } from '../../../../styles/themes';

const CourseCard = ({
  title,
  image,
  description,
  callToAction,
  priceInfo,
  theme,
}: CourseCardProps): JSX.Element => {
  const linkProps = useContentfulCallToAction(callToAction);

  return (
    <DefaultCard title={title} image={image} theme={theme} cardLink={linkProps}>
      <Typography.BodySmall>{description}</Typography.BodySmall>
      <Styled.CardFooter horizontal>
        {linkProps && (
          <ThemeContainer theme={Theme.Primary}>
            <ButtonLinkWrapper {...linkProps}>
              <Button>{linkProps.label}</Button>
            </ButtonLinkWrapper>
          </ThemeContainer>
        )}
        <CardPriceInfo {...priceInfo} />
      </Styled.CardFooter>
    </DefaultCard>
  );
};

export default CourseCard;
