import React from 'react';
import Supercluster, { PointFeature } from 'supercluster';
import useSupercluster from 'use-supercluster';
import { RestaurantCardProps } from '../common/card/types';
import { AccommodationMapInfoProps } from '../common/card/variants/AccommodationMapInfoCard';
import Map from './Map';
import Marker from './Marker';
import { getMapInfoComponent } from './utils';

type Bounds = [number, number, number, number];

type ClusterMapProps = {
  data: AccommodationMapInfoProps[] | RestaurantCardProps[];
};

const ClusterMap = ({ data = [] }: ClusterMapProps) => {
  const mapRef = React.useRef();
  const mapContainerRef = React.useRef<HTMLDivElement>(null);
  const [mapBounds, setBounds] = React.useState<Bounds | undefined>();
  const [mapZoom, setZoom] = React.useState(12);

  const mapPoints = React.useMemo(() => {
    let points: PointFeature<Supercluster.AnyProps>[] = [];
    data.forEach((item, index) => {
      const { location, id } = item;
      if (location) {
        points = points.concat({
          type: 'Feature',
          properties: {
            cluster: false,
            id,
            item,
            markerIndex: index + 1,
          },
          geometry: {
            type: 'Point',
            coordinates: [location.lon, location.lat],
          },
        });
      }
    });

    return points;
  }, [data]);

  const { clusters, supercluster } = useSupercluster({
    points: mapPoints,
    bounds: mapBounds,
    zoom: mapZoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <div ref={mapContainerRef}>
      <Map
        width="100%"
        height="90vh"
        settings={{
          defaultZoom: 12,
          defaultCenter: {
            lat: 67.8041426,
            lng: 24.8060495,
          },
          yesIWantToUseGoogleMapApiInternals: true,
          onGoogleApiLoaded: ({ map }) => {
            mapRef.current = map;
          },
          onChange: ({
            zoom,
            bounds,
          }: {
            zoom: number;
            bounds: {
              nw: { lat: number; lng: number };
              se: { lat: number; lng: number };
            };
          }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          },
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                variant="tertiary"
              >
                <div
                  className="cluster-marker"
                  onClick={() => {
                    if (!mapRef || !mapRef.current) {
                      return;
                    }
                    const expansionZoom =
                      supercluster && typeof cluster.id === 'number'
                        ? Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            60,
                          )
                        : 60;
                    mapRef.current.setZoom(expansionZoom);
                    mapRef.current.panTo({
                      lat: latitude,
                      lng: longitude,
                    });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }
          const type: string = cluster.properties.item.type || '';
          const InfoCard = getMapInfoComponent(type) as React.ElementType;
          return (
            <Marker
              key={`accommodation-${cluster.properties.id}`}
              variant="secondary"
              lat={latitude}
              lng={longitude}
              parentRect={
                mapContainerRef && mapContainerRef.current
                  ? mapContainerRef.current.getBoundingClientRect()
                  : undefined
              }
              infoBox={<InfoCard {...cluster.properties.item} />}
            >
              {cluster.properties.markerIndex}
            </Marker>
          );
        })}
      </Map>
    </div>
  );
};

export default ClusterMap;
