import React, { ReactNode } from 'react';
import { graphql } from 'gatsby';
import InternalLink from './internalLink';
import Button from '../../components/common/button/Button';
import { Typography } from '../../components/common/typography/Typography.styled';
import { ContentfulCallToActionTypes } from './types';

export type ContentfulCallToActionProps = ContentfulCallToActionTypes & {
  children?: ReactNode;
};

const ContentfulCallToAction = ({
  id,
  title,
  target,
  destination,
  type,
  children,
}: ContentfulCallToActionProps): JSX.Element | null => {
  if (!destination?.pageId && !destination?.externalUrl) {
    console.warn('CTA without destination', destination, id);
    return null;
  }
  let Component: Node[] | ReactNode | Element = (
    <Typography.LinkBig>{title}</Typography.LinkBig>
  );
  if (type === 'wrapper') {
    Component = children;
  } else if (type === 'button') {
    Component = <Button>{title}</Button>;
  } else if (type === 'inline-link') {
    Component = title;
  }

  if (destination.pageId) {
    return (
      <InternalLink target={target} pageId={destination.pageId} className="cta">
        {Component}
      </InternalLink>
    );
  }

  if (destination.externalUrl) {
    return (
      <a
        href={destination.externalUrl}
        target={target === 'tab' ? '_blank' : '_self'}
        rel="noopener nofollow"
        className="cta"
      >
        {Component}
      </a>
    );
  }

  console.warn('Unknown destination of a CTA', destination, id);
  return null;
};

export default ContentfulCallToAction;

export const contentfulCallToActionQuery = graphql`
  fragment contentfulCallToActionFields on ContentfulCallToAction {
    id
    title
    target
    destination {
      ... on ContentfulPage {
        pageId: id
      }
      ... on ContentfulEvent {
        pageId: id
      }
      ... on ContentfulRental {
        pageId: id
      }
      ... on ContentfulService {
        pageId: id
      }
      ... on ContentfulRestaurant {
        pageId: id
      }
      ... on ContentfulBlogPost {
        pageId: id
      }
      ... on ContentfulExternalUrl {
        externalUrl: url
      }
    }
  }
`;
