import React from 'react';

type MobileNavigationState = {
  open: boolean;
  subMenuIndex?: number;
};

type MenuAction = {
  type: 'OPEN' | 'CLOSE' | 'CLOSE_SUBMENU';
};

type SubMenuAction = {
  type: 'SET_SUBMENU_INDEX';
  payload: number;
};

type MobileMenuAction = MenuAction | SubMenuAction;

const initialState = {
  open: false,
  subMenuIndex: undefined,
};

const reducer = (state: MobileNavigationState, action: MobileMenuAction) => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        open: true,
      };
    case 'CLOSE':
      return {
        open: false,
        subMenuIndex: undefined,
      };
    case 'CLOSE_SUBMENU':
      return {
        ...state,
        subMenuIndex: undefined,
      };
    case 'SET_SUBMENU_INDEX':
      return {
        ...state,
        subMenuIndex: action.payload,
      };
    default:
      return state;
  }
};

type MobileNavigationHookType = {
  state: MobileNavigationState;
  actions: {
    open: () => void;
    close: () => void;
    openSubMenu: (index: number) => void;
    closeSubMenu: () => void;
  };
};

export const useMobileNavigationState = (): MobileNavigationHookType => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const open = () => dispatch({ type: 'OPEN' });
  const close = () => dispatch({ type: 'CLOSE' });
  const openSubMenu = (index: number) =>
    dispatch({ type: 'SET_SUBMENU_INDEX', payload: index });
  const closeSubMenu = () => dispatch({ type: 'CLOSE_SUBMENU' });

  return {
    state,
    actions: {
      open,
      close,
      openSubMenu,
      closeSubMenu,
    },
  };
};
