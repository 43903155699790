import { CardType } from '../../../components/common/card/Card';
import { Theme } from '../../../styles/themes';
import { DataTransformer } from '../cardDataTransformers';
import {
  resolveLinks,
  resolveOpenStatus,
  trimLeadingWhiteSpace,
} from './utils';

const restaurantFieldsTransformer: DataTransformer<CardType.Restaurant> = (
  props,
) => {
  // Restaurant properties might be wrapped with node property (fetched directly / linked entries)
  // I have no idea how to type this for typescript
  const restaurant = props.hasOwnProperty('node') ? props.node : props;
  const {
    id,
    name: title,
    ingress: description,
    categories,
    contact,
    openingHours,
    images,
    location,
    hubDbId,
    leviBlackCard,
    hideFromLocalization
  } = restaurant;

  return {
    type: CardType.Restaurant,
    id,
    title: trimLeadingWhiteSpace(title),
    description,
    categories,
    address: contact?.address,
    hideOpeningHours: openingHours?.hidden,
    openStatus: resolveOpenStatus(openingHours?.openingHours),
    image: images && images[0],
    location,
    links: resolveLinks(id, 'restaurants'),
    hubDbId,
    theme: leviBlackCard ? Theme.LeviBlack : Theme.Primary,
    hideFromLocalization
  };
};

export default restaurantFieldsTransformer;
