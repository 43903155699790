import React from 'react';
import { graphql } from 'gatsby';
import { ContentfulMarkdownTypes, PageProps } from '../types';
import { ContentfulGatsbyImage } from '../../../types/images';
import { ContentfulAsset } from '../../../../graphql-types';
import { Typography } from '../../../components/common/typography/Typography.styled';
import Section from '../../../components/section/Section';
import TextContent from '../../../components/common/textContent/TextContent';
import {
  SubpageGrid,
  SubpageGridElement,
} from '../../../components/common/layout/Grid.styled';
import ContactDetails from '../../../components/common/contactDetails/ContactDetails';
import MainHeader from '../../../components/header/PageHeader';
import Gallery from '../../../components/gallery/Gallery';
import MapContainer from '../../../components/common/map/MapContainer';
import { ServiceFields } from '../serviceListPages/contentfulSearchServicesPage';
import Grid from '../../../components/common/layout/Grid';
import { useSkiResortDataByApiPath } from '../../../hooks/useSkiResortDataByApiPath';
import { ApiPath } from '../../../network/skiresort-api';
import useBreakpoints from '../../../hooks/useBreakpoints';
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../../containers/contentfulSectionContainer';
import { ChildMeta } from '../../meta';
import useStructuredDataForRestaurant from '../../../hooks/useStructuredDataForRestaurant';
import { localize } from '../../../localization/i18n';
import useHtmlAst from '../../../hooks/useHtmlAst';
import ContentfulTextContent from '../../sections/contentfulTextContent';
import TextContentStyled from '../../../components/common/textContent/TextContent.styled';

export type AllRestaurantFields = ServiceFields & {
  body?: ContentfulMarkdownTypes;
  images: ContentfulAsset[];
  galleryImages: ContentfulGatsbyImage[];
  logo: ContentfulGatsbyImage;
  sections: ContentfulSectionContainerTypes[];
};

type RestaurantPageProps = PageProps & {
  data: {
    contentfulRestaurant: AllRestaurantFields;
  };
};

const ContentfulRestaurant: React.FC<RestaurantPageProps> = ({
  data,
  pageContext,
}) => {
  const {
    name,
    ingress,
    body,
    location: serviceLocation,
    //categories,
    openingHours,
    images,
    galleryImages,
    contact,
    logo,
    hubDbId,
    sections,
  } = data.contentfulRestaurant;

  const hubDbRestaurant = useSkiResortDataByApiPath(
    ApiPath.Restaurant,
    { id: hubDbId?.hubspotId },
    !!hubDbId?.hubspotId,
  );

  const structuredMetaData = useStructuredDataForRestaurant({
    contact,
    openingHours,
    hubDbOpeningHours:
      hubDbRestaurant && hubDbRestaurant.open
        ? hubDbRestaurant.open
        : undefined,
    image: images && images[0],
  });

  const meta = {
    lang: pageContext.locale,
    description: ingress,
    image: images && images[0],
    title: name,
    hiddenFromSearchEngines: false,
    structuredMetaData,
  };

  const { isBelowMd } = useBreakpoints();
  const bodyText = useHtmlAst(body?.childMarkdownRemark?.htmlAst);

  const hubDbRestaurantMenu = hubDbRestaurant?.menu?.[pageContext.locale || 'fi'];

  return (
    <>
      <ChildMeta {...meta} />
      <main>
        {galleryImages && <MainHeader image={galleryImages[0]} />}
        <Section subPage>
          <SubpageGrid>
            <SubpageGridElement padded>
              {isBelowMd && galleryImages && <Gallery items={galleryImages} />}
              <TextContent title={name} leftAligned>
                <Typography.BodyIngres>{ingress}</Typography.BodyIngres>
                {bodyText && (
                  <Typography.BodyLarge className="contentfulMarkdown" as="div">
                    {bodyText}
                  </Typography.BodyLarge>
                )}
                {/*
                {hubDbRestaurant && hubDbRestaurant.menu && (
                  <Typography.BodyLarge
                    as="div"
                    className="restaurant-menu"
                    dangerouslySetInnerHTML={{
                      __html: hubDbRestaurant.menu[pageContext.locale || 'fi'],
                    }}
                  />
                )}
                  */}
              </TextContent>
            </SubpageGridElement>
            <SubpageGridElement column="aside" padded>
              {!isBelowMd && galleryImages && <Gallery items={galleryImages} />}
              {contact && (
                <ContactDetails
                  logo={logo}
                  openingHours={
                    (hubDbRestaurant && hubDbRestaurant?.open) || openingHours
                  }
                  {...contact}
                />
              )}
            </SubpageGridElement>
          </SubpageGrid>
        </Section>
        {hubDbRestaurantMenu ? (
          <Section>
            <Grid>
              <TextContent title="Menu">
                <Typography.BodyLarge
                  as="div"
                  className="restaurant-menu"
                  dangerouslySetInnerHTML={{
                    __html: hubDbRestaurantMenu,
                  }}
                />
              </TextContent>
            </Grid>
          </Section>
        ) : <></>}
        {sections?.map((section: ContentfulSectionContainerTypes) => (
          <ContentfulSectionContainer key={section.id} {...section} />
        ))}
        {serviceLocation && (
          <Section>
            <Grid>
              <MapContainer
                title={localize('common.location')}
                address={contact?.address || ''}
                location={serviceLocation}
              />
            </Grid>
          </Section>
        )}
      </main>
    </>
  );
};

export default ContentfulRestaurant;

export const contentfulPageQuery = graphql`
  query RestaurantById($id: String!) {
    contentfulRestaurant(id: { eq: $id }) {
      ...contentfulBaseRestaurantFields
      body {
        childMarkdownRemark {
          htmlAst
        }
      }
      sections {
        ...allPageSections
      }
      images {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: [JPG]
          placeholder: NONE
          resizingBehavior: FILL
        )
      }
      galleryImages: images {
        image: gatsbyImageData(layout: FULL_WIDTH)
        description
        title
        id
      }
      contact {
        ...contentfulCompanyFields
      }
      logo {
        image: gatsbyImageData(width: 300)
        description
        title
      }
      analyticsTags
    }
  }
  fragment contentfulBaseRestaurantFields on ContentfulRestaurant {
    id
    contentful_id
    name
    categories
    contact {
      address
    }
    ingress
    location {
      lat
      lon
    }
    hubDbId {
      hubspotId
    }
    leviBlackCard
    hideFromLocalization
    openingHours {
      exceptions
      hidden

      openingHours {
        monday {
          to
          from
          closed
        }
        tuesday {
          closed
          from
          to
        }
        wednesday {
          to
          from
          closed
        }
        thursday {
          closed
          from
          to
        }
        friday {
          to
          from
          closed
        }
        saturday {
          closed
          from
          to
        }
        sunday {
          closed
          from
          to
        }
      }
    }
  }
`;
