import React from 'react';
import { LocalizedString } from '../contentful/templates/types';
import { WeekDayProgrammeDataItem } from './useSortedWeekProgrammeData';

const today = new Date();

export const useTodaysProgramme = (
  data: WeekDayProgrammeDataItem[] | undefined,
): LocalizedString | undefined => {
  const programme = React.useMemo(() => {
    if (!data) {
      return;
    }
    const currentDay = today.getDay();
    if (currentDay === 0) {
      return data[6].data;
    }
    return data[currentDay - 1].data;
  }, [data]);

  return programme;
};
