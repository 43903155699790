import React from 'react';
import ArrowLink from '../link/ArrowLink';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import useContentfulCallToAction from '../../../hooks/useContentfulCallToAction';

const CardFooterLink = (
  callToAction: ContentfulCallToActionTypes,
  index: number,
): JSX.Element | null => {
  const linkProps = useContentfulCallToAction(callToAction);
  if (!linkProps) return null;
  return <ArrowLink key={index} {...linkProps} />;
};

export default CardFooterLink;
