import { CardType } from '../../components/common/card/Card';
import { EventFields } from '../templates/eventPages/eventsPage';
import { CourseFields } from '../templates/serviceListPages/contentfulSearchCoursesPage';
import {
  BaseServiceFields,
  ServiceFields,
} from '../templates/serviceListPages/contentfulSearchServicesPage';
import { ActivityFields } from '../templates/serviceListPages/searchActivitiesPage';
import { AccommodationFieldsWithPrices } from '../templates/serviceListPages/searchAccommodationsPage';
import {
  accommodationFieldsTransformer,
  activityFieldsTransformer,
  courseFieldsTransformer,
  defaultFieldsTransformer,
  eventFieldsTransformer,
  rentalFieldsTransformer,
  restaurantFieldsTransformer,
  searchResultsFieldsTransformer,
  serviceFieldsTransformer,
  blogPostFieldsTransformer,
} from './transformers';
import { SearchResultHorizontalCardProps } from '../../components/common/horizontal-card/types';
import { BlogPostCardFields } from '../templates/contentfulBlogPostPage';
interface FieldDataTypes {
  [CardType.Default]: BaseServiceFields;
  [CardType.Accommodation]: AccommodationFieldsWithPrices;
  [CardType.Activity]: ActivityFields;
  [CardType.Course]: CourseFields;
  [CardType.Event]: EventFields;
  [CardType.Rental]: ServiceFields;
  [CardType.Restaurant]: ServiceFields;
  [CardType.Service]: ServiceFields;
  [CardType.SearchResult]: SearchResultHorizontalCardProps;
  [CardType.BlogPost]: BlogPostCardFields;
}

export interface CardDataType<Type extends CardType> {
  node: FieldDataTypes[Type];
}

export interface DataTransformer<Type extends CardType> {
  (args: CardDataType<Type>): unknown;
}

type DataTransformerKey = {
  [key in CardType]: DataTransformer<key>;
};

export const dataTransformers: DataTransformerKey = {
  [CardType.Default]: defaultFieldsTransformer,
  [CardType.Accommodation]: accommodationFieldsTransformer,
  [CardType.Activity]: activityFieldsTransformer,
  [CardType.Course]: courseFieldsTransformer,
  [CardType.Event]: eventFieldsTransformer,
  [CardType.Rental]: rentalFieldsTransformer,
  [CardType.Restaurant]: restaurantFieldsTransformer,
  [CardType.Service]: serviceFieldsTransformer,
  [CardType.SearchResult]: searchResultsFieldsTransformer,
  [CardType.BlogPost]: blogPostFieldsTransformer,
};

export type DataTransformerTypes = typeof dataTransformers;
