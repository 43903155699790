import React from 'react';
import Styled from './Card.styled';
import { Typography } from '../typography/Typography.styled';
import Icon, { IconSize } from '../icon/Icon';
import { CardTagProps } from './types';

const isStringChild = (children: React.ReactNode): children is string =>
  typeof children === 'string';

const CardTag = ({
  icon,
  iconSize = IconSize.Size24,
  children,
  label,
}: CardTagProps): JSX.Element => (
  <Styled.CardTag>
    <Icon type={icon} size={iconSize} />
    {label || isStringChild(children) ? (
      <Typography.SubLink>{label || children}</Typography.SubLink>
    ) : (
      children
    )}
  </Styled.CardTag>
);

export default CardTag;
