import styled from 'styled-components';

const Spinner = styled.div`
  display: inline-block;
  align-self: center;
  width: 1rem;
  height: 1rem;

  > svg {
    fill: none;
    stroke-width: 0.1;
    stroke: currentColor;
    stroke-dasharray: 2.8284271558125815;
    stroke-dashoffset: 0;
    stroke-miterlimit: 0;
  }
`;

export default {
  Spinner,
};
