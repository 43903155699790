import { ApiPath } from '..';
import { ApiDataParser } from './ApiDataParser';
import { locationToSlopeLocation, statusToStatusIcon } from './slopeDataParser';

export const liftDataParser: ApiDataParser<ApiPath.Lifts> = (data) =>
  data.map((lift) => ({
    ...lift,
    statusIcon: statusToStatusIcon({
      open: lift.open.now,
      temporarilyClosed: lift.open.temporarilyClosed,
    }),
    slopeLocation: locationToSlopeLocation(lift.location),
  }));
