import { ApiPath } from '..';
import { AppColor } from '../../../styles/colors';
import { Icons } from '../../../types/icon-types';
import { SlopeData } from '../types/SlopeDataTypes';
import { ApiDataParser } from './ApiDataParser';

export const slopeDataParser: ApiDataParser<ApiPath.Slopes> = (data) =>
  data.map((slope) => ({
    ...slope,
    displayName:
      slopeNameToDisplayName.find((slot) => slot.id === slope.id)
        ?.display_name || '',
    difficultyColor: slopeToColor(slope),
    statusIcon: statusToStatusIcon({
      open: slope.open.now,
      temporarilyClosed: slope.open.temporarilyClosed,
    }),
    slopeLocation: locationToSlopeLocation(slope.location),
  }));

export const enum SlopeDifficulty {
  Vaikea = 'Vaikea',
  Keskivaikea = 'Keskivaikea',
  Helppo = 'Helppo',
  ErittäinHelppo = 'ErittäinHelppo',
  //Connecting = 'Siirtymä',
}

export enum SlopeLocation {
  All = 'all',
  FrontSlopes = 'front_slopes',
  SouthSlopes = 'south_slopes',
  WestSlopes = 'west_slopes',
  NorthEastSlopes = 'north_east_slopes',
  SouthEastSlopes = 'south_east_slopes',
}

enum SlopeLocationMapped {
  'Eturinne' = SlopeLocation.FrontSlopes,
  'Eturinteet' = SlopeLocation.FrontSlopes,
  'Etelärinne' = SlopeLocation.SouthSlopes,
  'Etelärinteet' = SlopeLocation.SouthSlopes,
  'Länsirinne' = SlopeLocation.WestSlopes,
  'Länsirinteet' = SlopeLocation.WestSlopes,
  'Koillisrinne' = SlopeLocation.NorthEastSlopes,
  'Koillisrinteet' = SlopeLocation.NorthEastSlopes,
  'Kaakkoisrinne' = SlopeLocation.SouthEastSlopes,
  'Kaakkoisrinteet' = SlopeLocation.SouthEastSlopes,
}

type SlopeLocations = keyof typeof SlopeLocationMapped;

export const locationToSlopeLocation = (location: string) => {
  const key = location as SlopeLocations;
  return SlopeLocationMapped[key].toString() as SlopeLocation;
};

type SlopeDifficultyIndex = {
  [key in SlopeDifficulty]: string;
};

const SlopeDifficultyToColor: SlopeDifficultyIndex = {
  [SlopeDifficulty.Vaikea]: AppColor.Neutral700,
  [SlopeDifficulty.Keskivaikea]: AppColor.ErrorRed,
  [SlopeDifficulty.Helppo]: AppColor.LeviBlue,
  [SlopeDifficulty.ErittäinHelppo]: AppColor.EasyGreen,
  //[SlopeDifficulty.Connecting]: AppColor.SustainableGreen,
};

export const difficultyToColor = (difficulty: SlopeDifficulty) => {
  const color = SlopeDifficultyToColor[difficulty];
  return color || difficulty; // TODO
}

export const statusToStatusIcon = ({
  temporarilyClosed,
  open,
}: {
  temporarilyClosed: boolean;
  open: boolean;
}) => {
  const type = temporarilyClosed
    ? Icons.SlopeTemporarilyClosed
    : open
      ? Icons.SlopeOpen
      : Icons.SlopeClosed;
  const color = temporarilyClosed
    ? AppColor.HighlightOrange
    : open
      ? AppColor.SustainableGreen
      : AppColor.ErrorRed;

  return {
    type,
    color,
  };
};

const slopeToColor = (slope: SlopeData): string => {
  const { difficulty } = slope;
  return SlopeDifficultyToColor[difficulty];
  /*
  const isConnectingRoute = name.includes('Connecting');

  return !isConnectingRoute
    ? SlopeDifficultyToColor[difficulty]
    : SlopeDifficultyToColor[SlopeDifficulty.Connecting];
    */
};

const slopeNameToDisplayName = [
  {
    id: 6459593082,
    display_name: '9.2',
  },
  {
    id: 6461164553,
    display_name: 'STR',
  },
  {
    id: 6459593273,
    display_name: 'ATP',
  },
  {
    id: 6459596850,
    display_name: 'N 1',
  },
  {
    id: 6464806939,
    display_name: 'E 1',
  },
  {
    id: 6464807058,
    display_name: '4.1',
  },
  {
    id: 6467304663,
    display_name: 'ICE.1',
  },
  {
    id: 6464807419,
    display_name: 'ICE.2',
  },
  {
    id: 6467304947,
    display_name: '9.1',
  },
  {
    id: 6467305158,
    display_name: '8.5',
  },
  {
    id: 6464807925,
    display_name: '8.4',
  },
  {
    id: 6464808064,
    display_name: '8.3',
  },
  {
    id: 6467305775,
    display_name: '8.2',
  },
  {
    id: 6467305832,
    display_name: '8.1',
  },
  {
    id: 6464808333,
    display_name: 'W3',
  },
  {
    id: 6464808385,
    display_name: 'W1',
  },
  {
    id: 6467306136,
    display_name: 'W2',
  },
  {
    id: 6467306147,
    display_name: 'G.3',
  },
  {
    id: 6467341974,
    display_name: 'G.1',
  },
  {
    id: 6464808541,
    display_name: 'G.2',
  },
  {
    id: 6464808582,
    display_name: '14.3',
  },
  {
    id: 6464808583,
    display_name: '14.2',
  },
  {
    id: 6467307061,
    display_name: '14.1',
  },
  {
    id: 6464808988,
    display_name: '13.4',
  },
  {
    id: 6464808992,
    display_name: '13.2',
  },
  {
    id: 41970422341,
    display_name: '13.3',
  },
  {
    id: 6467307305,
    display_name: '13.1',
  },
  {
    id: 6467307384,
    display_name: '12.3',
  },
  {
    id: 6464809227,
    display_name: '12.2',
  },
  {
    id: 6467339878,
    display_name: '12.1',
  },
  {
    id: 87319338639,
    display_name: '7B.3',
  },
  {
    id: 6464809463,
    display_name: '7B.4',
  },
  {
    id: 6464809613,
    display_name: '7B.2',
  },
  {
    id: 6467308007,
    display_name: '7B.1',
  },
  {
    id: 6467308095,
    display_name: '7.5',
  },
  {
    id: 6464809804,
    display_name: '7.4',
  },
  {
    id: 6467308307,
    display_name: '7.3',
  },
  {
    id: 6464809939,
    display_name: '7.2',
  },
  {
    id: 6464810012,
    display_name: '7.1',
  },
  {
    id: 6467308606,
    display_name: '6.4',
  },
  {
    id: 6464810149,
    display_name: '6.3',
  },
  {
    id: 6467308823,
    display_name: '6.2',
  },
  {
    id: 6464810257,
    display_name: '6.1',
  },
  {
    id: 6464810266,
    display_name: '5.4',
  },
  {
    id: 6467308904,
    display_name: '5.3',
  },
  {
    id: 6467308906,
    display_name: '5.1',
  },
  {
    id: 7450901642,
    display_name: '1-5',
  },
  {
    id: 7451706044,
    display_name: '8-10',
  },
];
