import { addMonths } from 'date-fns';
import React from 'react';
import { LocaleType } from '../../../contentful/templates/types';
import { localize } from '../../../localization/i18n';
import { Icons } from '../../../types/icon-types';
import Icon from '../icon/Icon';
import { Typography } from '../typography/Typography.styled';
import Styled from './Calendar.styled';
import useCalendarAPI from './useCalendarAPI';
import useCalendarSelectedMonth from './useCalendarSelectedMonth';

type CalendarHeaderProps = {
  locale?: LocaleType;
  offset: number;
  totalInstances?: number;
};

const CalendarHeader = ({
  locale = 'fi',
  offset = 0,
  totalInstances = 1,
}: CalendarHeaderProps): JSX.Element => {
  const selectedMonth = useCalendarSelectedMonth();
  const { changeSelectedMonth } = useCalendarAPI();
  const displayMonth = React.useMemo(
    () => addMonths(selectedMonth, offset),
    [offset, selectedMonth],
  );

  const renderPrevButton = React.useMemo(
    () => totalInstances === 1 || offset === 0,
    [offset, totalInstances],
  );
  const renderNextButton = React.useMemo(
    () => totalInstances === 1 || offset > 0,
    [offset, totalInstances],
  );

  return (
    <Styled.CalendarHeader>
      <button
        aria-label={localize('calendar.previous')}
        style={!renderPrevButton ? { visibility: 'hidden' } : {}}
        onClick={() => changeSelectedMonth(-1)}
      >
        <Icon type={Icons.ArrowLeftLarge} />
      </button>
      <Typography.Button>
        {displayMonth.toLocaleDateString(locale, {
          year: 'numeric',
          month: 'long',
        })}
      </Typography.Button>
      <button
        aria-label={localize('calendar.next')}
        style={!renderNextButton ? { visibility: 'hidden' } : {}}
        onClick={() => changeSelectedMonth(1)}
      >
        <Icon type={Icons.ArrowRightLarge} />
      </button>
    </Styled.CalendarHeader>
  );
};

export default CalendarHeader;
