import React from 'react';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import {
  LinkComponentTypes,
  LinkProps,
} from '../../../hooks/useContentfulCallToAction';
import { Icons } from '../../../types/icon-types';
import Styled from './AccordionList.styled';
import AccordionListItem from './AccordionListItem';

export type AccordionListItemData = {
  icon?: Icons;
  title: string;
  body: string;
  linkProps?: LinkProps<LinkComponentTypes>;
  callToAction?: ContentfulCallToActionTypes;
};

type AccordionListProps = {
  items: AccordionListItemData[];
  selectedIndex: number;
  onSelect: (index: number) => void;
};

const AccordionList = ({
  items,
  selectedIndex,
  onSelect,
}: AccordionListProps): JSX.Element => {
  const onToggle = React.useCallback(
    (event: React.SyntheticEvent<HTMLDetailsElement>) => {
      event.preventDefault();
      if (!event.currentTarget.open) return;

      const { index = 0 } = event.currentTarget.dataset;
      if (selectedIndex !== +index) {
        onSelect(+index);
      }
    },
    [onSelect, selectedIndex],
  );

  return (
    <>
      <Styled.Accordion column={6}>
        {items.map((item, index) => (
          <AccordionListItem
            key={item.title}
            index={index}
            open={selectedIndex === index}
            onToggle={onToggle}
            {...item}
          />
        ))}
      </Styled.Accordion>
    </>
  );
};

export default AccordionList;
