import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import useContentfulGatsbyImage from '../../hooks/useContentfulGatsbyImage';
import { ContentfulGatsbyImage } from '../../types/images';
import { GridElement } from '../../components/common/layout/Grid.styled';
import styled from 'styled-components';

export type ContentfulImageTypes = {
  image: ContentfulGatsbyImage;
};

const ImageWrapper = styled(GridElement)`
  grid-column: span 6;
`;

const ContentfulImage = ({
  image,
}: ContentfulImageTypes): JSX.Element | null => {
  const imageProps = useContentfulGatsbyImage(image);
  if (!imageProps) return null;
  return (
    <ImageWrapper>
      <GatsbyImage {...imageProps} />
    </ImageWrapper>
  );
};

export default ContentfulImage;

export const contentfulImageQuery = graphql`
  fragment contentfulImageFields on ContentfulImage {
    id
    internal {
      type
    }
    image {
      image: gatsbyImageData(
        layout: FULL_WIDTH
        placeholder: BLURRED
        quality: 90
        backgroundColor: "transparent"
      )
      description
      title
    }
  }
`;
