import { localize } from '../../localization/i18n';

export const serviceCategoriesToLocalizedList = () => {
  const categories = localize('services.categories') as unknown as {
    [key: string]: string;
  };
  return Object.entries(categories).map(([value, label]) => ({
    label,
    value,
  }));
};
