import React from 'react';
import { LocalizedString } from '../contentful/templates/types';
import {
  ProgrammeParserReturnType,
  WeeklyProgrammeDays,
} from '../network/skiresort-api/types/ProgrammeDataTypes';

const days = ['ma', 'ti', 'ke', 'to', 'pe', 'la', 'su'];

export type WeekDayProgrammeDataItem = {
  data: LocalizedString;
  day: string;
};

export type SortedSelectedWeekProgramData =
  | {
      data: WeekDayProgrammeDataItem[];
      name: LocalizedString;
      dates: string;
    }
  | undefined;

export const useSortedSelectedWeekProgramData = (
  progammeData: ProgrammeParserReturnType | undefined,
  week: number | undefined,
): SortedSelectedWeekProgramData => {
  const isEmpty = (data: WeeklyProgrammeDays) => {
    const values = Object.values(data);
    for (let i = 0; i < values.length; i++) {
      if (values[i].en.length > 0 || values[i].fi.length > 0) {
        return false;
      }
    }
    return true;
  };

  const data = React.useMemo(() => {
    if (!progammeData) {
      return;
    }

    const weekData = !week
      ? progammeData.currentWeek
      : progammeData.allProgrammes.find((item) => item.week === week);

    if (!weekData || isEmpty(weekData.programme)) {
      return;
    }

    const sortedData = days.map((day) => {
      const dayName = day as unknown as keyof WeeklyProgrammeDays;
      return {
        data: weekData.programme[dayName],
        day,
      };
    });

    return {
      data: sortedData,
      name: progammeData.name,
      dates: weekData.dates,
    };
  }, [progammeData, week]);

  return data;
};
