import {
  OpeningHoursTypes,
  OpeningHoursDayType,
} from '../../../contentful/templates/types';
import { HubDBOpeningHoursType } from '../types/ServiceDataTypes';

// hubDB-weekday-key - contentful-weekday-key
export const weekdayKeys: HubDBOpeningHoursType = {
  ma: 'monday',
  ti: 'tuesday',
  ke: 'wednesday',
  to: 'thursday',
  pe: 'friday',
  la: 'saturday',
  su: 'sunday',
};
// Transform hubdb's opening hours to same format as contentful's
export const parseOpeningHours = (
  hubDBOpeningHours: HubDBOpeningHoursType,
): OpeningHoursTypes => {
  const openingHours = {} as OpeningHoursTypes;

  for (const [key, value] of Object.entries(hubDBOpeningHours)) {
    let day: OpeningHoursDayType = {
      from: undefined,
      to: undefined,
      closed: false,
    };
    day.closed = !value || value === 'suljettu' || value === 'SULJETTU';

    if (!day.closed) {
      const range = value.split('-');

      if (range.length === 2) {
        let [from, to] = range;
        from = from.includes(':') ? from : `${from}:00`;
        to = to.includes(':') ? to : `${to}:00`;
        day = { ...day, from, to };
      }
    }

    openingHours[weekdayKeys[key]] = day;
  }
  return openingHours;
};

export const stripHtmlString = (str: string) => {
  return str
    .replace(/(&nbsp;*)*<[^\/>][^>]*><\/[^>]+>/g, '')
    .replace(/<[^\/>][^>]*>(?:&nbsp;(?:\s)*)*<\/[^>]+>/g, '')
    .replace(/(?:<br\s*[^>]*>)+(?:<\/p>|$)/, '</p>')
    .replace(/(?:<br>)\s?$/, '');
};
