import styled from 'styled-components';
import { GridElement } from '../layout/Grid.styled';
import { AppColor } from '../../../styles/colors';
import FontRules from '../../../styles/fontRules';
import transitions from '../../../styles/transitions';
import {
  below,
  between,
  Breakpoint,
  breakpoints,
  only,
} from '../../../styles/breakpoints';
import { LeviBlackCardSize } from './LeviBlackCardSize';
import { LeviBlackCardPosition } from './LeviBlackCardPosition';
import leviBlackCard from '@static/images/levi-black-card.png';
import CardStyled from '../card/Card.styled';
import CardListStyled from '../cardlist/CardList.styled';

const LeviBlackCard = styled.img.attrs({
  src: leviBlackCard,
})`
  cursor: pointer;
`;

const LeviBlackCardTooltip = styled.div`
  position: absolute;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${AppColor.Neutral200};
  text-align: center;
  cursor: default;
  box-shadow: rgb(31 55 69 / 12%) 0px 5px 14px 0;

  span {
    ${FontRules.TradeGothicNext.Regular};
    color: ${AppColor.Neutral700};
  }

  ${transitions.TooltipHover};

  /* The arrow of the tooltip box */
  &:after {
    content: '';
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 0.5rem;
    border-left-width: 0.5rem;
    border-bottom: 0.4375rem solid ${AppColor.Neutral200};
    position: absolute;
    top: -1.375rem;
    left: 50%;
    transform: translate(var(--transform-x, -50%), 1rem);
  }
`;

type LeviBlackCardWrapperProps = {
  size?: LeviBlackCardSize;
  position?: LeviBlackCardPosition;
}

const LeviBlackCardWrapper = styled.div<LeviBlackCardWrapperProps>`
  position: absolute;
  z-index: 1;
  top: ${props => props.position === LeviBlackCardPosition.TopRight ? '0.8215rem' : 'unset'};
  bottom: ${props => props.position === LeviBlackCardPosition.Middle ? '-0.75rem' : 'unset'};
  right: ${props => props.position === LeviBlackCardPosition.TopRight ? '-1rem' : '0.5rem'};
  --black-card-width: ${props => props.size === LeviBlackCardSize.Large ? '4.125rem' : '3rem'};
  --black-card-height: ${props => props.size === LeviBlackCardSize.Large ? '2.75rem' : '2rem'};
  --black-card-tooltip-width: 9.5rem;

  ${below(Breakpoint.sm)} {
    --black-card-tooltip-width: 6rem;
  }

  ${only(Breakpoint.sm)} {
    top: ${props => props.position === LeviBlackCardPosition.TopRight ? '0.75rem' : 'unset'};
    right: ${props => props.position === LeviBlackCardPosition.TopRight ? '0.75rem' : '0.5rem'};
  }

  ${breakpoints.lg} {
    top: ${props => props.position === LeviBlackCardPosition.TopRight ? '1.125rem' : 'unset'};
    right: ${props => props.position === LeviBlackCardPosition.TopRight ? '-1.125rem' : '0.75rem'};
    --black-card-width: ${props => props.size === LeviBlackCardSize.Large ? '6rem' : '4.125rem'};
    --black-card-height: ${props => props.size === LeviBlackCardSize.Large ? '4rem' : '2.75rem'}
  }

  ${LeviBlackCard} {
    width: var(--black-card-width);
    height: var(--black-card-height);

    &:hover ~ ${LeviBlackCardTooltip} {
      opacity: 1;
    }
  }

  ${LeviBlackCardTooltip} {
    width: var(--black-card-tooltip-width);
    opacity: 0;
    top: calc(var(--black-card-height) + 0.5rem);
    right: calc(-1 * ((var(--black-card-tooltip-width) - var(--black-card-width)) / 2));
  }
`

const LeviBlackCardModalWrapper = styled.div`
  position: absolute;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const LeviBlackCardModal = styled.div`
  width: 27rem;
  max-width: min(27rem, 90%);
  margin: 1rem;
  background: transparent;
  overflow: auto;

  ${LeviBlackCard} {
    width: 8.125rem;
    height: 5.25rem;
    margin: auto;
    cursor: default;
  }

  ${CardStyled.CardContent},
  ${CardStyled.CardFooter} {
    padding: 2rem;

    ${below(Breakpoint.sm)} {
      padding: 1.5rem;
    }

    .cta {
      margin: auto;
    }
  }
`

const LeviBlackCardModalImageContainer = styled.div`
  position: relative;
  background:
    linear-gradient(135.58deg, rgba(255, 255, 255, 0.1) 15.93%, rgba(0, 0, 0, 0.1) 82.98%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
    #282828;
  padding: 3rem 0;
`;

const LeviBlackCardModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  color: ${AppColor.White};
  opacity: 0.6;
  cursor: pointer;

  ${transitions.ButtonHover};

  &:hover {
    background-color: ${AppColor.Neutral700};
  }
`;

export default  {
  LeviBlackCard,
  LeviBlackCardTooltip,
  LeviBlackCardWrapper,
  LeviBlackCardModal,
  LeviBlackCardModalWrapper,
  LeviBlackCardModalImageContainer,
  LeviBlackCardModalCloseButton,
}
