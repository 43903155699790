import React from 'react';
import { RowProps } from './SkiResortAmenitiesList';
import Styled from './SkiResortAmenities.styled';
import RowStatusIcon from './RowStatusIcon';
import { OtherActivity } from '@Levi/network/skiresort-api/types/OtherActivitiesTypes';
import { Link } from '../common/link';

const OtherActivityRow = ({ data, locale }: RowProps<OtherActivity>) => {
  const { name, statusIcon, open } = data;
  return (
    <Link to={data.url[locale]}>
    <Styled.Row>
      <Styled.Info>
        <span>{name[locale]}</span>
      </Styled.Info>
      <Styled.StatusCell>
        <Styled.Info>{open.hours}</Styled.Info>
        <RowStatusIcon {...statusIcon} />
      </Styled.StatusCell>
    </Styled.Row>
    </Link>
  );
};

export default OtherActivityRow;
