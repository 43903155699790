import styled from 'styled-components';
import transitions from '../../../styles/transitions';
import { hover } from '../../../styles/utils';
import {
  LinkUnderlineCSS,
  LinkUnderLineHoverCSS,
  Typography,
} from '../../common/typography/Typography.styled';

type NavLinkLiProps = {
  hasSubPages?: boolean;
};

export const NavLinkLi = styled.li<NavLinkLiProps>`
  --link-underline-height: 2px;
  position: relative;
  ${(props) =>
    props.hasSubPages &&
    `
    &:after {
      content: '';
      width: 0;
      height: 0;
      border: 0 solid transparent;
      border-right-width: 0.5rem;
      border-left-width: 0.5rem;
      border-bottom: 0.4375rem solid white;
      position: absolute;
      bottom: -1.075rem;
      left: 50%;

      transform: translate(var(--transform-x, -50%), 1rem);
  
      opacity: 0;

      ${transitions.NavLinkHoverDropdown}
    }
  `}
  ${hover} {
    &:hover {
      > a {
        ${LinkUnderLineHoverCSS}
      }

      &:after {
        opacity: 1;
        transform: translate(var(--transform-x, -50%), 0rem);
      }

      > div {
        pointer-events: auto;
      }
      > div > div {
        opacity: 1;
        transform: translate(0, 0rem);
      }
    }
  }
`;

const NavLink = styled(Typography.NavigationLink)`
  ${LinkUnderlineCSS}
`;

export default {
  NavLinkLi,
  NavLink,
};
