import React from 'react';

const useCarouselIndex = (itemCount: number) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const prev = React.useCallback(() => {
    setActiveIndex((prev) => (prev > 0 ? prev - 1 : itemCount - 1));
  }, [itemCount]);

  const next = React.useCallback(() => {
    setActiveIndex((prev) => (prev < itemCount - 1 ? prev + 1 : 0));
  }, [itemCount]);

  const set = React.useCallback(
    (index: number) => {
      index !== activeIndex ? setActiveIndex(index) : null;
    },
    [activeIndex],
  );

  return {
    activeIndex,
    next,
    prev,
    set,
  };
};

export default useCarouselIndex;
