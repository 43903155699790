import React from 'react';
import { InfoGridElementProps } from '../components/common/infogrid/InfoGridElement';
import { LeviTodayFieldKeys } from '../context/types';
import { LeviTodayFields } from '../contentful/data/leviTodayFields';
import { useSkiResortDataByApiPath } from './useSkiResortDataByApiPath';
import { ApiPath } from '../network/skiresort-api';
import { localize } from '../localization/i18n';
import { ContentfulCallToActionTypes } from '../contentful/callToActions/types';

export type ContentfulLeviTodayTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  content: LeviTodayFieldKeys[];
};

export type LeviTodayEntry = {fieldName: LeviTodayFieldKeys, value: string};
export type LeviTodayFields = Array<LeviTodayEntry>;

type LeviTodayCallToActionsType = {
  [key in LeviTodayFieldKeys]?: ContentfulCallToActionTypes;
};

const useLeviTodayData = (
  content: LeviTodayFieldKeys[],
  callToActions: LeviTodayCallToActionsType,
): InfoGridElementProps[] => {
  const todayData = useSkiResortDataByApiPath(ApiPath.LeviToday);

  const statisticsData: InfoGridElementProps[] = React.useMemo(() => {
    if (!todayData) {
      return [];
    }

    return todayData
      .reduce<InfoGridElementProps[]>((store, {fieldName, value}) => {
        // Find the actual data for this content
        if(content.find(c=>c == fieldName) == null)
          return store;

        // Get hardcoded values related to this field
        const { icon } = LeviTodayFields[fieldName] || {};
        const label = localize(`leviToday.${fieldName}`);

        if (!label) return store;
        const statistic = value || '-';
        const callToAction = callToActions[fieldName];

        store.push({
          icon,
          label,
          statistic,
          callToAction,
        });

        return store;
      }, [])
      .filter((i) => i.label !== '');
  }, [content, todayData]);

  return statisticsData;
};

export default useLeviTodayData;
