import React from 'react';
import { graphql, navigate } from 'gatsby';
import { ContentfulGatsbyImage } from '../../../types/images';
import { PageProps } from '../types';
import { CardType } from '../../../components/common/card/Card';
import useContentfulTransformedCardGridData from '../../../hooks/useContentfulTransformedCardGridData';
import CardGrid from '../../../components/common/cardgrid/CardGrid';
import useSortedData from '../../../hooks/useSortedData';
import {
  DateRange,
  isDateRange,
  isValidDateRange,
} from '../../../utils/date-utils';
import CardGridHeader from '../../../components/common/cardgrid/CardGridHeader';
import ContentfulSectionContainer, {
  ContentfulSectionContainerTypes,
} from '../../containers/contentfulSectionContainer';
import { BaseServiceFields } from '../serviceListPages/contentfulSearchServicesPage';
import { localize, resolveLocaleFromLocation } from '../../../localization/i18n';
import { endOfDay, format } from 'date-fns';
import { SearchPageGrid } from '../../../components/common/layout/Grid.styled';
import Styled from '../serviceListPages/ServiceListPage.styled';
import { Typography } from '../../../components/common/typography/Typography.styled';
import AvailabilitySearchForm from '../../../components/common/list-filters/AvailabilitySearchForm';
import { dateParamsToDateRange, encodeDateRangeParams, filterOutDummyCards } from '../utils';
import SearchFilters from '../../../components/common/list-filters/SearchFilters';
import useUrlParamAwareState from '../../../hooks/useUrlParamAwareState';
import useFilteredData, {
  containsString,
  FilterFunc,
  isInsideTimeInterval,
  matchesAny,
} from '../../../hooks/useFilteredData';
import { isDomAvailable } from '../../../utils/env-utils';
import { MultiSelectDropdownProps } from '../../../components/common/dropdown/MultiSelectDropdown';

const eventCategories = [
  { id: 'Well-being', titleEN: 'Well-being', titleFI: 'Hyvinvointi' },
  { id: 'Culture and entertainment', titleEN: 'Culture and entertainment', titleFI: 'Kulttuuri ja viihde' },
  { id: 'Music', titleEN: 'Music', titleFI: 'Musiikki' },
  { id: 'Families', titleEN: 'Families', titleFI: 'Perheet' },
  { id: 'Sports', titleEN: 'Sports', titleFI: 'Urheilu' },
  { id: 'Slope events', titleEN: 'Slope events', titleFI: 'Rinnetapahtumat' },
];
export interface EventFields extends BaseServiceFields {
  ingress?: string;
  startDate: string;
  endDate?: string;
  image: ContentfulGatsbyImage;
  leviBlackCard: boolean;
  pageLink?: {
    pageId: string;
  };
  hideFromLocalization?: boolean;
}

type EventsPageProps = PageProps & {
  data: { events: { edges: { node: EventFields }[] } };
};

const EventsPage: React.FC<EventsPageProps> = ({
  data: {
    events: { edges: cards },
    contentfulPage: { sections }
  },
  location
}) => {
  cards = filterOutDummyCards(cards);
  const locale = resolveLocaleFromLocation(location);

  const allEvents = useContentfulTransformedCardGridData({
    cards: cards.filter(({ node: { startDate, endDate, hideFromLocalization } }) =>
      !(locale !== 'fi' && hideFromLocalization) &&
      (!!endDate
        ? endDate >= format(new Date(), 'yyyy-MM-dd')
        : startDate >= format(new Date(), 'yyyy-MM-dd')),
    ),
    type: CardType.Event,
  });

  const { sortedData } = useSortedData({
    data: allEvents,
    sortBy: ({ date }) =>
      isDateRange(date) ? date.start.getTime() : date.getTime(),
  });

  const [searchTerm, setSearchTerm] = React.useState('');

  const [dates, setDates] = useUrlParamAwareState({
    location,
    paramName: 'dates',
    initialValue: [],
  });

  const [selectedCategories, setSelectedCategories] = useUrlParamAwareState({
    location,
    paramName: 'category',
    initialValue: [],
  });

  const filters = React.useMemo<FilterFunc[]>(() => {
    const defaultFilters = [
      containsString('title', searchTerm),
      matchesAny('categories', selectedCategories),
    ];
    const dateRange = dateParamsToDateRange(dates);
    if (dateRange && isValidDateRange(dateRange)) {
      // Selected date range is from midnight to midnight.
      //  If slection is only one day, the range would be 0 hours. So Extend the end filter to end of the end date.
      //  Change value only in filter, as changing it in chalendar has side effects.
      dateRange.end = endOfDay(dateRange.end);
      return [...defaultFilters, isInsideTimeInterval('date', dateRange)];
    }
    return defaultFilters;
  }, [dates, searchTerm, selectedCategories]);

  const listFilters = React.useMemo<MultiSelectDropdownProps[]>(
    () => [
      {
        name: 'categoryFilter',
        label: localize('filters.listFilters.category'),
        items: eventCategories.map(({ id, titleFI, titleEN }) => ({
          value: String(id),
          label: locale === 'fi' ? titleFI : titleEN,
        })),
        onChange: setSelectedCategories,
        value: selectedCategories,
        variant: 'primary',
      },
    ],
    [eventCategories, selectedCategories, setSelectedCategories],
  );

  const { filteredData } = useFilteredData({
    data: isDomAvailable() ? sortedData : [],
    filters,
  });

  const onResetFiltersClick = React.useCallback(() => {
    navigate(`${location.pathname}`, {
      state: { disableScrollUpdate: true },
    });
  }, [location.pathname]);

  return (
    <main>
      {sections?.map((section: ContentfulSectionContainerTypes) => (
        <ContentfulSectionContainer key={section.id} {...section} />
      ))}
      <SearchPageGrid noAside>
        <Styled.MainSection>
          <Typography.Display>{localize('events.headline')}</Typography.Display>

          <Styled.PrimaryFilterDateOnly>
            <AvailabilitySearchForm
              selectedRange={
                dateParamsToDateRange(dates) as Required<DateRange> | undefined
              }
              onSelectedDateRangeChange={(range) =>
                range && isValidDateRange(range)
                  ? setDates([encodeDateRangeParams(range)])
                  : setDates([])
              }
            />
          </Styled.PrimaryFilterDateOnly>

          <SearchFilters
            filters={listFilters}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            onResetClick={onResetFiltersClick}
          />

          <CardGridHeader
            title={localize('searchResults.seachResultsTitle')}
            count={filteredData.length}
          ></CardGridHeader>
          <CardGrid cards={filteredData} columns={3} mobileScrollable={false} />
        </Styled.MainSection>
      </SearchPageGrid>
    </main>
  );
};

export default EventsPage;

export const contentfulPageQuery = graphql`
  query EventPageById($id: String!, $locale: String!) {
    contentfulPage(id: { eq: $id }) {
      heroCarousel {
        ...contentfulHeroFields
      }
      meta {
        ...contentfulPageMetaFields
      }
      sections {
        ...allPageSections
      }
    }

    events: allContentfulEvent(
      filter: { node_locale: { eq: $locale }, isPast: { eq: false } }
    ) {
      edges {
        node {
          ...eventCard
        }
      }
    }
  }
  fragment eventCard on ContentfulEvent {
    ...contentfulBaseEventFields
    image {
      ...contentfulCardImage
    }
    internal {
      type
    }
    categories
    pageLink {
      # If event has this field, card link will direct to this page
      id
    }
    hideFromLocalization
  }
`;
