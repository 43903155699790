import { CardType } from '../../../components/common/card/Card';
import { Theme } from '../../../styles/themes';
import { DataTransformer } from '../cardDataTransformers';
import { resolveLinks, trimLeadingWhiteSpace } from './utils';

const BlogPostFieldsTransformer: DataTransformer<CardType.Default> = (
  props,
) => {
  const blogPost = props.hasOwnProperty('node') ? props.node : props;
  const { id, title, image, description, horizontalCardImage, hideFromLocalization } = blogPost;
  const cardImage = horizontalCardImage || image;

  const links = resolveLinks(id, 'blogPosts');
  const callToAction = links ? links[0] : undefined;
  return {
    type: CardType.Default,
    id,
    title: trimLeadingWhiteSpace(title),
    image: cardImage,
    description: description?.ingress || '',
    callToAction,
    theme: Theme.Primary,
    hideFromLocalization
  };
};

export default BlogPostFieldsTransformer;
