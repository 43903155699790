import React from 'react';
import { LocalizationContext } from './LocalizationContext';

const useLocale = () => {
  const { locale } = React.useContext(LocalizationContext);

  return locale;
};

export default useLocale;
