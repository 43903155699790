import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Typography } from '../typography/Typography.styled';
import { Theme } from '../../../styles/themes';
import ThemeContainer from '../ThemeContainer';
import { Icons } from '../../../types/icon-types';
import Icon, { IconSize } from '../icon/Icon';
import Portal from '../portal/Portal';
import ContentfulCallToAction, {
  ContentfulCallToActionProps,
} from '../../../contentful/callToActions/contentfulCallToAction';
import { ContentfulCallToActionTypes } from '../../../contentful/callToActions/types';
import useLocale from '../../../localization/useLocale';
import useHtmlAst from '../../../hooks/useHtmlAst';
import { ContentfulMarkdownTypes } from '../../../contentful/templates/types';
import CardStyled from '../card/Card.styled';
import Styled from './LeviBlackCard.styled';
import { LeviBlackCardSize } from './LeviBlackCardSize';
import { LeviBlackCardPosition } from './LeviBlackCardPosition';
import { LeviBlackCardTooltipText } from './LeviBlackCardTooltipText';

// Get the modal content from the contentfulSite instance.
const query = graphql`
  query LeviBlackCardQuery {
    fi: contentfulSite(
      contentful_id: { eq: "1guLuNt5b3mO9jB13rrnfR" }
      node_locale: { eq: "fi" }
    ) {
      leviBlackCardModalContent {
        ...contentfulLeviBlackCardContent
      }
    }
    en: contentfulSite(
      contentful_id: { eq: "1guLuNt5b3mO9jB13rrnfR" }
      node_locale: { eq: "en" }
    ) {
      leviBlackCardModalContent {
        ...contentfulLeviBlackCardContent
      }
    }
  }

  fragment contentfulLeviBlackCardContent on ContentfulCard {
    title
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
    callToAction {
      id
      title
      destination {
        ... on ContentfulPage {
          pageId: id
        }
        ... on ContentfulExternalUrl {
          externalUrl: url
        }
      },
      target
    }
  }
`;

type LeviBlackCardModalContentProps = {
  handleModalClose: React.EventHandler<any>;
}

type ContentfulLeviBlackCardModalContentTypes = {
  title: string;
  body: ContentfulMarkdownTypes;
  callToAction: ContentfulCallToActionTypes;
};

type SiteLeviBlackCardModalContentQueryTypes = {
  fi: {
    leviBlackCardModalContent: ContentfulLeviBlackCardModalContentTypes;
  };
  en: {
    leviBlackCardModalContent: ContentfulLeviBlackCardModalContentTypes;
  };
};

const LeviBlackCardModalContent = ({
  handleModalClose
}: LeviBlackCardModalContentProps): JSX.Element => {
  const site: SiteLeviBlackCardModalContentQueryTypes = useStaticQuery(query);
  const locale = useLocale();

  const { leviBlackCardModalContent } = site[locale];
  const { title } = leviBlackCardModalContent;
  const bodyText = useHtmlAst(leviBlackCardModalContent?.body?.childMarkdownRemark?.htmlAst);
  const callToActionProps = { ...leviBlackCardModalContent.callToAction, type: 'button' };

  return (
    <CardStyled.Card
      theme={Theme.Primary}
      $hasLink={true}
      role="dialog"
    >
      <Styled.LeviBlackCardModalImageContainer>
        <Styled.LeviBlackCardModalCloseButton onClick={handleModalClose}>
          <Icon type={Icons.Close}/>
        </Styled.LeviBlackCardModalCloseButton>
        <CardStyled.CardImageWrapper>
          <Styled.LeviBlackCard />
        </CardStyled.CardImageWrapper>
      </Styled.LeviBlackCardModalImageContainer>
      <CardStyled.CardContent>
        <CardStyled.CardHeading
          centered={true}
          icon={undefined}
        >
          <Typography.HeadingLarge>{title}</Typography.HeadingLarge>
        </CardStyled.CardHeading>
        {bodyText && (
          <Typography.BodySmall
            className="contentfulMarkdown"
            as="div"
          >
            {bodyText}
          </Typography.BodySmall>
        )}
      </CardStyled.CardContent>
      <ThemeContainer theme={Theme.Primary}>
        <CardStyled.CardFooter>
          <ContentfulCallToAction {...callToActionProps} />
        </CardStyled.CardFooter>
      </ThemeContainer>
    </CardStyled.Card>
  );
};

export type LeviBlackCardProps = {
  size?: LeviBlackCardSize;
  position?: LeviBlackCardPosition;
};

const LeviBlackCard = ({
  size = LeviBlackCardSize.Small,
  position = LeviBlackCardPosition.Middle,
}): JSX.Element => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const locale = useLocale();
  const tooltipText = LeviBlackCardTooltipText[locale];

  const handleModalOpen = React.useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    setShowModal(true);
    document.addEventListener('keydown', (event) => closeModalOnEsc(event));
  }, [setShowModal]);

  const handleModalClose = React.useCallback((event?: React.PointerEvent) => {
    event?.preventDefault();
    setShowModal(false);
    document.removeEventListener('keydown', closeModalOnEsc)
  }, [setShowModal]);

  const closeModalOnEsc = (event: any) => {
    if (event.key === 'Escape') {
      handleModalClose();
    }
  }

  const closeModalOnClickOutside = (event: any) => {
    event.stopPropagation();
    handleModalClose();
  }

  return (
    <Styled.LeviBlackCardWrapper
      size={size}
      position={position}
    >
      <Styled.LeviBlackCard
        alt="Levi Black Card"
        onClick={(event) => handleModalOpen(event)}
      />
      <Styled.LeviBlackCardTooltip>
        <Typography.LabelSmall>{tooltipText}</Typography.LabelSmall>
      </Styled.LeviBlackCardTooltip>
      {showModal && (
        <Portal>
          <Styled.LeviBlackCardModalWrapper onClick={closeModalOnClickOutside}>
            <Styled.LeviBlackCardModal onClick={(e) => e.stopPropagation()}>
              <LeviBlackCardModalContent handleModalClose={handleModalClose} />
            </Styled.LeviBlackCardModal>
          </Styled.LeviBlackCardModalWrapper>
        </Portal>
      )}
    </Styled.LeviBlackCardWrapper>
  )
}

export default LeviBlackCard;
