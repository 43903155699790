import React, { ReactNode } from 'react';
import { Link } from "@Levi/components/common/link";
import useSitePageData from '../../hooks/useSitePageData';

type InternalLinkProps = {
  pageId: string;
  target: 'self' | 'tab';
  children: ReactNode;
  className?: string;
};

const InternalLink: React.FC<InternalLinkProps> = ({
  pageId,
  target,
  children,
  className,
}) => {
  const sitePageData = useSitePageData();
  const path = sitePageData.get(pageId);

  if (!path) {
    console.warn('No path found for internal link', pageId);
    return null;
  }

  if (target === 'tab') {
    return (
      <a
        href={path}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }

  return (
    <Link to={path} className={className}>
      {children}
    </Link>
  );
};

export default InternalLink;
