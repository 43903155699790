import { GatsbyImageProps } from 'gatsby-plugin-image';
import {
  ChildImageSharpProp,
  GatsbyImageDataProp,
} from '../components/common/horizontal-card/types';
import { ContentfulGatsbyImage } from '../types/images';

export const isIGatsbyImageData = (
  source: ContentfulGatsbyImage | GatsbyImageDataProp | ChildImageSharpProp,
): source is GatsbyImageDataProp =>
  !!(source as GatsbyImageDataProp).gatsbyImageData;

export const isChildImageSharp = (
  source: ContentfulGatsbyImage | GatsbyImageDataProp | ChildImageSharpProp,
): source is ChildImageSharpProp =>
  !!(source as ChildImageSharpProp).childImageSharp;

const useContentfulGatsbyImage = (
  source?: ContentfulGatsbyImage | GatsbyImageDataProp | ChildImageSharpProp,
): GatsbyImageProps | undefined => {
  if (!source) return undefined;
  if (isIGatsbyImageData(source)) {
    return {
      alt: '',
      image: source.gatsbyImageData,
    };
  }
  if (isChildImageSharp(source)) {
    return {
      alt: '',
      image: source.childImageSharp.image,
    };
  }

  const { image, description, title } = source;

  if (!image) {
    return undefined;
  }

  return {
    alt: description || title || '',
    image,
  };
};

export default useContentfulGatsbyImage;
