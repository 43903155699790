import { Link } from "@Levi/components/common/link";
import React from 'react';
import styled from 'styled-components';
import Button from '../../components/common/button/Button';
import { Typography } from '../../components/common/typography/Typography.styled';
import Section from '../../components/section/Section';

export type NotFoundPageProps = {
  title: string;
  body: string;
  link: {
    label: string;
    to: string;
  };
};

// TODO: styling
const ContentWrapper = styled.div`
  max-width: var(--max-content-width);
  display: flex;
  flex-flow: column nowrap;
  row-gap: 2rem;
  align-items: center;
  padding-top: 2rem;
  width: 100%;
  margin: 0 auto;
`;

const NotFoundPageTemplate: React.FC<NotFoundPageProps> = ({
  title,
  body,
  link,
}) => {
  return (
    <main>
      <Section>
        <ContentWrapper>
          <Typography.Display>{title}</Typography.Display>
          <Typography.BodyLarge>{body}</Typography.BodyLarge>
          <Link to={link.to}>
            <Button>{link.label}</Button>
          </Link>
        </ContentWrapper>
      </Section>
    </main>
  );
};

export default NotFoundPageTemplate;
