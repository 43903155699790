import React from 'react';
import { graphql } from 'gatsby';
import HighlightWithRelatedListing from '../../components/liftup/HighlightWithRelatedListing';
import { ContentfulGatsbyImage } from '../../types/images';
import { ContentfulCallToActionTypes } from '../callToActions/types';
import { HighlightListItem } from '../../components/liftup/HighlightItem';
import { eventFieldsTransformer } from '../data/transformers';
import { EventFields } from '../templates/eventPages/eventsPage';
import { CardProps, CardType } from '../../components/common/card/Card';

export type ContentfulHighlightItemListTypes = {
  id: string;
  internal: {
    type: string;
  };
  title: string;
  items: ContentfulHighlightItemsTypes[];
};

export type ContentfulHighlightItemsTypes = {
  id: string;
  title: string;
  body: {
    text: string;
  };
  image: ContentfulGatsbyImage;
  callToAction: ContentfulCallToActionTypes | undefined;
  relatedCards: EventFields[];
};

const ContentfulHighlightItemList = ({
  title,
  items = [],
}: ContentfulHighlightItemListTypes): JSX.Element => {
  const highlightListItems: HighlightListItem[] = React.useMemo(() => {
    const transformer = eventFieldsTransformer;
    return items.map((item) => ({
      ...item,
      body: item.body?.text,
      relatedCards: item.relatedCards?.map(
        (card) => transformer({ node: card }) as CardProps<CardType.Event>,
      ),
    }));
  }, [items]);

  return (
    <HighlightWithRelatedListing title={title} items={highlightListItems} />
  );
};

export default ContentfulHighlightItemList;

export const contentfulHighlightItemListQuery = graphql`
  fragment contentfulHighlightItemListFields on ContentfulHighlightItemList {
    id
    internal {
      type
    }
    title
    items {
      ...contentfulHightlightItem
    }
    locale: node_locale
  }
  fragment contentfulHightlightItem on ContentfulHighlightItem {
    id
    title
    body {
      # for now we use body as a text not as a markdown
      text: body
    }
    image {
      image: gatsbyImageData(width: 427, aspectRatio: 0.935, breakpoints: [427])
      description
      title
    }

    callToAction {
      ...contentfulCallToActionFields
    }
    relatedCards: cards {
      # only events for now but other types can be added later
      ...contentfulEventCard
    }
  }

  fragment contentfulEventCard on ContentfulEvent {
    id
    name
    ingress
    image {
      ...contentfulCardImage
    }
    startDate
    endDate
  }
`;
