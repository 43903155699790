import { createGlobalStyle } from 'styled-components';
import debugLayoutGrid from './global/debugLayoutGrid';
import innerHtmlElements from './global/innerHtmlElements';
import layoutGrid from './global/layoutGrid';
import portal from './global/portal';
import reset from './global/reset';
import responsiveFontSize from './global/responsiveFontSize';
import theming from './global/theming';

const DEBUG = false;

const GlobalStyle = createGlobalStyle`
  ${DEBUG && debugLayoutGrid}
  
  ${reset}
  ${responsiveFontSize}
  ${theming}
  ${layoutGrid}
  ${innerHtmlElements}
  ${portal}
`;

export default GlobalStyle;
